import React, {
  FunctionComponent, ReactNode, ReactChild, useState,
} from 'react';
import IconButton from '../IconButton';
import Icons from '../Icons';
import { ThemeProp } from '../../types/index';

type ResultsCardProps = {
  theme: ThemeProp,
  className?: string,
  hideTitle?: boolean,
  expandableContents?: ReactChild|ReactNode,
  title?: string|ReactNode,
  withBorder?: boolean,
  children?: ReactChild|ReactNode,
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>|null) => void|null,
  onDownload?: (event?: React.MouseEvent<HTMLElement, MouseEvent>|null) => void|null|Promise<void>,
  isDownloadDisable?: boolean,
  downloadLoading?: boolean,
  isExpanded?: boolean,
};
const ResultsCard: FunctionComponent<ResultsCardProps> = ({
  className = '',
  title = null,
  expandableContents = null,
  theme = 'light',
  withBorder = true,
  children = null,
  onClose = null,
  onDownload = null,
  isDownloadDisable = true,
  downloadLoading = false,
  isExpanded = false,
}) => {
  const [open, toggleOpen] = useState(isExpanded);
  const {
    ExpandIcon, CollapseIcon, CloseIcon, DownloadIcon,
  } = Icons;
  return (
    <div className={`${className} ${theme}`} data-test="results-card">
      <div className={`results-card ${withBorder ? 'include-border' : ''}`}>
        <header className="results-card-header">
          { title && (<h3 className="title">{title}</h3>)}
          <div className="button-menu">
            { onDownload && (
              <IconButton
                onClick={onDownload}
                theme={theme}
                className="download-icon"
                disabled={isDownloadDisable}
                loading={downloadLoading}
              >
                <DownloadIcon />
              </IconButton>
            )}
            { onClose && (
              <IconButton
                onClick={onClose}
                theme={theme}
                className="close-icon"
              >
                <CloseIcon />
              </IconButton>
            )}
            {expandableContents && (
              <IconButton
                onClick={() => toggleOpen(!open)}
                theme={theme}
                className="expand-icon"
              >
                {open ? <CollapseIcon color="#95A6B8" /> : <ExpandIcon color="#95A6B8" />}
              </IconButton>
            )}
          </div>
        </header>
        {children && (
          <div className="content">
            {children}
          </div>
        )}
        {expandableContents && (
          <div className="results-expandable-section" style={{ display: open ? 'block' : 'none' }}>
            {expandableContents}
          </div>
        )}
      </div>
    </div>
  );
};

export default ResultsCard;
