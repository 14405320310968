import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import TextInput from 'components/TextInput';
import NumberInput from 'components/NumberInput';
import { isDefined } from 'helpers/utils';
import { gramPerkWh, scaleFactorForUnit } from 'helpers/units';

import Helpers from '../../helpers/EquipmentLibraryHelpers';
import {
  areAssetModelPropertiesChanged,
  areAssetModelsEqual,
  defaultAssetModel,
  getAssetModelProperties,
  isAssetModelValid,
} from '../../helpers/assetModelHelpers';
import SelectRow from '../SelectRow';
import DescriptionEditor from './DescriptionEditor';
import CostEditor from './CostEditor';
import PanelTabs from './PanelTabs';

import './common.scss';

const getNewThermalGenerator = () => ({
  name: '',
  description: '',
  fossilFuelType: '',
  fuelHeatContent: '',
  assetModel: { ...defaultAssetModel },
  id: 'add',
});

class ThermalGeneratorPanel extends Component {
  fuelTypes = [
    { value: 'gas', label: 'Natural Gas' },
    { value: 'oil', label: 'Fuel Oil' },
  ]

  state = { ...(getNewThermalGenerator()) }

  UNSAFE_componentWillMount() {
    this.setState({ ...this.getInputValues(this.props.selected) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.selected !== nextProps.selected) {
      this.setState({ ...this.getInputValues(nextProps.selected) });
    }
  }

  getInputValues = (selected) => {
    let asset = { ...(getNewThermalGenerator()) };
    if (selected) {
      const {
        name, description, fossilFuelType, id, fuelHeatContent, AssetModel,
      } = selected;
      asset = {
        name: name || asset.name,
        description: description || asset.description,
        fossilFuelType: fossilFuelType || asset.fossilFuelType,
        fuelHeatContent: `${fuelHeatContent}` || asset.fuelHeatContent,
        assetModel: isDefined(AssetModel) ? getAssetModelProperties(AssetModel) : asset.assetModel,
        id,
      };
    }
    return asset;
  }

  handleSave = () => {
    const diffModel = {
      name: this.state.name,
      description: this.state.description,
      FossilFuels: [{
        fossilFuelType: this.state.fossilFuelType,
        fuelHeatContent: parseFloat(this.state.fuelHeatContent),
      }],
      AssetModel: {
        ...this.state.assetModel,
        EmissionRate: {
          emissionRate:
          parseFloat(this.state.assetModel.EmissionRate.emissionRate * scaleFactorForUnit(gramPerkWh)),
        },
      },
    };
    if (this.state.id === 'add') {
      this.props.handleCreate('thermal_generating_unit_info', diffModel, 'ThermalGeneratingUnitInfo');
    } else {
      // Only submit the values that have changed
      const { selected } = this.props;
      const keys = Object.keys(diffModel);
      const editDiffModel = keys.reduce((diff, key) => {
        switch (key) {
          case 'name':
          case 'description':
            if (diffModel[key] !== selected[key]) {
              diff[key] = diffModel[key];
            }
            return diff;
          case 'FossilFuels':
            const fuelUpdated = ['fossilFuelType', 'fuelHeatContent'].some(type => (
              diffModel[key][0][type] !== selected[type]
            ));
            if (fuelUpdated) {
              if (this.props.selected.fossilFuelId) {
                diffModel[key][0].UUID = this.props.selected.fossilFuelId;
              }
              diff[key] = diffModel[key];
            }
            return diff;
          case 'AssetModel':
            if (!areAssetModelsEqual(diffModel[key], selected[key])) {
              diff[key] = diffModel[key];
            }
            return diff;
          default:
            return diff;
        }
      }, {});
      this.props.handleEdit(selected.id, editDiffModel);
    }
  }

  handleInputChange = ({ id, value }) => this.setState({ [id]: value });

  handleOnChange = prop => this.setState(prevState => ({
    assetModel: {
      ...prevState.assetModel,
      EmissionRate: {
        ...prevState.assetModel.EmissionRate,
        ...prop,
      },
    },
  }))

  valuesUpdated = (selected) => {
    if (!selected) return true;
    const stringUpdated = ['name', 'description', 'fossilFuelType'].some(val => selected[val] !== this.state[val]);
    const numberUpdated = ['fuelHeatContent']
      .some(val => selected[val] !== parseFloat(this.state[val]));
    const assetModelUpdated = !areAssetModelsEqual(
      getAssetModelProperties(selected.AssetModel),
      this.state.assetModel,
    );
    return stringUpdated || numberUpdated || assetModelUpdated;
  }

  formValid = () => {
    const postiveNonZeroValue = val => (val?.toString().length ?? 0) > 0 && parseFloat(val) > 0;
    return (
      this.state.name.trim().length > 0
      && this.state.fossilFuelType.length > 0
      && postiveNonZeroValue(this.state.fuelHeatContent)
      && isAssetModelValid(this.state.assetModel)
      && this.valuesUpdated(this.props.selected)
    );
  };

  render() {
    const fossilFuelType = Helpers.createDisplayObject('Fuel Type', 'fossilFuelType', this.state.fossilFuelType);
    const fuelHeatContent = Helpers.createDisplayObject(
      'Fuel Heat Content',
      'fuelHeatContent',
      this.state.fuelHeatContent,
      <span>
        W
        <sub>t</sub>
        /L
      </span>,
    );
    const emissionRate = Helpers.createEmissionRate(this.state.assetModel.EmissionRate.emissionRate);

    const isDisabled = (
      this.props.isAuthEnabled
      && ((this.state.id === 'add' && !this.props.permissions.has('create_equipment_type'))
      || (this.state.id !== 'add' && !this.props.permissions.has('edit_equipment_type'))
      || (this.props.match.params.branch === 'master' && !this.props.permissions.has('modify_network_as_built')))
    );

    const defaultProps = {
      disabled: isDisabled,
      theme: this.props.theme,
    };

    return (
      <PanelTabs
        submitDisabled={!this.formValid() || isDisabled}
        onSubmit={this.handleSave}
        createInstanceReq={this.props.createInstanceReq}
        tabs={['General', 'Description', 'Costs']}
        assetID={this.state.id}
        showSave
      >
        {[
          <div className="equipment-info-container" key={this.state.id}>
            <div className="right-panel">
              <TextInput
                {...defaultProps}
                id="name"
                required
                label="Name"
                value={this.state.name}
                onChange={({ target }) => this.handleInputChange({ id: target.id, value: target.value })}
              />
              <SelectRow
                {...fossilFuelType}
                options={this.fuelTypes}
                disabled={isDisabled}
                onChange={e => this.setState({ [fossilFuelType.id]: e.value })}
                theme={this.props.theme}
                isRequired
              />
              <NumberInput
                onChange={this.handleInputChange}
                ge={0}
                posNonZero
                step="0.001"
                {...defaultProps}
                {...fuelHeatContent}
                required
                inputStyle="eq-lib"
              />
              <NumberInput
                onChange={({ id, value }) => this.handleOnChange({ [id]: value })}
                type="number"
                ge={0}
                step="0.001"
                {...defaultProps}
                {...emissionRate}
                inputStyle="eq-lib"
              />
            </div>
            <div className="column">
              <h2 className="column-title">Description</h2>
              <div className="markdown-body">
                <Markdown
                  escapeHtml
                  source={decodeURIComponent(this.state.description)}
                />
              </div>
            </div>
          </div>,
          <DescriptionEditor
            description={this.state.description}
            key={`${this.state.id}-description`}
            onChange={d => this.setState({ description: d })}
            isDisabled={isDisabled}
          />,
          <CostEditor
            assetModel={this.state.assetModel}
            assetModelDiff={areAssetModelPropertiesChanged(
              this.state.assetModel,
              getAssetModelProperties(this.props.selected?.AssetModel),
            )[1]}
            includeCommunityCredit
            key={`${this.state.id}-costs`}
            onChange={prop => this.setState(prevState => ({
              assetModel: { ...prevState.assetModel, ...prop },
            }))}
            isDisabled={isDisabled}
            theme={this.props.theme}
          />,
        ]}
      </PanelTabs>
    );
  }
}

ThermalGeneratorPanel.defaultProps = {
  selected: null,
};

ThermalGeneratorPanel.propTypes = {
  handleCreate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  createInstanceReq: PropTypes.number.isRequired,
  selected: PropTypes.object,
  isAuthEnabled: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
};

export default ThermalGeneratorPanel;
