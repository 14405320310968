import React, {
  FunctionComponent, useContext, useState,
} from 'react';
import Button from 'components/Button';
import moment from 'moment';
import { Request } from '@opusonesolutions/gridos-app-framework';
import FileSaver from 'file-saver';

import ThemeContext from 'helpers/ThemeContext';
import './AnalysisLogs.scss';
import HelpTooltip from 'components/HelpTooltip';

interface SubjobAdditionalInfo {
  report_file?: string,
}

interface JobType {
  additional_info: SubjobAdditionalInfo,
  job_id: string,
  status: string,
  last_changed: string,
}

type PowerflowReportButtonProps = {
  event: JobType,
  workspace: string,
  branch: string,
}

const PowerflowReportButton: FunctionComponent<PowerflowReportButtonProps> = ({
  event,
  workspace,
  branch,
}) => {
  const theme = useContext(ThemeContext);
  const [downloading, setDownloading] = useState(false);
  const createdDate = moment.utc(event.last_changed);
  const expiryDate = moment.utc(createdDate).add(14, 'days');

  const downloadReport = async () => {
    setDownloading(true);
    const urlRequest = new Request(`/api/workspace/${workspace}/branch/${branch}/activity-log/${event.job_id}/report-download-url`);
    try {
      const response = await urlRequest.get();
      if (response.data.download_path) {
        FileSaver.saveAs(`/${response.data.download_path}`, 'Powerflow Report.zip');
      }
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        disabled={!event.additional_info.report_file || downloading}
        loading={downloading}
        onClick={downloadReport}
        theme={theme}
        type="text"
      >
        {!event.additional_info.report_file ? 'Report Expired' : 'Download Report'}
      </Button>
      <HelpTooltip message={(
        <p>
          {`This report was created on ${createdDate.format('LL')} and expires on ${expiryDate.format('LL')}.`}
          <br />
          Once expired you will be required to recreate the report to download it.
        </p>
      )}
      />
    </div>
  );
};

export default PowerflowReportButton;
