/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Card from 'components/Card';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomScrollBar from 'components/CustomScrollBar';
import Tooltip from 'components/Tooltip';
import TextInput from 'components/TextInput';
import DragDrop from 'components/DragDrop';
import Select from 'components/Select';
import VirtualizedReactSelect from 'components/VirtualizedSelect';
import asyncActionStates from 'helpers/asyncActionStates';
import TopNav from 'components/TopNav';
import './ImportCIM.scss';
import FileUploadStatus from './FileUploadStatus';
import coordinateSystemsData from '../coordinate_systems.json';

const importOptions = [
  { label: 'CIM16 XML', value: 'cim' },
  { label: 'SEQ & STD', value: 'seq_std' },
  { label: 'MDB Files', value: 'mdb' },
  { label: 'Other', value: 'other' },
  { label: 'Excel', value: 'excel' },
  { label: 'CSV', value: 'csv' },
];

const csvFileNames = [
  'Source.csv', 'Nodes.csv', 'Lines.csv', 'PositionPoints.csv', 'Batteries.csv', 'Capacitors.csv', 'EVChargers.csv',
  'Transformers.csv', 'ProtDevices.csv', 'SyncGenerators.csv', 'AsyncGenerators.csv', 'Equip_Conductors.csv',
  'ConductorGeometry.csv', 'Equip_ProtDevices.csv',
];

const coordinateSystems = coordinateSystemsData.map(coordinate => ({
  label: coordinate.label,
  value: coordinate.value,
}));
class ImportCIM extends Component {
  state = {
    workspaceName: this.props.match.params.workspace || '',
    workspaceDisabled: false,
    invalidWorkspaceName: this.props.match.params.workspace === undefined,
    createEmpty: false,
    importMode: 'cim',
  }

  componentWillUnmount() {
    this.props.actions.clearFileDetails();
  }

  getButtonLabel = () => (this.state.createEmpty ? 'Finish' : 'Upload')

  getFileExtensionForType = (importMode) => {
    if (importMode === 'cim') {
      return ['xml'];
    } if (importMode === 'seq_std') {
      return ['std', 'seq'];
    } if (importMode === 'mdb') {
      return ['mdb'];
    } if (importMode === 'csv') {
      return ['csv'];
    } if (importMode === 'excel') {
      return ['xls', 'xlsx', 'xlsm'];
    }

    return '*.*';
  }

  checkExtensions = (fileNames, mode) => {
    if (mode === 'cim' || mode === 'other') {
      return true;
    }
    const std_count = fileNames.filter(element => element.endsWith('seq')).length;
    const seq_count = fileNames.filter(element => element.endsWith('std')).length;
    const mdb_count = fileNames.filter(element => element.endsWith('mdb')).length;
    const excel_count = fileNames.filter(element => element.endsWith('xls') + element.endsWith('xlsx') + element.endsWith('xlsm')).length;
    const csv_count = fileNames.filter(element => element.endsWith('csv')).length;

    if (mode === 'seq_std' && std_count === 1 && seq_count === 1) {
      return true;
    }
    if (mode === 'mdb' && mdb_count === 2) {
      return true;
    }
    if (mode === 'excel' && excel_count === 1) {
      return true;
    }
    if (mode === 'csv' && csv_count >= 1) {
      return true;
    }
    return false;
  }

  checkFileNames = (fileNames, mode) => {
    if (mode !== 'csv') {
      return true;
    }
    const incorrect_filenames = fileNames.filter(element => !csvFileNames.includes(element)).length;
    if (incorrect_filenames > 0) {
      return false;
    } return true;
  }

  checkRequiredNumFiles = (selectedMode, numUploaded) => {
    if (selectedMode.value === 'seq_std' && numUploaded !== 2) {
      return false;
    } if (selectedMode.value === 'mdb' && numUploaded !== 2) {
      return false;
    } if (selectedMode.value === 'excel' && numUploaded !== 1) {
      return false;
    } if (selectedMode.value === 'csv' && (numUploaded > 14 || numUploaded === 0)) {
      return false;
    }
    return true;
  }

  /**
   * The a change or drop event on a file input form and sorts the data into
   * processable and non-processable lookups. There are used to either display
   * errors or to send the files to the orchestrator for processing.
   */
  handleFileSelection = (e) => {
    if ((!e.target || !e.target.files) && !e.dataTransfer.files) return;

    const files = e.target && e.target.files.length ? e.target.files : e.dataTransfer.files;
    const fileIndexes = Object.keys(files).filter(key => key !== 'length');
    const processedFiles = {};
    const expectedExtension = this.getFileExtensionForType(this.state.importMode);
    fileIndexes.forEach((index) => {
      // If file is the correct type, include it for processing.
      // If file is incorrect type, add to invalid files lookup.
      const file_extension = files[index].name.split('.').pop();
      if (expectedExtension === '*.*' || expectedExtension.includes(file_extension)) {
        const formData = new FormData();
        formData.append(files[index].name, files[index]);
        processedFiles[files[index].name] = { status: 'loading', formData, file: files[index] };
      } else {
        processedFiles[files[index].name] = { status: 'invalid', file: files[index] };
      }
    });
    this.props.actions.addToFileList(processedFiles);
  };

  handleChangeWorkspaceName = (e) => {
    const workspaceName = e.target.value;
    this.setState({
      workspaceName,
      invalidWorkspaceName: (
        workspaceName.length === 0
        || workspaceName.match(/[/\\!@#$%^&*(),.?":{}|<>\s]/g) !== null
      ),
    });
  }

  handleFinishClick = () => {
    if (this.state.createEmpty) {
      this.props.actions.createEmptyWorkspace(this.state.workspaceName);
    } else if (this.state.coordinateSystem) {
      this.props.actions.beginNetworkImport(
        this.props.files,
        this.state.workspaceName,
        this.props.match.params.branch,
        this.state.importMode,
        this.state.coordinateSystem,
      );
    } else {
      this.props.actions.beginNetworkImport(
        this.props.files,
        this.state.workspaceName,
        this.props.match.params.branch,
        this.state.importMode,
      );
    }

    this.setState({ workspaceDisabled: true });
  }

  handleModeChange = (e) => {
    if (e.value === 'mdb' || e.value === 'seq_std') {
      this.setState({ coordinateSystem: 'urn:ogc:def:crs:EPSG:6.3:3760' });
    }
    this.setState({ importMode: e.value },
      () => {
        if (Object.keys(this.props.files).length) {
          // Re-validate any already uploaded files.
          const rawFiles = Object.values(this.props.files).reduce(
            (files, { file }) => [...files, file],
            [],
          );
          if (rawFiles.length) {
            this.handleFileSelection({ target: { files: rawFiles } });
          }
        }
      });
  }

  render() {
    const { workspaceName, invalidWorkspaceName } = this.state;
    const {
      match: {
        params: { workspace: existingWorkspace, branch },
      },
      files,
      importErrors,
      uploadInProgress,
      theme,
      createWorkspaceStatus,
    } = this.props;
    const branchName = branch || 'master';
    const fileNames = Object.keys(files);
    const hasFiles = fileNames.length !== 0;
    const hasInvalidFiles = Object.values(files).some(file => file.status === 'invalid');
    const invalidEmpty = workspaceName.length === 0 || invalidWorkspaceName || uploadInProgress;
    const showWorkspaceError = createWorkspaceStatus === asyncActionStates.ERROR;
    const showUploadError = Object.keys(importErrors).length > 0;
    const selectedMode = importOptions.find(option => option.value === this.state.importMode);
    const hasRequiredNumFiles = this.checkRequiredNumFiles(selectedMode, fileNames.length);
    const fileExtensions = this.getFileExtensionForType(this.state.importMode);
    const hasCorrectExtensions = this.checkExtensions(
      fileNames, selectedMode.value,
    );
    const hasCorrectFileNames = this.checkFileNames(fileNames, selectedMode.value);
    const invalidWithFiles = invalidEmpty || hasInvalidFiles || !hasFiles
    || !hasRequiredNumFiles || !hasCorrectExtensions || !hasCorrectFileNames;

    return (
      <div className={`import-cim-container ${theme}`}>
        <CustomScrollBar>
          <TopNav label="Back" />
          <div className="page-contents">
            <Card className="import-cim-modal" hideTitle theme={theme}>
              <div className="import-cim-modal-contents">
                <h1>{existingWorkspace ? 'Upload Network Model' : 'Create Workspace'}</h1>

                {!existingWorkspace
                  && (
                  <div className="workspace-name">
                    <TextInput
                      value={workspaceName}
                      onChange={this.handleChangeWorkspaceName}
                      theme={theme}
                      invalid={this.state.invalidWorkspaceName}
                      setFocus
                      validationMessage="Invalid Workspace Name"
                      label="Workspace Name"
                      disabled={this.state.workspaceDisabled}
                      id="workspace"
                    />
                    <div className="checkbox-row">
                      <Tooltip content={hasFiles ? 'Remove selected files to create an empty workspace' : ''} placement="top">
                        <CustomCheckbox
                          id="empty-workspace"
                          checked={this.state.createEmpty}
                          onClick={() => this.setState(prevState => ({ createEmpty: !prevState.createEmpty }))}
                          disabled={hasFiles}
                        />
                      </Tooltip>
                      <label htmlFor="empty-workspace">Create Empty Workspace</label>
                    </div>
                  </div>
                  )}
                <div className="upload-container">
                  <span>What type of file(s) would you like to upload?</span>
                  <Select
                    className="mode-select"
                    options={importOptions}
                    value={selectedMode.value}
                    onChange={this.handleModeChange}
                    searchable={false}
                    clearable={false}
                    disabled={false}
                    theme={theme}
                  />
                </div>
                {(this.state.importMode === 'mdb' || this.state.importMode === 'seq_std')
                && (
                <div className="upload-container">
                  <span>Select the coordinate system used in the model:</span>
                  <VirtualizedReactSelect
                    options={coordinateSystems}
                    value={this.state.coordinateSystem}
                    onChange={d => this.setState({ coordinateSystem: d.value })}
                    clearable={false}
                    disabled={false}
                    theme={theme}
                  />
                </div>
                )}
                {(this.state.importMode === 'csv' || this.state.importMode === 'excel')
                && (
                  <span>
                    Documentation and sample templates located
                    {' '}
                    <a
                      href="/documentation/network-models/excel-cim"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      here
                    </a>
                  </span>
                )}
                {(this.state.importMode === 'other')
                  && (
                  <div className="email-upload-container">
                    <span>
                      For
                      {' '}
                      {selectedMode.label}
                      {' '}
                      files,
                      Opus One Solutions will import these on your behalf.
                      <br />
                      Simply attach your files below and we will let you
                      know once they are available!
                    </span>
                  </div>
                  )}
                {(this.state.importMode === 'mdb')
                  && (
                  <div className="email-upload-container">
                    <span>
                      Upload the instance database (.mdb file):
                      {' '}
                      <br />
                    </span>
                  </div>
                  )}
                {(this.state.importMode === 'seq_std')
                  && (
                  <div className="email-upload-container">
                    <span>
                      Upload the .seq file:
                      {' '}
                      <br />
                    </span>
                  </div>
                  )}
                <div className="file-container">
                  <DragDrop
                    accept={(this.getFileExtensionForType(this.state.importMode)).toString()}
                    handleFileSelection={this.handleFileSelection}
                    theme={theme}
                    dragDropMessage1="Drag and drop file to"
                    dragDropMessage2="update the network"
                    disabled={uploadInProgress || this.state.createEmpty || (this.state.importMode === 'mdb' && fileNames.length !== 0)}
                  />
                  {(this.state.importMode === 'mdb')
                  && (
                  <div className="email-upload-container">
                    <span>
                      Upload the warehouse database (.mdb file):
                      {' '}
                      <br />
                    </span>
                  </div>
                  )}
                  {(this.state.importMode === 'seq_std')
                    && (
                    <div className="email-upload-container">
                      <span>
                        Upload the .std file:
                        {' '}
                        <br />
                      </span>
                    </div>
                    )}
                  {(this.state.importMode === 'mdb' || this.state.importMode === 'seq_std')
                  && (
                  <DragDrop
                    accept={(this.getFileExtensionForType(this.state.importMode)).toString()}
                    handleFileSelection={this.handleFileSelection}
                    theme={theme}
                    dragDropMessage1="Drag and drop file to"
                    dragDropMessage2="update the network"
                    disabled={!hasFiles || uploadInProgress || this.state.createEmpty || (this.state.importMode === 'mdb' && fileNames.length !== 1)}
                  />
                  )}

                  <div className="file-row-container">
                    {showWorkspaceError && (
                      <p className="error caption-text">Could not create workspace. Please try again.</p>
                    )}

                    {showUploadError && (
                      <p className="error caption-text">
                        {'One or more files failed to upload. Select additional files to upload or '}
                        <Link to={`/${workspaceName}/${branchName}/gis`}>click here</Link>
                        {' to go to your workspace.'}
                      </p>
                    )}

                    {selectedMode.value === 'seq_std' && fileNames.length !== 2
                      && <p className="error caption-text">Two files are required to be uploaded.</p>}

                    {selectedMode.value === 'mdb' && fileNames.length !== 2
                      && <p className="error caption-text">Two mdb files are required to be uploaded.</p>}

                    {selectedMode.value === 'excel' && fileNames.length > 1
                      && <p className="error caption-text">One excel file is required to be uploaded.</p>}

                    {selectedMode.value === 'csv' && fileNames.length > 14
                      && <p className="error caption-text">Up to 14 csv files can be uploaded.</p>}

                    {selectedMode.value === 'csv' && !hasCorrectFileNames
                      && <p className="error caption-text">One or more files have incorrect filenames.</p>}

                    {hasCorrectExtensions === false && fileNames.length === 2 && selectedMode === 'seq_std'
                      && (
                      <p className="error caption-text">
                        Only one .std file and one .seq file can be uploaded at once.
                        <br />
                        Remove the duplicate file and upload the missing file.
                      </p>
                      )}

                    <CustomScrollBar alwaysShow>
                      {fileNames.map(file => (
                        <FileUploadStatus
                          key={file}
                          fileExtension={fileExtensions.toString()}
                          details={files[file]}
                          file={file}
                          errorMessage={importErrors[file]}
                          removeFromFileList={this.props.actions.removeFromFileList}
                          theme={theme}
                          uploading={uploadInProgress}
                        />
                      ))}
                    </CustomScrollBar>
                  </div>
                </div>
                <Button
                  label={this.getButtonLabel()}
                  theme={theme}
                  id="finish-btn"
                  disabled={this.state.createEmpty ? invalidEmpty : invalidWithFiles}
                  loading={uploadInProgress
                    && Object.values(files).some(
                      f => f.loadingState === asyncActionStates.LOADING,
                    )}
                  onClick={this.handleFinishClick}
                />
              </div>
            </Card>
          </div>
        </CustomScrollBar>
      </div>
    );
  }
}

ImportCIM.defaultProps = {
  files: {},
  importErrors: {},
  uploadInProgress: false,
  createWorkspaceStatus: asyncActionStates.INITIAL,
};

ImportCIM.propTypes = {
  actions: PropTypes.shape({
    addToFileList: PropTypes.func,
    clearFileDetails: PropTypes.func,
    createEmptyWorkspace: PropTypes.func,
    beginNetworkImport: PropTypes.func,
    removeFromFileList: PropTypes.func,
  }).isRequired,
  files: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      workspace: PropTypes.string,
      branch: PropTypes.string,
    }),
  }).isRequired,
  theme: PropTypes.string.isRequired,
  uploadInProgress: PropTypes.bool,
  importErrors: PropTypes.object,
  createWorkspaceStatus: PropTypes.number,
};

export default ImportCIM;
