import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MODAL_TYPES } from 'helpers/EditMode';
import IconButton from 'components/IconButton';
import Modal from 'components/Modal';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import Select from 'components/Select';
import asyncActionStates from 'helpers/asyncActionStates';
import Analytics from 'helpers/Analytics';
import browserHistory from 'routes/history';

import { Request } from '@opusonesolutions/gridos-app-framework';
import { alphabetize } from 'helpers/utils';
import './DropdownSections.scss';

const WorkspaceSection = ({
  actions,
  isAuthEnabled,
  permissions,
  theme,
  workspace,
  inEditMode,
  saveModalOpen,
  handleMenuSelection,
  params,
  view,
}) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  // the workspace being confirmed for delete
  const [workspaceToDelete, setWorkspaceToDelete] = useState(null);
  const [deleteWorkspaceState, setDeleteWorkspaceState] = useState(asyncActionStates.INITIAL);
  const [workspaces, setWorkspaces] = useState([]);

  const loadWorkspaceList = () => {
    let didCancel = false;

    async function fetchWorkspaces() {
      if (permissions.has('get_workspaces')) {
        const request = new Request('/api/workspace');
        try {
          const { data } = await request.get();
          if (didCancel) {
            // Cancelled before the request finished so do nothing
            return;
          }
          const sortedWorkspaces = alphabetize(data.workspaces).map(ws => ({
            value: ws,
            label: ws,
          }));
          setWorkspaces(sortedWorkspaces);
        } catch (error) {
        }
      } else {
        setWorkspaces([{ value: params.workspace, label: params.workspace }]);
      }
    }

    fetchWorkspaces();
    return () => { didCancel = true; };
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadWorkspaceList, []);

  const canCreateWorkspace = !isAuthEnabled || permissions.has('create_workspace');
  const canDeleteWorkspace = !isAuthEnabled || permissions.has('delete_workspace');

  const deleteWorkspace = async () => {
    setDeleteWorkspaceState(asyncActionStates.LOADING);
    const request = new Request(`/api/workspace/${workspaceToDelete}`);

    try {
      await request.delete();
      // Mark as success and close the modal
      // Also remove the workspace that was deleted from the list
      loadWorkspaceList();
      setDeleteWorkspaceState(asyncActionStates.SUCCESS);
      setDeleteOpen(false);
      actions.loadNetworkData(workspaces[0].value, 'master', []);
      browserHistory.push(`/${workspaces[0].value}/master/gis`);
    } catch (error) {
      setDeleteWorkspaceState(asyncActionStates.ERROR);
    }
  };

  const makeActionButtons = (canDelete, canCreate) => ([
    {
      id: 'add',
      icon: 'add',
      key: 'Create Workspace',
      onClick: () => {
        browserHistory.push('/import-cim');
        Analytics.logEvent('Create Workspace', 'Workspaces');
      },
      theme,
      className: 'add-btn',
      disabled: !canCreate || inEditMode,
      disabledMessage: !canCreate ? 'You do not have create permission' : 'You cannot create workspaces in edit Mode',
      tooltip: canCreate && !inEditMode ? 'Create Workspace' : null,
    }, {
      id: 'edit',
      icon: 'edit',
      key: 'Edit Workspaces',
      className: 'top-row-buttons',
      tooltip: !canCreateWorkspace ? null : 'Edit Workspaces',
      onClick: () => {
        handleMenuSelection('workspaces');
        Analytics.logEvent('Edit Workspaces', 'Workspaces');
      },
      theme,
      disabled: !canCreateWorkspace,
      disabledMessage: 'You do not have edit permission',
    }, {
      id: 'delete',
      icon: 'delete',
      key: 'Delete',
      className: 'top-row-buttons',
      tooltip: canDelete && !inEditMode ? 'Delete Workspace' : null,
      onClick: () => {
        setWorkspaceToDelete(workspace);
        setDeleteOpen(true);
        Analytics.logEvent('Delete Workspace', 'Workspaces');
      },
      theme,
      disabled: !canDelete || inEditMode,
      disabledMessage: !canDelete ? 'You do not have delete permission' : 'You cannot delete workspaces in edit mode',
    },
  ]);

  const changeWorkspace = (value) => {
    if (inEditMode) {
      saveModalOpen(MODAL_TYPES.WORKSPACE, 'master', value);
    } else {
      actions.clearNetworkData();
      if (view === 'overview') {
        browserHistory.push(`/${value}`);
      } else {
        browserHistory.push(`/${value}/master/${view}`);
        actions.getContainersList([], value, 'master');
      }
    }
  };

  return (
    <div className="branch-scenario-section branch-section" id="workspace-selector-container" data-test="workspace-selector">
      <div className="select-container">
        <div className="select-top-row">
          <p className="select-label">1. Workspace</p>
          <div className="buttons">
            {view !== 'results'
              && makeActionButtons(canDeleteWorkspace, canCreateWorkspace).map(button => (
                <PermissionDisabledTip
                  key={button.key}
                  title={button.key}
                  hide={!button.disabled}
                  theme={theme}
                  message={button.disabledMessage}
                >
                  <IconButton
                    {...button}
                  />
                </PermissionDisabledTip>
              ))}
          </div>
        </div>
        <Select
          theme={theme}
          options={workspaces}
          value={workspace}
          onChange={e => changeWorkspace(e.value)}
          clearable={false}
          searchable
          id="workspace-selector"
          disabled={!permissions.has('get_workspaces')}
        />
      </div>
      <Modal
        title="Delete Workspace"
        active={deleteOpen}
        width="300px"
        onCancel={() => setDeleteOpen(false)}
        onConfirm={() => deleteWorkspace()}
        disableConfirm={deleteWorkspaceState === asyncActionStates.LOADING}
        labels={{
          confirm: deleteWorkspaceState === asyncActionStates.LOADING
            ? <i className="material-icons rotate">autorenew</i>
            : 'Confirm',
        }}
        reverseFooterButtons
        theme={theme}
      >
        <p className="modal-message__p">
          Once a workspace has been deleted, it cannot be restored.
        </p>
        <p className="modal-message__p">
          <span>Would you like to permanently remove </span>
          <b>{workspaceToDelete}</b>
          ?
        </p>
      </Modal>
    </div>
  );
};

WorkspaceSection.propTypes = {
  actions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  saveModalOpen: PropTypes.func.isRequired,
  handleMenuSelection: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired,
};

export default WorkspaceSection;
