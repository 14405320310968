import React, { useContext, useState, FunctionComponent } from 'react';

import asyncActionStates from 'helpers/asyncActionStates';
import { pu } from 'helpers/units';
import ThemeContext from 'helpers/ThemeContext';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import { VoltageLimit, SimulationOptions } from 'types/analysis';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { networkEditActions } from 'routes/WorkspaceLayout/routes/Network/modules/networkEdit';
import NumberInput from '../NumberInput';
import Button from '../Button';

const limitInfo = {
  voltage_lower_limit: {
    label: 'Lower voltage limit',
    type: 'number',
    unit: pu,
    default: 0.94,
    // Disable when voltage limits disabled
    disabled: (options: SimulationOptions|undefined) => options?.dont_constrain_voltages ?? false,
  },
  voltage_upper_limit: {
    label: 'Upper voltage limit',
    type: 'number',
    unit: pu,
    default: 1.06,
    // Disable when voltage limits disabled
    disabled: (options: SimulationOptions|undefined) => options?.dont_constrain_voltages ?? false,
  },
};

interface RootState {
  network: {
    workspace: string,
    branch: string,
    applyDifferenceModelRequest: { updateSubstation?: number },
  }
}

const mapState = (state: RootState) => ({
  branch: state.network.branch,
  workspace: state.network.workspace,
  request: state.network.applyDifferenceModelRequest.updateSubstation,
});

export const connector = connect(
  mapState,
  dispatch => ({ ...bindActionCreators({ updateContainer: networkEditActions.updateContainer }, dispatch) }),
);

type VoltageLimitReduxProps = ConnectedProps<typeof connector>;

type VoltageLimitProps = VoltageLimitReduxProps & {
  substationId?: string;
  isAnalysis?: boolean;
  simulationOptions?: SimulationOptions;
  setSimulationOptions?: React.Dispatch<React.SetStateAction<SimulationOptions>>;
  disableButton?: (disable: boolean) => void;
}

const VoltageLimits: FunctionComponent<VoltageLimitProps> = ({
  workspace,
  branch,
  updateContainer,
  substationId,
  isAnalysis = false,
  simulationOptions,
  setSimulationOptions = () => {},
  request,
  disableButton,
}) => {
  const theme = useContext(ThemeContext);
  const [limits, setLimits] = useState<VoltageLimit>({});

  const { data: substation, loading, refetch } = useRequestEffect<VoltageLimit>({
    url: `/api/workspace/${workspace}/branch/${branch}/asset_panel/substation/${substationId}`,
    method: 'get',
    refetchOnChange: [workspace, branch, substationId],
    initialData: {},
    toast: {
      error: 'An error occurred loading the substation voltage limits. Default values are displayed',
    },
    blockRequest: () => !substationId,
  });

  const getLimit = (limit: keyof VoltageLimit) => limits?.[limit] ?? substation?.[limit] ?? limitInfo[limit].default;

  const upper = getLimit('voltage_upper_limit');
  const lower = getLimit('voltage_lower_limit');
  const validation = {
    voltage_upper_limit: {
      invalid:
        lower >= upper
        || upper >= 1.5,
      required: true,
      gt: lower,
      lt: 1.5,
      validationMessage: `Voltage limit must be between ${lower || limitInfo.voltage_lower_limit.default} and 1.5`,
    },
    voltage_lower_limit: {
      invalid:
        lower >= upper
        || lower <= 0.6,
      gt: 0.6,
      lt: upper,
      required: true,
      validationMessage: `Voltage limit must be between 0.6 and ${upper || limitInfo.voltage_upper_limit.default}`,
    },
  };

  const updateLimits = async () => {
    const keys = Object.keys(limits) as (keyof VoltageLimit)[];
    const updatedLimits = keys.reduce(
      (body, limit) => ({
        ...body,
        [limit]: limits[limit] || limitInfo[limit].default,
      }),
      {},
    );
    await updateContainer('substation', substationId, updatedLimits, 'updateSubstation');
    refetch();
  };

  const saving = request === asyncActionStates.LOADING;
  const anyInvalid = Object.values(validation).some(limit => limit.invalid);

  const lim = Object.keys(limits) as (keyof VoltageLimit)[];
  const showSaveButton = !isAnalysis
    && !anyInvalid
    && (limits
      && substation
      && lim.some(limit => limits[limit] !== substation[limit]));

  if (disableButton) {
    disableButton(anyInvalid); // To prevent permanent disabling
  }

  if (loading) {
    return (
      <div
        className="asset-loading"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: '0.5',
          marginTop: '37px',
          marginBottom: '37px',
        }}
      >
        Loading Limit data...
      </div>
    );
  }
  return (
    <div>
      <p style={{
        color: 'crimson', display: 'flex', alignItems: 'center', fontSize: '12px',
      }}
      >
        {request === asyncActionStates.NOTALLOWED && (
          <>
            <i className="material-icons edit-values-error__i">warning</i>
            You do not have permission to edit.
          </>
        )}
        {request === asyncActionStates.ERROR && (
          <>
            <i className="material-icons edit-values-error__i">warning</i>
            Failed to update.
          </>
        )}
      </p>
      {Object.entries(limitInfo).map(([_limit, info]) => {
        const limit = _limit as keyof VoltageLimit;
        return (
          <NumberInput
            step="0.001"
            id={limit}
            key={limit}
            unit={info.unit}
            value={getLimit(limit)}
            onChange={(evt) => {
              setLimits({ ...limits, [limit]: evt.value });
              if (isAnalysis && simulationOptions) {
                setSimulationOptions({
                  ...simulationOptions,
                  [limit]: evt.value,
                });
              }
            }}
            label={info.label}
            {...validation[limit]}
            disabled={saving || (isAnalysis && info.disabled(simulationOptions))}
            theme={theme}
          />
        );
      })}
      {showSaveButton && (
        <Button
          id="update-limits"
          onClick={updateLimits}
          theme={theme}
          loading={saving}
          disabled={saving || loading}
        >
          Update voltage limits
        </Button>
      )}
    </div>
  );
};

export default VoltageLimits;
