import React, {
  createContext, useState, useEffect, useMemo,
} from 'react';
import { useParams } from 'react-router';
import useResetableState from 'hooks/useResetableState';
import { useSelector } from 'react-redux';
import { useRequestEffect, Request } from '@opusonesolutions/gridos-app-framework';
import { isEmptyObject } from 'helpers/utils';
import { views, modalTypes } from '../helpers/ScenarioGenerationHelpers';

type scenarioType = { [key: string]: string }|null;
type scenarioOptionType = { value: string, label: string };
type ContextState = {
  selectedScenario: scenarioType
  setSelectedScenario: (scenario: scenarioType) => void,
  selectedContainer: string|null,
  setSelectedContainer: (container: string|null) => void,
  feedersInScenario?: {[key: string]: string}[],
  feedersInSceLoading: boolean,
  view: views,
  setView: (type: views) => void,
  scenarios: scenarioOptionType[],
  resetScenario: () => void,
  modalActiveType: modalTypes|null,
  setModalActiveType: (type: modalTypes) => void,
  resetModalType: () => void,
}
interface ReduxState {
  feeders: {
    list: {[key: string]: string}[],
  }
}
interface Selected {
  containerList: {[key: string]: string}[],
}
type containersListHasSchedules = {
  [container: string]: {[key: string]: string},
};
export const ScenarioGenerationContext = createContext<ContextState>({
  selectedScenario: null,
  setSelectedScenario: () => {},
  selectedContainer: null,
  setSelectedContainer: () => {},
  feedersInScenario: [],
  feedersInSceLoading: false,
  view: views.scenario,
  setView: () => {},
  scenarios: [],
  resetScenario: () => {},
  modalActiveType: null,
  setModalActiveType: () => {},
  resetModalType: () => {},
});

interface ScenarioGenerationProvider {
  children: JSX.Element
}

export default function ScenarioGenerationContextProvider({ children }: ScenarioGenerationProvider): JSX.Element {
  const [scenario, setScenario, resetScenario] = useResetableState<scenarioType|null>(null);
  const [scenarios, setScenarios] = useState<scenarioOptionType[]>([]);
  const [selectedContainer, setSelectedContainer] = useState<string|null>(null);
  const [view, setView] = useState<views>(views.scenario);
  const [modalActiveType, setModalActiveType, resetModalType] = useResetableState<modalTypes|null>(null);
  const setSelectedScenario = (value: scenarioType|null) => (value ? setScenario(value) : resetScenario());
  const { workspace, branch } = useParams<{ workspace: string, branch: string }>();
  const { containerList } = useSelector<ReduxState, Selected>(state => ({
    containerList: state.feeders.list,
  }));
  const fetchScenarios = useMemo(() => async () => {
    const url = `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios`;
    try {
      const results = await new Request(url).get();
      const allScenariosData = results.data.map((sc: {[key: string]: string}) => (
        { value: sc.id, label: sc.name, type: sc.scenario_type }
      ));
      setScenarios(allScenariosData);
    } catch (err) {
      setScenarios([]);
    }
  }, [workspace, branch]);
  useEffect(() => {
    if (scenario && workspace && branch) {
      resetScenario();
    }
    fetchScenarios();
    setSelectedContainer(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace, branch]);
  const {
    data: allContainers, reset, loading: feedersInSceLoading,
  } = useRequestEffect<{[key: string]: string}[]>({
    url: `/api/workspace/${workspace}/branch/${branch}/asset_schedule`,
    method: 'get',
    dataTransform: (data: containersListHasSchedules) => {
      const dataTransformed = data && !isEmptyObject(data) && scenario && containerList
        ? containerList.filter((container) => Object.keys(data)?.includes(container?.id)) : [];
      return dataTransformed;
    },
    params: {
      scenario_id: scenario?.value,
      schedule_type: 'Feeder',
    },
    refetchOnChange: [workspace, branch, scenario],
    blockRequest: () => !(workspace && branch && scenario),
    initialData: [],
  });
  useEffect(() => {
    if (!scenario && allContainers?.length !== 0) {
      reset();
    }
  }, [allContainers, scenario, reset]);
  return (
    <ScenarioGenerationContext.Provider
      value={{
        selectedScenario: scenario,
        setSelectedScenario,
        selectedContainer,
        setSelectedContainer,
        feedersInScenario: allContainers,
        feedersInSceLoading,
        view,
        setView,
        scenarios,
        resetScenario,
        modalActiveType,
        setModalActiveType,
        resetModalType,
      }}
    >
      {children}
    </ScenarioGenerationContext.Provider>
  );
}
