import React, { FunctionComponent } from 'react';
import { ThemeProp } from 'types/index';
import { settings, unitInfos } from 'types/edit';
import Select from 'components/Select';

type BulkAssetPVSettingsProps = {
    theme: ThemeProp,
    assetSettings: settings,
    cutomerTypesofLoads: string[],
    setAssetSettings: (agrs: settings) => void,
    invInfos?: unitInfos,
    pvInfos?: unitInfos,
};

const BulkAssetPVSettings: FunctionComponent<BulkAssetPVSettingsProps> = ({
  theme,
  assetSettings,
  setAssetSettings,
  cutomerTypesofLoads,
  invInfos = [],
  pvInfos = [],
}) => (
  <div className="pv-settings">
    { cutomerTypesofLoads.map((type) => (
      <div className="asset-type margin-10" key={type}>
        { type !== 'all' && (<p className="text-bold type-title">{type === '' ? 'Unspecified loads' : type}</p>)}
        <div className="grid-columns one-one">
          <Select
            label="Inverter type"
            onChange={(e) => setAssetSettings(
              {
                ...assetSettings,
                [type]: { ...assetSettings[type], asset_info: e.value },
              },
            )}
            id={`select-${type}`}
            options={invInfos.map((inv) => ({ value: inv.id, label: inv.name }))}
            value={assetSettings[type]?.asset_info}
            theme={theme}
            type="secondary"
            clearable={false}
          />
          <Select
            label="PV type"
            onChange={(e) => setAssetSettings(
              {
                ...assetSettings,
                [type]: { ...assetSettings[type], unit_info: e.value },
              },
            )}
            id={`select-${type}`}
            options={pvInfos.map((inv) => ({ value: inv.id, label: inv.name }))}
            value={assetSettings[type]?.unit_info}
            theme={theme}
            type="secondary"
            clearable={false}
          />
        </div>
      </div>
    ))}
  </div>
);

export default BulkAssetPVSettings;
