import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberInputGroup from '../NumberInputGroup';
import TextInputGroup from '../TextInputGroup';
import formatValue from './formatValue';
import './Input.scss';

const defaultValidation = {
  min: null,
  max: null,
  invalid: false,
  showValidationMessage: 'after-edit',
  validationMessage: '',
  required: false,
  nonZero: null,
};

const defaultOptions = {
  divisor: 1,
  step: 'any',
  precision: null,
};

const defaultAttributes = {
  phase: null,
};

// Can create 2 kinds of inputs
//   - Number / Text Inputs with default styling       ("default-input")
//   - Number / Text Inputs with dashed bottom borders ("panel")
class Input extends Component {
  defaults = (defaults, newProps = {}) => ({ ...defaults, ...newProps })

  getValue = (value, divisor, edited, precision) => {
    if (divisor || precision) {
      const divisorValue = divisor || 1;
      return this.props.formatValue(value, divisorValue, edited, precision);
    }
    return value;
  };

  getStyles = (disabled, edited, invalid, phase) => {
    if (phase) {
      return {
        'per-phase-row-input': true,
        'per-phase-row-input--editable': !disabled,
        'per-phase-row-input--edited': edited,
        'per-phase-row-input--invalid': invalid,
      };
    }
    return {
      'single-row-input': true,
      'single-row-input--editable': !disabled,
      'single-row-input--edited': edited,
      'single-row-input--invalid': invalid,
    };
  };

  handleChange = (e, phase, divisor) => {
    let { value } = e.target;
    // If divisor is provided, adjust value before triggering callback
    if (this.props.type !== 'text' && value !== '') {
      value *= divisor;
    }

    // If input is a 'per phase' input, include the phase in the callback arguments
    let onChangeArgs = [value, this.props.id];
    if (phase) {
      onChangeArgs = [...onChangeArgs, phase];
    }
    this.props.onChange(...onChangeArgs);
  };

  render() {
    const {
      disabled, edited, value, type, inputStyle, unit,
    } = this.props;

    const validation = this.defaults(defaultValidation, this.props.validation);

    const options = this.defaults(defaultOptions, this.props.options);

    const customAttributes = this.defaults(defaultAttributes, this.props.customAttributes);

    const styles = this.getStyles(disabled, edited, validation.invalid, customAttributes.phase);

    const nonZero = validation.nonZero === 'pos';

    const negNonZero = validation.nonZero === 'neg';

    if (type === 'number') {
      const formattedValue = this.getValue(value, options.divisor, edited, options.precision);

      return (
        <NumberInputGroup
          {...this.props}
          realValue={value}
          className={classNames({ [inputStyle]: true, ...styles })}
          value={formattedValue}
          onChange={e => this.handleChange(e, customAttributes.phase, options.divisor)}
          showValidationMessage={validation.showValidationMessage}
          validationMessage={validation.validationMessage}
          invalid={validation.invalid}
          min={typeof validation.min === 'number' ? validation.min / options.divisor : null}
          max={typeof validation.max === 'number' ? validation.max / options.divisor : null}
          nonZero={nonZero}
          negNonZero={negNonZero}
          required={validation.required}
          step={options.step}
          phase={customAttributes.phase}
          inputColor={customAttributes.inputColor}
          unit={unit}
        />
      );
    }

    if (type === 'text') {
      return (
        <TextInputGroup
          {...this.props}
          className={classNames({ [inputStyle]: true, ...styles })}
          onChange={e => this.handleChange(e, customAttributes.phase)}
          phase={customAttributes.phase}
          showValidationMessage={validation.showValidationMessage}
          validationMessage={validation.validationMessage}
          invalid={validation.invalid}
          required={validation.required}
          inputColor={customAttributes.inputColor}
          unit={unit}
        />
      );
    }
    return null;
  }
}

Input.defaultProps = {
  onChange: null,
  disabled: false,
  value: null,
  edited: null,
  inputStyle: 'default-input',
  validation: null,
  options: null,
  customAttributes: null,
  unit: null,
};

Input.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  formatValue: PropTypes.func.isRequired,
  edited: PropTypes.any,
  inputStyle: PropTypes.oneOf(['default-input', 'panel', 'primary']),
  unit: PropTypes.node,
  validation: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    gt: PropTypes.number,
    lt: PropTypes.number,
    invalid: PropTypes.bool,
    showValidationMessage: PropTypes.oneOf(['always', 'after-edit', 'never']),
    validationMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]),
    required: PropTypes.bool,
    nonZero: PropTypes.oneOf(['pos', 'neg']),
  }),
  options: PropTypes.shape({
    step: PropTypes.string,
    divisor: PropTypes.number,
    precision: PropTypes.number,
  }),
  customAttributes: PropTypes.shape({
    phase: PropTypes.string,
    inputColor: PropTypes.string,
  }),
};

export default formatValue(Input);
