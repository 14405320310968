/* eslint-disable import/no-cycle */
import { determineDefaultZoomLevel, getEndOfInterval } from 'components/ZoomableRangeSlider/intervals';
import Analytics from 'helpers/Analytics';
import asyncActionStates from 'helpers/asyncActionStates';
import { MODAL_TYPES } from 'helpers/EditMode';
import Network from 'helpers/Network';
import { Request } from '@opusonesolutions/gridos-app-framework';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import moment from 'moment';
import browserHistory from 'routes/history';
import { displayAlertMessage } from 'store/appState';
import Helpers, {
  ANALYSIS_TYPES,
  determineScenarioTimeSpan,
  getAnalysisTimepoints,
  getLinkDeviceResults,
  getNodeResults,
  getShuntDeviceResults,
} from '../helpers/NetworkHelpers';
import { feederActions } from './feeders';
import { CLEAR_SELECTED_SCENARIO, loadForecastActions, SCENARIO_HAS_DATA } from './loadForecast';
import { CLEAR_NEW_ASSET, networkEditActions, SELECT_NEW_ASSET } from './networkEdit';
import {
  CLEAR_POWERFLOW_RESULTS,
  CLEAR_SELECTED_RANGE_DATA,
  powerflowActions,
  POWERFLOW_SUCCESS,
} from './powerflow';

export const defaultSV = {
  powers: {},
  voltages: {},
  energies: {},
  tap_positions: {},
  link_devices: {},
};

const updateHistory = (workspace, branch, feeders, assetID, view, link = null) => {
  const feederIDs = feeders.map(fdr => fdr.id).join(',');
  if (link) {
    browserHistory.push(link);
  } else if (view === 'gis' && assetID) {
    browserHistory.push(`/${workspace}/${branch}/${view}/${feederIDs}/asset/${assetID}`);
  } else if (view === 'gis' && feederIDs.length > 0) {
    browserHistory.push(`/${workspace}/${branch}/${view}/${feederIDs}`);
  } else if (view === 'overview') {
    browserHistory.push(`/${workspace}`);
  } else if (view !== 'workspaces') {
    browserHistory.push(`/${workspace}/${branch}/${view}`);
  }
};

// ------------------------------------
// Constants
// ------------------------------------
const NETWORK_DATA_PENDING = 'NETWORK_DATA_PENDING';
export const NETWORK_DATA_SUCCESS = 'NETWORK_DATA_SUCCESS';
export const NETWORK_DATA_FAILURE = 'NETWORK_DATA_FAILURE';
const ADD_ASSET_PENDING = 'ADD_ASSET_PENDING';
const ADD_ASSET_SUCCESS = 'ADD_ASSET_SUCCESS';
const ADD_ASSET_FAILURE = 'ADD_ASSET_FAILURE';
const ADD_ASSET_NOT_ALLOWED = 'ADD_ASSET_NOT_ALLOWED';
export const CLEAR_NETWORK_DATA = 'CLEAR_NETWORK_DATA';
export const SET_SELECTED_ASSET = 'SET_SELECTED_ASSET';
const SET_HOVERED_OBJECT_ID = 'SET_HOVERED_OBJECT_ID';
const TOGGLE_ASSET_PANEL = 'TOGGLE_ASSET_PANEL';
const SET_ACTIVE_PANEL = 'SET_ACTIVE_PANEL';
const SET_SELECTED_LAYER = 'SET_SELECTED_LAYER';
export const UPDATE_APPLY_DIFFERENCE = 'UPDATE_APPLY_DIFFERENCE';
const SET_SELECTED_CONTAINER = 'SET_SELECTED_CONTAINER';
const SET_ACTIVE_LEFT_PANEL = 'SET_ACTIVE_LEFT_PANEL';
const CHECK_COORDINATE_SYSTEM = 'CHECK_COORDINATE_SYSTEM';
const TOGGLE_FEEDER_PANEL = 'TOGGLE_FEEDER_PANEL';
export const SET_CURRENT_WORKSPACE = 'SET_CURRENT_WORKSPACE';
export const QSTS_VALUES_SUCCESS = 'QSTS_VALUES_SUCCESS';
export const QSTS_VALUES_ERROR = 'QSTS_VALUES_ERROR';
export const GET_QSTS_TIMEPOINTS = 'GET_QSTS_TIMEPOINTS';
const QSTS_VALUES_LOADING = 'QSTS_VALUES_LOADING';
export const DELETE_ASSET_PENDING = 'DELETE_ASSET_PENDING';
export const DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS';
export const DELETE_ASSET_FAILURE = 'DELETE_ASSET_FAILURE';
export const DELETE_CONTAINER_PENDING = 'DELETE_CONTAINER_PENDING';
export const DELETE_CONTAINER_SUCCESS = 'DELETE_CONTAINER_SUCCESS';
export const DELETE_CONTAINER_FAILURE = 'DELETE_CONTAINER_FAILURE';
const DETERMINING_TIME_RANGE = 'DETERMINING_TIME_RANGE';
export const UPDATE_TIME_RANGE = 'UPDATE_TIME_RANGE';
const UPDATE_HIGHLIGHTED_TIME_RANGE = 'UPDATE_HIGHLIGHTED_TIME_RANGE';
const SET_MAX_RANGE = 'SET_MAX_RANGE';
const SET_SCENARIO_RANGE = 'SET_SCENARIO_RANGE';
const FETCH_BRANCHES_SUCCESS = 'FETCH_BRANCHES_SUCCESS';
const FETCH_BRANCHES_FAILURE = 'FETCH_BRANCHES_FAILURE';
export const UPDATE_SELECTED_BRANCH = 'UPDATE_SELECTED_BRANCH';
const UPDATE_CREATE_BRANCH_STATUS = 'UPDATE_CREATE_BRANCH_STATUS';
const UPDATE_CREATE_FEEDER_STATUS = 'UPDATE_CREATE_FEEDER_STATUS';
const DELETE_BRANCH_SUCCESS = 'DELETE_BRANCH_SUCCESS';
const DELETE_BRANCH_FAILURE = 'DELETE_BRANCH_FAILURE';
const GET_NODE_SUBSTATION_DISTANCE_LOADING = 'GET_NODE_SUBSTATION_DISTANCE_LOADING';
const GET_NODE_SUBSTATION_DISTANCE_SUCCESS = 'GET_NODE_SUBSTATION_DISTANCE_SUCCESS';
const GET_NODE_SUBSTATION_DISTANCE_ERROR = 'GET_NODE_SUBSTATION_DISTANCE_ERROR';
export const SCENARIO_TIMESPAN_LOADING = 'SCENARIO_TIMESPAN_LOADING';
const SET_FILTERED_PANEL = 'SET_FILTERED_PANEL';
const SET_FLY_TO_ASSET = 'SET_FLY_TO_ASSET';
const SET_ASSET_VISIBILITY = 'SET_ASSET_VISIBILITY';
const SET_PANEL_EXPANSION = 'SET_PANEL_EXPANSION';
const UPDATE_ATTACHMENTS = 'UPDATE_ATTACHMENTS';
export const CLEAR_ANALYSIS_RESULTS = 'CLEAR_ANALYSIS_RESULTS';
const SET_SELECTED_ASSET_ID = 'SET_SELECTED_ASSET_ID';

// ------------------------------------
// Actions
// ------------------------------------

export const updateAttachments = () => async (dispatch, getState) => {
  try {
    const {
      feeders: { selected },
      network: { workspace, branch },
    } = getState();
    const requests = [
      new Request(`/api/workspace/${workspace}/branch/${branch}/attachments`),
      new Request(`/api/workspace/${workspace}/branch/${branch}/notes`),
    ];
    const [{ data: withFiles }, { data: withNotes }] = await Promise.all(
      requests.map(req => req.get({ params: { feeder: selected.map(fdr => fdr.id) } })),
    );

    dispatch({
      type: UPDATE_ATTACHMENTS,
      withFiles,
      withNotes,
    });
  } catch (error) {
  }
};

const setFlyToAsset = asset => ({
  type: SET_FLY_TO_ASSET,
  payload: asset,
});

const setFilteredPanel = filteredObj => ({
  type: SET_FILTERED_PANEL,
  payload: filteredObj,
});

const setCurrentWorkspace = workspace => ({
  type: SET_CURRENT_WORKSPACE,
  workspace,
});

function setSelectedLayer(layer, toggleIfSet = false) {
  return {
    type: SET_SELECTED_LAYER,
    layer,
    toggleIfSet,
  };
}

const checkCoordinateSystem = (workspace, branch) => (dispatch) => {
  const coordinateSystemRequest = new Request(
    `/api/workspace/${workspace}/branch/${branch}/coordinate_system`,
  );
  return coordinateSystemRequest
    .get()
    .then(({ data }) => {
      const urn = Helpers.getShortURNCode(data);
      dispatch({
        type: CHECK_COORDINATE_SYSTEM,
        invalid: urn !== 'EPSG:4326',
      });
    })
    .catch(() => {
      dispatch({
        type: CHECK_COORDINATE_SYSTEM,
        invalid: true,
      });
    });
};

/**
 * Get updated SVs for the selected timepoint from measurements datastore
 * @param  {String} workspace  Workspace name
 * @param  {String} feeder     Feeder UUID
 * @param  {String} timepoint  Selected Timestamp
 */
function updateQSTSValues(workspace, branch, feeder, timeRange) {
  let qstsResults;
  return async (dispatch, getState) => {
    try {
      const { selectedScenario, selectedAnalysis } = getState().loadForecast;
      qstsResults = new Request(`/api/workspace/${workspace}/branch/${branch}/power-flow-results/all`);

      dispatch({
        type: QSTS_VALUES_LOADING,
        request: qstsResults,
      });
      const res = await qstsResults.get({
        params: {
          feeder,
          scenario_id: selectedScenario,
          analysis_name: selectedAnalysis.name,
          start_date: timeRange.start.toISOString(),
          end_date: timeRange.end.toISOString(),
        },
      });
      let pqValues;

      if (res.data[0] && res.data[0].powers) {
        const nodes = Object.values(res.data[0].powers);
        if (nodes.length) {
          pqValues = nodes.reduce(
            (lu, node) => {
              const filterValues = values => values.reduce((list, val) => {
                if (!val) return list;
                return [...list, val];
              }, []);
              const pValues = filterValues([node.p_a_avg, node.p_b_avg, node.p_c_avg]);
              const qValues = filterValues([node.q_a_avg, node.q_b_avg, node.q_c_avg]);
              return {
                p: [...lu.p, ...pValues],
                q: [...lu.q, ...qValues],
              };
            },
            { p: [], q: [] },
          );
        }
      }
      const newSVs = res.data[0] || defaultSV;
      const { networkInstance } = getState().network;
      const {
        _cables,
        linkDevices,
        shuntDevices,
        connectivityNodes,
        ratedCurrentRange,
        baseVoltages,
        ratedSRange,
      } = networkInstance;
      const nodeResults = getNodeResults(connectivityNodes, newSVs.voltages);
      const linkResults = getLinkDeviceResults(_cables, linkDevices, newSVs.link_devices, nodeResults);
      const shuntResults = getShuntDeviceResults(shuntDevices, newSVs.powers);

      const results = {
        ...linkResults, ...shuntResults, ...nodeResults, ...newSVs.tap_positions,
      };
      const hasSavedLayers = await dispatch(powerflowActions.hasLayerOptions());
      const isAggregatedData = res.data[0]?.is_aggregated || false;
      dispatch({
        type: QSTS_VALUES_SUCCESS,
        ratedCurrentRange,
        baseVoltages,
        ratedSRange,
        pqValues,
        hasSavedLayers,
        results,
        isAggregatedData,
      });
    } catch (err) {
      if (!qstsResults.wasCancelled) {
        const { lookup } = getState().network;
        if (Object.keys(lookup).length > 0) {
          dispatch({
            type: QSTS_VALUES_ERROR,
            SV: defaultSV,
          });
        }
      }
    }
  };
}

const setTimeRange = (start, end) => (dispatch, getState) => {
  const range = { start, end };
  dispatch({ type: UPDATE_TIME_RANGE, range });
  dispatch({ type: CLEAR_SELECTED_RANGE_DATA });

  const { workspace, branch } = getState().network;
  const { selected: selectedFeeders } = getState().feeders;
  const { selectedScenario, selectedAnalysis } = getState().loadForecast;
  if (selectedAnalysis && selectedFeeders?.length > 0) {
    const timestamp = range?.end?.diff(range.start, 'hours') === 0 ? range.start.toISOString() : null;
    switch (selectedAnalysis.type) {
      case ANALYSIS_TYPES.POWERFLOW:
      case ANALYSIS_TYPES.QSTS:
      case ANALYSIS_TYPES.QSTS_OPF:
        dispatch(updateQSTSValues(
          workspace, branch, selectedFeeders.map(fdr => fdr.id), range,
        ));
        dispatch(
          powerflowActions.getGISViolations(
            workspace,
            branch,
            selectedFeeders.map(fdr => fdr.id),
            range.start,
            range.end,
            selectedScenario,
            selectedAnalysis.name,
          ),
        );
        break;
      case ANALYSIS_TYPES.HOSTING_CAPACITY:
        // Fetch hosting capacity results if a single timepoint is selected
        if (timestamp) {
          dispatch(powerflowActions.getHostingCapacityResults(
            workspace, branch, selectedFeeders.map(fdr => fdr.id), timestamp,
          ));
        }
        break;
      case ANALYSIS_TYPES.EV_CAPACITY:
        // Fetch ev capacity results if a single timepoint is selected
        if (timestamp) {
          dispatch(powerflowActions.getEVCapacityResults(
            workspace, branch, selectedFeeders.map(fdr => fdr.id), timestamp,
          ));
        }
        break;
      case ANALYSIS_TYPES.BATTERY_SIZING:
        dispatch(powerflowActions.getBatterySizingResults(
          workspace, branch, selectedFeeders.map(fdr => fdr.id),
        ));
        break;
      default:
        break;
    }
  }
};

export function setMaxRange(maxStart, maxEnd, selectedStart = null, selectedEnd = null, zoomLevel = null) {
  return (dispatch, getState) => {
    const { workspace, branch } = getState().network;
    const { selected: selectedFeeders } = getState().feeders;
    const { selectedScenario, selectedAnalysis, subHourInterval } = getState().loadForecast;

    if (maxStart && maxEnd) {
      // restrict the end range to 10 years
      const lastValidDate = moment(maxStart).add(10, 'years').subtract(1, 'millisecond');
      if (maxEnd > lastValidDate) {
        maxEnd = lastValidDate;
      }
    }

    if (!zoomLevel && maxStart && maxEnd) {
      zoomLevel = determineDefaultZoomLevel(maxStart, maxEnd, subHourInterval);
      maxEnd = getEndOfInterval(maxEnd, zoomLevel);
    }
    dispatch({
      type: SET_MAX_RANGE,
      range: { start: maxStart, end: maxEnd },
      zoomLevel,
    });
    dispatch({ type: CLEAR_POWERFLOW_RESULTS });
    dispatch(setTimeRange(
      selectedStart ?? maxStart,
      selectedEnd ?? (maxStart ? getEndOfInterval(maxStart, zoomLevel) : null),
    ));

    if (selectedAnalysis && selectedFeeders.length > 0) {
      dispatch(powerflowActions.getTimebarViolations(
        workspace,
        branch,
        selectedFeeders.map(fd => fd.id),
        { start: maxStart, end: maxEnd },
        selectedScenario,
        selectedAnalysis.name,
        zoomLevel,
      ));
    }
  };
}

function setScenarioRange(start, end, zoomLevel) {
  return (dispatch, getState) => {
    if (!zoomLevel && start && end) {
      const { subHourInterval } = getState().loadForecast;
      zoomLevel = determineDefaultZoomLevel(start, end, subHourInterval);
      end = getEndOfInterval(end, zoomLevel);
    }
    dispatch({ type: SET_SCENARIO_RANGE, range: { start, end } });
  };
}

export function determineTimeRangeForScenario(workspace, branch, scenarioId) {
  // Use the scenario extents to determine our range.
  return async (dispatch) => {
    dispatch({ type: SCENARIO_TIMESPAN_LOADING });
    const timeSpan = await determineScenarioTimeSpan(workspace, branch, scenarioId);
    dispatch({ type: SCENARIO_HAS_DATA, payload: true });

    const { start, end } = timeSpan;

    dispatch(setMaxRange(start, end));
    dispatch(setScenarioRange(start, end));
  };
}

export function determineTimeRangeForAnalysis(workspace, branch, scenarioId, feeders, analysis) {
  // Use the scenario extents to determine our range.
  return async (dispatch) => {
    if (!analysis) {
      await dispatch(determineTimeRangeForScenario(workspace, branch, scenarioId));
      return;
    }

    try {
      const tpPromise = getAnalysisTimepoints(workspace, branch, scenarioId, feeders, analysis);
      dispatch({ type: SCENARIO_TIMESPAN_LOADING });
      const timepoints = await tpPromise;
      switch (analysis.type) {
        case ANALYSIS_TYPES.POWERFLOW:
        case ANALYSIS_TYPES.QSTS:
        case ANALYSIS_TYPES.QSTS_OPF:
          dispatch(setSelectedLayer('real_power'));
          break;
        case ANALYSIS_TYPES.HOSTING_CAPACITY:
          dispatch(setSelectedLayer('hosting_capacity'));
          break;
        case ANALYSIS_TYPES.EV_CAPACITY:
          dispatch(setSelectedLayer('ev_capacity'));
          break;
        case ANALYSIS_TYPES.BATTERY_SIZING:
          dispatch(setSelectedLayer('battery_sizing_reactive_power'));
          break;
        default:
          break;
      }
      if (timepoints.length === 0) {
        throw new Error('No analysis timepoints found');
      }
      dispatch(setMaxRange(
        moment.utc(timepoints[0]),
        moment.utc(timepoints[timepoints.length - 1]),
      ));
      dispatch({ type: GET_QSTS_TIMEPOINTS, timepoints });
    } catch (err) {
      if (analysis.type === ANALYSIS_TYPES.BATTERY_SIZING) {
        dispatch(setMaxRange(null, null));
      } else {
        // if there is no analysis timepoints fallback on the scenario timerange
        await dispatch(determineTimeRangeForScenario(workspace, branch, scenarioId));
      }
    }
  };
}

// Loads network data
function loadNetworkData(workspace, branch, feederList) {
  return async (dispatch, getState) => {
    // Get the specific feeder if ID is provided. If not, load the entire workspace
    const feeders = uniq(feederList);

    const feederReq = new Request(
      `/api/workspace/${workspace}/branch/${branch}/feeder-content/stream`,
    );

    dispatch({
      type: NETWORK_DATA_PENDING,
      networkReq: feederReq,
      feeders,
    });

    try {
      const { data: feederData } = await feederReq.get({ params: { feeder: feeders } });
      dispatch(checkCoordinateSystem(workspace, branch));
      const { classes, objects } = feederData;
      let originalNetworkInstance;
      if (getState().network.networkInstance) {
        originalNetworkInstance = getState().network.networkInstance;
        originalNetworkInstance.addFeeder(classes, objects);
      } else {
        originalNetworkInstance = new Network(classes, objects);
      }

      Object.values(originalNetworkInstance.connectivityNodes).forEach((node) => {
        if (feeders.includes(node.feeder)) {
          const feederID = node.feeder;
          originalNetworkInstance.feeders[feederID].addNode(node);
        }
      });

      // Update url with newly selected feeder
      const { selectedAssetID, networkInstance, displayBranch } = getState().network;
      const existingFeeders = networkInstance ? networkInstance.feeders : {};
      const { selected } = getState().feeders;
      const { view } = getState().global;

      const allFeederIds = new Set([
        ...Object.keys(existingFeeders),
        ...Object.keys(originalNetworkInstance.feeders),
      ]);

      let feederIDs;
      if (selected.length) {
        feederIDs = selected.filter(fdr => allFeederIds.has(fdr.id));
      } else {
        feederIDs = Object.keys(originalNetworkInstance.feeders).map(id => ({ id }));
      }

      updateHistory(workspace, displayBranch, feederIDs, selectedAssetID, view);

      const hasCoordSystem = originalNetworkInstance.classes.CoordinateSystem
        && originalNetworkInstance.classes.CoordinateSystem[0]
        && originalNetworkInstance.lookup[originalNetworkInstance.classes.CoordinateSystem[0]];

      let invalidCoordinateSystem = true;
      if (hasCoordSystem) {
        const coordSystem = originalNetworkInstance.lookup[originalNetworkInstance.classes.CoordinateSystem[0]].attributes['CoordinateSystem.crsUrn'];
        invalidCoordinateSystem = Helpers.getShortURNCode(coordSystem) !== 'EPSG:4326';
      }

      dispatch(updateAttachments());
      dispatch({
        type: NETWORK_DATA_SUCCESS,
        payload: {
          lookup: originalNetworkInstance.lookup,
          feeders: originalNetworkInstance.feeders,
          requestedFeeders: feeders.reduce((lu, fdr_1) => {
            lu[fdr_1] = originalNetworkInstance.feeders[fdr_1];
            return lu;
          }, {}),
          network: originalNetworkInstance,
          networkGeoJSON: Helpers.createGeoJSON(
            originalNetworkInstance.connectivityNodes,
            originalNetworkInstance.shuntDevices,
            originalNetworkInstance.cables,
            originalNetworkInstance.linkDevices,
          ),
          invalidCoordinateSystem,
        },
      });
    } catch (err) {
      if (!feederReq.wasCancelled) {
        const { selected: selected_1 } = getState().feeders;
        const { view } = getState().global;
        const list = selected_1.filter(fdr_2 => !feeders.includes(fdr_2.id));
        updateHistory(workspace, getState().network.displayBranch, list, null, view);
        dispatch({ type: NETWORK_DATA_FAILURE, error: err, feeders });
      }
    }
  };
}

function clearNetworkData() {
  return {
    type: CLEAR_NETWORK_DATA,
  };
}

function setSelectedAsset(asset, name, asset_type, feeder) {
  return (dispatch, getState) => {
    const {
      leftRailPanel,
      workspace,
      displayBranch,
      lookup,
    } = getState().network;
    const assetObj = lookup?.[asset] ?? null;
    const { selected } = getState().feeders;
    const { view } = getState().global;
    const updatedLeftRailPanel = assetObj ? 'asset' : leftRailPanel;
    let selectedAsset = null;
    if (asset) {
      selectedAsset = {
        id: asset,
        name,
        asset_type,
        feeder,
      };
    }

    updateHistory(workspace, displayBranch, selected, asset, view);

    if (getState().edit.inPathEditMode) {
      // If in path edit mode, this implies cancel
      dispatch(networkEditActions.togglePathEditMode(true));
    }

    dispatch({
      type: SET_SELECTED_ASSET,
      selectedJSCimAsset: assetObj,
      selectedAsset,
      activePanel: 'asset',
      leftRailPanel: updatedLeftRailPanel,
    });
  };
}

function setHoveredObjectID(id) {
  return {
    type: SET_HOVERED_OBJECT_ID,
    payload: id,
  };
}

function toggleAssetPanel() {
  return {
    type: TOGGLE_ASSET_PANEL,
  };
}

export function setActivePanel(panel) {
  return {
    type: SET_ACTIVE_PANEL,
    panel,
  };
}

function toggleFeederPanel(feeder) {
  return {
    type: TOGGLE_FEEDER_PANEL,
    feeder,
  };
}

function createNewAsset(workspace, branch, request, extraData, type, properties) {
  return (dispatch, getState) => {
    dispatch({ type: ADD_ASSET_PENDING });
    const isNode = type === 'ConnectivityNode';

    return request
      .post({
        ...extraData,
        project_id: getState().edit.selectedProject,
      })
      .then(({ data }) => {
        const { networkInstance } = getState().network;
        const { lookup } = networkInstance;
        const newAssets = networkInstance.applyDifferenceModel(data);
        const [newAsset] = newAssets.filter(asset => (isNode
          ? lookup[asset].class === 'ConnectivityNode'
          : lookup[asset].class !== 'ConnectivityNode'));

        const state = getState();
        updateHistory(workspace, state.network.displayBranch, state.feeders.selected, newAsset, state.global.view);

        const selectedAsset = properties
          ? {
            id: properties.id,
            name: properties.name,
            asset_type: properties.asset_type,
            feeder: extraData.feeder,
          } : null;

        dispatch({
          type: ADD_ASSET_SUCCESS,
          networkInstance,
          networkGeoJSON: Helpers.createGeoJSON(
            networkInstance.connectivityNodes,
            networkInstance.shuntDevices,
            networkInstance.cables,
            networkInstance.linkDevices,
          ),
          newAsset: lookup[newAsset],
          newAssetID: newAsset,
          showAssetPanel: !isNode || (isNode && getState().feeders.selected.length === 1),
          selectedAsset,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          dispatch({ type: ADD_ASSET_NOT_ALLOWED });
          dispatch(
            displayAlertMessage(
              'Create Asset Failure',
              'You do not have permission to create assets on this network.',
            ),
          );
        } else {
          dispatch({ type: ADD_ASSET_FAILURE });
          dispatch(
            displayAlertMessage(
              'Create Asset Failure',
              `Could not create selected asset. Please confirm ${isNode ? '' : 'node '}configuration and try again.`,
              null,
              () => dispatch(createNewAsset(workspace, branch, request, extraData, type)),
            ),
          );
        }
      });
  };
}

function createNewNodeAsset(workspace, branch, node, updateType) {
  Analytics.logEvent('Added Shunt Device', 'Assets', updateType);
  const request = new Request(
    `/api/workspace/${workspace}/branch/${branch}/feeder/${node.feeder}/editor/node/${node.id}/${updateType}`,
  );
  return createNewAsset(workspace, branch, request);
}

function createNewLineAsset(workspace, branch, assetType, data) {
  Analytics.logEvent('Added Link Device', 'Assets', assetType);
  const request = new Request(`/api/workspace/${workspace}/branch/${branch}/editor/${assetType}`);
  return createNewAsset(workspace, branch, request, data);
}

const createNewNode = ([longitude, latitude], properties) => async (dispatch, getState) => {
  const { workspace, branch } = getState().network;
  const feeder = getState().edit.newAssetFeeder;
  const request = new Request(`/api/workspace/${workspace}/branch/${branch}/editor/node`);
  const data = {
    position: { latitude, longitude },
    feeder,
  };
  return dispatch(createNewAsset(workspace, branch, request, data, 'ConnectivityNode', properties));
};

const createNewLine = (start_node, end_node, positions, properties) => (
  (dispatch, getState) => {
    const { workspace, branch } = getState().network;
    const feeder = getState().edit.newAssetFeeder;
    const request = new Request(`/api/workspace/${workspace}/branch/${branch}/editor/line`);

    let data = {};
    if (end_node) {
      data = {
        start_node,
        end_node,
        positions: positions.map(([lng, lat]) => ({ longitude: lng, latitude: lat })),
      };
    } else if (start_node) {
      data = {
        start_node,
        feeder,
        positions: positions.map(([lng, lat]) => ({ longitude: lng, latitude: lat })),
      };
    }
    return dispatch(createNewAsset(workspace, branch, request, data, 'ACLineSegment', properties));
  }
);

const duplicateLine = (oldLineId) => (
  (dispatch, getState) => {
    const { workspace, branch } = getState().network;
    const request = new Request(`/api/workspace/${workspace}/branch/${branch}/editor/line`);
    const data = {};
    data.duplicate_from = oldLineId;
    return dispatch(createNewAsset(workspace, branch, request, data));
  }
);

function setActiveLeftPanel(panel) {
  return {
    type: SET_ACTIVE_LEFT_PANEL,
    payload: panel,
  };
}

function deleteContainer(container, workspace, branch) {
  return (dispatch, getState) => {
    const request = new Request(
      `/api/workspace/${workspace}/branch/${branch}/${container.type.toLowerCase()}/${container.id}`,
    );
    dispatch({
      type: DELETE_CONTAINER_PENDING,
    });

    return request
      .delete()
      .then(async ({ data }) => {
        const diffModel = data;
        const { networkInstance } = getState().network;
        if (networkInstance) networkInstance.applyDifferenceModel(diffModel);
        const networkGeoJSON = Helpers.createGeoJSON(
          networkInstance.connectivityNodes,
          networkInstance.shuntDevices,
          networkInstance.cables,
          networkInstance.linkDevices,
        );
        dispatch({
          type: DELETE_CONTAINER_SUCCESS,
          payload: { networkInstance, networkGeoJSON },
        });
        // unselect the delete container
        const feedersSelected = getState().feeders.selected.map(fdr => fdr.id);
        if (feedersSelected.includes(container.id)) {
          await dispatch(feederActions.setSelectedContainers(container));
        }
        // refresh the list of feeders
        const feeders = getState().feeders.selected.map(fdr => fdr.id);
        await dispatch(feederActions.getContainersList(feeders));
      })
      .catch((error) => {
        dispatch({
          type: DELETE_CONTAINER_FAILURE,
          payload: error,
        });
        dispatch(
          displayAlertMessage(
            `Delete ${container.type} Failed`,
            `An error occured when attempting to delete ${container.type}. Please refresh the page and try again`,
          ),
        );
      });
  };
}

function deleteAsset(id, workspace, branch, mergeLink = false, selectedProject) {
  return (dispatch, getState) => {
    const request = new Request(`/api/workspace/${workspace}/branch/${branch}/editor/asset/${id}`);
    dispatch({
      type: DELETE_ASSET_PENDING,
    });
    return request
      .deleteWithData({
        project_id: selectedProject,
      },
      {
        params: {
          merge_link: mergeLink,
        },
      })
      .then(({ data }) => {
        const diffModel = data;
        const { networkInstance } = getState().network;
        // force the selected asset to null first because the
        // rerender order of the containers results in exceptions
        // due to the selected asset not existing
        dispatch(setSelectedAssetID(null));
        networkInstance.applyDifferenceModel(diffModel);
        const networkGeoJSON = Helpers.createGeoJSON(
          networkInstance.connectivityNodes,
          networkInstance.shuntDevices,
          networkInstance.cables,
          networkInstance.linkDevices,
        );
        dispatch({
          type: DELETE_ASSET_SUCCESS,
          payload: { networkInstance, networkGeoJSON },
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_ASSET_FAILURE,
          payload: error,
        });
      });
  };
}

export const fetchBranches = workspace => async (dispatch) => {
  try {
    const req = new Request(`/api/workspace/${workspace}/branch`);
    const response = await req.get();

    return dispatch({
      type: FETCH_BRANCHES_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    return dispatch({
      type: FETCH_BRANCHES_FAILURE,
    });
  }
};

function createContainer(workspace, branch, containerName, containerType, substation) {
  const request = new Request(
    `/api/workspace/${workspace}/branch/${branch}/${containerType.toLowerCase()}`,
  );
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_CREATE_FEEDER_STATUS,
      status: asyncActionStates.LOADING,
    });

    Analytics.logEvent('Created New Container', containerType);
    try {
      const { data: diffModel } = await request.post({
        name: containerName,
        substation,
      });
      // Dont apply the diff model at this time, becuase we want the feeder model to be loaded
      // through the normal code path when the user 'checks' it in the left rail.
      dispatch({
        type: UPDATE_CREATE_FEEDER_STATUS,
        status: asyncActionStates.SUCCESS,
      });

      if (diffModel && diffModel.create) {
        const newContainers = Object.entries(diffModel.create).reduce(
          (acc, [id, obj]) => (obj.class === 'Substation' || obj.class === 'Feeder' ? [...acc, id] : acc),
          [],
        );
        await dispatch(feederActions.getContainersList(newContainers));
        dispatch(loadNetworkData(workspace, branch, newContainers));
      } else {
        updateHistory(workspace, getState().network.displayBranch, [], null, getState().global.view);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_CREATE_FEEDER_STATUS,
        status: asyncActionStates.ERROR,
      });
      if (error.response && error.response.status === 403) {
        dispatch(
          displayAlertMessage(
            `Create ${containerType} Failure`,
            `You do not have permission to create ${containerType}s on this network.`,
          ),
        );
      } else {
        dispatch(
          displayAlertMessage(
            `Create ${containerType} Failure`,
            `Could not create requested ${containerType}.`,
          ),
        );
      }
    }
  };
}

export function expandPanel() {
  return (dispatch, getState) => {
    const expand = !getState().network.panelOptions.expanded;
    dispatch({
      type: SET_PANEL_EXPANSION,
      expanded: expand,
    });
  };
}

/**
 * Updates the selected branch. Checks for edit mode branch before setting the branch into state.
 * @param {String}  name              Name of selected branch
 * @param {Array}   feeders           List of feeders (needed for fetching feeder data)
 * @param {Boolean} fetchData         If the branch change should trigger refetch of network data
 * @param {String}  modalType         The type of modal triggering the function for edit mode info
 * @param {String}  newWorkspace      The workspace to switch to
 */
export const updateSelectedBranch = (
  name,
  feeders = null,
  fetchData = true,
  modalType = MODAL_TYPES.TOGGLE,
  newWorkspace = null,
) => async (dispatch, getState) => {
  const { workspace, displayBranch } = getState().network;
  const ws = newWorkspace || workspace;

  const editBranchReq = new Request(`/api/workspace/${ws}/branch/${name}/edit_branch`);

  let branchName = name || displayBranch;
  let inEditMode = (
    [MODAL_TYPES.VERSION, MODAL_TYPES.SAVEAS, MODAL_TYPES.WORKSPACE].includes(modalType))
    ? getState().edit.inEditMode
    : false;

  try {
    let resp;
    if (([MODAL_TYPES.VERSION, MODAL_TYPES.SAVEAS].includes(modalType)
      && name !== displayBranch) || modalType === MODAL_TYPES.WORKSPACE) {
      resp = await editBranchReq.post();
    } else {
      resp = await editBranchReq.get();
    }
    if (resp.data.edit_branch_name) {
      branchName = resp.data.edit_branch_name;
      inEditMode = true;
    }
    // eslint-disable-next-line no-empty
  } catch (err) { }

  await dispatch({
    type: UPDATE_SELECTED_BRANCH,
    workspace: ws,
    branch: branchName,
    displayBranch: name,
    inEditMode,
    fetchData,
  });
  await dispatch(loadForecastActions.clearSelectedScenario());
  if (fetchData) {
    const activeFeeders = feeders || getState().feeders.selected.map(fdr => fdr.id);
    await dispatch(feederActions.getContainersList(activeFeeders, newWorkspace));
    feeders = getState().feeders.selected.map(fdr => fdr.id);
    if (feeders.length) {
      dispatch(loadNetworkData(ws, branchName, feeders));
    } else {
      const { view } = getState().global;
      const { newLink } = getState().edit;
      if (!newLink) updateHistory(ws, name, [], null, view);
    }
    dispatch(setActiveLeftPanel('network'));
  }
};

const createBranch = (
  workspace,
  name,
  start,
  notes = '',
  modalType = MODAL_TYPES.TOGGLE,
) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CREATE_BRANCH_STATUS,
      status: asyncActionStates.LOADING,
    });

    const req = new Request(`/api/workspace/${workspace}/branch`);
    await req.post({ name, notes, start });

    await dispatch(fetchBranches(workspace));

    await dispatch(updateSelectedBranch(name, [], true, modalType));
    Analytics.logEvent('Created New Network Version', 'Network Version');
    return dispatch({
      type: UPDATE_CREATE_BRANCH_STATUS,
      status: asyncActionStates.SUCCESS,
    });
  } catch (err) {
    return dispatch({
      type: UPDATE_CREATE_BRANCH_STATUS,
      status: asyncActionStates.ERROR,
    });
  }
};

const deleteBranch = (workspace, name, modalType = MODAL_TYPES.TOGGLE) => async (dispatch) => {
  try {
    const req = new Request(`/api/workspace/${workspace}/branch/${name}`);
    await req.delete();
    dispatch({ type: DELETE_BRANCH_SUCCESS });
    if (modalType !== MODAL_TYPES.VERSION) {
      await dispatch(fetchBranches(workspace));
      dispatch(updateSelectedBranch('master'));
    }
  } catch (err) {
    dispatch({ type: DELETE_BRANCH_FAILURE });
    dispatch(
      displayAlertMessage(
        'Delete Network Version Failed',
        'An error occured when attempting to delete network version. Please try again',
      ),
    );
  }
};

const getNodeSubstationDistances = (workspace, branch, feeder) => async (dispatch) => {
  const req = new Request(
    `/api/workspace/${workspace}/branch/${branch}/feeder/${feeder}/node_substation_distance`,
  );
  dispatch({ type: GET_NODE_SUBSTATION_DISTANCE_LOADING });
  try {
    const { data } = await req.get();
    dispatch({ type: GET_NODE_SUBSTATION_DISTANCE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: GET_NODE_SUBSTATION_DISTANCE_ERROR });
  }
};

function setAssetTypeVisibility(assetType, visibility) {
  return {
    type: SET_ASSET_VISIBILITY,
    assetType,
    visibility,
  };
}

export const clearResults = () => ({ type: CLEAR_ANALYSIS_RESULTS });

export const setSelectedAssetID = (id) => ({ type: SET_SELECTED_ASSET_ID, id });

export const actions = {
  loadNetworkData,
  clearNetworkData,
  setSelectedAsset,
  setHoveredObjectID,
  toggleAssetPanel,
  setActivePanel,
  setSelectedLayer,
  setActiveLeftPanel,
  checkCoordinateSystem,
  toggleFeederPanel,
  setCurrentWorkspace,
  updateQSTSValues,
  createNewNodeAsset,
  createNewLineAsset,
  createNewNode,
  createNewLine,
  duplicateLine,
  deleteAsset,
  deleteContainer,
  determineTimeRangeForScenario,
  determineTimeRangeForAnalysis,
  setMaxRange,
  setScenarioRange,
  setTimeRange,
  fetchBranches,
  updateSelectedBranch,
  createBranch,
  deleteBranch,
  getNodeSubstationDistances,
  setFilteredPanel,
  setFlyToAsset,
  createContainer,
  setAssetTypeVisibility,
  expandPanel,
  clearResults,
  setSelectedAssetID,
};

// ------------------------------------
// Reducer
// ------------------------------------
const networkState = {
  filteredPanel: [],
  lookup: {},
  requestStatus: {},
  assetTypeVisibility: {},
};

const initialState = {
  ...networkState,
  selectedNode: null,
  // The RDF ID of the thing selected by the user on the GIS view
  selectedAssetID: null,
  // The ViewModel class of the thing selected by the user on the GIS View
  // NOTE: This is NOT guaranteed to be the CIM class. In some cases the ViewModel
  // class is identical to the CIM class (e.g. ConnectivityNode). In other cases it is
  // different (e.g. InverterPV view model is an Inverter in cim)
  selectedAssetViewModelClass: null,
  hoveredAssetID: null,
  panelOptions: {
    open: true,
    expanded: false,
    active: 'asset',
    selectedLayer: 'real_power',
  },
  pendingReqs: {},
  errors: {},
  addAssetRequest: asyncActionStates.INITIAL,
  applyDifferenceModelRequest: {},
  leftRailPanel: 'network',
  workspace: null,
  SV: {
    powers: {},
    voltages: {},
    energies: {},
    tap_positions: {},
  },
  timepoints: {
    results: [],
  },
  qstsLoading: false,
  networkInstance: null,
  networkGeoJSON: null,
  determiningTimeRange: false,
  timeRange: {
    start: null,
    end: null,
  },
  scenarioRange: {
    start: null,
    end: null,
  },
  maxRange: {
    start: null,
    end: null,
  },
  timeBarZoomLevel: 'hour',
  branch: 'master',
  displayBranch: 'master',
  branches: [],
  newBranchReq: asyncActionStates.INITIAL,
  nodeSubstationDistances: {},
  flyToAsset: null,
  deleteStatus: asyncActionStates.INITIAL,
  withFiles: [],
  withNotes: [],
  isAggregatedData: false,
};

export default function NetworkReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ATTACHMENTS:
      return {
        ...state,
        withFiles: action.withFiles,
        withNotes: action.withNotes,
      };
    case SET_FLY_TO_ASSET:
      return {
        ...state,
        flyToAsset: action.payload,
      };
    case NETWORK_DATA_PENDING:
      const pendingNetReq = action.feeders.reduce(
        (lookup, feeder) => ({ ...lookup, [feeder]: action.networkReq }),
        { ...state.pendingReqs },
      );
      const pendingReqStatus = action.feeders.reduce(
        (lookup, feeder) => ({ ...lookup, [feeder]: asyncActionStates.LOADING }),
        { ...state.requestStatus },
      );
      return {
        ...state,
        pendingReqs: pendingNetReq,
        requestStatus: pendingReqStatus,
        withFiles: [],
        withNotes: [],
      };
    case NETWORK_DATA_SUCCESS:
      const {
        feeders,
      } = action.payload;

      // Remove requests from pending and set request status
      const withoutSuccessfulReq = Object.values(feeders).reduce((lu, feeder) => {
        delete lu[feeder.id];
        return lu;
      }, state.pendingReqs);
      const successReqStatus = Object.values(feeders).reduce(
        (lu, feeder) => ({ ...lu, [feeder.id]: asyncActionStates.SUCCESS }),
        { ...state.requestStatus },
      );

      // Merge feeders and determine which colors to use for them.
      const feederIDs = Object.keys(feeders);
      const totalColors = feederIDs.length <= 40 ? 40 : feederIDs.length;
      feederIDs.forEach((feeder, i) => {
        feeders[feeder].color = Helpers.generateColor(totalColors, feederIDs.length, i);
      });

      return {
        ...state,
        networkInstance: action.payload.network,
        networkGeoJSON: action.payload.networkGeoJSON,
        lookup: { ...action.payload.network.lookup },
        pendingReqs: {
          ...state.pendingReqs,
          ...withoutSuccessfulReq,
        },
        panelOptions: {
          ...state.panelOptions,
          open: feederIDs.length < 1 ? true : state.panelOptions.open,
          active: !state.panelOptions.active ? 'asset' : state.panelOptions.active,
        },
        requestStatus: successReqStatus,
        invalidCoordinateSystem: action.payload.invalidCoordinateSystem,
      };
    case NETWORK_DATA_FAILURE:
      const updatedReq = { ...state.pendingReqs };
      const updatedStatus = { ...state.requestStatus };
      const updatedErrors = { ...state.errors };
      action.feeders.forEach((feeder) => {
        delete updatedReq[feeder];
        updatedStatus[feeder] = asyncActionStates.ERROR;
        updatedErrors[feeder] = action.error;
      });
      return {
        ...state,
        errors: updatedErrors,
        pendingReqs: updatedReq,
        requestStatus: updatedStatus,
      };
    case CLEAR_NETWORK_DATA:
      const pendingReqs = Object.values(state.pendingReqs);
      pendingReqs.forEach((req) => {
        if (req !== null && req !== undefined) {
          req.cancel('Cancelled due to navigation');
        }
      });
      return {
        ...state,
        ...initialState,
        invalidCoordinateSystem: undefined,
      };
    case SET_SELECTED_ASSET:
      return {
        ...state,
        selectedNode: action.selectedJSCimAsset,
        selectedAssetID: action.selectedJSCimAsset ? action.selectedJSCimAsset.id : null,
        selectedAssetViewModelClass: action.selectedJSCimAsset ? action.selectedJSCimAsset.class : null,
        selectedAssetName: action.selectedJSCimAsset ? action.selectedJSCimAsset.name : null,
        selectedAsset: action.selectedAsset,
        panelOptions: {
          ...state.panelOptions,
          open: true,
          active: action.activePanel,
        },
        leftRailPanel: action.leftRailPanel,
        applyDifferenceModelRequest: {
          ...state.applyDifferenceModelRequest,
          addBattery: asyncActionStates.INITIAL,
          addPV: asyncActionStates.INITIAL,
          editValues: asyncActionStates.INITIAL,
          error: {},
        },
        deleteStatus: asyncActionStates.INITIAL,
        errors: {
          ...state.errors,
          delete: '',
        },
      };
    case SET_HOVERED_OBJECT_ID:
      return {
        ...state,
        hoveredAssetID: action.payload,
      };
    case TOGGLE_ASSET_PANEL:
      return {
        ...state,
        panelOptions: {
          ...state.panelOptions,
          open: !state.panelOptions.open,
        },
      };
    case SET_ACTIVE_PANEL:
      return {
        ...state,
        panelOptions: {
          ...state.panelOptions,
          active: action.panel,
          open: true,
        },
      };
    case SET_SELECTED_LAYER:
      const current = state.panelOptions.selectedLayer;
      const updatedValue = current === action.layer && action.toggleIfSet ? null : action.layer;

      return {
        ...state,
        panelOptions: {
          ...state.panelOptions,
          selectedLayer: updatedValue,
        },
      };
    case ADD_ASSET_PENDING:
      return {
        ...state,
        addAssetRequest: asyncActionStates.LOADING,
      };
    case ADD_ASSET_SUCCESS:
      return {
        ...state,
        addAssetRequest: asyncActionStates.SUCCESS,
        networkInstance: action.networkInstance,
        networkGeoJSON: action.networkGeoJSON,
        lookup: { ...action.networkInstance.lookup },
        selectedNode: action.newAsset,
        selectedAsset: action.selectedAsset,
        selectedAssetID: action.newAssetID || null,
        selectedAssetViewModelClass: action.newAsset ? action.newAsset.class : null,
        newAssetID: action.newAssetID || null,
        hoveredAssetID: null,
        panelOptions: {
          ...state.panelOptions,
          active: action.showAssetPanel ? 'asset' : state.panelOptions.active,
          open: true,
        },
        leftRailPanel: 'asset',
      };
    case ADD_ASSET_FAILURE:
      return {
        ...state,
        addAssetRequest: asyncActionStates.ERROR,
        hoveredAssetID: null,
      };
    case ADD_ASSET_NOT_ALLOWED:
      return {
        ...state,
        addAssetRequest: asyncActionStates.NOTALLOWED,
        hoveredAssetID: null,
      };
    case UPDATE_APPLY_DIFFERENCE:
      let networkUpdate = {};
      if (action.status === asyncActionStates.SUCCESS) {
        networkUpdate = {
          networkInstance: action.network,
          networkGeoJSON: Helpers.createGeoJSON(
            action.network.connectivityNodes,
            action.network.shuntDevices,
            action.network.cables,
            action.network.linkDevices,
          ),
          lookup: { ...action.network.lookup },
        };
      }
      return {
        ...state,
        applyDifferenceModelRequest: {
          ...state.applyDifferenceModelRequest,
          [action.updateType]: action.status,
          error: action.error,
        },
        ...networkUpdate,
      };
    case SET_SELECTED_CONTAINER:
      return {
        ...state,
        panelOptions: {
          ...state.panelOptions,
          active: 'asset',
        },
        filteredPanel: [],
      };
    case SET_ACTIVE_LEFT_PANEL:
      return {
        ...state,
        leftRailPanel: action.payload,
      };
    case CHECK_COORDINATE_SYSTEM:
      return {
        ...state,
        invalidCoordinateSystem: action.invalid,
      };
    case TOGGLE_FEEDER_PANEL:
      return {
        ...state,
        leftRailPanel: 'network',
      };
    case SET_CURRENT_WORKSPACE:
      return {
        ...state,
        workspace: action.workspace,
      };
    case QSTS_VALUES_LOADING:
      return {
        ...state,
        qstsLoading: true,
        pendingReqs: {
          ...state.pendingReqs,
          qstsResults: action.request,
        },
      };
    case CLEAR_SELECTED_SCENARIO:
      return {
        ...state,
        timepoints: {
          scenario: [],
          results: [],
          hcresults: [],
        },
        pendingReqs: omit(state.pendingReqs, ['qstsTimePoints']),
      };
    case GET_QSTS_TIMEPOINTS:
      return {
        ...state,
        timepoints: {
          results: action.timepoints,
        },
        pendingReqs: omit(state.pendingReqs, ['qstsTimePoints']),
      };
    case QSTS_VALUES_SUCCESS:
    case QSTS_VALUES_ERROR:
      return {
        ...state,
        SV: action.SV || defaultSV,
        qstsLoading: false,
        pendingReqs: omit(state.pendingReqs, ['qstsResults']),
        results: action.results,
        isAggregatedData: action.isAggregatedData,
      };
    case CLEAR_SELECTED_RANGE_DATA:
      return {
        ...state,
        SV: defaultSV,
        qstsLoading: false,
      };
    case POWERFLOW_SUCCESS:
      // Cancel any old timepoints requests if powerflow has just run.
      if (state.pendingReqs.qstsTimePoints) {
        state.pendingReqs.qstsTimePoints.cancel();
      }
      if (state.pendingReqs.qstsResults) {
        state.pendingReqs.qstsResults.cancel();
      }
      return {
        ...state,
        pendingReqs: omit(state.pendingReqs, ['qstsTimePoints', 'qstsResults']),
      };
    case DELETE_ASSET_PENDING:
      return {
        ...state,
        deleteStatus: asyncActionStates.LOADING,
      };
    case DELETE_ASSET_SUCCESS:
      return {
        ...state,
        networkInstance: action.payload.networkInstance,
        networkGeoJSON: action.payload.networkGeoJSON,
        lookup: { ...action.payload.networkInstance.lookup },
        deleteStatus: asyncActionStates.SUCCESS,
        selectedNode: null,
        selectedAssetID: null,
        selectedAssetViewModelClass: null,
      };
    case DELETE_ASSET_FAILURE:
      return {
        ...state,
        deleteStatus: asyncActionStates.ERROR,
        errors: {
          ...state.errors,
          delete: action.payload,
        },
      };
    case DELETE_CONTAINER_PENDING:
      Object.values(state.pendingReqs).forEach((req) => {
        if (req !== null && req !== undefined) {
          req.cancel('Cancelled due to navigation');
        }
      });
      return {
        ...state,
        pendingReqs: initialState.pendingReqs,
        deleteStatus: asyncActionStates.LOADING,
      };
    case DELETE_CONTAINER_SUCCESS:
      return {
        ...state,
        deleteStatus: asyncActionStates.SUCCESS,
        networkInstance: action.payload.networkInstance,
        networkGeoJSON: action.payload.networkGeoJSON,
        lookup: { ...action.payload.networkInstance.lookup },
        selectedNode: null,
        selectedAssetID: null,
        selectedAssetViewModelClass: null,
      };
    case DELETE_CONTAINER_FAILURE:
      return {
        ...state,
        deleteStatus: asyncActionStates.ERROR,
        errors: {
          ...state.errors,
          delete: action.payload,
        },
      };
    case DETERMINING_TIME_RANGE:
      return {
        ...state,
        determiningTimeRange: true,
      };
    case UPDATE_TIME_RANGE:
      return {
        ...state,
        timeRange: { ...action.range },
        determiningTimeRange: false,
      };
    case UPDATE_HIGHLIGHTED_TIME_RANGE:
      return {
        ...state,
        timeRange: { ...action.range },
        determiningTimeRange: false,
      };
    case SET_MAX_RANGE:
      const maxRangeSame = (
        action.range.start === state.maxRange.start
        || action.range.start?.isSame(state.maxRange.start)
      ) && (
        action.range.end === state.maxRange.end
        || action.range.end?.isSame(state.maxRange.end)
      );
      const zoomLevelSame = action.zoomLevel === state.timeBarZoomLevel;
      return {
        ...state,
        ...(maxRangeSame ? {} : { maxRange: action.range }),
        ...(zoomLevelSame ? {} : { timeBarZoomLevel: action.zoomLevel }),
      };
    case SET_SCENARIO_RANGE:
      return {
        ...state,
        scenarioRange: action.range,
      };
    case FETCH_BRANCHES_SUCCESS:
      return {
        ...state,
        branches: action.payload,
      };
    case UPDATE_SELECTED_BRANCH:
      if (action.fetchData) {
        return {
          ...state,
          ...networkState,
          workspace: action.workspace,
          branch: action.branch,
          displayBranch: action.displayBranch,
          SV: defaultSV,
          selectedNode: null,
          selectedAssetID: null,
          selectedAssetViewModelClass: null,
          timepoints: {
            results: [],
          },
          networkInstance: null,
          networkGeoJSON: null,
          map: null,
          results: null,
        };
      }
      return {
        ...state,
        branch: action.branch,
        displayBranch: action.displayBranch,
        results: null,
      };
    case UPDATE_CREATE_BRANCH_STATUS:
      return {
        ...state,
        newBranchReq: action.status,
      };
    case UPDATE_CREATE_FEEDER_STATUS:
      return {
        ...state,
      };
    case GET_NODE_SUBSTATION_DISTANCE_SUCCESS: {
      return {
        ...state,
        nodeSubstationDistances: action.payload,
      };
    }
    case SET_FILTERED_PANEL:
      return {
        ...state,
        filteredPanel: action.payload,
      };
    case SELECT_NEW_ASSET:
    case CLEAR_NEW_ASSET:
      return {
        ...state,
        hoveredAssetID: null,
      };
    case SET_ASSET_VISIBILITY:
      return {
        ...state,
        assetTypeVisibility: {
          ...state.assetTypeVisibility,
          [action.assetType]: action.visibility,
        },
      };
    case SET_PANEL_EXPANSION:
      return {
        ...state,
        panelOptions: {
          ...state.panelOptions,
          expanded: action.expanded,
        },
      };
    case CLEAR_ANALYSIS_RESULTS:
      return {
        ...state,
        results: null,
      };
    case SET_SELECTED_ASSET_ID:
      return {
        ...state,
        selectedAssetID: action.id,
      };
    default:
      return state;
  }
}
