import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'components/Button';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import ErrorDetails from './ErrorDetails/ErrorDetails';
import PanelHeader from '../PanelHeader';

import './ValidationPanel.scss';

const ValidationPanel = (props) => {
  const {
    fixersRunning, workspaceErrors, validationLoading,
  } = props.validation;

  let totalErrors = 0;
  let totalWarnings = 0;

  if (workspaceErrors) {
    Object.values(workspaceErrors).forEach((workspace) => {
      Object.values(workspace.data).forEach((data) => {
        let warnings = 0;
        let errors = 0;

        for (let i = 0; i < data.messages.length; i += 1) {
          const { severity } = data.messages[i];
          if (severity === 'ERROR') {
            errors += 1;
          } else if (severity === 'WARNING') {
            warnings += 1;
          }
        }

        totalErrors += errors;
        totalWarnings += warnings;
      });
    });
  }

  const canValidate = !props.isAuthEnabled || props.permissions.has('run_validate');

  return (
    <div
      className={classNames({
        'validation-panel': true,
        'validation-panel--expanded': props.expanded,
      })}
    >
      <PanelHeader
        name={props.workspace}
        type="Workspace"
        icon="/ic_workspace.svg"
        header="Network Validation"
      />
      <div className="validation-check-container">
        <div className="validation-totals-container">
          <div className="validation-type-container">
            <i style={{ color: totalErrors ? 'red' : 'inherit' }} className="material-icons">highlight_off</i>
            <p className="validation-type-total">{totalErrors}</p>
          </div>
          <div className="validation-type-container">
            <i style={{ color: totalWarnings ? 'orange' : 'inherit' }} className="material-icons">warning</i>
            <p className="validation-type-total">{totalWarnings}</p>
          </div>
        </div>
        <div>
          <PermissionDisabledTip title="Validate" hide={canValidate} placement="left" theme={props.theme}>
            <Button
              className={`powerflow-btn ${validationLoading ? 'powerflow-btn--active' : ''}`}
              onClick={() => props.actions.validateWorkspace(props.workspace)}
              disabled={!canValidate || validationLoading || fixersRunning}
              type="primary"
              theme={props.theme}
            >
              Validate
              { validationLoading
                && <i className="material-icons rotate validate-loading">refresh</i>}
            </Button>
          </PermissionDisabledTip>
        </div>
      </div>
      {canValidate
        && (
        <ErrorDetails
          workspaceErrors={workspaceErrors}
          fixersRunning={fixersRunning}
          applyFixers={props.actions.applyFixers}
          displayAlertMessage={props.actions.displayAlertMessage}
          workspace={props.workspace}
          selectedFeeders={props.selectedFeeders}
          theme={props.theme}
          branch={props.branch}
          validationRunning={validationLoading}
        />
        )}
    </div>
  );
};

ValidationPanel.propTypes = {
  validation: PropTypes.shape({
    fixersRunning: PropTypes.bool,
    workspaceErrors: PropTypes.object,
    fixers: PropTypes.object,
    fixerLog: PropTypes.array,
    validationLoading: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    applyFixers: PropTypes.func,
    validateWorkspace: PropTypes.func,
    displayAlertMessage: PropTypes.func,
    resetFixerLogs: PropTypes.func,
  }).isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  selectedFeeders: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default ValidationPanel;
