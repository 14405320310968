/* eslint-disable import/no-cycle */
import { Request } from '@opusonesolutions/gridos-app-framework';
import escapeRegExp from 'lodash/escapeRegExp';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import asyncActionStates from 'helpers/asyncActionStates';
import { sortByName } from 'helpers/utils';
import browserHistory from 'routes/history';
import { CLEAR_NETWORK_DATA, NETWORK_DATA_FAILURE, actions } from './network';

const CONTAINER_LIST_LOADING = 'CONTAINER_LIST_LOADING';
const CONTAINER_LIST_SUCCESS = 'CONTAINER_LIST_SUCCESS';
const CONTAINER_LIST_FAILURE = 'CONTAINER_LIST_FAILURE';
const CONTAINER_RENAME_SUCCESS = 'CONTAINER_RENAME_SUCCESS';
const CONTAINERS_FILTER_UPDATED = 'CONTAINERS_FILTER_UPDATED';
export const SET_SELECTED_CONTAINER = 'SET_SELECTED_CONTAINER';
export const OVERRIDE_SELECTED_CONTAINERS = 'OVERRIDE_SELECTED_CONTAINERS';

const updateInArray = (array, newValue, key) => array.map(
  item => (item[key] === newValue[key] ? { ...item, ...newValue } : item),
);

const isSub = container => container.type === 'Substation';
const getAncestorKey = container => (isSub(container) ? 'upstream_feeder' : 'substation');
const isRoot = c => c.parent === null;

const buildContainerTree = (containers) => {
  const containersMap = containers.reduce((acc, c) => ({ ...acc, [c.id]: c }), {});
  const ancestryMap = containers.reduce((acc, container) => {
    const parent = container[getAncestorKey(container)];
    if (parent && containersMap[parent]) {
      acc = {
        ...acc,
        [parent]: {
          ...acc[parent],
          children: acc[parent] ? [...acc[parent].children, container] : [container],
        },
      };
    }
    return {
      ...acc,
      [container.id]: {
        ...container,
        parent: containersMap[parent] ? parent : null,
        children: acc[container.id] ? acc[container.id].children : [],
      },
    };
  }, {});

  const buildSubTree = ({ id }) => {
    const container = ancestryMap[id];
    if (!container.children.length) {
      return container;
    }
    return {
      ...container,
      children: container.children.map(buildSubTree),
    };
  };

  return Object.values(ancestryMap)
    .filter(isRoot)
    .map(buildSubTree);
};

export function getContainersList(selectedList, newWorkspace = null, newBranch = null) {
  // Get list of feeder names and IDs.
  return (dispatch, getState) => {
    const {
      network: { branch, workspace },
      feeders: { selected },
    } = getState();

    const ws = newWorkspace || workspace;
    const br = newBranch || branch;
    const request = new Request(`/api/workspace/${ws}/branch/${br}/feeder`);
    dispatch({ type: CONTAINER_LIST_LOADING, feederReq: request });
    return request
      .get()
      .then(({ data }) => {
        const sortedFeeders = data.feeders.map(f => ({ ...f, type: 'Feeder' })).sort(sortByName);
        const sortedSubstations = data.substations
          .map(s => ({ ...s, type: 'Substation' }))
          .sort(sortByName);
        const containers = [...sortedFeeders, ...sortedSubstations];
        dispatch({
          type: CONTAINER_LIST_SUCCESS,
          list: containers,
          tree: buildContainerTree(containers),
        });
        if ((selectedList && selectedList.length) || (selected && selected.length)) {
          const errors = Object.entries(getState().network.pendingReqs)
            .filter(req => req[1] === asyncActionStates.ERROR)
            .map(req => req[0]);
          const selectedIds = selected.map(c => c.id);
          const containerList = containers.filter(
            container => selectedIds.includes(container.id) && !errors.includes(container.id),
          );

          uniq(selectedList).forEach((id) => {
            const selectedContainer = containers.find(c => c.id === id);
            if (selectedContainer && !selectedIds.includes(id)) {
              containerList.push(selectedContainer);
            }
          });

          dispatch({
            type: SET_SELECTED_CONTAINER,
            payload: containerList,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: CONTAINER_LIST_FAILURE,
          payload: error,
        });
      });
  };
}

export function updateContainerFilter(filter) {
  return (dispatch, getState) => {
    const escapedFilter = escapeRegExp(filter);
    const regex = new RegExp(`.*${escapedFilter}.*`, 'i');

    const nameOrIdMatches = ({ id, name }) => (name || id) && (name || id).match(regex);

    const { list } = getState().feeders;
    const filteredContainers = list.filter(nameOrIdMatches);
    dispatch({
      type: CONTAINERS_FILTER_UPDATED,
      filter,
      tree: buildContainerTree(filteredContainers),
    });
  };
}

function updateSelectedUrl(containerIds) {
  return (dispatch, getState) => {
    const {
      selectedNode, workspace, displayBranch, networkInstance,
    } = getState().network;
    const feeders = networkInstance ? networkInstance.feeders : {};
    const anyContainersLoaded = containerIds.some(id => feeders[id]);

    if (!anyContainersLoaded) {
      browserHistory.push(`/${workspace}/${displayBranch}/gis`);
      return;
    }

    const containerIdStr = containerIds.join(',');
    if (!selectedNode) {
      browserHistory.push(`/${workspace}/${displayBranch}/gis/${containerIdStr}`);
      return;
    }

    // Only set the asset if it is actually in the list of feeders
    if (containerIds.length && containerIds.includes(selectedNode.feeder)) {
      browserHistory.push(
        `/${workspace}/${displayBranch}/gis/${containerIdStr}/asset/${selectedNode.id}`,
      );
    } else {
      // Clear node as it is no longer in the set of selected feeders
      dispatch(actions.setSelectedAssetID(null));
      browserHistory.push(`/${workspace}/${displayBranch}/gis/${containerIdStr}`);
    }
  };
}

function containersToUpdate(selected, container) {
  let containersToSelect = [...selected];
  const alreadySelected = !!selected.find(({ id }) => id === container.id);

  if (alreadySelected) {
    containersToSelect = containersToSelect.filter(({ id }) => id !== container.id);
  } else {
    containersToSelect = isSub(container) // Only select direct children for substations
      ? [...containersToSelect, container, ...container.children]
      : [...containersToSelect, container];
    containersToSelect = uniqBy(containersToSelect, ({ id }) => id);
  }

  return containersToSelect;
}

function setSelectedContainers(container) {
  return (dispatch, getState) => {
    const {
      network,
      feeders: { selected },
      loadForecast: { selectedScenario, selectedAnalysis },
    } = getState();
    const { workspace, branch, networkInstance } = network;
    const feeders = networkInstance ? networkInstance.feeders : {};

    const containersToSelect = containersToUpdate(selected, container);
    const containerIds = containersToSelect.map(c => c.id);

    // Don't need to refetch already loaded containers
    const containersToLoad = containerIds.filter(id => !feeders[id]);
    if (containersToLoad.length) {
      dispatch(actions.loadNetworkData(workspace, branch, containersToLoad));
    }

    dispatch({
      type: SET_SELECTED_CONTAINER,
      payload: containersToSelect,
    });

    dispatch(updateSelectedUrl(containerIds));

    if (selectedAnalysis) {
      // Use the new list of selected feeders to figure out what the time range should be
      dispatch(actions.determineTimeRangeForAnalysis(
        workspace,
        branch,
        selectedScenario,
        containersToSelect,
        selectedAnalysis,
      ));
    }
  };
}

function overrideSelectedContainers(feeders) {
  return {
    type: OVERRIDE_SELECTED_CONTAINERS,
    payload: feeders,
  };
}

function renameContainer(workspace, container, name) {
  return async (dispatch, getState) => {
    const { branch } = getState().network;
    const request = new Request(
      `/api/workspace/${workspace}/branch/${branch}/${container.type.toLowerCase()}/${container.id}`,
    );
    const data = { name };

    return request
      .patch(data)
      .then(() => dispatch({
        type: CONTAINER_RENAME_SUCCESS,
        payload: { ...container, name },
      }))
      .catch(error => dispatch({
        type: CONTAINER_LIST_FAILURE,
        payload: error.message,
      }));
  };
}

export const feederActions = {
  getContainersList,
  setSelectedContainers,
  overrideSelectedContainers,
  renameContainer,
  updateSelectedUrl,
};

const initialState = {
  list: [],
  tree: [],
  selected: [],
  pendingReq: {},
  filter: '',
};

export default function FeederReducer(state = initialState, action) {
  let updatePending;
  switch (action.type) {
    case CONTAINER_LIST_LOADING:
      return {
        ...state,
        list: [],
        tree: [],
        pendingReq: {
          ...state.pendingReq,
          feederReq: action.feederReq,
        },
      };
    case CONTAINERS_FILTER_UPDATED:
      return {
        ...state,
        tree: action.tree,
        filter: action.filter,
      };
    case CONTAINER_LIST_SUCCESS:
      updatePending = omit(state.pendingReq, ['feederReq']);
      return {
        ...state,
        list: action.list,
        tree: action.tree,
        pendingReq: updatePending,
      };
    case CONTAINER_RENAME_SUCCESS:
      updatePending = omit(state.pendingReq, ['feederReq']);
      return {
        ...state,
        list: updateInArray(state.list, action.payload, 'id'),
        selected: [action.payload],
        pendingReq: updatePending,
      };
    case CONTAINER_LIST_FAILURE:
      updatePending = omit(state.pendingReq, ['feederReq']);
      return {
        ...state,
        errors: {
          ...state.errors,
          list: action.payload,
        },
        pendingReq: updatePending,
      };
    case SET_SELECTED_CONTAINER:
      return {
        ...state,
        selected: action.payload,
      };
    case CLEAR_NETWORK_DATA:
      if (state.pendingReq.feederReq) {
        state.pendingReq.feederReq.cancel('Cancelled due to navigation');
      }

      return {
        ...state,
        selected: [],
        list: [],
        tree: [],
      };
    case NETWORK_DATA_FAILURE:
      const updatedSelected = state.selected.filter((feeder) => {
        if (typeof feeder === 'string') {
          return !action.feeders.includes(feeder);
        }
        return !action.feeders.includes(feeder.id);
      });
      return {
        ...state,
        selected: updatedSelected,
      };
    case OVERRIDE_SELECTED_CONTAINERS:
      return {
        ...state,
        selected: action.payload,
      };
    default:
      return state;
  }
}
