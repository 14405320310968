import Analytics from 'helpers/Analytics';

export const DISPLAY_ALERT_MESSAGE = 'DISPLAY_ALERT_MESSAGE';
export const CLEAR_ALERT_MESSAGE = 'CLEAR_ALERT_MESSAGE';
export const SET_THEME = 'SET_THEME';
export const CHANGE_MAP_MODE = 'CHANGE_MAP_MODE';
export const SET_VIEW = 'SET_VIEW';

const LOAD_SETTINGS = 'LOAD_SETTINGS';
const UPDATE_SETTINGS = 'UPDATE_SETTINGS';

function setView(view) {
  Analytics.logEvent('Activated View', view);
  return {
    type: SET_VIEW,
    payload: view,
  };
}

export function displayAlertMessage(title, message, onConfirm, onRetry) {
  return {
    type: DISPLAY_ALERT_MESSAGE,
    title,
    message,
    onRetry,
    onConfirm,
  };
}

function clearAlertMessage() {
  return {
    type: CLEAR_ALERT_MESSAGE,
  };
}

function changeTheme(theme) {
  return (dispatch) => {
    dispatch({
      type: SET_THEME,
      theme,
    });
  };
}

function loadSettings() {
  const str = localStorage.getItem('GRIDOS_SETTINGS');

  let settings;
  try {
    settings = str ? JSON.parse(str) : {};
  } catch (err) {
    settings = {};
  }

  return {
    type: LOAD_SETTINGS,
    settings: {
      ...initialState.settings, // ensure defaults are applied
      ...settings,
    },
  };
}

function updateSettings(key, mergeSettings) {
  return (dispatch, getState) => {
    const state = getState().global;
    const settings = {
      ...state.settings,
      [key]: {
        ...(state.settings[key] || {}),
        ...mergeSettings,
      },
    };

    localStorage.setItem('GRIDOS_SETTINGS', JSON.stringify(settings));

    dispatch({
      type: UPDATE_SETTINGS,
      settings,
    });
  };
}

export const actions = {
  displayAlertMessage,
  clearAlertMessage,
  changeTheme,
  loadSettings,
  updateSettings,
  setView,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  permissions: new Set([]),
  alertMessage: null,
  themes: localStorage.getItem('theme')
    ? localStorage.getItem('theme').split(' ')
    : ['dark'],
  theme: localStorage.getItem('theme')
    ? localStorage.getItem('theme').split(' ')[0]
    : 'dark',
  settings: {
    analysis: {},
  },
  view: 'overview',
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_VIEW:
      return {
        ...state,
        view: action.payload,
      };
    case DISPLAY_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: {
          title: action.title,
          message: action.message,
          onRetry: action.onRetry,
          onConfirm: action.onConfirm,
        },
      };
    case CLEAR_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: null,
      };
    case SET_THEME:
      const { theme } = action;
      const updatedThemes = [theme, ...state.themes.slice(1)];
      localStorage.setItem('theme', updatedThemes.join(' '));
      return {
        ...state,
        themes: updatedThemes,
        theme,
      };
    case LOAD_SETTINGS:
      return {
        ...state,
        settings: action.settings,
      };
    case UPDATE_SETTINGS:
      return {
        ...state,
        settings: action.settings,
      };
    default:
      return state;
  }
}
