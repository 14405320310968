import React, {
  FunctionComponent, useEffect, useState, useContext,
} from 'react';
import ResultsCard from 'components/ResultsCard';
import Select from 'components/Select';
import Button from 'components/Button';
import { ThemeProp } from 'types/index';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import { alphabetizeByKey } from 'helpers/utils';
import LoadingSkeleton from 'components/LoadingSkeleton';
import Tooltip from 'components/Tooltip';
import { ActivityLogContext } from 'contexts/ActivityLogContext';
import { ACTIVITY_LOG_STATUS, ANALYSIS_TYPES } from '../../Network/helpers/NetworkHelpers';
import AssetRow from './AssetRow';
import BulkScheduleModal from './BulkScheduleModal';
import { assetTypes, modalTypes, assetProperties } from '../helpers/ScenarioGenerationHelpers';
import { ScenarioGenerationContext } from '../context/ScenarioGenerationContext';

type AssetSchedulesProps = {
  workspace: string,
  branch: string,
  theme: ThemeProp,
  scenario?: {[key: string]: string}|null,
  selectedContainer: string|null,
};

type assetTypeResults = [{
  [key: string]: string,
}];
const AssetSchedules: FunctionComponent<AssetSchedulesProps> = ({
  theme,
  workspace,
  branch,
  selectedContainer,
  scenario,
}) => {
  const {
    RUNNING, PENDING, CANCELING, POSTPROCESSING,
  } = ACTIVITY_LOG_STATUS;
  const {
    BULK_SCHEDULE_GENERATION,
  } = ANALYSIS_TYPES;
  const { logEntries } = useContext(ActivityLogContext);
  const { setModalActiveType } = useContext(ScenarioGenerationContext);
  const [bulkScheduleGenLoading, setBulkScheduleGenLoading] = useState(
    { [assetTypes.pv]: false, [assetTypes.ev]: false },
  );
  const [selectedAssetType, setSelectedAssetType] = useState(assetTypes.pv);
  const {
    data: assetList, loading: assetListLoading,
  } = useRequestEffect<assetTypeResults>({
    url: `/api/workspace/${workspace}/branch/${branch}/asset/${selectedAssetType}`,
    method: 'get',
    params: {
      container: selectedContainer,
    },
    refetchOnChange: [workspace, branch, selectedContainer, selectedAssetType],
    blockRequest: () => !(workspace && branch && selectedContainer && selectedAssetType),
  });
  const {
    data: assetsHavingSchedule, refetch: refetchSchedule,
  } = useRequestEffect<{[key: string]: string}>({
    url: `/api/workspace/${workspace}/branch/${branch}/asset_schedule`,
    method: 'get',
    params: {
      scenario_id: scenario?.value,
      schedule_type: selectedAssetType === assetTypes.ev ? 'Global' : 'Normal',
    },
    refetchOnChange: [workspace, branch, scenario, selectedAssetType],
    blockRequest: () => !(workspace && branch && scenario && selectedAssetType),
  });

  const bulkScheduleEvent = logEntries.filter(
    (log) => [RUNNING, CANCELING, PENDING, POSTPROCESSING].includes(log.status)
      && log.activity_type === ANALYSIS_TYPES.BULK_SCHEDULE_GENERATION
      && log.scenario_id === scenario?.value
      && log.additional_info.asset_type === assetProperties[selectedAssetType].class,
  );
  const isBulkScheduleGenEventRunning = bulkScheduleEvent.length > 0;
  const AnalysisInProgress = logEntries.filter(
    (log) => [RUNNING, CANCELING, PENDING, POSTPROCESSING].includes(log.status)
      && log.scenario_id === scenario?.value
      && ![BULK_SCHEDULE_GENERATION].includes(log.activity_type),
  );
  const isAnalysisInProgress = AnalysisInProgress.length > 0;
  const assetsArrHaveSchedule = assetsHavingSchedule ? Object.keys(assetsHavingSchedule) : [];
  useEffect(() => {
    if (!bulkScheduleGenLoading[selectedAssetType] && isBulkScheduleGenEventRunning) {
      setBulkScheduleGenLoading({ ...bulkScheduleGenLoading, [selectedAssetType]: true });
    } else if (bulkScheduleGenLoading[selectedAssetType] && !isBulkScheduleGenEventRunning) {
      setBulkScheduleGenLoading({ ...bulkScheduleGenLoading, [selectedAssetType]: false });
      refetchSchedule();
    }
    // disabling this since we just want to set loading based only on isBulkScheduleGenEventRunning
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBulkScheduleGenEventRunning, selectedAssetType]);

  const updateProgress = (bulkScheduleEvent.length
    ? ((100 * (bulkScheduleEvent[0].num_completed_tasks + bulkScheduleEvent[0].num_failed_tasks))
    / (bulkScheduleEvent[0].num_tasks || 1)) : 0)
    .toFixed(2).replace(/\.00$/, '');
  const percentOfAssetsHasSchedules = (100 * (assetList?.filter((asset) => assetsArrHaveSchedule
    .includes(asset.id))?.length || 0) / (assetList?.length || 1))
    .toFixed(2).replace(/\.00$/, '');
  const assetTypesOptions = [
    { value: assetTypes.pv, label: assetProperties[assetTypes.pv].label },
    { value: assetTypes.ev, label: assetProperties[assetTypes.ev].label },
  ];
  return (
    <div data-test="asset-schedules" className={`asset-schedules ${theme}`}>
      <ResultsCard
        theme={theme}
        withBorder={false}
        className="schedules-card"
      >
        <div className="grid-columns items-centered one-three-one margin-10">
          <div>
            <Select
              theme={theme}
              options={assetTypesOptions}
              onChange={(e) => setSelectedAssetType(e.value)}
              value={selectedAssetType}
              label="Asset"
              type="secondary"
              width={240}
              clearable={false}
            />
          </div>
          <div className="grid-columns auto">
            <div>
              <p className="label-light">Number of assets</p>
              <p className="margin-10">
                {assetList?.length || 0}
              </p>
            </div>
            <div>
              <p className="label-light">Schedules available (%)</p>
              <p className="margin-10 schedule-status">
                {bulkScheduleGenLoading[selectedAssetType] && bulkScheduleEvent.length
                  ? `${updateProgress}% updated`
                  : `${percentOfAssetsHasSchedules}% assets have schedules`}
              </p>
            </div>
            <div />
          </div>
          <div className="flex-end">
            <Tooltip
              theme={theme}
              content={bulkScheduleGenLoading[selectedAssetType] || isAnalysisInProgress
                ? `${bulkScheduleEvent.length ? 'Bulk Schedule Generation'
                  : 'Analysis'} for selected scenario is in progress` : ''}
            >
              <Button
                theme={theme}
                label={`Generate ${selectedAssetType.toUpperCase()} schedules`}
                type="info"
                onClick={() => setModalActiveType(modalTypes.bulkSchedule)}
                disabled={
                  !(scenario && selectedContainer)
                  || bulkScheduleGenLoading[selectedAssetType]
                  || !assetList?.length
                  || isAnalysisInProgress
                }
                loading={bulkScheduleGenLoading[selectedAssetType] && !isAnalysisInProgress}
                className="bulk-generate-schedule"
              />
            </Tooltip>
          </div>
        </div>
        { selectedContainer && scenario && selectedAssetType && (
        <>
          <div className="margin-custom"><hr className="section-divider" /></div>
          <div className="grid-columns items-centered one-three-one label-light margin-10">
            <p>Asset Type</p>
            <div className="grid-columns one-two">
              <p>All assets</p>
              <div className="grid-columns auto">
                <p>Schedule</p>
                <p>Type</p>
              </div>
            </div>
          </div>
          <div className="grid-columns one-three-one">
            <strong className="margin-10">{assetProperties[selectedAssetType].label}</strong>
            { assetListLoading
              ? (
                <LoadingSkeleton count={4} theme={theme} />
              ) : (
                <div>
                  { assetList && alphabetizeByKey(assetList, 'name').map((asset: {[key: string]: string}) => (
                    <AssetRow
                      key={asset.id}
                      theme={theme}
                      workspace={workspace}
                      branch={branch}
                      selectedContainer={selectedContainer}
                      scenario={scenario}
                      asset={asset}
                      bulkScheduleGenLoading={bulkScheduleGenLoading[selectedAssetType]}
                      isAnalysisInProgress={isAnalysisInProgress}
                      refetchSchedule={refetchSchedule}
                      assetsArrHaveSchedule={assetsArrHaveSchedule}
                      selectedAssetType={selectedAssetType}
                    />
                  ))}
                </div>
              )}
          </div>
        </>
        )}
      </ResultsCard>
      <BulkScheduleModal
        theme={theme}
        workspace={workspace}
        branch={branch}
        selectedContainer={selectedContainer}
        scenario={scenario}
        bulkScheduleGenLoading={bulkScheduleGenLoading}
        setBulkScheduleGenLoading={setBulkScheduleGenLoading}
        selectedAssetType={selectedAssetType}
        assetList={assetList}
      />
    </div>
  );
};

export default AssetSchedules;
