import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import asyncStates from 'helpers/asyncActionStates';
import classNames from 'classnames';

import Analytics from 'helpers/Analytics';
import getControlModeName from 'helpers/cim/getControlModeName';
import ThemeContext from 'helpers/ThemeContext';

import AssetSchedule from '../partials/AssetSchedule';
import AnalysisSchedule from '../partials/AnalysisSchedule';
import ControlPanel from '../partials/ControlPanel';
import ShuntDeviceHeader from '../partials/ShuntDeviceHeader';
import InstanceInfo from './InstanceInfo';

// Creates a values template for EnergySource instances
const EquivalentSubstation = ({
  asset: eqSubstation,
  workspace,
  branch,
  selected: { id, class: assetClass },
  canEditNetwork,
  inEditMode,
  applyDifferenceModelRequest,
  toggleFeederPanel,
  setSelectedAssetID,
  editActions,
  actions,
  loadForecast: { selectedScenario, selectedScenarioType },
  timeRange,
  maxRange,
  timeBarZoomLevel,
  permissions,
  isAuthEnabled,
  expanded,
}) => {
  const theme = useContext(ThemeContext);
  const saving = applyDifferenceModelRequest.editValues === asyncStates.LOADING;

  const controlTypes = [
    { value: 'fixedValue', label: getControlModeName('EquivalentSubstation', 'fixedValue') },
    { value: 'attachedSchedule', label: getControlModeName('EquivalentSubstation', 'attachedSchedule') },
    { value: 'analysisSchedule', label: getControlModeName('EquivalentSubstation', 'analysisSchedule'), disabled: !eqSubstation.linked_substation },
  ];

  const controlHelp = {
    fixedValue: 'During timeseries powerflow, real and reactive power will be subject to load allocation. During powerflow, it will not be allocated real and reactive power.',
    attachedSchedule: 'During timeseries powerflow, real and reactive power will follow the attached schedule. During powerflow, real and reactive power will be zero.',
    analysisSchedule: 'During timeseries powerflow, real and reactive power will follow the values from a previously run analysis on the downstream substation.'
      + 'The previous analysis will be selected in the analysis menu. It can be previewed below.',
  };

  const analysisBehavior = eqSubstation.analysis_options?.analysisBehavior;

  const handleSave = body => editActions.editSingleEquipment(workspace, branch, 'equivalent_substation', id, body);

  const handleControlModeChange = async ({ value: mode }) => handleSave({
    analysis_options: { analysisBehavior: mode },
  });

  return (
    <div
      key={id}
      className={classNames({
        'asset-panel-values': true,
        'asset-panel-values--expanded': expanded,
      })}
    >
      <ShuntDeviceHeader
        asset={{ ...eqSubstation }}
        assetId={id}
        toggleFeederPanel={toggleFeederPanel}
        inEditMode={inEditMode}
        disabled={saving || !canEditNetwork}
        setSelectedAssetID={setSelectedAssetID}
        handleSave={handleSave}
        phaseOptions="readOnly"
      />

      <InstanceInfo
        key={id}
        asset={eqSubstation}
        assetId={id}
        toggleFeederPanel={toggleFeederPanel}
        getContainersList={actions.getContainersList}
        disabled={!canEditNetwork || saving}
        inEditMode={inEditMode}
        handleSave={handleSave}
        theme={theme}
      />

      <hr className="section-divider" />

      <ControlPanel
        selectedControlType={analysisBehavior}
        controlTypes={controlTypes}
        controlPanelValues={[]}
        help={controlHelp[analysisBehavior]}
        onChangeControlType={(value) => {
          handleControlModeChange(value);
          Analytics.logEvent('Change Downstream Substation Control Mode', 'Control Modes', value.value);
        }}
        disabled={saving || !canEditNetwork}
      >
        {analysisBehavior === 'attachedSchedule' && !inEditMode
          && (
          <AssetSchedule
            workspace={workspace}
            branch={branch}
            scenario={selectedScenario}
            scenarioType={selectedScenarioType}
            asset={{ id, class: assetClass }}
            scheduleType="Normal"
            timeRange={timeRange}
            maxRange={maxRange}
            timeBarZoomLevel={timeBarZoomLevel}
            panelValues={{
              ratedS: eqSubstation && eqSubstation.attributes
                ? eqSubstation.attributes.ratedS : undefined,
              isBalanced: eqSubstation ? eqSubstation.balanced : undefined,
            }}
            editable={!isAuthEnabled || permissions.has('modify_asset_schedule')}
            expanded={expanded}
          />
          )}
        {analysisBehavior === 'analysisSchedule' && !inEditMode
          && (
          <AnalysisSchedule
            asset={{
              id,
              class: assetClass,
              linked: eqSubstation.linked_energy_source,
              control_mode: eqSubstation.analysis_options?.analysisBehavior,
              linked_equipment_container: eqSubstation.linked_substation?.id,
            }}
            branch={branch}
            expanded={expanded}
            maxRange={maxRange}
            scenario={selectedScenario}
            scenarioType={selectedScenarioType}
            timeRange={timeRange}
            workspace={workspace}
            permissions={permissions}
            timeBarZoomLevel={timeBarZoomLevel}
          />
          )}
      </ControlPanel>
    </div>
  );
};

EquivalentSubstation.propTypes = {
  asset: PropTypes.object.isRequired,
  canEditNetwork: PropTypes.bool.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  setSelectedAssetID: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string,
    class: PropTypes.string,
  }).isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  applyDifferenceModelRequest: PropTypes.object.isRequired,
  editActions: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    getContainersList: PropTypes.func.isRequired,
  }).isRequired,
  timeRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  maxRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  loadForecast: PropTypes.shape({
    selectedScenario: PropTypes.string.isRequired,
    selectedScenarioType: PropTypes.string.isRequired,
  }).isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default EquivalentSubstation;
