import mergeWith from 'lodash/mergeWith';
import asyncActionStates from 'helpers/asyncActionStates';
import { Request } from '@opusonesolutions/gridos-app-framework';
import JSCIM from 'helpers/JSCIM';
import browserHistory from 'routes/history';

import { SET_CURRENT_WORKSPACE } from 'routes/WorkspaceLayout/routes/Network/modules/network';

import Helpers from '../helpers/EquipmentLibraryHelpers';

function mergeRefs(objValue, srcValue) {
  if (Array.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  return undefined;
}

const infoCategories = () => ([
  {
    label: 'Batteries', key: 'batteries', showAdd: true, selected: new Set(),
  },
  {
    label: 'Conductors', key: 'conductors', showAdd: true, selected: new Set(),
  },
  {
    label: 'Customer Types', key: 'customerTypes', selected: new Set(), showAdd: true,
  },
  {
    label: 'Inverters', key: 'inverters', selected: new Set(), showAdd: true,
  },
  {
    label: 'Wire Geometries', key: 'wireGeometries', showAdd: true, selected: new Set(),
  },
  {
    label: 'Photovoltaic Panels', key: 'photovoltaics', selected: new Set(), showAdd: true,
  },
  {
    label: 'Wind', key: 'winds', selected: new Set(), showAdd: true,
  },
  {
    label: 'Switches', key: 'switches', showAdd: true, selected: new Set(),
  },
  {
    label: 'Thermal Generators', key: 'thermalGenerators', selected: new Set(), showAdd: true,
  },
  {
    label: 'Transformers', key: 'transformers', showAdd: true, selected: new Set(),
  },
  {
    label: 'EV Charging Stations', key: 'evChargingStations', showAdd: true, selected: new Set(),
  },
  {
    label: 'Customer Programs', key: 'customerPrograms', selected: new Set(), showAdd: true,
  },
  {
    label: 'Combined Heat and Power Unit', key: 'CHPs', selected: new Set(), showAdd: true,
  },
  {
    label: 'Run of the River Hydro Plant', key: 'hydroGeneratingUnits', selected: new Set(), showAdd: true,
  },
]);

export const generateEqInfoCategories = () => (
  infoCategories().reduce((map, type) => {
    map.set(type.key, type);
    return map;
  }, new Map())
);

// ------------------------------------
// Constants
// ------------------------------------
export const LOAD_LIB_PENDING = 'LOAD_LIB_PENDING';
export const LOAD_LIB_SUCCESS = 'LOAD_LIB_SUCCESS';
export const LOAD_LIB_FAILURE = 'LOAD_LIB_FAILURE';
export const SELECT_EQUIPMENT = 'SELECT_EQUIPMENT';
export const CLEAR_EQUIPMENT_LIBRARY = 'CLEAR_EQUIPMENT_LIBRARY';
const LIBRARY_INSTANCE_LOADING = 'LIBRARY_INSTANCE_LOADING';
const LIBRARY_INSTANCE_SUCCESS = 'LIBRARY_INSTANCE_SUCCESS';
const LIBRARY_INSTANCE_FAILURE = 'LIBRARY_INSTANCE_FAILURE';
const UPDATE_SELECTED_INFOS = 'UPDATE_SELECTED_INFOS';
const DELETE_EQTYPE_PENDING = 'DELETE_EQTYPE_PENDING';
const DELETE_EQTYPE_SUCCESS = 'DELETE_EQTYPE_SUCCESS';
const DELETE_EQTYPE_FAILURE = 'DELETE_EQTYPE_FAILURE';
const UPDATE_EXPANDED_CATEGORIES = 'UPDATE_EXPANDED_CATEGORIES';
const SET_CURRENT_BRANCH = 'SET_CURRENT_BRANCH';
const CLEAR_FAILED_SAVE_ERROR = 'CLEAR_FAILED_SAVE_ERROR';

// ------------------------------------
// Actions
// ------------------------------------

function setRealBranch(workspace, branch) {
  return async (dispatch) => {
    dispatch({
      type: SET_CURRENT_WORKSPACE,
      workspace,
    });

    const editBranchReq = new Request(`/api/workspace/${workspace}/branch/${branch}/edit_branch`);
    let branchName = branch;

    try {
      const resp = await editBranchReq.get();
      if (resp.data.edit_branch_name) {
        branchName = resp.data.edit_branch_name;
      }
    // eslint-disable-next-line no-empty
    } catch (err) {}

    return dispatch({ type: SET_CURRENT_BRANCH, branch: branchName, displayBranch: branch });
  };
}

/**
 * Fetches the equipment library profile to display the existing library instances
 * @param  {String} workspace     Name of the workspace
 * @param  {String} equipmentType Optional param to set a selected equipment type
 * @param  {string} equipmentId   Optional param to set a selected equipment id
 */
function loadEquipmentLibrary(workspace, branch, equipmentType, equipmentId) {
  return async (dispatch, getState) => {
    await dispatch(setRealBranch(workspace, branch));
    const { branch: realBranch } = getState().equipmentLibrary;
    const request = new Request(`/api/workspace/${workspace}/branch/${realBranch}/profile/equipment_library.json`);
    dispatch({
      type: LOAD_LIB_PENDING,
    });
    return request.get()
      .then(({ data }) => {
        dispatch({
          type: LOAD_LIB_SUCCESS,
          equipmentType,
          payload: Helpers.buildLibraryFromCIM(data),
        });
        if (equipmentType && equipmentId) {
          // This case happens when the user manually types
          // a URL that includes the type & id
          dispatch({
            type: SELECT_EQUIPMENT,
            equipmentType,
            equipmentId,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: LOAD_LIB_FAILURE,
        });
      });
  };
}

/**
 * Make a library instance the selected instance and display it in the UI
 * @param  {String}  workspace            Name of the workspace
 * @param  {String}  equipmentType        The type of equipment being selected
 * @param  {String}  equipmentId          The id of the selected equipment
 * @param  {Boolean} [updateHistory=true] Flag for if we want to update the browser url
 */
function selectEquipment(workspace, equipmentType, equipmentId, updateHistory = true) {
  return (dispatch, getState) => {
    const {
      selectedEquipmentID,
      selectedEquipmentType,
      displayBranch,
    } = getState().equipmentLibrary;

    const deselecting = (
      selectedEquipmentID === equipmentId
      && selectedEquipmentType === equipmentType
    );

    if (updateHistory) {
      if (equipmentType && equipmentId && !deselecting) {
        browserHistory.push(`/${workspace}/${displayBranch}/library/${equipmentType}/${equipmentId}`);
      } else if (equipmentId === 'add') {
        browserHistory.push(`/${workspace}/${displayBranch}/library/${equipmentType}/add`);
      } else {
        browserHistory.push(`/${workspace}/${displayBranch}/library`);
      }
    }

    dispatch({
      type: SELECT_EQUIPMENT,
      equipmentType: deselecting ? null : equipmentType,
      equipmentId: deselecting ? null : equipmentId,
    });
  };
}

/**
 * Create an instance of a library type from user input
 * @param  {String} workspace Name of the workspace
 * @param  {String} branch    Name of the branch
 * @param  {String} type      Equipment library type being created (as defined in cim-datastore)
 * @param  {Object} values    Attributes and references being set on the new info
 * @param  {String} classType CIM Class name. Used to find the instance in the difference model
 */
function createInfoInstance(workspace, type, values, classType) {
  return async (dispatch, getState) => {
    const { branch, displayBranch } = getState().equipmentLibrary;
    const request = new Request(`/api/workspace/${workspace}/branch/${branch}/equipment_library/${type}`);
    dispatch({ type: LIBRARY_INSTANCE_LOADING });
    try {
      const { data } = await request.post(values);
      const { create } = data;
      const { library } = getState().equipmentLibrary;
      const newAssets = Object.entries(create);

      // Update Objects Lookup
      const updatedObjects = newAssets.reduce((lu, [id, instance]) => {
        lu[id] = instance;
        return lu;
      }, { ...library.objects });

      // Update Library Types
      const updatedLibrary = { ...library };

      let newAssetId;
      let assetDetails = {};

      newAssets.forEach(([id_1, instance_1]) => {
        // Create a JSCIM instance for the newly created info
        let infoInstance;
        if (JSCIM[instance_1.class]) {
          infoInstance = new JSCIM[instance_1.class](id_1, updatedObjects);
          // If it is the class type we want to display after creation, set it as the new asset
          newAssetId = instance_1.class === classType ? id_1 : newAssetId;
          // Determine if it should be bucketed and if so, add it to it's list
          const libType = Helpers.getLibraryType(instance_1.class, instance_1);
          if (libType) {
            updatedLibrary[libType] = updatedLibrary[libType].concat(infoInstance);
            updatedLibrary[libType].sort(Helpers.librarySortFunction(libType));
          }
        } else {
          infoInstance = instance_1;
        }
        // Update the lookup
        updatedObjects[id_1] = infoInstance;
      });

      // If we were able to find the desired asset in the created items set it
      // as the newly selected asset and navigate to the correct page
      if (newAssetId) {
        const newAsset = updatedObjects[newAssetId];

        assetDetails = {
          newAssetId,
          newAssetType: Helpers.getLibraryType(newAsset.class, newAsset),
        };

        const category = assetDetails.newAssetType;
        browserHistory.push(`/${workspace}/${displayBranch}/library/${category}/${newAssetId}`);
      }

      return dispatch({
        type: LIBRARY_INSTANCE_SUCCESS,
        objects: updatedObjects,
        library: updatedLibrary,
        ...assetDetails,
      });
    } catch (e) {
      let assetFailureErrorObject = {};
      if (e?.response?.data?.message) {
        assetFailureErrorObject = e?.response?.data?.message?.AssetModel;
      }
      return dispatch({ type: LIBRARY_INSTANCE_FAILURE, payload: assetFailureErrorObject });
    }
  };
}

function updateInfoInstance(workspace, id, values) {
  return (dispatch, getState) => {
    const { branch } = getState().equipmentLibrary;
    const request = new Request(`/api/workspace/${workspace}/branch/${branch}/equipment_library/update/${encodeURIComponent(id)}`);
    dispatch({ type: LIBRARY_INSTANCE_LOADING });
    return request.post(values)
      .then(({ data }) => {
        const {
          set, delete: deleted, unset, create,
        } = data;
        const { library } = getState().equipmentLibrary;

        let updatedLibrary = Object.keys(unset).reduce((lu, uuid) => {
          if (lu[uuid]) {
            const { attributes, references } = unset[uuid];
            // Delete removed attributes
            Object.keys(attributes).forEach((attr) => {
              delete lu[uuid].attributes[attr];
            });
            // Filter out or remove unset references
            Object.keys(references).forEach((ref) => {
              if (Array.isArray(lu[uuid].references[ref])) {
                const filteredList = lu[uuid].references[ref]
                  .filter(refID => !references[ref].includes(refID));
                lu[uuid].references[ref] = filteredList;
              } else {
                delete lu[uuid].references[ref];
              }
            });
          }
          return lu;
        }, { ...library.objects });

        // Delete infos that were removed
        updatedLibrary = Object.keys(deleted).reduce((lu, uuid) => {
          delete lu[uuid];
          return lu;
        }, updatedLibrary);

        // Add any newly created objects to lookup
        updatedLibrary = Object.keys(create).reduce((lu, uuid) => {
          lu[uuid] = create[uuid];
          return lu;
        }, updatedLibrary);

        // Convert any new created objects to JSCIM where defined
        updatedLibrary = Object.keys(create).reduce((lu, uuid) => {
          lu[uuid] = Helpers.getJSCimInstance(uuid, create[uuid].class, lu);
          return lu;
        }, updatedLibrary);

        // Update infos that were edited
        updatedLibrary = Object.keys(set).reduce((lu, uuid) => {
          if (lu[uuid]) {
            lu[uuid].attributes = { ...lu[uuid].attributes, ...set[uuid].attributes };
            lu[uuid].references = mergeWith(
              {},
              lu[uuid].references,
              set[uuid].references,
              mergeRefs,
            );
          }
          return lu;
        }, updatedLibrary);

        Object.keys(updatedLibrary).forEach((uuid) => {
          updatedLibrary[uuid].cimDict = updatedLibrary;
        });

        dispatch({
          type: LIBRARY_INSTANCE_SUCCESS,
          objects: updatedLibrary,
          library: { ...library, objects: updatedLibrary },
        });
      })
      .catch((e) => {
        let assetFailureErrorObject = {};
        if (e?.response?.data?.message) {
          assetFailureErrorObject = e?.response?.data?.message?.AssetModel;
        }
        return dispatch({ type: LIBRARY_INSTANCE_FAILURE, payload: assetFailureErrorObject });
      });
  };
}

/**
 * Handle click on the select all checkbox
 * @param  {Object} library          Lookup including bucketed library instances
 * @param  {Map}    eqInfoCategories Map keyed on category. Includes selected instance info
 */
const handleSelectAllClick = (library, eqInfoCategories) => {
  // turning select all on
  const [, allSelected] = Helpers.getSelectedStatus(eqInfoCategories, library);
  const eqInfoCopy = new Map(eqInfoCategories);
  if (!allSelected) {
    eqInfoCopy.forEach((obj) => {
      if (library[obj.key].length) {
        library[obj.key].forEach((eq) => {
          const selected = new Set(eqInfoCopy.get(obj.key).selected);
          if (!selected.has(eq.id)) {
            selected.add(eq.id);
          }

          eqInfoCopy.set(obj.key, { ...eqInfoCopy.get(obj.key), selected });
        });
      }
    });
  // turning select all off
  } else {
    eqInfoCopy.forEach((eq) => { eq.selected = new Set(); });
  }
  return {
    type: UPDATE_SELECTED_INFOS,
    payload: eqInfoCopy,
  };
};

/**
 * Handle click on a category checkbox
 * @param  {String} categoryKey      Name of the category
 * @param  {Array}  equipmentList    List of instances in selected category
 * @param  {Map}    eqInfoCategories Map keyed on category. Includes selected instance info
 */
const handleSelectCategoryClick = (categoryKey, equipmentList, eqInfoCategories) => {
  const eqInfoCopy = new Map(eqInfoCategories);
  const selected = new Set(eqInfoCopy.get(categoryKey).selected);

  // turning category off
  if (selected.size === equipmentList.length) {
    selected.clear();
  // turning category on
  } else {
    equipmentList.forEach((obj) => {
      if (!selected.has(obj.id)) {
        selected.add(obj.id);
      }
    });
  }
  eqInfoCopy.set(categoryKey, { ...eqInfoCategories.get(categoryKey), selected });

  return {
    type: UPDATE_SELECTED_INFOS,
    payload: eqInfoCopy,
  };
};

/**
 * Handle click on single info checkbox
 * @param  {String} categoryKey      Category name
 * @param  {Object} equipment        Equipment Info class instance
 * @param  {Map}    eqInfoCategories Map keyed on category. Includes selected instance info
 */
const handleSelectEquipmentClick = (categoryKey, equipment, eqInfoCategories) => {
  const eqInfoCopy = new Map(eqInfoCategories);
  const selected = new Set(eqInfoCopy.get(categoryKey).selected);

  // turning off equipment checkbox
  if (selected.has(equipment.id)) {
    selected.delete(equipment.id);
  // turning on equipment checkbox
  } else {
    selected.add(equipment.id);
  }

  eqInfoCopy.set(categoryKey, { ...eqInfoCopy.get(categoryKey), selected });

  return {
    type: UPDATE_SELECTED_INFOS,
    payload: eqInfoCopy,
  };
};

const handleClearSelections = () => ({
  type: UPDATE_SELECTED_INFOS,
  payload: generateEqInfoCategories(),
});

const clearEquipmentLibrary = () => ({ type: CLEAR_EQUIPMENT_LIBRARY });

function deleteEquipmentType(categories, workspace) {
  const selectedIds = categories.map(cat => cat.selected);
  const uuids = selectedIds.reduce((list, cat) => {
    list = [...list, ...cat];
    return list;
  }, []);

  return (dispatch, getState) => {
    const { branch, displayBranch } = getState().equipmentLibrary;
    const request = new Request(`/api/workspace/${workspace}/branch/${branch}/equipment_library/delete`);
    dispatch({
      type: DELETE_EQTYPE_PENDING,
    });

    return request.post({ uuids })
      .then(({ data }) => {
        const { delete: deleted } = data;
        const { library } = getState().equipmentLibrary;
        const updatedLibrary = { ...library };

        Object.keys(updatedLibrary).forEach((category) => {
          const categoryObj = categories.find(cat => cat.key === category);
          if (categoryObj) {
            updatedLibrary[category] = updatedLibrary[category]
              .filter(a => !categoryObj.selected.has(a.id));
            categoryObj.selected.clear();
          }
        });

        let updatedObjects = { ...library.objects };

        updatedObjects = Object.keys(deleted).reduce((lu, uuid) => {
          delete lu[uuid];
          return lu;
        }, updatedObjects);

        let { selectedEquipmentID, selectedEquipmentType } = getState().equipmentLibrary;

        if (uuids.includes(getState().equipmentLibrary.selectedEquipmentID)) {
          selectedEquipmentID = '';
          selectedEquipmentType = '';

          browserHistory.push(`/${workspace}/${displayBranch}/library`);
        }

        dispatch({
          type: DELETE_EQTYPE_SUCCESS,
          objects: updatedObjects,
          library: { ...updatedLibrary, objects: updatedObjects },
          selectedEquipmentID,
          selectedEquipmentType,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_EQTYPE_FAILURE,
          payload: error,
        });
      });
  };
}

function updateExpandedCategories(category, isOpen) {
  return (dispatch, getState) => {
    const { expandedCategories } = getState().equipmentLibrary;

    const updatedCategories = new Set(expandedCategories);

    if (isOpen) {
      updatedCategories.add(category);
    } else {
      updatedCategories.delete(category);
    }

    dispatch({
      type: UPDATE_EXPANDED_CATEGORIES,
      payload: updatedCategories,
    });
  };
}

export const clearFailedSaveError = () => ({ type: CLEAR_FAILED_SAVE_ERROR });

export const eqLibActions = {
  loadEquipmentLibrary,
  selectEquipment,
  clearEquipmentLibrary,
  createInfoInstance,
  updateInfoInstance,
  handleSelectEquipmentClick,
  handleSelectAllClick,
  handleSelectCategoryClick,
  handleClearSelections,
  deleteEquipmentType,
  updateExpandedCategories,
  setRealBranch,
  clearFailedSaveError,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  library: {
    batteries: [],
    conductors: [],
    customerTypes: [],
    wireGeometries: [],
    reactors: [],
    switches: [],
    thermalGenerators: [],
    transformers: [],
    inverters: [],
    photovoltaics: [],
    winds: [],
    evChargingStations: [],
    customerPrograms: [],
    CHPs: [],
    hydroGeneratingUnits: [],
    objects: {},
  },
  libraryLoadingState: asyncActionStates.INITIAL,
  createInstanceReq: asyncActionStates.INITIAL,
  // Which item the user has selected in the left rail
  selectedEquipmentType: null,
  selectedEquipmentID: null,
  eqInfoCategories: generateEqInfoCategories(),
  deleteStatus: asyncActionStates.INITIAL,
  expandedCategories: new Set([]),
  branch: 'master',
  saveLibraryError: {},
};

export default function EquipmentLibraryReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_LIB_PENDING:
      return {
        ...state,
        libraryLoadingState: asyncActionStates.LOADING,
      };
    case LOAD_LIB_FAILURE:
      return {
        ...state,
        libraryLoadingState: asyncActionStates.ERROR,
      };
    case LOAD_LIB_SUCCESS: {
      const { equipmentType } = action;
      return {
        ...state,
        library: action.payload,
        selectedEquipmentType: equipmentType,
        libraryLoadingState: asyncActionStates.SUCCESS,
      };
    }
    case SET_CURRENT_BRANCH:
      return {
        ...state,
        branch: action.branch,
        displayBranch: action.displayBranch,
      };
    case SELECT_EQUIPMENT:
      // If we reselect the currently selected equipment, that means
      // we need to clear
      const { equipmentId, equipmentType } = action;
      return {
        ...state,
        selectedEquipmentType: equipmentType,
        selectedEquipmentID: equipmentId,
        createInstanceReq: asyncActionStates.INITIAL,
      };
    case LIBRARY_INSTANCE_LOADING:
      return {
        ...state,
        createInstanceReq: asyncActionStates.LOADING,
        saveLibraryError: {},
      };
    case LIBRARY_INSTANCE_SUCCESS:
      return {
        ...state,
        createInstanceReq: asyncActionStates.SUCCESS,
        selectedEquipmentType: action.newAssetType || state.selectedEquipmentType,
        selectedEquipmentID: action.newAssetId || state.selectedEquipmentID,
        library: {
          ...state.library,
          ...action.library,
          objects: action.objects,
        },
        saveLibraryError: {},
      };
    case LIBRARY_INSTANCE_FAILURE:
      return {
        ...state,
        createInstanceReq: asyncActionStates.ERROR,
        saveLibraryError: action.payload,
      };
    case CLEAR_EQUIPMENT_LIBRARY:
      return initialState;
    case UPDATE_SELECTED_INFOS:
      return {
        ...state,
        eqInfoCategories: action.payload,
      };
    case DELETE_EQTYPE_PENDING:
      return {
        ...state,
        deleteStatus: asyncActionStates.LOADING,
      };
    case DELETE_EQTYPE_SUCCESS:
      return {
        ...state,
        library: {
          ...state.library,
          ...action.library,
          objects: action.objects,
        },
        deleteStatus: asyncActionStates.SUCCESS,
        selectedEquipmentID: action.selectedEquipmentID,
        selectedEquipmentType: action.selectedEquipmentType,
      };
    case DELETE_EQTYPE_FAILURE:
      return {
        ...state,
        deleteStatus: asyncActionStates.ERROR,
        errors: {
          ...state.errors,
          delete: action.payload,
        },
      };
    case UPDATE_EXPANDED_CATEGORIES:
      return {
        ...state,
        expandedCategories: action.payload,
      };
    case CLEAR_FAILED_SAVE_ERROR:
      return {
        ...state,
        createInstanceReq: asyncActionStates.INITIAL,
      };
    default:
      return state;
  }
}
