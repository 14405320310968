import React, { FunctionComponent } from 'react';
import { ThemeProp } from 'types/index';
import { Moment } from 'moment';
import { isEmptyObject } from 'helpers/utils';
import CostSummary from './Costs';
import ReliabilitySummary from './Reliability';
import EnergySummary from './Energy';
import Violations from './Violations';
import EmissionsSummary from './Emissions';
import NetPresentValueSummary from './NetPresentValue';
import { views } from '../helpers/ResultsComparisonHelpers';

type ResultsSummaryProps = {
    theme: ThemeProp,
    violationLimit?: number|null,
    workspace: string|null,
    branch: string|null,
    maxRange: {
      start?: Moment|null,
      end?: Moment|null,
    },
    scenario: string|null,
    analysis: { name: string }|null,
    feeders?: [],
    violationType: string,
    setViolationType: (value: string) => void,
    isBaseline: boolean,
    viewType: string,
  };

const ResultsSummary: FunctionComponent<ResultsSummaryProps> = ({
  theme,
  violationLimit = 0,
  workspace,
  branch,
  maxRange,
  scenario,
  analysis,
  feeders = [],
  violationType,
  setViolationType,
  isBaseline,
  viewType,
}) => {
  const commonProps = {
    workspace,
    branch,
    theme,
  };
  const analysisProps = {
    ...commonProps,
    maxRange,
    scenario,
    analysis,
    feeders,
    isBaseline,
  };
  return (
    <>
      {scenario && analysis && !isEmptyObject(analysis) && (
      <>
        <Violations
          {...analysisProps}
          violationLimit={violationLimit}
          violationType={violationType}
          setViolationType={setViolationType}
          viewType={viewType}
        />
        { viewType === views.overview && (
          <>
            <EmissionsSummary {...analysisProps} />
            <NetPresentValueSummary {...commonProps} />
            <ReliabilitySummary {...analysisProps} />
            <EnergySummary {...analysisProps} />
            <CostSummary {...analysisProps} />
          </>
        )}
      </>
      )}
    </>
  );
};

export default ResultsSummary;
