import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import TextInput from 'components/TextInput';
import NumberInput from 'components/NumberInput';
import Select from 'components/Select';

import { isDefined } from 'helpers/utils';
import { amps, ohmsPerMeter, perMeter } from 'helpers/units';
import Helpers from '../../../helpers/EquipmentLibraryHelpers';
import {
  areAssetModelPropertiesChanged,
  areAssetModelsEqual,
  defaultAssetModel,
  getAssetModelProperties,
  isAssetModelValid,
} from '../../../helpers/assetModelHelpers';
import DescriptionEditor from '../DescriptionEditor';
import CostEditor from '../CostEditor';
import ReliabilityMetrics from '../ReliabilityMetrics';
import PanelTabs from '../PanelTabs';

import '../common.scss';

const getNewOverheadConductor = () => ({
  name: '',
  description: '',
  material: '',
  strandCount: '',
  radius: '',
  gmr: '',
  ratedCurrent: '',
  rAC25: '',
  assetModel: { ...defaultAssetModel },
  id: 'add',
  isValidShiftFactor: true,
});

class OverheadConductorPanel extends Component {
  state = { ...(getNewOverheadConductor()) };

  addClassParams = { class: 'OverheadWireInfo', endpointArg: 'overhead_wire_info' };

  title = 'Overhead Conductor'

  materialTypes = [
    { value: 'aaac', label: 'AAAC' },
    { value: 'acsr', label: 'ACSR' },
    { value: 'aluminum', label: 'Aluminum' },
    { value: 'aluminumAlloy', label: 'Aluminum Alloy' },
    { value: 'aluminumAlloySteel', label: 'Aluminum Alloy Steel' },
    { value: 'aluminumSteel', label: 'Aluminum Steel' },
    { value: 'copper', label: 'Copper' },
    { value: 'other', label: 'Other' },
    { value: 'steel', label: 'Steel' },
  ]

  UNSAFE_componentWillMount() {
    this.setState({ ...this.getInputValues(this.props.selected) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.selected !== nextProps.selected) {
      this.setState({ ...this.getInputValues(nextProps.selected) });
    }
  }

  getInputValues = (selected) => {
    let asset = { ...(getNewOverheadConductor()) };
    if (selected) {
      const {
        name, description, material, strandCount, radius, gmr, ratedCurrent, rAC25, AssetModel, id,
      } = selected;
      asset = {
        name: name || asset.name,
        description: description || asset.description,
        material: material || asset.material,
        strandCount: isDefined(strandCount) ? `${strandCount}` : asset.strandCount,
        radius: isDefined(radius) ? (radius * 1000).toFixed(4) : asset.radius,
        gmr: isDefined(gmr) ? (gmr * 1000).toFixed(4) : asset.gmr,
        ratedCurrent: isDefined(ratedCurrent) ? `${ratedCurrent}` : asset.ratedCurrent,
        rAC25: isDefined(rAC25) ? `${rAC25}` : asset.rAC25,
        assetModel: isDefined(AssetModel) ? getAssetModelProperties(AssetModel) : asset.assetModel,
        id,
      };
    }
    return asset;
  }

  handleSave = () => {
    const diffModel = {
      name: this.state.name,
      description: this.state.description,
      material: this.state.material,
      strandCount: parseFloat(this.state.strandCount),
      radius: parseFloat(this.state.radius) / 1000,
      gmr: parseFloat(this.state.gmr) / 1000,
      ratedCurrent: parseFloat(this.state.ratedCurrent),
      rAC25: parseFloat(this.state.rAC25),
      AssetModel: { ...this.state.assetModel },
    };
    if (this.state.id === 'add') {
      this.props.handleCreate(
        this.addClassParams.endpointArg,
        diffModel,
        this.addClassParams.class,
      );
    } else {
      // Only submit the values that have changed
      const { selected } = this.props;
      const keys = Object.keys(diffModel);
      const editDiffModel = keys.reduce((diff, key) => {
        if (key === 'AssetModel') {
          if (!areAssetModelsEqual(diffModel[key], selected[key])) {
            diff[key] = diffModel[key];
          }
        } else if (diffModel[key] !== selected[key]) {
          diff[key] = diffModel[key];
        }
        return diff;
      }, {});
      this.props.handleEdit(selected.id, editDiffModel);
    }
  }

  handleInputChange = ({ id, value }) => this.setState({ [id]: value });

  valuesUpdated = (selected) => {
    if (!selected) return true;

    return (
      ['name', 'description', 'material'].some(val => selected[val] !== this.state[val])
      || ['strandCount', 'ratedCurrent', 'rAC25'].some(val => selected[val] !== parseFloat(this.state[val]))
      || ['radius', 'gmr'].some(val => (selected[val] * 1000).toFixed(4) !== parseFloat(this.state[val]).toFixed(4))
      || !areAssetModelsEqual(getAssetModelProperties(selected.AssetModel), this.state.assetModel)
    );
  }

  shiftFactorValid = (isShiftFactorValid = false) => this.setState({ isValidShiftFactor: isShiftFactorValid });

  formValid = () => (
    this.state.name.trim().length > 0
    && this.state.material.length > 0
    && parseFloat(this.state.strandCount) >= 1
    && parseFloat(this.state.strandCount) === Math.round(parseFloat(this.state.strandCount))
    && parseFloat(this.state.radius) > 0
    && parseFloat(this.state.gmr) > 0
    && parseFloat(this.state.ratedCurrent) > 0
    && parseFloat(this.state.rAC25) > 0
    && isAssetModelValid(this.state.assetModel)
    && this.valuesUpdated(this.props.selected)
    && this.state.isValidShiftFactor
  );

  getInvalid() {
    const {
      radius, gmr, ratedCurrent, rAC25,
    } = this.state;
    const strandCount = parseFloat(this.state.strandCount);
    // Extra checks here to ensure that there is a value
    const strandValid = !!this.state.strandCount
      && (strandCount < 1 || strandCount !== Math.round(strandCount));

    return {
      gmr: parseFloat(gmr) <= 0,
      rAC25: parseFloat(rAC25) <= 0,
      radius: parseFloat(radius) <= 0,
      ratedCurrent: parseFloat(ratedCurrent) <= 0,
      strandCount: strandValid,
    };
  }

  updateAssetModel = prop => this.setState(prevState => ({
    assetModel: {
      ...prevState.assetModel,
      ...prop,
    },
  }));

  render() {
    const isDisabled = (
      this.props.isAuthEnabled
      && ((this.state.id === 'add' && !this.props.permissions.has('create_equipment_type'))
      || (this.state.id !== 'add' && !this.props.permissions.has('edit_equipment_type'))
      || (this.props.match.params.branch === 'master' && !this.props.permissions.has('modify_network_as_built')))
    );

    const material = Helpers.createDisplayObject('Material', 'material', this.state.material, '');
    const strandCount = Helpers.createDisplayObject('Number of Strands', 'strandCount', this.state.strandCount, '');
    const outsideRadius = Helpers.createDisplayObject('Outside Radius', 'radius', this.state.radius, 'mm');
    const GMR = Helpers.createDisplayObject('Geometric Mean Radius', 'gmr', this.state.gmr, 'mm');
    const ratedCurrent = Helpers.createDisplayObject('Rated Current', 'ratedCurrent', this.state.ratedCurrent, amps);
    const rAC25 = Helpers.createDisplayObject('Resistance at 25°C', 'rAC25', this.state.rAC25, ohmsPerMeter);

    const invalid = this.getInvalid();

    return (
      <PanelTabs
        submitDisabled={!this.formValid() || isDisabled}
        onSubmit={this.handleSave}
        createInstanceReq={this.props.createInstanceReq}
        tabs={['General', 'Description', 'Costs', 'Reliability Metrics']}
        assetID={this.state.id}
        showSave
      >
        {[
          <div className="equipment-info-container" key={this.state.id}>
            <div className="right-panel">
              {this.props.children}
              <h2>{this.title}</h2>
              <TextInput
                id="name"
                required
                label="Name"
                theme={this.props.theme}
                value={this.state.name}
                onChange={({ target }) => this.handleInputChange({ id: target.id, value: target.value })}
                disabled={isDisabled}
                inputWidth="225px"
              />
              <div className="select-section">
                <div className="select-row">
                  <div className="select-label">
                    <p>Material *</p>
                  </div>
                  <div className="select">
                    <Select
                      id={material.id}
                      width={225}
                      theme={this.props.theme}
                      value={material.value}
                      searchable={false}
                      clearable={false}
                      options={this.materialTypes}
                      onChange={e => this.setState({ material: e.value })}
                      disabled={isDisabled}
                      isRequired
                    />
                  </div>
                </div>
              </div>
              <NumberInput
                theme={this.props.theme}
                onChange={this.handleInputChange}
                disabled={isDisabled}
                required
                {...outsideRadius}
                invalid={invalid.radius}
                validationMessage="Outside radius must be greater than 0."
                inputStyle="eq-lib"
              />
              <NumberInput
                theme={this.props.theme}
                onChange={this.handleInputChange}
                disabled={isDisabled}
                required
                {...GMR}
                invalid={invalid.gmr}
                validationMessage="Gmr must be greater than 0."
                inputStyle="eq-lib"
              />
              <NumberInput
                theme={this.props.theme}
                onChange={this.handleInputChange}
                disabled={isDisabled}
                required
                {...strandCount}
                invalid={invalid.strandCount}
                validationMessage="The strand count must be a positive integer."
                inputStyle="eq-lib"
              />
              <NumberInput
                theme={this.props.theme}
                onChange={this.handleInputChange}
                disabled={isDisabled}
                required
                {...ratedCurrent}
                invalid={invalid.ratedCurrent}
                validationMessage="Rated current must be greater than 0."
                inputStyle="eq-lib"
              />
              <NumberInput
                theme={this.props.theme}
                onChange={this.handleInputChange}
                disabled={isDisabled}
                required
                {...rAC25}
                invalid={invalid.rAC25}
                validationMessage="Resistance must be greater than 0."
                inputStyle="eq-lib"
              />
            </div>
            <div className="column">
              <h2 className="column-title">Description</h2>
              <div className="markdown-body">
                <Markdown
                  escapeHtml
                  source={decodeURIComponent(this.state.description)}
                />
              </div>
            </div>
          </div>,
          <DescriptionEditor
            description={this.state.description}
            key={`${this.state.id}-description`}
            onChange={d => this.setState({ description: d })}
            isDisabled={isDisabled}
          />,
          <CostEditor
            assetModel={this.state.assetModel}
            assetModelDiff={areAssetModelPropertiesChanged(
              this.state.assetModel,
              getAssetModelProperties(this.props.selected?.AssetModel),
            )[1]}
            key={`${this.state.id}-costs`}
            onChange={prop => this.setState(prevState => ({
              assetModel: { ...prevState.assetModel, ...prop },
            }))}
            isDisabled={isDisabled}
            theme={this.props.theme}
            unit={perMeter}
            includePerUnitMaintenance
          />,
          <ReliabilityMetrics
            assetModel={this.state.assetModel || null}
            probabilityOfFailureEquation={this.state.assetModel.AssetFailureInfo.probabilityOfFailureEquation || null}
            mTTR={this.state.assetModel.AssetFailureInfo.mTTR}
            key={`${this.state.id}-reliability`}
            onChange={this.updateAssetModel}
            isDisabled={isDisabled}
            theme={this.props.theme}
            saveLibraryError={this.props.saveLibraryError}
            shiftFactorValid={this.shiftFactorValid}
          />,
        ]}
      </PanelTabs>
    );
  }
}

OverheadConductorPanel.defaultProps = {
  selected: null,
  children: null,
  saveLibraryError: {},
};

OverheadConductorPanel.propTypes = {
  children: PropTypes.object,
  handleCreate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  createInstanceReq: PropTypes.number.isRequired,
  selected: PropTypes.object,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  saveLibraryError: PropTypes.object,
};

export default OverheadConductorPanel;
