import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/IconButton';
import DropdownMenu from 'components/DropdownMenu';

export default function NetworkNavExpandedMenu({
  theme,
  handleMenuSelection,
}) {
  const [open, setOpen] = useState(false);
  const options = [
    {
      contents: 'API Documentation',
      id: 'apiDocs',
      type: 'standard',
    },
  ];

  return (
    <DropdownMenu
      id="expanded-nav-menu"
      className="nav-menu"
      open={open}
      contentPosition={{ right: '0px', top: '50px', zIndex: 1001 }}
      theme={theme}
      onClick={id => {
        if (id !== 'markets') {
          handleMenuSelection(id);
        }
      }}
      onToggle={openState => setOpen(openState)}
      menuOptions={options}
    >
      {onClick => (
        <IconButton
          icon="more_vert"
          className="expanded-menu"
          onClick={onClick}
          theme={theme}
          tooltip="More Navigation"
        />
      )}
    </DropdownMenu>
  );
}

NetworkNavExpandedMenu.propTypes = {
  theme: PropTypes.string.isRequired,
  handleMenuSelection: PropTypes.func.isRequired,
};
