import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const CircleCheckMarkIcon: FunctionComponent<IconPropsSchema> = ({
  color = '#7CA504',
  height = '14px',
  width = '14px',
}) => (
  <svg width={width} height={height} viewBox="0 0 14 14" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="IDP-home-Copy-38-(b)-Copy-25" transform="translate(-186.000000, -150.000000)">
        <rect x="0" y="0" width="1366" height="800" />
        <path d="M214,40.25 C215.035534,40.25 215.973034,40.669733 216.65165,41.3483496 C217.330267,42.0269661 217.75,42.9644661 217.75,44 L217.75,44 L217.75,793.75 L0.25,793.75 L0.25,40.25 Z" id="Rectangle-Copy-38" stroke="#ECF3FA" strokeWidth="0.5" />
        <g id="checked-copy-5" transform="translate(186.000000, 150.000000)" fillRule="nonzero">
          <g id="checked">
            <path d="M13.9902513,7.00018325 C13.9902513,10.8543613 10.8543613,13.9898848 7.00018325,13.9898848 C3.14563874,13.9898848 0.0101151832,10.8543613 0.0101151832,7.00018325 C0.0101151832,3.14563874 3.14563874,0.0101151832 7.00018325,0.0101151832 C10.8543613,0.0101151832 13.9902513,3.14563874 13.9902513,7.00018325 Z" id="Path" fill={color} />
            <path d="M10.989289,5.52185237 L6.98684846,9.52495224 L6.6210783,9.8907224 C6.42500756,10.0867931 6.16815094,10.1845648 5.91063504,10.1845648 L5.89547153,10.1845648 C5.64191133,10.1845648 5.38841705,10.0881776 5.19491752,9.89474403 L3.04051284,7.73968006 C2.6535797,7.35340621 2.6535797,6.72616445 3.04051284,6.33929724 C3.42744598,5.95243003 4.05468773,5.95236411 4.44089566,6.33929724 L5.88683493,7.78457723 L6.98678253,6.68462963 L9.56965517,4.10175699 C9.96186257,3.70954959 10.597675,3.70954959 10.9892231,4.10175699 C11.3814964,4.49383253 11.3814964,5.12964497 10.989289,5.52185237 Z" id="Path" fill="#FFFFFF" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CircleCheckMarkIcon;
