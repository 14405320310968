import moment from 'moment';

// (int) The current year
export const THIS_YEAR = (moment().year());

// (int) The current month starting from 1 - 12
// 1 => January, 12 => December
export const THIS_MONTH = +(moment().month());

// Week days names and shortnames
export const WEEK_DAYS = {
  Sunday: 'Sun',
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
};

// Calendar months names and shortnames
export const CALENDAR_MONTHS = {
  January: 'Jan',
  February: 'Feb',
  March: 'Mar',
  April: 'Apr',
  May: 'May',
  June: 'Jun',
  July: 'Jul',
  August: 'Aug',
  September: 'Sep',
  October: 'Oct',
  November: 'Nov',
  December: 'Dec',
};

// Weeks displayed on calendar
export const CALENDAR_WEEKS = 6;

// Pads a string value with leading zeroes(0) until length is reached
// For example: zeroPad(5, 2) => '05'
export const zeroPad = (value, length) => `${value}`.padStart(length, '0');

// (int) Number days in a month for a given year from 28 - 31
// eslint-disable-next-line max-len
export const getMonthDays = (month = THIS_MONTH, year = THIS_YEAR) => moment({ month, year }).daysInMonth();

// (int) First day of the month for a given year from 1 - 7
// 1 => Sunday, 7 => Saturday
export const getMonthFirstDay = (month = THIS_MONTH, year = THIS_YEAR) => (moment({ month, year }).startOf('month')
  .day());

// (bool) Checks if a value is a date - this is just a simple check
export const isDate = (date) => moment(date).isValid();

// ({hour, day, month, year}) Gets the hour, day, month and year before the given hour, day, month and year
export const getPreviousHour = (hour, date, month, year) => {
  const previous = moment({
    hour, date, month, year,
  }).subtract(1, 'hour');
  const prevHour = previous.hour();
  const prevDay = previous.date();
  const prevMonth = previous.month();
  const prevMonthYear = previous.year();

  return {
    hour: prevHour, day: prevDay, month: prevMonth, year: prevMonthYear,
  };
};

// ({hour, day, month, year}) Gets the hour, day, month and year before the given hour, day, month and year
export const getNextHour = (hour, date, month, year) => {
  const next = moment({
    hour, date, month, year,
  }).add(1, 'hour');
  const nextHour = next.hour();
  const nextDay = next.date();
  const nextMonth = next.month();
  const nextMonthYear = next.year();

  return {
    hour: nextHour, day: nextDay, month: nextMonth, year: nextMonthYear,
  };
};

// ({day, month, year}) Gets the day, month and year before the given day, month and year
// For example: getPreviousMonth(1, 1, 2000) => {day: 31, month: 12, year: 1999}
// while: getPreviousMonth(1, 12, 2000) => {day: 30, month: 11, year: 2000}
export const getPreviousDay = (date, month, year) => {
  const previous = moment({ date, month, year }).subtract(1, 'day');
  const prevDay = previous.date();
  const prevMonth = previous.month();
  const prevMonthYear = previous.year();

  return { day: prevDay, month: prevMonth, year: prevMonthYear };
};

// ({day, month, year}) Gets the day, month and year before the given day, month and year
// For example: getPreviousMonth(1, 1, 2000) => {day: 31, month: 12, year: 1999}
// while: getPreviousMonth(1, 12, 2000) => {day: 30, month: 11, year: 2000}
export const getNextDay = (date, month, year) => {
  const next = moment({ date, month, year }).add(1, 'day');
  const nextDay = next.date();
  const nextMonth = next.month();
  const nextMonthYear = next.year();

  return { day: nextDay, month: nextMonth, year: nextMonthYear };
};

// ({month, year}) Gets the month and year before the given month and year
// For example: getPreviousMonth(1, 2000) => {month: 12, year: 1999}
// while: getPreviousMonth(12, 2000) => {month: 11, year: 2000}
export const getPreviousMonth = (month, year) => {
  const previous = moment({ month, year }).subtract(1, 'month');
  const prevMonth = previous.month();
  const prevMonthYear = previous.year();

  return { month: prevMonth, year: prevMonthYear };
};

// ({month, year}) Gets the month and year after the given month and year
// For example: getNextMonth(1, 2000) => {month: 2, year: 2000}
// while: getNextMonth(12, 2000) => {month: 1, year: 2001}
export const getNextMonth = (month, year) => {
  const next = moment({ month, year }).add(1, 'month');
  const nextMonth = next.month();
  const nextMonthYear = next.year();

  return { month: nextMonth, year: nextMonthYear };
};

// Calendar builder for a month in the specified year
// Returns an array of the calendar dates.
// Each calendar date is represented as an array => [YYYY, MM, DD]

const calendarBuilder = (month = THIS_MONTH, year = THIS_YEAR) => {
  // Get number of days in the month and the month's first day
  const monthDays = getMonthDays(month, year);
  const monthFirstDay = getMonthFirstDay(month, year);

  // Get number of days to be displayed from previous and next months
  // These ensure a total of 42 days (6 weeks) displayed on the calendar
  const daysFromPrevMonth = monthFirstDay;
  const daysFromNextMonth = (CALENDAR_WEEKS * 7) - (daysFromPrevMonth + monthDays);

  // Get the previous and next months and years
  const { month: prevMonth, year: prevMonthYear } = getPreviousMonth(month, year);
  const { month: nextMonth, year: nextMonthYear } = getNextMonth(month, year);

  // Get number of days in previous month
  const prevMonthDays = getMonthDays(prevMonth, prevMonthYear);

  // Builds dates to be displayed from previous month
  const prevMonthDates = [...new Array(daysFromPrevMonth)].map((n, index) => {
    const day = index + 1 + (prevMonthDays - daysFromPrevMonth);
    return [prevMonthYear, zeroPad(prevMonth, 2), zeroPad(day, 2)];
  });

  // Builds dates to be displayed from current month
  const thisMonthDates = [...new Array(monthDays)].map((n, index) => {
    const day = index + 1;
    return [year, zeroPad(month, 2), zeroPad(day, 2)];
  });

  // Builds dates to be displayed from next month
  const nextMonthDates = [...new Array(daysFromNextMonth)].map((n, index) => {
    const day = index + 1;
    return [nextMonthYear, zeroPad(nextMonth, 2), zeroPad(day, 2)];
  });

  // Combines all dates from previous, current and next months
  return [...prevMonthDates, ...thisMonthDates, ...nextMonthDates];
};
export default calendarBuilder;
