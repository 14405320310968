import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import NumberInput from 'components/NumberInput';
import TextInput from 'components/TextInput';
import Tooltip from 'components/Tooltip';
import { isDefined } from 'helpers/utils';

import Helpers from '../../helpers/EquipmentLibraryHelpers';
import {
  areAssetModelsEqual,
  areAssetModelPropertiesChanged,
  defaultAssetModel,
  getAssetModelProperties,
  isAssetModelValid,
} from '../../helpers/assetModelHelpers';
import DescriptionEditor from './DescriptionEditor';
import CostEditor from './CostEditor';
import PanelTabs from './PanelTabs';

import './common.scss';

const getNewInverter = () => ({
  name: '',
  description: '',
  ratedS: '',
  ratedU: '',
  minP: '',
  maxP: '',
  maxQ: '',
  minQ: '',
  ratedPowerFactor: '',
  assetModel: { ...defaultAssetModel },
  id: 'add',
});

class InverterPanel extends Component {
  state = { ...(getNewInverter()) }

  componentDidMount() {
    this.setState({ ...this.getInputValues(this.props.selected) });
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      this.setState({ ...this.getInputValues(this.props.selected) });
    }
  }

  getInputValues = (selected) => {
    let asset = { ...(getNewInverter()) };
    if (selected) {
      const {
        name, description, ratedS, ratedU, minP, maxP, maxQ, minQ, id, ratedPowerFactor,
        AssetModel,
      } = selected;
      asset = {
        name: name || asset.name,
        description: description || asset.description,
        ratedS: `${ratedS / 1000}` || asset.ratedS,
        ratedU: `${ratedU / 1000}` || asset.ratedU,
        minP: `${minP / 1000}` || asset.minP,
        maxP: `${maxP / 1000}` || asset.maxP,
        maxQ: `${maxQ / 1000}` || asset.maxQ,
        minQ: `${minQ / -1000}` || asset.minQ,
        ratedPowerFactor: `${ratedPowerFactor}` || asset.ratedPowerFactor,
        assetModel: isDefined(AssetModel) ? getAssetModelProperties(AssetModel) : asset.assetModel,
        id,
      };
    }
    return asset;
  }

  handleSave = () => {
    const diffModel = {
      name: this.state.name,
      description: this.state.description,
      ratedS: parseFloat(this.state.ratedS * 1000),
      ratedU: parseFloat(this.state.ratedU * 1000),
      minP: parseFloat(this.state.minP * 1000),
      maxP: parseFloat(this.state.maxP * 1000),
      minQ: parseFloat(this.state.minQ * -1000),
      maxQ: parseFloat(this.state.maxQ * 1000),
      AssetModel: { ...this.state.assetModel },
    };
    if (!(this.state.minP < 0 && this.state.maxP > 0)) {
      diffModel.ratedPowerFactor = parseFloat(this.state.ratedPowerFactor);
    }
    if (this.state.id === 'add') {
      this.props.handleCreate('inverter_info', diffModel, 'InverterInfo');
    } else {
      // Only submit the values that have changed
      const { selected } = this.props;
      const keys = Object.keys(diffModel);
      const editDiffModel = keys.reduce((diff, key) => {
        if (key === 'AssetModel') {
          if (!areAssetModelsEqual(diffModel[key], selected[key])) {
            diff[key] = diffModel[key];
          }
        } else if (diffModel[key] !== selected[key]) {
          diff[key] = diffModel[key];
        }
        return diff;
      }, {});
      this.props.handleEdit(selected.id, editDiffModel);
    }
  }

  handleInputChange = (id, value) => {
    this.setState({ [id]: value });
  }

  valuesUpdated = (selected) => {
    if (!selected) return true;
    const stringUpdated = selected.name !== this.state.name
      || selected.description !== this.state.description;
    const numbers = [{
      name: 'ratedS',
      multiplier: 1000,
    }, {
      name: 'ratedU',
      multiplier: 1000,
    }, {
      name: 'minP',
      multiplier: 1000,
    }, {
      name: 'maxP',
      multiplier: 1000,
    }, {
      name: 'maxQ',
      multiplier: 1000,
    }, {
      name: 'minQ',
      multiplier: -1000,
    }, {
      name: 'ratedPowerFactor',
      multiplier: 1,
    }];
    const numberUpdated = numbers.some(
      val => selected[val.name] !== (parseFloat(this.state[val.name]) * val.multiplier),
    );
    const assetModelUpdated = !areAssetModelsEqual(
      getAssetModelProperties(selected.AssetModel),
      this.state.assetModel,
    );
    return stringUpdated || numberUpdated || assetModelUpdated;
  }

  formValid = () => {
    const positiveValue = val => (val?.toString().length ?? 0) > 0 && parseFloat(val) >= 0;
    const maxOne = val => positiveValue(val) && parseFloat(val) <= 1;
    const pfDisabled = parseFloat(this.state.minP) < 0 && parseFloat(this.state.maxP) > 0;
    return (
      this.state.name.trim().length > 0
      && positiveValue(this.state.ratedS)
      && positiveValue(this.state.ratedU)
      && positiveValue(this.state.maxQ)
      && positiveValue(this.state.minQ)
      && isAssetModelValid(this.state.assetModel)
      && this.valuesUpdated(this.props.selected)
      && (pfDisabled
        ? true
        : positiveValue(this.state.ratedPowerFactor) && maxOne(this.state.ratedPowerFactor)
      )
    );
  };

  render() {
    const ratedS = Helpers.createDisplayObject('Apparent Power Limit', 'ratedS', this.state.ratedS, 'kVA');
    const ratedU = Helpers.createDisplayObject('Voltage Limit', 'ratedU', this.state.ratedU, 'kV');
    const minP = Helpers.createDisplayObject('Maximum Rate of Discharge', 'minP', this.state.minP, 'kW');
    const maxP = Helpers.createDisplayObject('Maximum Rate of Charge', 'maxP', this.state.maxP, 'kW');
    const maxQInd = Helpers.createDisplayObject('Maximum Reactive Power (Ind)', 'maxQ', this.state.maxQ, 'kVAr');
    const maxQCap = Helpers.createDisplayObject('Maximum Reactive Power (Cap)', 'minQ', this.state.minQ, 'kVAr');
    const ratedPowerFactor = Helpers.createDisplayObject('Rated Power Factor', 'ratedPowerFactor', this.state.ratedPowerFactor, '');
    const isDisabled = (
      this.props.isAuthEnabled
      && ((this.state.id === 'add' && !this.props.permissions.has('create_equipment_type'))
        || (this.state.id !== 'add' && !this.props.permissions.has('edit_equipment_type'))
        || (this.props.match.params.branch === 'master' && !this.props.permissions.has('modify_network_as_built')))
    );
    const pfDisabled = parseFloat(this.state.minP) < 0 && parseFloat(this.state.maxP) > 0;

    const defaultProps = {
      disabled: isDisabled,
      theme: this.props.theme,
    };

    return (
      <PanelTabs
        submitDisabled={!this.formValid() || isDisabled}
        onSubmit={this.handleSave}
        createInstanceReq={this.props.createInstanceReq}
        tabs={['General', 'Description', 'Costs']}
        assetID={this.state.id}
        showSave
      >
        {[
          <div className="equipment-info-container" key={this.state.id}>
            <div className="right-panel">
              <TextInput
                {...defaultProps}
                id="name"
                label="Name"
                value={this.state.name}
                onChange={({ target }) => this.handleInputChange(target.id, target.value)}
                required
                inputWidth="225px"
              />
              <NumberInput
                onChange={({ id, value }) => this.handleInputChange(id, value)}
                posNonZero
                step="0.001"
                required
                {...defaultProps}
                {...ratedS}
                inputStyle="eq-lib"
              />
              <NumberInput
                onChange={({ id, value }) => this.handleInputChange(id, value)}
                posNonZero
                step="0.001"
                required
                {...defaultProps}
                {...ratedU}
                inputStyle="eq-lib"
              />
              <NumberInput
                onChange={({ id, value }) => this.handleInputChange(id, value)}
                step="0.001"
                required
                ge={0}
                {...defaultProps}
                {...minP}
                inputStyle="eq-lib"
              />
              <NumberInput
                onChange={({ id, value }) => this.handleInputChange(id, value)}
                step="0.001"
                required
                ge={0}
                {...defaultProps}
                {...maxP}
                inputStyle="eq-lib"
              />
              <NumberInput
                onChange={({ id, value }) => this.handleInputChange(id, value)}
                ge={0}
                step="0.001"
                required
                {...defaultProps}
                {...maxQInd}
                inputStyle="eq-lib"
              />
              <NumberInput
                onChange={({ id, value }) => this.handleInputChange(id, value)}
                ge={0}
                step="0.001"
                required
                {...defaultProps}
                {...maxQCap}
                inputStyle="eq-lib"
              />
              <Tooltip
                content="Rated Power Factor is not considered for a 4-quadrant inverter"
                placement="bottom"
                enabled={pfDisabled}
                className="pf-tooltip"
              >
                <NumberInput
                  onChange={({ id, value }) => this.handleInputChange(id, value)}
                  ge={0}
                  le={1}
                  step="0.001"
                  required={!pfDisabled}
                  {...defaultProps}
                  disabled={isDisabled || pfDisabled}
                  {...ratedPowerFactor}
                  inputStyle="eq-lib"
                />
              </Tooltip>
            </div>
            <div className="column">
              <h2 className="column-title">Description</h2>
              <div className="markdown-body">
                <Markdown
                  escapeHtml
                  source={decodeURIComponent(this.state.description)}
                />
              </div>
            </div>
          </div>,
          <DescriptionEditor
            description={this.state.description}
            key={`${this.state.id}-description`}
            onChange={d => this.setState({ description: d })}
            isDisabled={isDisabled}
          />,
          <CostEditor
            assetModel={this.state.assetModel}
            assetModelDiff={areAssetModelPropertiesChanged(
              this.state.assetModel,
              getAssetModelProperties(this.props.selected?.AssetModel),
            )[1]}
            includeCommunityCredit
            key={`${this.state.id}-costs`}
            onChange={prop => this.setState(prevState => ({
              assetModel: { ...prevState.assetModel, ...prop },
            }))}
            isDisabled={isDisabled}
            theme={this.props.theme}
          />,
        ]}
      </PanelTabs>
    );
  }
}

InverterPanel.defaultProps = {
  selected: null,
};

InverterPanel.propTypes = {
  handleCreate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  createInstanceReq: PropTypes.number.isRequired,
  selected: PropTypes.object,
  isAuthEnabled: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
};

export default InverterPanel;
