import ACLineSegment from './ACLineSegment';
import AsynchronousMachine from './Shunt/AsynchronousMachine';
import Battery from './Shunt/Battery';
import Breaker from './Link/Breaker';
import CHP from './Shunt/CHP';
import ConnectivityNode from './ConnectivityNode';
import Cut from './Link/Cut';
import Disconnector from './Link/Disconnector';
import ElectricVehicleChargingStation from './Shunt/ElectricVehicleChargingStation';
import EnergyConsumer from './Shunt/EnergyConsumer';
import EnergySource from './Shunt/EnergySource';
import EquivalentSubstation from './Shunt/EquivalentSubstation';
import Feeder from './NonDevice/Feeder';
import Fuse from './Link/Fuse';
import InverterPV from './Shunt/InverterPV';
import Inverter from './Shunt/Inverter';
import Jumper from './Link/Jumper';
import LinearShuntCompensator from './Shunt/LinearShuntCompensator';
import LoadResponseCharacteristic from './NonDevice/LoadResponseCharacteristic';
import PhotoVoltaic from './Shunt/PhotoVoltaic';
import PowerTransformer from './Link/PowerTransformer';
import Recloser from './Link/Recloser';
import Regulator from './Link/Regulator';
import RunOfRiverHydro from './Shunt/RunOfRiverHydro';
import Sectionaliser from './Link/Sectionaliser';
import Substation from './NonDevice/Substation';
import Switch from './Link/Switch';
import SynchronousMachine from './Shunt/SynchronousMachine';
import Wind from './Shunt/Wind';

// AssetInfo
import AssetInfo from './AssetInfo/AssetInfo';
import Agreement from './Agreement/Agreement';
import AssetModel from './AssetInfo/AssetModel';
import AssetFailureInfo from './AssetInfo/AssetFailureInfo';
import EmissionRate from './AssetInfo/EmissionRate';
import GenericAssetPriceModel from './AssetInfo/GenericAssetPriceModel';
import CableInfo from './AssetInfo/CableInfo';
import CogenerationGeneratingUnitInfo from './AssetInfo/CogenerationGeneratingUnitInfo';
import ConcentricNeutralCableInfo from './AssetInfo/ConcentricNeutralCableInfo';
import ElectricVehicleChargingStationInfo from './AssetInfo/ElectricVehicleChargingStationInfo';
import EnergyStorageUnitInfo from './AssetInfo/EnergyStorageUnitInfo';
import InverterInfo from './AssetInfo/InverterInfo';
import OverheadWireInfo from './AssetInfo/OverheadWireInfo';
import PhotoVoltaicUnitInfo from './AssetInfo/PhotoVoltaicUnitInfo';
import WindGeneratingUnitInfo from './AssetInfo/WindGeneratingUnitInfo';
import PowerTransformerInfo from './AssetInfo/PowerTransformerInfo';
import ProductAssetModel from './AssetInfo/ProductAssetModel';
import ShuntCompensatorInfo from './AssetInfo/ShuntCompensatorInfo';
import SwitchInfo from './AssetInfo/SwitchInfo';
import TapeShieldCableInfo from './AssetInfo/TapeShieldCableInfo';
import ThermalGeneratingUnitInfo from './AssetInfo/ThermalGeneratingUnitInfo';
import TransformerEndInfo from './AssetInfo/TransformerEndInfo';
import TransformerStarImpedance from './AssetInfo/TransformerStarImpedance';
import TransformerTankInfo from './AssetInfo/TransformerTankInfo';
import WireInfo from './AssetInfo/WireInfo';
import WirePosition from './AssetInfo/WirePosition';
import WireSpacingInfo from './AssetInfo/WireSpacingInfo';
import HydroGeneratingUnitInfo from './AssetInfo/HydroGeneratingUnitInfo';
import CustomerAgreement from './Agreement/CustomerAgreement';
import DerAuxiliaryAgreement from './Agreement/DerAuxiliaryAgreement';

const cimClasses = {
  ACLineSegment,
  AssetInfo,
  Agreement,
  AssetModel,
  AsynchronousMachine,
  Battery,
  Breaker,
  CableInfo,
  CHP,
  CogenerationGeneratingUnitInfo,
  ConcentricNeutralCableInfo,
  ConnectivityNode,
  Cut,
  Disconnector,
  ElectricVehicleChargingStation,
  ElectricVehicleChargingStationInfo,
  AssetFailureInfo,
  EmissionRate,
  EnergyConsumer,
  EnergySource,
  EnergyStorageUnitInfo,
  EquivalentSubstation,
  Feeder,
  Fuse,
  GenericAssetPriceModel,
  Inverter,
  InverterInfo,
  InverterPV,
  Jumper,
  LinearShuntCompensator,
  LoadResponseCharacteristic,
  OverheadWireInfo,
  PhotoVoltaic,
  PhotoVoltaicUnitInfo,
  WindGeneratingUnitInfo,
  PowerTransformer,
  PowerTransformerInfo,
  ProductAssetModel,
  Regulator,
  Recloser,
  RunOfRiverHydro,
  Sectionaliser,
  ShuntCompensatorInfo,
  Substation,
  Switch,
  SwitchInfo,
  SynchronousMachine,
  TapeShieldCableInfo,
  ThermalGeneratingUnitInfo,
  HydroGeneratingUnitInfo,
  TransformerEndInfo,
  TransformerStarImpedance,
  TransformerTankInfo,
  Wind,
  WireInfo,
  WirePosition,
  WireSpacingInfo,
  CustomerAgreement,
  DerAuxiliaryAgreement,
};
export default cimClasses;
