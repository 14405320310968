import React from 'react';
import { getMultiplier } from 'helpers/utils';
import JSCIM from 'helpers/JSCIM';

const createDisplayObject = (label, id, val, unit, applyMultiplier = false) => {
  const { value, letter } = getMultiplier(val);
  let obj; let objValue;

  if (applyMultiplier && val !== 0) {
    objValue = (val / value) || '';
    obj = {
      label,
      id,
      value: objValue,
      unit: `${letter}${unit}`,
      multiplier: value,
    };
  } else {
    obj = {
      label,
      id,
      value: val,
      unit,
      multiplier: value,
    };
  }

  return obj;
};

const createEmissionRate = (value) => {
  const obj = createDisplayObject(
    <span>
      Emission (CO
      <sub>2</sub>
      ) Rate
    </span>, 'emissionRate', value, 'g/kWh',
  );
  return obj;
};

const LIBCLASSES = [
  'EnergyStorageUnitInfo',
  'CableInfo',
  'ConcentricNeutralCableInfo',
  'LoadResponseCharacteristic',
  'OverheadWireInfo',
  'PowerTransformerInfo',
  'ShuntCompensatorInfo',
  'SwitchInfo',
  'TapeShieldCableInfo',
  'ThermalGeneratingUnitInfo',
  'TransformerEndInfo',
  'TransformerStarImpedance',
  'TransformerTankInfo',
  'WirePosition',
  'WireSpacingInfo',
  'WireInfo',
  'InverterInfo',
  'PhotoVoltaicUnitInfo',
  'WindGeneratingUnitInfo',
  'ElectricVehicleChargingStationInfo',
  'GenericAssetPriceModel',
  'AssetFailureInfo',
  'EmissionRate',
  'CustomerAgreement',
  'DerAuxiliaryAgreement',
  'CogenerationGeneratingUnitInfo',
  'HydroGeneratingUnitInfo',
];

function byLabel(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }

  // names must be equal
  return 0;
}

function byClassThenLabel(a, b) {
  if (a.class < b.class) {
    return -1;
  }
  if (a.class > b.class) {
    return 1;
  }
  return byLabel(a, b);
}

function byConfigurationTypeThenLabel(a, b) {
  if (a.configurationType < b.configurationType) {
    return -1;
  }
  if (a.configurationType > b.configurationType) {
    return 1;
  }
  return byLabel(a, b);
}

const librarySortFunction = (infoType) => {
  switch (infoType) {
    case 'wireGeometries':
      return byConfigurationTypeThenLabel;
    case 'conductors':
      return byClassThenLabel;
    default:
      return byLabel;
  }
};

const classToLibType = {
  EnergyStorageUnitInfo: 'batteries',
  CableInfo: 'conductors',
  ConcentricNeutralCableInfo: 'conductors',
  LoadResponseCharacteristic: 'customerTypes',
  OverheadWireInfo: 'conductors',
  WireInfo: 'conductors',
  ShuntCompensatorInfo: 'capacitors',
  SwitchInfo: 'switches',
  TapeShieldCableInfo: 'conductors',
  ThermalGeneratingUnitInfo: 'thermalGenerators',
  TransformerTankInfo: 'transformers',
  WireSpacingInfo: 'wireGeometries',
  InverterInfo: 'inverters',
  PhotoVoltaicUnitInfo: 'photovoltaics',
  WindGeneratingUnitInfo: 'winds',
  ElectricVehicleChargingStationInfo: 'evChargingStations',
  CustomerAgreement: 'customerPrograms',
  CogenerationGeneratingUnitInfo: 'CHPs',
  HydroGeneratingUnitInfo: 'hydroGeneratingUnits',
};

function getLibraryType(cls) {
  return classToLibType[cls];
}

function getJSCimInstance(uuid, cls, objectLookup) {
  const Constructor = JSCIM[cls];
  if (Constructor) {
    return new Constructor(uuid, objectLookup);
  }
  return objectLookup[uuid];
}

/// Take CIM and pull out all the pieces of the library
function buildLibraryFromCIM(cimData) {
  const lib = {
    batteries: [],
    capacitors: [],
    conductors: [],
    customerTypes: [],
    wireGeometries: [],
    switches: [],
    thermalGenerators: [],
    transformers: [],
    inverters: [],
    photovoltaics: [],
    winds: [],
    evChargingStations: [],
    customerPrograms: [],
    CHPs: [],
    hydroGeneratingUnits: [],
    objects: { ...cimData.objects },
  };
  const seen = new Set();
  LIBCLASSES.forEach((cls) => {
    if (!cimData.classes[cls]) {
      return;
    }
    cimData.classes[cls].forEach((uuid) => {
      if (seen.has(uuid)) {
        // Only process IDs once
        // This case can happen when loading derived types such
        // as ConcentricNeutralCableInfo
        return;
      }

      seen.add(uuid);
      const objClass = cimData.objects[uuid].class;
      const obj = getJSCimInstance(uuid, objClass, lib.objects);
      const bucket = classToLibType[objClass];
      if (bucket) {
        lib[bucket].push(obj);
      }

      // Store the JSCIM object for later
      lib.objects[uuid] = obj;
    });
    Object.keys(lib).forEach((infoType) => {
      if (infoType !== 'objects') {
        lib[infoType].sort(librarySortFunction(infoType));
      }
    });
  });
  return lib;
}

const areSomeReferenced = (selected, library) => {
  let someReferenced = false;

  selected.forEach((item) => {
    const instance = library.objects[item];
    if (!someReferenced && instance) {
      if (instance.class === 'LoadResponseCharacteristic') {
        const loads = instance.references['LoadResponseCharacteristic.EnergyConsumer'];
        someReferenced = someReferenced || (!!loads && loads.length > 0);
      } else {
        const psr = instance.references['AssetInfo.PowerSystemResources'];
        someReferenced = someReferenced || (!!psr && psr.length > 0);
      }
    }
  });
  return someReferenced;
};

const getSelectedStatus = (infos, library) => {
  const selectedStatus = (vals, someSelected, allSelected, referenced, total = 0) => {
    const currentVal = vals.next().value;
    // Handle the undefined case
    if (currentVal === undefined) {
      return [someSelected, allSelected, referenced, total];
    }
    const libSize = library[currentVal.key].length;

    // Handle case of no instances / no loaded eq lib
    if (libSize === 0) {
      return selectedStatus(vals, someSelected, allSelected, referenced, total);
    }

    const { size } = currentVal.selected;

    let someReferenced = false;
    if (size > 0 && !referenced) {
      someReferenced = areSomeReferenced(currentVal.selected, library);
    }

    const some = someSelected || size > 0;
    const all = allSelected === false ? false : (size === libSize);
    const refd = referenced || someReferenced;
    const newTotal = total + size;
    return selectedStatus(vals, some, all, refd, newTotal);
  };

  return selectedStatus(infos.values());
};

const eql = {
  createDisplayObject,
  createEmissionRate,
  buildLibraryFromCIM,
  byClassThenLabel,
  byConfigurationTypeThenLabel,
  getLibraryType,
  getSelectedStatus,
  getJSCimInstance,
  librarySortFunction,
};
export default eql;
