import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { List, AutoSizer } from 'react-virtualized';
import { useSelector } from 'react-redux';
import { Request, useAuthContext } from '@opusonesolutions/gridos-app-framework';

import Analytics from 'helpers/Analytics';
import ThemeContext from 'helpers/ThemeContext';
import { useAttachments } from 'contexts/AttachmentsContext';
import Button from '../Button';
import NoteCard from './NoteCard';
import './Notes.scss';

const NotesPanel = ({ assetID }) => {
  const {
    workspace, branch, inEditMode,
  } = useSelector(state => ({
    workspace: state.network.workspace,
    branch: state.network.branch,
    inEditMode: state.edit.inEditMode,
  }));
  const { permissions } = useAuthContext();
  const [loading, setLoadingState] = useState(false);
  const [error, setError] = useState();
  const theme = useContext(ThemeContext);
  const {
    loading: { attachments: notesLoading },
    error: { attachments: notesError },
    notes,
    deleteAssetAttachment,
    refetch,
  } = useAttachments();

  // For saving and creating notes
  const saveNote = async (index, content) => {
    const note = notes[index];
    const request = new Request(
      `/api/workspace/${workspace}/branch/${branch}/asset/${assetID}/note/${note.id}`,
    );
    setLoadingState(true);

    try {
      // Updating an existing note
      await request.patch({
        content,
      });
      refetch();
    } catch (err) {
      setError(err.message);
    }
    setLoadingState(false);
  };

  const createNote = async () => {
    const request = new Request(
      `/api/workspace/${workspace}/branch/${branch}/asset/${assetID}/note`,
    );
    setLoadingState(true);

    Analytics.logEvent('Note Created', 'Note Panel');

    try {
      // Create a new blank note
      await request.post({ content: '' });
      refetch();
    } catch (err) {
      setError(err.message);
    }
    setLoadingState(false);
  };

  const loadingState = loading || notesLoading;
  const errorState = error || notesError;

  const cardsDisabled = loadingState || inEditMode;

  // Renderer for each row of the virtualized list
  // eslint-disable-next-line react/prop-types
  const rowRenderer = ({ index, style }) => {
    const note = notes[index];
    return (
      <div key={`note-${note.id}`} style={{ ...style, paddingRight: '10px' }}>
        <NoteCard
          content={note.content}
          createdTime={note.created_time}
          disabled={cardsDisabled}
          onDelete={() => deleteAssetAttachment({ type: 'note', id: note.id })}
          onSave={newContent => saveNote(index, newContent)}
          permissions={permissions}
          theme={theme}
          updatedTime={note.updated_time}
        />
      </div>
    );
  };

  const wasSuccessful = !loadingState && !errorState;

  return (
    <div className="notes-panel">
      <Button
        className="create-button"
        disabled={
          !permissions.has('edit_asset_notes') || cardsDisabled || inEditMode || loadingState
        }
        onClick={createNote}
        theme={theme}
      >
        Create Note
      </Button>
      {loadingState && <div className="load-message">Loading Notes</div>}
      {errorState && <div className="error-message">Error retrieving notes.</div>}
      {wasSuccessful && (
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="gray-scrollbars"
              overscanColumnCount={10}
              rowCount={notes.length}
              rowHeight={280}
              width={width}
              height={height}
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
      )}
    </div>
  );
};

NotesPanel.defaultProps = {
  assetID: undefined,
};

NotesPanel.propTypes = {
  assetID: PropTypes.string,
};

export default NotesPanel;
