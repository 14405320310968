import React, { FunctionComponent, useContext } from 'react';
import Select from 'components/Select';
import ThemeContext from 'helpers/ThemeContext';
import Analytics from 'helpers/Analytics';
import { ActivityLogContext } from 'contexts/ActivityLogContext';
import getBadgedAnalysisOption from '../../Network/components/getBadgedAnalysisOption';

type analysis = { id: string, name: string, type: string }|null;
type AnalysisSelectionProps = {
   analysesList: analysis[],
   analysis: analysis,
   resultsBranch: string|null,
   scenario: string|null,
   setSelectedAnalysis: (arg: analysis) => void,
   updateMaxRange: (sce: string|null, arg: analysis) => void,
   title: string,
};
const AnalysisSelection: FunctionComponent<AnalysisSelectionProps> = ({
  analysesList,
  analysis,
  resultsBranch,
  scenario,
  setSelectedAnalysis,
  updateMaxRange,
  title,
}) => {
  const theme = useContext(ThemeContext);
  const { analysisActive } = useContext(ActivityLogContext);
  return (
    <div className="analysis-selection">
      <p className="select-label space-bottom">Analysis</p>
      <Select
        id="results-analysis-section"
        theme={theme}
        options={analysesList.map((opt: any) => {
          const isAnalysisActive = analysisActive(resultsBranch, scenario, opt.name);
          return getBadgedAnalysisOption(opt, isAnalysisActive);
        })}
        value={analysis?.id}
        onChange={(e: any) => {
          setSelectedAnalysis(e?.analysis);
          updateMaxRange(scenario, e?.analysis);
          Analytics.logEvent(`Analysis change for ${title}`, 'Analysis');
        }}
        type="secondary"
      />
    </div>
  );
};

export default AnalysisSelection;
