import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import StatusBar from 'components/StatusBar';
import ExpandableSection from 'components/ExpandableSection';
import NavigationLink from 'components/NavigationLink';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import ThemeContext from 'helpers/ThemeContext';
import { pluralize } from 'helpers/utils';
import fileExportSave from 'helpers/FileDownload';
import { Request } from '@opusonesolutions/gridos-app-framework';
import { ACTIVITY_LOG_STATUS } from 'routes/WorkspaceLayout/routes/Network/helpers/NetworkHelpers';
import IconButton from 'components/IconButton';
import CloseIcon from 'components/Icons/CloseIcon';
import ActivityModal from './ActivityModal';
import AnalysisLogs from './AnalysisLogs';
import PowerflowReportButton from './PowerflowReportButton';

import { ANALYSIS_TYPES, ASSET_TYPE_TO_DISPLAY } from '../../../helpers/NetworkHelpers';

const {
  QSTS, POWERFLOW, HOSTING_CAPACITY, EV_CAPACITY, BATTERY_SIZING,
  ASSET_SCHEDULE_PREAGG_MIGRATION, CLONING_SCENARIOS, POWERFLOW_REPORT,
  BULK_SCHEDULE_GENERATION,
} = ANALYSIS_TYPES;

const getEventName = (event) => {
  const { activity_type: type, additional_info: info } = event;
  const { objective, node, asset_type } = info;

  const qstsTitles = {
    cvr: 'Optimal Powerflow: Conservation Voltage Reduction',
    loss: 'Optimal Powerflow: Loss Minimization',
    'peak-shaving': 'Optimal Powerflow: Peak Shaving',
    pf: 'Timeseries Powerflow',
    'voltage-deviation': 'Optimal Powerflow: Voltage Optimization',
    cost: 'Optimal Powerflow: Cost Minimization',
    'no-violations': 'Non-Wires Evaluation',
  };
  const titles = {
    [QSTS]: qstsTitles[objective],
    [POWERFLOW]: 'Snapshot Powerflow',
    [HOSTING_CAPACITY]: node ? 'Hosting Capacity' : 'Network Hosting Capacity',
    [EV_CAPACITY]: node ? 'EV Capacity' : 'Network EV Capacity',
    [CLONING_SCENARIOS]: 'Cloning scenario data',
    [BATTERY_SIZING]: 'Battery Evaluation',
    [ASSET_SCHEDULE_PREAGG_MIGRATION]: 'Asset Schedule Upgrade',
    [POWERFLOW_REPORT]: 'Powerflow Report',
    [BULK_SCHEDULE_GENERATION]: `Bulk Schedule Generation: ${ASSET_TYPE_TO_DISPLAY[asset_type] ?? asset_type}`,
  };

  return titles[type];
};

const STATUS_DISPLAY = {
  [ACTIVITY_LOG_STATUS.COMPLETED]: 'Completed',
  [ACTIVITY_LOG_STATUS.FAILED]: 'Failed',
  [ACTIVITY_LOG_STATUS.PARTIAL_COMPLETED]: 'Partially Completed',
  [ACTIVITY_LOG_STATUS.RUNNING]: 'Running',
  [ACTIVITY_LOG_STATUS.CANCELED]: 'Canceled',
  [ACTIVITY_LOG_STATUS.PENDING]: 'Pending',
  [ACTIVITY_LOG_STATUS.CANCELING]: 'Canceling',
  [ACTIVITY_LOG_STATUS.CANCELED_PARTIAL_COMPLETED]: 'Canceled, Partially Completed',
  [ACTIVITY_LOG_STATUS.POSTPROCESSING]: 'Postprocessing',
};

const JOB_IS_OVER = {
  [ACTIVITY_LOG_STATUS.COMPLETED]: true,
  [ACTIVITY_LOG_STATUS.FAILED]: true,
  [ACTIVITY_LOG_STATUS.CANCELED]: true,
  [ACTIVITY_LOG_STATUS.CANCELED_PARTIAL_COMPLETED]: true,
  [ACTIVITY_LOG_STATUS.PARTIAL_COMPLETED]: true,
  [ACTIVITY_LOG_STATUS.RUNNING]: false,
  [ACTIVITY_LOG_STATUS.PENDING]: false,
  [ACTIVITY_LOG_STATUS.CANCELING]: false,
  [ACTIVITY_LOG_STATUS.POSTPROCESSING]: false,
};
const extractNodeLabel = (additionalInfo) => {
  const nodeLabel = additionalInfo.node_name ? `Node ${additionalInfo.node_name}` : `Node ${additionalInfo.node}`;
  return additionalInfo.node ? nodeLabel : null;
};

const extractRowLabel = (additionalInfo) => {
  const format = 'YYYY-MM-DD HH:mm';
  return `${moment.parseZone(additionalInfo.start_date).format(format)} -`
    + ` ${moment.parseZone(additionalInfo.end_date).format(format)}`;
};

// eslint-disable-next-line react/prop-types
const FailedSubJob = ({ job: { additional_info, status_description }, theme }) => {
  const nodeLabel = extractNodeLabel(additional_info);
  const label = extractRowLabel(additional_info);
  const value = 'Failed';
  const tooltipContent = () => (
    <>
      <p>{nodeLabel || ''}</p>
      <p>{label}</p>
      <p>{status_description || ''}</p>
    </>
  );

  return (
    <Tooltip content={tooltipContent()} theme={theme} placement="right">
      <div className="subjobs-info-row">
        <div className="subjobs-info-row-description-column">
          <div className="warning-description">
            <i className="material-icons failed-status">warning</i>
            <div className="subjobs-info-row-description-container">
              {
                nodeLabel == null
                  ? <p className="subjob-label">{label}</p>
                  : (
                    <p className="subjob-label">
                      {nodeLabel}
                      <br />
                      {label}
                    </p>
                  )
              }
              <p title={status_description} className="subjob-status-description">{status_description}</p>
            </div>
          </div>
          <div className="status failed-status">{value}</div>
        </div>
      </div>
    </Tooltip>
  );
};

// eslint-disable-next-line react/prop-types
const RunningSubJob = ({ job: { additional_info } }) => {
  const nodeLabel = extractNodeLabel(additional_info);
  const label = extractRowLabel(additional_info);
  return (
    <div className="subjobs-info-row">
      {nodeLabel == null
        ? <span>{label}</span>
        : (
          <span>
            {nodeLabel}
            <br />
            {label}
          </span>
        )}
      <span className="status">Running</span>
    </div>
  );
};

const elapsedTimeMessage = (event) => {
  const startOfduration = moment.utc(event.time_created, 'YYYY-MM-DD HH:mm');
  const endOfDuration = JOB_IS_OVER[event.status]
    ? moment.utc(event.last_changed, 'YYYY-MM-DD HH:mm') : moment.utc();
  const elapsed_time = moment.duration(endOfDuration.diff(startOfduration)).humanize();
  if (!elapsed_time) {
    return '';
  }
  return JOB_IS_OVER[event.status]
    ? `Finished in ${elapsed_time}.` : `Started ${elapsed_time} ago.`;
};

const AnalysisEvent = ({
  event, cancelJob, containers, selectedContainers, permissions,
}) => {
  const theme = useContext(ThemeContext);
  const hasAdditionalInfo = Object.keys(event.additional_info)
    .some(key => ['start_date', 'end_date', 'scenario_name', 'containers', 'node'].includes(key));
  const hasTasks = event.num_tasks > 0;
  const finishedTasks = event.num_completed_tasks
    + event.num_failed_tasks;
  const progress = (finishedTasks / event.num_tasks) * 100;
  const eventName = getEventName(event);
  const { branch, workspace, job_id } = event;
  const [batteryAnalysisActive, toggleBatteryAnalysis] = useState(false);
  const [failureLogsActive, toggleFailureLogsActive] = useState(false);
  const [batteryResults, setBatteryResults] = useState({});
  const [batteryResultsLoading, setBatteryResultsLoading] = useState(false);
  const [correctFeedersSelected, setCorrectFeedersSelected] = useState(false);

  const elapsed_time_message = elapsedTimeMessage(event);
  const jobUnits = 'job';
  const container_names = () => {
    const [feeder_names, substation_names] = event.additional_info.containers.reduce(
      ([feeders, substations], container_id) => {
        const container = containers.find(c => c.id === container_id);
        if (!container) return [feeders, substations];

        if (container.type === 'Substation') {
          return [feeders, [...substations, container.name]];
        }
        return [[...feeders, container.name], substations];
      },
      [[], []],
    );

    return (
      <>
        {substation_names.length > 0 && (
        <p className="caption-text">
          {pluralize('Substation', substation_names.length)}
          :
          {substation_names.join(', ')}
        </p>
        )}
        {feeder_names.length > 0 && (
        <p className="caption-text">
          {pluralize('Feeder', feeder_names.length)}
          :
          {feeder_names.join(', ')}
        </p>
        )}
      </>
    );
  };

  useEffect(() => {
    async function getResults() {
      const req = new Request(`/api/workspace/${workspace}/branch/${branch}/nodal-analysis-results/all`);
      try {
        setBatteryResultsLoading(true);
        const res = await req.get({
          params: {
            job_id,
            analysis_subtype: 'battery',
          },
        });
        setBatteryResults(res.data);
      } catch (err) {
        setBatteryResults({});
      }
      setBatteryResultsLoading(false);
    }

    if (event.status === 'COMPLETED'
      && event.activity_type === ANALYSIS_TYPES.BATTERY_SIZING
    && correctFeedersSelected
    ) {
      getResults();
    }
  }, [workspace, branch, job_id, correctFeedersSelected, event]);

  useEffect(() => {
    const containerList = selectedContainers.map(f => f.id);
    const result = event.activity_type === ANALYSIS_TYPES.BATTERY_SIZING
      && event.additional_info.containers?.every(c => containerList.includes(c));
    setCorrectFeedersSelected(result);
  }, [event, selectedContainers]);

  const downloadSimInfo = async (pathname, jobId) => {
    if (pathname && jobId) {
      const baseURL = `/api/files/${pathname}`;
      const request = new Request(baseURL);
      try {
        const { data, headers } = await request.getFile();
        fileExportSave(data, headers, `${jobId}-debug-data.zip`);
      } catch (err) {
      }
    }
  };

  const logsExist = (evt) => {
    let hasLogs = false;
    if (evt.subjobs?.length) {
      let i = 0;
      do {
        hasLogs = Object.keys(evt.subjobs[i]?.additional_info?.debug_files ?? []).length > 0;
        i += 1;
      } while (i < evt.subjobs.length && !hasLogs);
    } else {
      hasLogs = Object.keys(evt.additional_info?.debug_files ?? []).length > 0;
    }
    return hasLogs;
  };

  const subjobsToDisplay = event.subjobs?.filter(
    ({ status }) => [ACTIVITY_LOG_STATUS.RUNNING, ACTIVITY_LOG_STATUS.FAILED].includes(status),
  ) ?? [];
  const numDisplayableFails = subjobsToDisplay.filter(x => x.status === ACTIVITY_LOG_STATUS.FAILED).length;
  return (
    <div className="activity-log-row">
      <div className="activity-contents">
        <div className="event-row event-header caption-text">
          <p>{eventName}</p>
          <p className="timestamp">
            {moment(event.time_created).format('YYYY-MM-DD HH:mm')}
            <br />
            {event.short_job_id ? event.short_job_id.toUpperCase() : null}
          </p>
        </div>
        <div className="event-status caption-text">
          <p>
            Status:
            {STATUS_DISPLAY[event.status]}
            {(
              permissions.has('view_analytics_simulation_debug_data')
              || permissions.has('view_analytics_simulation_debug_summary')
            ) && logsExist(event) && (
              <Button type="text" onClick={() => toggleFailureLogsActive(true)} theme={theme}>Download Logs</Button>
            )}
            {event.activity_type === POWERFLOW_REPORT && event.status.match(/completed/i) && (
              <PowerflowReportButton
                event={event}
                workspace={workspace}
                branch={branch}
              />
            )}
          </p>
          {(event.status.match(/completed/i) && event.num_failed_tasks === 0 && event.num_completed_tasks > 1)
            && (
            <p className="progress-label">
              {`${event.num_completed_tasks} ${pluralize(jobUnits, event.num_completed_tasks)} completed`}
            </p>
            )}
          {(event.status.match(/completed/i) && event.num_failed_tasks > 0)
            && (
            <p className="progress-label">
              {`${event.num_completed_tasks} ${pluralize(jobUnits, event.num_completed_tasks)} completed, ${event.num_failed_tasks} ${pluralize(jobUnits, event.num_failed_tasks)} failed`}
            </p>
            )}
          {(event.status.match(/failed/i) && event.num_failed_tasks > 1)
            && (
            <p className="progress-label">
              {`${event.num_failed_tasks} ${pluralize(jobUnits, event.num_failed_tasks)} failed`}
            </p>
            )}
        </div>
        {event.status.match(/running|postprocessing|pending/i)
          && (
          <div className="status-section">
            <div className="status-bar-container">
              {event.status.match(/running|postprocessing/i)
                && (
                <StatusBar
                  barColor="#06AFA8"
                  progress={hasTasks ? progress : 100}
                  processing={!hasTasks || event.status.match(/postprocessing/i)}
                  cornerType="round"
                />
                )}
              {event.status.match(/pending/i)
                && (
                <StatusBar
                  backgroundColor="#949899"
                  progress={0}
                  processing={false}
                  cornerType="round"
                />
                )}
            </div>
            {!event.status.match(/postprocessing/i)
              && (
              <IconButton
                id="cancelJob"
                onClick={() => cancelJob(job_id)}
                theme={theme}
                className="close-icon"
                tooltip="cancel"
              >
                <CloseIcon />
              </IconButton>
              )}
          </div>
          )}
        {(event.status.match(/running/i) || event.status.match(/pending/i)) && hasTasks
          && (
          <p className="progress-label">
            {`${eventName} in progress...`}
            <br />
            {`${finishedTasks}/${event.num_tasks} ${pluralize(jobUnits, event.num_tasks)} completed, ${event.num_failed_tasks} ${pluralize('error', event.num_failed_tasks)}`}
          </p>
          )}
        {
          event.status_description
          && <p className="status-description caption-text">{event.status_description}</p>
        }
      </div>
      { hasAdditionalInfo && event.activity_type === 'BATTERY_SIZE' && event.status === 'COMPLETED' && (
        <NavigationLink
          label="See Summary"
          link="/test-url"
          onClick={() => toggleBatteryAnalysis(true)}
          disabled={!correctFeedersSelected || Object.keys(batteryResults).length === 0}
          disabledMsg={{
            title: 'Battery Analysis Results',
            message: `${!correctFeedersSelected
              ? 'Please select the appropriate feeder for this analysis'
              : 'There are no results for this analysis or the analysis has been deleted'}`,
          }}
          theme={theme}
        />
      )}
      { hasAdditionalInfo
        && (
        <ExpandableSection
          renderHeaderContent={sectionProps => (
            <span className="more-details-toggle">{sectionProps.open ? 'Less Details' : 'More Details'}</span>
          )}
        >
          { (subjobsToDisplay.length > 0)
            && (
            <>
              {event.num_failed_tasks > 0
                && (
                <span className="subjobs-label caption-text">
                  {`Showing ${numDisplayableFails} of ${event.num_failed_tasks} errors`}
                </span>
                )}
              <div className="subjobs-info">
                {subjobsToDisplay.map(job => (
                  job.status === ACTIVITY_LOG_STATUS.RUNNING
                    ? <RunningSubJob job={job} key={job.job_id} />
                    : <FailedSubJob job={job} theme={theme} key={job.job_id} />
                ))}
              </div>
            </>
            )}
          {event.additional_info.analysis_name
            && (
            <p className="caption-text">
              {`Analysis Name: ${event.additional_info.analysis_name}`}
            </p>
            )}
          {event.additional_info.start_date
            && (
            <p className="caption-text">
              {`Analysis Start: ${moment.parseZone(event.additional_info.start_date).format('YYYY-MM-DD HH:mm')}`}
            </p>
            )}
          {event.additional_info.end_date
            && (
            <p className="caption-text">
              {`Analysis End: ${moment.parseZone(event.additional_info.end_date).format('YYYY-MM-DD HH:mm')}`}
            </p>
            )}
          {event.additional_info.scenario_name
            && (
            <p className="caption-text">
              {`Scenario Name: ${event.additional_info.scenario_name}`}
            </p>
            )}
          {event.additional_info.containers && container_names()}
          {elapsed_time_message
            && <p className="caption-text">{elapsed_time_message}</p>}
        </ExpandableSection>
        )}
      {batteryAnalysisActive === true
        && event.status === 'COMPLETED'
        && event.activity_type === ANALYSIS_TYPES.BATTERY_SIZING
        && correctFeedersSelected
        && (
          <ActivityModal
            toggleModal={toggleBatteryAnalysis}
            batteryResults={batteryResults}
            theme={theme}
            resultsLoading={batteryResultsLoading}
            workspace={workspace}
            branch={branch}
          />
        )}
      <AnalysisLogs
        failureLogsActive={failureLogsActive}
        toggleFailureLogsActive={toggleFailureLogsActive}
        jobs={event?.subjobs?.length ? event?.subjobs : [event]}
        extractRowLabel={extractRowLabel}
        extractNodeLabel={extractNodeLabel}
        downloadLogFiles={downloadSimInfo}
        permissions={permissions}
      />
    </div>

  );
};

AnalysisEvent.propTypes = {
  cancelJob: PropTypes.func.isRequired,
  event: PropTypes.shape({
    activity_type: PropTypes.string,
    time_created: PropTypes.string,
    status: PropTypes.string,
    additional_info: PropTypes.object,
    num_tasks: PropTypes.number,
    num_completed_tasks: PropTypes.number,
    num_failed_tasks: PropTypes.number,
    workspace: PropTypes.string,
    branch: PropTypes.string,
    job_id: PropTypes.string,
    short_job_id: PropTypes.string,
    subjobs: PropTypes.array,
    status_description: PropTypes.string,
  }).isRequired,
  containers: PropTypes.array.isRequired,
  selectedContainers: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default AnalysisEvent;
