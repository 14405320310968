import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import nullable from 'helpers/nullablePropType';
import classNames from 'classnames';
import CustomScrollBar from 'components/CustomScrollBar';
import { ActivityLogContext } from 'contexts/ActivityLogContext';

import VoltageLimits from 'components/VoltageLimits';

import ReportList from '../ContainerPanel/ReportList';
import AssetSchedule from '../AssetPanel/templates/partials/AssetSchedule';
import AnalysisResultsSection from '../FeederPanel/components/AnalysisResultsSection';

import '../FeederPanel/FeederPanel.scss';

const SubstationPanel = ({
  anyResults,
  containers,
  selectedAnalysis,
  subHourInterval,
  selectedScenario,
  selectedScenarioType,
  downloadHostingCapacityReport,
  downloadPowerFlowReport,
  generatePowerflowReport,
  downloadCostTrackingReport,
  workspace,
  branch,
  isAuthEnabled,
  selectedContainers,
  inEditMode,
  permissions,
  expanded,
  timeRange,
  maxRange,
  timeBarZoomLevel,
  containerInAnalysis,
  updateSelectedScenario,
}) => {
  const substation = selectedContainers[0];
  const { analysisActive } = useContext(ActivityLogContext);
  const updateScenario = useCallback(
    () => updateSelectedScenario(workspace, branch, selectedScenario, selectedScenarioType),
    [workspace, branch, selectedScenario, selectedScenarioType, updateSelectedScenario],
  );

  let analysisRunning = false;
  if (selectedAnalysis) {
    analysisRunning = analysisActive(branch, selectedScenario, selectedAnalysis.name);
  }

  return (
    <CustomScrollBar>
      <div
        className={classNames({
          'container-panel-tab-contents': true,
          'container-panel-tab-contents--expanded': expanded,
        })}
      >
        <VoltageLimits substationId={substation.id} />
        <ReportList
          workspace={workspace}
          branch={branch}
          containers={containers}
          selectedAnalysis={selectedAnalysis}
          selectedScenario={selectedScenario}
          inEditMode={inEditMode}
          downloadPowerFlowReport={downloadPowerFlowReport}
          generatePowerflowReport={generatePowerflowReport}
          downloadHostingCapacityReport={downloadHostingCapacityReport}
          downloadCostTrackingReport={downloadCostTrackingReport}
          anyResults={anyResults}
          containerInAnalysis={containerInAnalysis}
          analysisRunning={analysisRunning}
          timeRange={timeRange}
          subHourInterval={subHourInterval}
        />
        {!inEditMode && (
          <>
            <AssetSchedule
              workspace={workspace}
              branch={branch}
              scenario={selectedScenario}
              scenarioType={selectedScenarioType}
              header="Substation Schedule"
              asset={{ id: substation.id, class: 'feeder' }}
              scheduleType="Feeder"
              timeRange={timeRange}
              maxRange={maxRange}
              timeBarZoomLevel={timeBarZoomLevel}
              editable={!isAuthEnabled || permissions.has('modify_asset_schedule')}
              expanded={expanded}
              onScheduleUploadSuccess={updateScenario}
            />
            <AnalysisResultsSection />
          </>
        )}
      </div>
    </CustomScrollBar>
  );
};

SubstationPanel.propTypes = {
  anyResults: PropTypes.bool.isRequired,
  containers: PropTypes.array.isRequired,
  selectedContainers: PropTypes.array.isRequired,
  selectedScenario: PropTypes.string.isRequired,
  selectedScenarioType: PropTypes.string.isRequired,
  selectedAnalysis: nullable(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  subHourInterval: PropTypes.number.isRequired,
  timeRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  workspace: PropTypes.array.isRequired,
  branch: PropTypes.string.isRequired,
  downloadPowerFlowReport: PropTypes.func.isRequired,
  generatePowerflowReport: PropTypes.func.isRequired,
  downloadHostingCapacityReport: PropTypes.func.isRequired,
  downloadCostTrackingReport: PropTypes.func.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  containerInAnalysis: PropTypes.bool.isRequired,
  updateSelectedScenario: PropTypes.func.isRequired,
};

export default SubstationPanel;
