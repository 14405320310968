export default function getControlModeName(className: string, controlMode: string): string {
  const names: {[key: string]: {[key: string]: string}} = {
    EnergySource: {
      fixedValue: 'Fixed',
      attachedSchedule: 'Schedule',
      analysisSchedule: 'Previous Analysis',
    },
    EquivalentSubstation: {
      fixedValue: 'Allocate',
      attachedSchedule: 'Schedule',
      analysisSchedule: 'Previous Analysis',
    },
  };

  return (names?.[className]?.[controlMode] ?? controlMode);
}

export const controlModesWithAnalysis:{[key: string]: string} = {
  fixedValue: 'Fixed/Allocate',
  attachedSchedule: 'Attached Schedule',
  analysisSchedule: 'Previous Analysis',
};

export const controlModesList:{[key: string]: string} = {
  globallyOptimized: 'Global',
  scheduled: 'Schedule',
  uncontrolled: 'Uncontrolled',
  locallyControlled: 'Local',
};

export const validControlModesByAsset:{[key: string]: string[]} = {
  Switch: ['uncontrolled', 'scheduled'],
  Disconnector: ['uncontrolled', 'scheduled'],
  Fuse: ['uncontrolled', 'scheduled'],
  Recloser: ['uncontrolled', 'scheduled'],
  Sectionaliser: ['uncontrolled', 'scheduled'],
  Breaker: ['uncontrolled', 'scheduled'],
  Cut: ['uncontrolled', 'scheduled'],
  GroundDisconnector: ['uncontrolled', 'scheduled'],
  Jumper: ['uncontrolled', 'scheduled'],
  LoadBreakSwitch: ['uncontrolled', 'scheduled'],
  NonlinearShuntCompensator: ['uncontrolled', 'locallyControlled', 'globallyOptimized', 'scheduled'],
  ProtectedSwitch: ['uncontrolled', 'scheduled'],
  EnergySource: ['analysisSchedule', 'attachedSchedule', 'fixedValue'],
  EquivalentSubstation: ['analysisSchedule', 'attachedSchedule', 'fixedValue'],
  InverterPV: ['uncontrolled', 'locallyControlled', 'globallyOptimized', 'scheduled'],
  Battery: ['uncontrolled', 'globallyOptimized', 'scheduled'],
  AsynchronousMachine: ['uncontrolled', 'globallyOptimized', 'scheduled'],
  SynchronousMachine: ['uncontrolled', 'globallyOptimized', 'scheduled'],
  ElectricVehicleChargingStation: ['uncontrolled', 'globallyOptimized', 'scheduled'],
  PowerTransformer: ['uncontrolled', 'locallyControlled', 'globallyOptimized', 'scheduled'],
  LinearShuntCompensator: ['uncontrolled', 'locallyControlled', 'globallyOptimized', 'scheduled'],
  CHP: ['uncontrolled', 'globallyOptimized', 'scheduled'],
  RunOfRiverHydro: ['uncontrolled', 'globallyOptimized', 'scheduled'],
  Wind: ['uncontrolled', 'globallyOptimized', 'scheduled'],
  PhotoVoltaic: ['uncontrolled', 'globallyOptimized', 'scheduled'],
  Regulator: ['uncontrolled', 'locallyControlled', 'globallyOptimized', 'scheduled'],
};
