import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import escapeRegExp from 'lodash/escapeRegExp';
import PropTypes from 'prop-types';
import {
  List, AutoSizer, CellMeasurerCache, CellMeasurer,
} from 'react-virtualized';

import Card from 'components/Card';
import Button from 'components/Button';
import SearchInput from 'components/SearchInput';
import Modal from 'components/Modal';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import asyncActionStates from 'helpers/asyncActionStates';

import TopNav from 'components/TopNav';
import { Request } from '@opusonesolutions/gridos-app-framework';
import { alphabetize } from 'helpers/utils';
import WorkspaceCard from './WorkspaceCard';
import './WorkspaceSelection.scss';

const WorkspaceSelection = ({
  actions, isAuthEnabled, permissions, theme,
}) => {
  const _cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 50,
  });

  const location = useLocation();
  const history = useHistory();

  const [deleteOpen, setDeleteOpen] = useState(false);
  // the workspace being confirmed for delete
  const [workspaceToDelete, setWorkspaceToDelete] = useState(null);
  const [deleteWorkspaceState, setDeleteWorkspaceState] = useState(asyncActionStates.INITIAL);
  const [openIndex, setOpenIndex] = useState(null);
  const [filterString, setFilterString] = useState('');
  const [workspaces, setWorkspaces] = useState([]);
  const [filteredWorkspaces, setFilteredWorkspaces] = useState([]);

  useEffect(() => {
    const { state } = location;

    if (state && state.search) {
      setFilterString(state.search);
    }
  }, [location]);

  const loadWorkspaceList = () => {
    let didCancel = false;

    async function fetchWorkspaces() {
      const request = new Request('/api/workspace');
      try {
        const { data } = await request.get();
        if (didCancel) {
          // Cancelled before the request finished so do nothing
          return;
        }
        const sortedWorkspaces = alphabetize(data.workspaces);
        setWorkspaces(sortedWorkspaces);
      } catch (error) {
      }
    }

    fetchWorkspaces();
    return () => { didCancel = true; };
  };
  useEffect(loadWorkspaceList, []);

  const runFilter = () => {
    let filtered;
    if (filterString) {
      const escapedStr = escapeRegExp(filterString);
      const regex = new RegExp(`.*${escapedStr}.*`, 'i');
      filtered = workspaces.filter(workspace => workspace.match(regex));
    } else {
      filtered = workspaces;
    }
    setFilteredWorkspaces(filtered);
  };
  useEffect(runFilter, [filterString, workspaces]);

  const canCreateWorkspace = !isAuthEnabled || permissions.has('create_workspace');
  const canDeleteWorkspace = !isAuthEnabled || permissions.has('delete_workspace');
  const canDownloadWorkspace = !isAuthEnabled || permissions.has('download_CIM_xml');
  const canEditAnalytics = !isAuthEnabled || permissions.has('modify_analytics_configuration');
  const canEditCimDatastore = !isAuthEnabled || permissions.has('cim_datastore_admin_settings');
  const canViewUsers = !isAuthEnabled || permissions.has('view_users');

  const deleteWorkspace = async () => {
    setDeleteWorkspaceState(asyncActionStates.LOADING);
    const request = new Request(`/api/workspace/${workspaceToDelete}`);

    try {
      await request.delete();
      // Mark as success and close the modal
      // Also remove the workspace that was deleted from the list
      setWorkspaces(workspaces.filter(w => w !== workspaceToDelete));
      setDeleteWorkspaceState(asyncActionStates.SUCCESS);
      setDeleteOpen(false);
      setWorkspaceToDelete(null);
    } catch (error) {
      setDeleteWorkspaceState(asyncActionStates.ERROR);
    }
  };

  const rowRenderer = ({
    // eslint-disable-next-line react/prop-types
    index, key, parent, style,
  }) => {
    const workspace = filteredWorkspaces[index];
    return (
      <CellMeasurer
        cache={_cache}
        columnIndex={0}
        key={key}
        rowIndex={index}
        parent={parent}
      >
        <div style={{ ...style, paddingRight: '10px' }}>
          <WorkspaceCard
            canEditCimDatastore={canEditCimDatastore}
            canEditAnalytics={canEditAnalytics}
            canDelete={canDeleteWorkspace}
            canDownload={canDownloadWorkspace}
            canViewUsers={canViewUsers}
            permissions={permissions}
            displayAlertMessage={actions.displayAlertMessage}
            onDeleteWorkspace={(wkspc) => {
              setWorkspaceToDelete(wkspc);
              setDeleteOpen(true);
            }}
            onToggle={open => setOpenIndex(open ? index : null)}
            open={index === openIndex}
            theme={theme}
            workspace={workspace}
          />
        </div>
      </CellMeasurer>
    );
  };

  return (
    <div className="workspace-selection">
      {location.query?.canGoBack && <TopNav label="Back" />}
      <div className="page-header">
        <h1 className="page-title">Workspaces</h1>
        <div className="create-button">
          <PermissionDisabledTip title="Create New Workspace" hide={canCreateWorkspace} theme={theme}>
            <Button
              disabled={!canCreateWorkspace}
              onClick={() => history.push('/import-cim')}
              theme={theme}
            >
              Create New
            </Button>
          </PermissionDisabledTip>
        </div>
      </div>
      <Card className="import-cim-modal" hideTitle theme={theme}>
        <SearchInput
          placeholder="Search..."
          onChange={e => setFilterString(e.target.value)}
          value={filterString}
          theme={theme}
        />
        <div className="list-container">
          <AutoSizer>
            {({ height, width }) => (
              <List
                className="gray-scrollbars"
                // allow dropdowns to overflow
                containerStyle={{ overflow: 'visible' }}
                deferredMeasurementCache={_cache}
                overscanColumnCount={10}
                rowCount={filteredWorkspaces.length}
                rowHeight={_cache.rowHeight}
                width={width}
                height={height}
                rowRenderer={rowRenderer}
              />
            )}
          </AutoSizer>
        </div>
      </Card>
      <Modal
        title="Delete Workspace"
        active={deleteOpen}
        width="300px"
        onCancel={() => setDeleteOpen(false)}
        onConfirm={() => deleteWorkspace()}
        disableConfirm={deleteWorkspaceState === asyncActionStates.LOADING}
        labels={{
          confirm: deleteWorkspaceState === asyncActionStates.LOADING
            ? <i className="material-icons rotate">autorenew</i>
            : 'Confirm',
        }}
        reverseFooterButtons
        theme={theme}
      >
        <p className="modal-message__p">
          Once a workspace has been deleted, it cannot be restored.
        </p>
        <p className="modal-message__p">
          <span>Would you like to permanently remove </span>
          <b>{workspaceToDelete}</b>
          ?
        </p>
      </Modal>
    </div>
  );
};

WorkspaceSelection.propTypes = {
  actions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
};

export default WorkspaceSelection;
