import React, { FunctionComponent } from 'react';
import { ThemeProp } from 'types/index';
import chargerTypes from 'helpers/Constants';
import { settings, unitInfos } from 'types/edit';
import Select from 'components/Select';
import Input from 'components/Input';
import { kVA } from 'helpers/units';

type BulkAssetEVSettingsProps = {
    theme: ThemeProp,
    assetSettings: settings,
    cutomerTypesofLoads: string[],
    setAssetSettings: (agrs: settings) => void,
    evInfos?: unitInfos,
};

const BulkAssetEVSettings: FunctionComponent<BulkAssetEVSettingsProps> = ({
  theme,
  assetSettings,
  setAssetSettings,
  cutomerTypesofLoads,
  evInfos = [],
}) => (
  <div className="ev-settings">
    { cutomerTypesofLoads.map((type) => (
      <div className="asset-type margin-10" key={type}>
        { type !== 'all' && (<p className="text-bold type-title">{type === '' ? 'Unspecified loads' : type}</p>)}
        <div className="grid-columns one-one">
          <Select
            label="EV type"
            onChange={(e) => setAssetSettings(
              {
                ...assetSettings,
                [type]: { ...assetSettings[type], asset_info: e.value },
              },
            )}
            id={`select-${type}`}
            options={evInfos.map((ev) => ({ value: ev.id, label: ev.name }))}
            value={assetSettings[type]?.asset_info}
            theme={theme}
            type="secondary"
            clearable={false}
          />
          <Select
            label="Charger type"
            onChange={(e) => setAssetSettings(
              {
                ...assetSettings,
                [type]: { ...assetSettings[type], ev_charger_type: e.value },
              },
            )}
            id={`select-${type}`}
            options={chargerTypes}
            value={assetSettings[type]?.ev_charger_type}
            theme={theme}
            type="secondary"
            clearable={false}
          />
          <Input
            type="number"
            id={`input-${type}`}
            className="ev-rated-power"
            label="Rated power"
            value={assetSettings[type]?.rated_power}
            onChange={(e: any) => setAssetSettings(
              {
                ...assetSettings,
                [type]: { ...assetSettings[type], rated_power: e },
              },
            )}
            onBlur={() => {}}
            theme={theme}
            options={{
              divisor: 1000,
              precision: 0,
            }}
            unit={kVA}
            inputStyle="primary"
          />
        </div>
      </div>
    ))}
  </div>
);
export default BulkAssetEVSettings;
