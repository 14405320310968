import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import withAuth from 'helpers/withAuth';
import { actions } from 'store/appState';
import CoreLayout from './CoreLayout';

/*
  This is a container component. This component is only responsible for
  wiring in the actions and state necessary to render a presentational
  component
*/

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...actions,
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  alertMessage: state.global.alertMessage,
  login: state.login,
  theme: state.global.theme,
  themes: state.global.themes,
  view: state.global.view,
  inEditMode: state.edit?.inEditMode,
});

export default compose(withAuth, withRouter, connect(mapStateToProps, mapDispatchToProps))(CoreLayout);
