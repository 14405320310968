import React, { FunctionComponent, useContext } from 'react';
import { Moment } from 'moment';
import ResultsCard from 'components/ResultsCard';
import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';
import { isDefined } from 'helpers/utils';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import { ThemeProp } from 'types/index';
import { useBaseline } from '../context/ResultsComparisonContext';
import ValueCard from './ValueCard';

type ReliabilityProps = {
    theme: ThemeProp,
    workspace: string|null,
    branch: string|null,
    maxRange: {
      start?: Moment|null,
      end?: Moment|null,
    },
    scenario: string|null,
    analysis: {[key: string]: any}|null,
    feeders: [],
    isBaseline: boolean,
};

type RealibilityResults = {
    totals: {
        customer_hours_lost: number,
        customer_interruptions: number,
        hourly_system_average_interruption_index: number,
    };
    warnings: {
        customer_hours_lost: number|{[key: string]: any},
        hourly_system_average_interruption_index: number|{[key: string]: any},
        customer_interruptions: number|{[key: string]: any},
    }
  };

const ReliabilitySummary: FunctionComponent<ReliabilityProps> = ({
  theme,
  workspace,
  branch,
  maxRange,
  scenario,
  analysis,
  feeders,
  isBaseline,
}) => {
  const { settings } = useContext(WorkspaceSettingsContext);
  const { baseline, setBaseline } = useBaseline<RealibilityResults>('reliabilityBaseline');
  const {
    data: reliabilityData, loading,
  } = useRequestEffect<RealibilityResults>({
    url: `/api/workspace/${workspace}/branch/${branch}/power-flow-results/reliability/summary`,
    method: 'get',
    params: {
      feeder: feeders?.map((fdr: any) => fdr.id),
      scenario_id: scenario,
      start_date: maxRange?.start?.toISOString(),
      end_date: maxRange?.end?.toISOString(),
      analysis_name: analysis?.name,
    },
    refetchOnChange: [workspace, branch, scenario, analysis?.name, maxRange, feeders],
    blockRequest: () => !(workspace && branch && scenario && analysis?.name && maxRange?.start && maxRange?.end
        && feeders?.length),
    onSuccess: (results) => {
      if (results && isBaseline) {
        setBaseline(results);
      }
    },
  });
  const tooltip = (key: any) => (isDefined(key) ? 'Missing reliability data from assets' : '');
  const warnings = reliabilityData?.warnings;
  const totals = {
    // explicity converting falsy values (including 0) into null
    cml: (reliabilityData?.totals && reliabilityData.totals.customer_hours_lost * 60) || null,
    cmlCost: (reliabilityData?.totals && reliabilityData.totals.customer_hours_lost * settings.CHLCost) || null,
    ci: (reliabilityData?.totals && reliabilityData.totals.customer_interruptions) || null,
    ciCost: (reliabilityData?.totals && reliabilityData.totals.customer_interruptions * settings.CICost) || null,
    saidi: (reliabilityData?.totals && reliabilityData.totals.hourly_system_average_interruption_index) || null,
  };
  return (
    <ResultsCard
      theme={theme}
      expandableContents={(
        <>
          <div className="one-one grid-columns">
            <ValueCard
              includeWarning={isDefined(warnings?.customer_hours_lost)}
              value={totals.cml}
              unit="minute"
              label="Customer Minutes Lost"
              tooltipMsg={tooltip(warnings?.customer_hours_lost)}
              warnLowSeverity
              baselineValue={
                isDefined(baseline?.totals?.customer_hours_lost)
                  ? baseline?.totals?.customer_hours_lost * 60 : null
              }
              loading={loading}
            />
            <ValueCard
              includeWarning={isDefined(warnings?.customer_hours_lost)}
              value={totals.cmlCost}
              isCurrency
              label="Cost"
              tooltipMsg={tooltip(warnings?.customer_hours_lost)}
              warnLowSeverity
              baselineValue={
                isDefined(baseline?.totals?.customer_hours_lost)
                  ? baseline?.totals?.customer_hours_lost * settings.CHLCost : null
              }
              loading={loading}
            />
          </div>
          <div className="one-one grid-columns margin-10">
            <ValueCard
              includeWarning={isDefined(warnings?.customer_interruptions)}
              value={totals.ci}
              label="Customer Interruptions"
              tooltipMsg={tooltip(warnings?.customer_interruptions)}
              warnLowSeverity
              baselineValue={baseline?.totals?.customer_interruptions ?? null}
              loading={loading}
            />
            <ValueCard
              includeWarning={isDefined(warnings?.customer_interruptions)}
              value={totals.ciCost}
              isCurrency
              label="Cost"
              tooltipMsg={tooltip(warnings?.customer_interruptions)}
              warnLowSeverity
              baselineValue={
                isDefined(baseline?.totals?.customer_interruptions)
                  ? baseline?.totals?.customer_interruptions * settings.CICost : null
              }
              loading={loading}
            />
          </div>
          <div className="one-one grid-columns margin-10">
            <ValueCard
              includeWarning={isDefined(warnings?.hourly_system_average_interruption_index)}
              value={totals.saidi}
              unit="minute"
              label="SAIDI"
              tooltipMsg={tooltip(warnings?.hourly_system_average_interruption_index)}
              warnLowSeverity
              baselineValue={
                baseline?.totals?.hourly_system_average_interruption_index ?? null
              }
              loading={loading}
            />
          </div>
        </>
      )}
      className="results-comparison-card summary-card"
    >
      <div className="one-one grid-columns items-centered">
        <div>Reliability</div>
        <ValueCard
          primary
          value={totals.ci}
          baselineValue={baseline?.totals?.customer_interruptions ?? null}
          showComparisonPercent
          loading={loading}
        />
      </div>
    </ResultsCard>
  );
};

export default ReliabilitySummary;
