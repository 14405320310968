export enum views { scenario = 'Scenario', assetSchedule = 'Asset Schedule' }
export enum assetTypes { pv = 'pv', ev = 'ev' }
export enum modalTypes { bulkSchedule = 'bulkSchedule', externalSource = 'externalSource' }
export const assetProperties = {
  [assetTypes.pv]: {
    class: 'inverterpv',
    label: 'PV Solar panels',
  },
  [assetTypes.ev]: {
    class: 'electric_vehicle',
    label: 'EV charging stations',
  },
};

export type assetTypesStataus = { [assetTypes.pv]: boolean, [assetTypes.ev]: boolean };

export const defaultSettings = {
  start_time: '00:00',
  end_time: '23:59',
  min_events: 0,
  max_events: 100,
  min_charge_length: '00:15',
  max_charge_length: '23:00',
};

const helpers = { views };
export default helpers;
