import React, { FunctionComponent, useContext } from 'react';
import { Moment } from 'moment';
import {
  Cell, Legend, Pie, PieChart, ResponsiveContainer,
} from 'recharts';
import LoadingSkeleton from 'components/LoadingSkeleton';
import ResultsCard from 'components/ResultsCard';
import { IntlContext } from 'contexts/IntlContext';
import { Wh } from 'helpers/units';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import { ThemeProp } from 'types/index';
import { useBaseline } from '../context/ResultsComparisonContext';
import ValueCard from './ValueCard';

type EnergyProps = {
    theme: ThemeProp,
    workspace: string|null,
    branch: string|null,
    maxRange: {
      start?: Moment|null,
      end?: Moment|null,
    },
    scenario: string|null,
    analysis: {[key: string]: any}|null,
    feeders: [],
    isBaseline: boolean,
};
type EnergyResults = {
    totals: {
        substation_energy: number,
        energy_from_der: number,
        real_power_loss: number,
        load_served: number,
    };
};

const EnergySummary: FunctionComponent<EnergyProps> = ({
  theme,
  workspace,
  branch,
  maxRange,
  scenario,
  analysis,
  feeders,
  isBaseline = false,
}) => {
  const { unitFormatter } = useContext(IntlContext);
  const { baseline, setBaseline } = useBaseline<EnergyResults>('energyBaseline');
  const {
    data: EnergyData, loading,
  } = useRequestEffect<EnergyResults>({
    url: `/api/workspace/${workspace}/branch/${branch}/power-flow-results/feeder/summary`,
    method: 'get',
    params: {
      feeder: feeders?.map((fdr: any) => fdr.id),
      scenario_id: scenario,
      start_date: maxRange?.start?.toISOString(),
      end_date: maxRange?.end?.toISOString(),
      analysis_name: analysis?.name,
    },
    refetchOnChange: [workspace, branch, scenario, analysis?.name, maxRange, feeders],
    blockRequest: () => !(workspace && branch && scenario && analysis?.name && maxRange?.start && maxRange?.end
        && feeders?.length),
    onSuccess: (results) => {
      if (results && isBaseline) {
        setBaseline(results);
      }
    },
  });
  const totals = EnergyData?.totals;
  const energyValues = [
    {
      name: 'Substation',
      value: totals?.substation_energy,
      baseline: baseline?.totals?.substation_energy,
      fill: '#213AB8',
    },
    {
      name: 'DERs',
      value: totals?.energy_from_der,
      baseline: baseline?.totals?.energy_from_der,
      fill: '#2785FC',
    },
    {
      name: 'Real power losses',
      value: totals?.real_power_loss,
      baseline: baseline?.totals?.real_power_loss,
      fill: '#AEBBC9',
    },
  ];
  const renderLegend = (props: any) => {
    const { payload } = props;
    return (
      <div className="legend-group" style={{ display: 'grid', gridGap: '10px' }}>
        {
        payload?.map((entry: any) => {
          const dataValue = entry.payload;
          return (
            <div className="legend-entry" key={entry.value} style={{ display: 'flex', alignItems: 'baseline' }}>
              <div style={{
                background: entry.color, width: '8px', height: '8px', marginRight: '5px', borderRadius: '100%',
              }}
              />
              <ValueCard
                value={dataValue.value}
                baselineValue={dataValue.baseline}
                label={dataValue.name}
                unit={Wh}
                loading={loading}
              />
            </div>
          );
        })
        }
      </div>
    );
  };
  return (
    <ResultsCard
      theme={theme}
      expandableContents={(
        <div>
          { loading
            ? (
              <div className="one-one grid-columns">
                <LoadingSkeleton template="circle" width={100} theme={theme} />
                <LoadingSkeleton template="line" width={80} theme={theme} count={3} />
              </div>
            )
            : (
              <>
                <ResponsiveContainer height={150} width="90%">
                  <PieChart>
                    <Pie
                      data={energyValues}
                      cx={70}
                      cy={65}
                      innerRadius={55}
                      outerRadius={60}
                      dataKey="value"
                      isAnimationActive
                      stroke="0"
                    >
                      {
                        energyValues.map((entry) => <Cell key={entry.name} fill={entry.fill} />)
                      }
                    </Pie>
                    <text x={75} y={65} textAnchor="middle" fill="#95A6B8" dominantBaseline="middle">Total</text>
                    <text x={75} y={80} textAnchor="middle" dominantBaseline="middle">
                      {totals?.load_served ? unitFormatter(totals?.load_served, Wh) : '--'}
                    </text>
                    <Legend content={renderLegend} align="right" layout="vertical" verticalAlign="top" />
                  </PieChart>
                </ResponsiveContainer>
              </>
            )}
        </div>
      )}
      className="results-comparison-card summary-card"
    >
      <div className="one-one grid-columns items-centered">
        <div>Energy Consumed</div>
        <ValueCard
          primary
          value={totals?.load_served}
          unit={Wh}
          baselineValue={baseline?.totals?.load_served}
          showComparisonPercent
          loading={loading}
        />
      </div>
    </ResultsCard>
  );
};

export default EnergySummary;
