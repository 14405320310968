import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/Tooltip';
import ThemeContext from 'helpers/ThemeContext';

function HelpTooltip({ message, type }) {
  const theme = useContext(ThemeContext);
  return (
    <Tooltip placement="right" content={message} theme={theme} className={type}>
      <i className="material-icons help-icon">help_outline</i>
    </Tooltip>
  );
}

HelpTooltip.defaultProps = {
  type: 'default',
};

HelpTooltip.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  type: PropTypes.string,
};

export default HelpTooltip;
