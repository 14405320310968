import React, { PureComponent } from 'react';
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceArea,
  ReferenceLine,
  Line,
} from 'recharts';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import ThemeContext from 'helpers/ThemeContext';
import { kW } from 'helpers/units';
import { toISO, extendDataTimeRange, addMissingTimepoints } from 'helpers/utils';
import ResultsChartCard from './ResultsChartCard';
import './TimeSeriesChart.scss';
import PhaseBadgeContainer from '../../templates/partials/PhaseBadgeContainer';

const getActivePhases = (phaseCode, results) => {
  const supportedPhases = phaseCode.split('');
  if (phaseCode === 'ABC') {
    supportedPhases.push(phaseCode);
  }
  const activePhases = supportedPhases.reduce((acc, phase) => ({ ...acc, [phase]: false }), {});

  results.forEach((result) => {
    Object.entries(result)
      .filter(([key]) => key !== 'timepoint')
      .forEach(([phase, value]) => {
        if (activePhases[phase] || !supportedPhases.includes(phase)) {
          return;
        }
        if (!!value && typeof value === 'object') {
          activePhases[phase] = Object.values(value).every(val => val !== null);
        } else {
          activePhases[phase] = value !== null;
        }
      });
  });
  return activePhases;
};

class HostingCapacityChart extends PureComponent {
  static getDerivedStateFromProps = (props, state) => {
    const activePhases = props.phase ? getActivePhases(props.phase, props.hostingCapacityResults) : '';

    if (props.hostingCapacityResults.length > 0 && !state.activePhasesUpdated) {
      return {
        ...state,
        activePhases,
      };
    }

    return state;
  }

  state = {
    activePhases: {
      A: false,
      B: false,
      C: false,
      ABC: true,
    },
    // eslint-disable-next-line react/no-unused-state
    activePhasesUpdated: false,
    hovered: null,
  };

  handleActivePhaseChange = value => this.setState(prevState => ({
    activePhases: { ...prevState.activePhases, ...value },
    // eslint-disable-next-line react/no-unused-state
    activePhasesUpdated: true,
  }));

  handleHoverChange = value => this.setState({ hovered: value });

  phaseColors = () => {
    const theme = typeof this.context === 'string' ? this.context : 'dark';

    return {
      A: '#639DD1',
      B: '#2dafa8',
      C: '#fd813b',
      ABC: theme === 'dark' ? '#FFFFFF' : '#262626',
    };
  }

  customTooltip = ({ payload, label }) => {
    if (!payload) return null;

    const values = payload[0] ? payload[0].payload : {};
    const phaseColors = this.phaseColors();

    const generateRow = (phase) => {
      if (this.props.resultsAggregated) {
        const allResultsNull = Object.values(values[phase]).every(val => val === null);
        if (!allResultsNull) {
          return (
            <tr key={phase}>
              <td style={{ color: phaseColors[phase] }}>{phase}</td>
              <td style={{ color: phaseColors[phase] }}>{Math.round(values[phase].min / 1000)}</td>
              <td style={{ color: phaseColors[phase] }}>{Math.round(values[phase].avg / 1000)}</td>
              <td style={{ color: phaseColors[phase] }}>{Math.round(values[phase].max / 1000)}</td>
            </tr>
          );
        }
      } else {
        return (
          <tr key={phase}>
            <td style={{ color: phaseColors[phase] }}>{phase}</td>
            <td style={{ color: phaseColors[phase] }}>{Math.round(values[phase] / 1000)}</td>
          </tr>
        );
      }
      return null;
    };

    return (
      <div className="tooltip">
        <p>{moment.parseZone(label).format('YYYY/MM/DD HH:mm')}</p>
        <table>
          <thead>
            {this.props.resultsAggregated
              ? (
                <tr>
                  <th>Phase</th>
                  <th>Min</th>
                  <th>Avg</th>
                  <th>Max</th>
                </tr>
              )
              : (
                <tr>
                  <th>Phase</th>
                  <th>Value</th>
                </tr>
              )}
          </thead>
          <tbody>
            {Object.keys(this.state.activePhases).map(
              phase => (values[phase] || values[phase] === 0) && generateRow(phase),
            )}
          </tbody>
        </table>
      </div>
    );
  }

  generateLine = phase => (this.props.resultsAggregated
    ? ([<Line
        name={`Max ${phase}`}
        stroke={this.state.activePhases[phase] ? this.phaseColors()[phase] : null}
        dataKey={`${phase}.max`}
        key={`${phase}.max`}
        strokeWidth={this.state.hovered === phase ? 3 : 1}
        isAnimationActive={false}
        type="stepAfter"
        dot={false}
    />, <Line
      name={`Avg ${phase}`}
      stroke={this.state.activePhases[phase] ? this.phaseColors()[phase] : null}
      dataKey={`${phase}.avg`}
      key={`${phase}.avg`}
      strokeWidth={this.state.hovered === phase ? 3 : 1}
      isAnimationActive={false}
      type="stepAfter"
      dot={false}
    />, <Line
      name={`Min ${phase}`}
      stroke={this.state.activePhases[phase] ? this.phaseColors()[phase] : null}
      dataKey={`${phase}.min`}
      key={`${phase}.min`}
      strokeWidth={this.state.hovered === phase ? 3 : 1}
      isAnimationActive={false}
      type="stepAfter"
      dot={false}
    />]) : (
      <Line
        name={phase}
        stroke={this.state.activePhases[phase] ? this.phaseColors()[phase] : null}
        dataKey={phase}
        key={phase}
        strokeWidth={this.state.hovered === phase ? 3 : 1}
        isAnimationActive={false}
        type="stepAfter"
        dot={false}
      />
    ));

  render() {
    const theme = typeof this.context === 'string' ? this.context : 'dark';
    const {
      resultsAggregated,
      hostingCapacityResults,
      highlightRange,
      aggregation,
      maxRange,
      chartMode,
      loading,
    } = this.props;
    const { activePhases } = this.state;
    const phaseColors = this.phaseColors();
    const agg = aggregation || 'hour';

    const { start, end } = highlightRange;
    const highlightStart = toISO(start);
    const highlightEnd = toISO(moment(end).startOf(agg));

    const getPaddedResults = () => {
      let results = maxRange
        ? extendDataTimeRange(hostingCapacityResults, 'timepoint', maxRange.start, moment(maxRange.end).startOf(agg))
        : hostingCapacityResults;
      results = addMissingTimepoints(results, 'timepoint');
      return results;
    };

    let cardState = 'initial';
    if (loading) {
      cardState = 'loading';
    } else if (hostingCapacityResults.length > 0) {
      cardState = 'loaded';
    }

    return (
      <ResultsChartCard
        title={chartMode === 'generation' ? 'Hosting Capacity' : 'EV Capacity'}
        className="time-series-chart"
        theme={theme}
        state={cardState}
      >
        <>
          <div className="legend">
            <div className="legend-entry">
              <div className="left-axis-box axis-box-solid" style={{ borderTopColor: phaseColors.ABC }} />
              <div>
                Balanced (
                {kW}
                )
              </div>
            </div>
            <div className="legend-entry">
              <div>
                Unbalanced (
                {kW}
                )
              </div>
              <div className="right-axis-box axis-box-solid unbalanced-legend" />
            </div>
          </div>
          <div
            className={classNames({
              'chart-pane': true,
              'chart-pane--expanded': this.props.expanded,
            })}
          >
            <ResponsiveContainer height="100%" width="100%">
              <LineChart data={getPaddedResults()}>
                <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#606060" />
                <XAxis
                  dataKey="timepoint"
                  stroke="#949899"
                  scale="point"
                  tickLine={false}
                  tickFormatter={val => moment.parseZone(val).format(
                    resultsAggregated ? 'MM/DD/YY' : 'HH:mm',
                  )}
                />
                <YAxis
                  stroke="#949899"
                  tickLine={false}
                  tickFormatter={val => val / 1000}
                  domain={['auto', 'auto']}
                />
                <Tooltip
                  content={this.customTooltip}
                />
                {highlightStart === highlightEnd ? (
                  <ReferenceLine
                    x={highlightStart}
                    stroke="teal"
                  />
                ) : (
                  <ReferenceArea
                    x1={highlightStart}
                    x2={highlightEnd}
                    ifOverflow="visible"
                    fillOpacity={0.3}
                    fill="teal"
                  />
                )}
                {['ABC', 'A', 'B', 'C'].map(phase => this.state.activePhases[phase] && this.generateLine(phase))}
              </LineChart>
            </ResponsiveContainer>
          </div>
          <PhaseBadgeContainer
            activePhases={activePhases}
            phaseColors={phaseColors}
            onActivePhaseChange={this.handleActivePhaseChange}
            onHoverChange={this.handleHoverChange}
          />
        </>
      </ResultsChartCard>
    );
  }
}

HostingCapacityChart.contextType = ThemeContext;

HostingCapacityChart.defaultProps = {
  resultsAggregated: false,
  chartMode: 'generation',
};

HostingCapacityChart.propTypes = {
  hostingCapacityResults: PropTypes.array.isRequired,
  resultsAggregated: PropTypes.bool,
  aggregation: PropTypes.string.isRequired,
  highlightRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  chartMode: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  phase: PropTypes.string.isRequired,
};

export default HostingCapacityChart;
