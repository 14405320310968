import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { List, AutoSizer } from 'react-virtualized';
import { useSelector } from 'react-redux';
import { Request, useAuthContext } from '@opusonesolutions/gridos-app-framework';

import Analytics from 'helpers/Analytics';
import ThemeContext from 'helpers/ThemeContext';
import { useAttachments } from 'contexts/AttachmentsContext';

import FileForm from '../FileForm';

import FileCard from './FileCard';
import './Files.scss';

const FilesPanel = ({ assetID }) => {
  const { workspace, branch } = useSelector(state => ({
    workspace: state.network.workspace,
    branch: state.network.branch,
  }));

  const { permissions } = useAuthContext();
  const [loading, setLoadingState] = useState(false);
  const [error, setError] = useState();
  const theme = useContext(ThemeContext);
  const {
    loading: { attachments: filesLoading },
    error: { attachments: filesError },
    files,
    deleteAssetAttachment,
    refetch,
  } = useAttachments();

  // For adding files
  const createAttachment = async (e) => {
    // If there not exactly one files attached, do nothing
    if (e.target.files.length !== 1) return;

    // Only 1 file should exist / only the first file matters
    const file = e.target.files[0];

    // First POST to create object
    const request = new Request(
      `/api/workspace/${workspace}/branch/${branch}/asset/${assetID}/attachment`,
    );
    setLoadingState(true);

    Analytics.logEvent('Attachment Created', 'File Panel');

    try {
      const fileName = file.name;
      const { data } = await request.post({ file_name: fileName });

      const { upload_target } = data;

      const bucket = upload_target.url.split('/').reverse()[0];
      const saveRequest = new Request(`/api/files/${bucket}`);

      const formData = new FormData();
      formData.set('file', file);

      Object.entries(upload_target.fields).forEach(([k, v]) => {
        formData.set(k, v);
      });
      await saveRequest.post(formData);

      refetch();
    } catch (err) {
      setError(true);
    }
    setLoadingState(false);
  };

  const loadingState = filesLoading || loading;
  const errorState = filesError || error;
  const wasSuccessful = !loadingState && !errorState;

  // Renderer for each row of the virtualized list
  // eslint-disable-next-line react/prop-types
  const rowRenderer = ({ index, style }) => {
    const file = files[index];
    return (
      <div key={`file-${file.id}`} style={{ ...style, paddingRight: '10px' }}>
        <FileCard
          createdTime={file.created_time}
          disabled={loadingState}
          onDelete={() => deleteAssetAttachment({ type: 'attachment', id: file.id })}
          path={file.path}
          permissions={permissions}
          theme={theme}
        />
      </div>
    );
  };

  return (
    <div className="files-panel">
      <FileForm
        id="upload-attachment"
        onChange={createAttachment}
        disabled={!permissions.has('edit_asset_attachments') || loadingState}
      >
        <div className={`create-button ${theme}`} title="Upload">
          Upload
        </div>
      </FileForm>
      {loadingState && <div className="load-message">Loading Attachments</div>}
      {errorState && <div className="error-message">Error retrieving attachments.</div>}
      {wasSuccessful && (
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="gray-scrollbars"
              overscanColumnCount={10}
              rowCount={files.length}
              rowHeight={120}
              width={width}
              height={height}
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
      )}
    </div>
  );
};

FilesPanel.defaultProps = {
  assetID: undefined,
};

FilesPanel.propTypes = {
  assetID: PropTypes.string,
};

export default FilesPanel;
