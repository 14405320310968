import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */
import withAuth from 'helpers/withAuth';
import { networkEditActions } from '../../routes/Network/modules/networkEdit';
import { actions } from '../../routes/Network/modules/network';
import EditModeRibbon from '../EditModeRibbon';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...networkEditActions,
    ...actions,
  }, dispatch),
});

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  selectedAsset: state.network.selectedAsset,
  selectedFeeders: state.feeders.selected,
  theme: state.global.theme,
  branch: state.network.branch,
  branches: state.network.branches,
  displayBranch: state.network.displayBranch,
  newAssetID: state.edit.newAssetID,
  editSaveMsg: state.edit.editSaveMsg,
  inPathEditMode: state.edit.inPathEditMode,
  inPathCreateMode: state.edit.inPathCreateMode,
  canMergeBranch: state.edit.canMergeBranch,
  editBranchMerged: state.edit.branchMerged,
  saveEditBranchReq: state.edit.saveEditBranchReq,
  newBranchReq: state.network.newBranchReq,
  newLink: state.edit.newLink,
  selectedProject: state.edit.selectedProject,
  feederList: state.feeders.list,
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EditModeRibbon));
