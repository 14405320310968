import React from 'react';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';

const withAuth = (Component) => {
  const C = props => {
    const auth = useAuthContext();

    return (
      <Component
        {...props}
        {...auth}
      />
    );
  };

  C.displayName = `withAuth(${Component.displayName})`;
  return C;
};

export default withAuth;
