import React, { FunctionComponent, useState } from 'react';
import { ThemeProp } from 'types/index';
import CircleCheckMarkIcon from 'components/Icons/CircleCheckMarkIcon';
import CircleCrossIcon from 'components/Icons/CircleCrossIcon';
import LoadingSkeleton from 'components/LoadingSkeleton';
import Button from 'components/Button';
import { Request } from '@opusonesolutions/gridos-app-framework';
import Tooltip from 'components/Tooltip';
import { assetTypes } from '../helpers/ScenarioGenerationHelpers';

type AssetRowProps = {
    workspace: string,
    branch: string,
    theme: ThemeProp,
    scenario?: {[key: string]: string}|null,
    selectedContainer?: string|null,
    asset: {[key: string]: string},
    bulkScheduleGenLoading: boolean,
    isAnalysisInProgress: boolean,
    refetchSchedule: () => void;
    assetsArrHaveSchedule: string[],
    selectedAssetType: assetTypes,
};

const AssetRow: FunctionComponent<AssetRowProps> = ({
  theme,
  workspace,
  branch,
  selectedContainer,
  scenario,
  asset,
  bulkScheduleGenLoading,
  isAnalysisInProgress,
  refetchSchedule,
  assetsArrHaveSchedule,
  selectedAssetType,
}) => {
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const generateAssetSchedule = async (assetID: string) => {
    try {
      setScheduleLoading(true);
      const pvScheduleRequest = new Request(
        `/api/workspace/${workspace}/branch/${branch}/asset_schedule/${assetID}/generate_schedule`,
      );
      await pvScheduleRequest.post(undefined, {
        params: {
          feeder: selectedContainer,
          scenario_id: scenario?.value,
          asset_type: 'inverterpv',
          schedule_type: 'Normal',
          shift_tz: true,
        },
      });
    } catch (error) {
    }
    setScheduleLoading(false);
    refetchSchedule();
  };
  const loading = scheduleLoading || bulkScheduleGenLoading;
  return (
    <div className="pv-asset" key={asset.id}>
      <div className="grid-columns one-two asset-row">
        <p className="asset-name">{asset.name}</p>
        <div className="grid-columns auto">
          {loading && (
            <>
              <p><i className="material-icons rotate">refresh</i></p>
              <LoadingSkeleton theme={theme} />
            </>
          )}
          {!loading && (
            assetsArrHaveSchedule.includes(asset.id)
              ? (
                <>
                  <p><CircleCheckMarkIcon /></p>
                  <p>Generated</p>
                </>
              )
              : (
                <>
                  <p><CircleCrossIcon /></p>
                  { selectedAssetType === assetTypes.pv ? (
                    <Tooltip
                      theme={theme}
                      content={isAnalysisInProgress ? 'Analysis for selected scenario is in progress' : ''}
                    >
                      <Button
                        type="text"
                        onClick={() => generateAssetSchedule(asset.id)}
                        className="asset-schedule-link"
                        disabled={isAnalysisInProgress}
                      >
                        Generate now
                      </Button>
                    </Tooltip>
                  ) : (
                    <p> Not generated</p>
                  )}
                </>
              )
          )}
        </div>
      </div>
    </div>
  );
};
export default AssetRow;
