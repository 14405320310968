import React, { Fragment, PureComponent } from 'react';
import {
  ComposedChart,
  ReferenceArea,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Line,
  Area,
  ReferenceLine,
} from 'recharts';
import { getNiceTickValues } from 'recharts-scale';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import ThemeContext from 'helpers/ThemeContext';
import {
  isDefined, toISO, addMissingTimepoints, extendDataTimeRange,
} from 'helpers/utils';
import { getStartOfInterval } from 'components/ZoomableRangeSlider/intervals';
import './TimeSeriesChart.scss';
import ResultsChartCard from './ResultsChartCard';
import PhaseBadgeContainer from '../../templates/partials/PhaseBadgeContainer';

class ContinuousTimeSeriesChart extends PureComponent {
  state = {
    activePhases: {
      A: false,
      B: false,
      C: false,
      ABC: true,
    },
    hovered: null,
  };

  phaseColors = () => {
    const theme = typeof this.context === 'string' ? this.context : 'dark';

    return {
      A: '#639DD1',
      B: '#2dafa8',
      C: '#fd813b',
      ABC: theme === 'dark' ? '#FFFFFF' : '#262626',
    };
  }

  handleActivePhaseChange = (value) => {
    // activate the specified phase
    this.setState(prevState => ({
      activePhases: { ...prevState.activePhases, ...value },
    }));
  }

  handleHoverChange = (value) => {
    this.setState({ hovered: value });
  }

  customTooltip = ({ payload, label }) => {
    const values = payload && payload[0] ? payload[0].payload : {};
    const { activePhases } = this.state;
    const phaseColors = this.phaseColors();
    const scheduleOpacity = 0.6;
    const hasValue = name => values[`${name}_ABC`] !== undefined
                             || values[`${name}_A`] !== undefined
                             || values[`${name}_B`] !== undefined
                             || values[`${name}_C`] !== undefined;
    const hasSchedule = (name, phase) => values[`${name}_${phase}_scheduled`] !== undefined;

    const generateAggRows = phase => (
      <Fragment key={phase}>
        <tr>
          <td colSpan="2" style={{ borderBottom: '1px dotted black' }}>
            {phase}
            {phase === 'ABC' ? ' Total' : ''}
          </td>
        </tr>
        <tr>
          <td>Max:</td>
          {hasValue(`${this.props.dataKey}_range`)
            && <td style={{ color: phaseColors[phase] }}>{(values[`${this.props.dataKey}_range_${phase}`][1] / 1000.0).toFixed(2)}</td>}
        </tr>
        <tr>
          <td>Average:</td>
          {hasValue(this.props.dataKey)
            && <td style={{ color: phaseColors[phase] }}>{(values[`${this.props.dataKey}_${phase}`] / 1000.0).toFixed(2)}</td>}
        </tr>
        <tr>
          <td>Min:</td>
          {hasValue(`${this.props.dataKey}_range`)
            && <td style={{ color: phaseColors[phase] }}>{(values[`${this.props.dataKey}_range_${phase}`][0] / 1000.0).toFixed(2)}</td>}
        </tr>
      </Fragment>
    );
    const generateRawRows = phase => (
      <tr key={phase}>
        <td>{phase}</td>
        {hasValue(this.props.dataKey)
          && <td style={{ color: phaseColors[phase] }}>{(values[`${this.props.dataKey}_${phase}`] / 1000.0).toFixed(2)}</td>}
        {hasSchedule(this.props.dataKey, phase)
          && <td style={{ color: phaseColors[phase], opacity: scheduleOpacity }}>{(values[`${this.props.dataKey}_${phase}_scheduled`] / 1000.0).toFixed(2)}</td>}
      </tr>
    );
    const getFormatString = (aggregation) => {
      switch (aggregation) {
        case 'day':
          return 'YYYY/MM/DD';
        case 'month':
          return 'YYYY/MM';
        case 'year':
          return 'YYYY';
        case 'hour':
        default:
          return 'YYYY/MM/DD HH:mm';
      }
    };

    const isAggregated = values.aggregation !== 'none';
    const phases = Object.keys(activePhases).filter(key => activePhases[key]);
    return (
      <div className="tooltip">
        <p><b>{moment.parseZone(label).format(getFormatString(values.aggregation))}</b></p>
        <table>
          <thead>
            <tr>
              <td colSpan="3">
                {this.props.tooltipTitle}
                (
                {this.props.unit}
                )
              </td>
            </tr>
            { phases.some(phase => hasSchedule(this.props.dataKey, phase))
              && (
              <tr>
                <td colSpan="2">Simulated</td>
                <td style={{ opacity: scheduleOpacity }}>Scheduled</td>
              </tr>
              )}
          </thead>
          <tbody>
            {phases.map(activePhase => ((values[`p_${activePhase}`] !== undefined || values[`q_${activePhase}`] !== undefined) && isAggregated
              ? generateAggRows(activePhase)
              : generateRawRows(activePhase)))}
          </tbody>
        </table>
      </div>
    );
  }

  generateLine = (phase, variable, axis) => (
    <Line
      key={`line_${phase}`}
      yAxisId={axis}
      name={`${variable}_${phase}`}
      stroke={this.state.activePhases[phase] ? this.phaseColors()[phase] : null}
      strokeWidth={this.state.hovered === phase ? 3 : 1}
      dataKey={`${variable}_${phase}`}
      isAnimationActive={false}
      dot={false}
      connectNulls={false}
      type="stepAfter"
    />
  );

  generateScheduleLine = (variable, phase, axis) => (
    <Line
      key={`line_${phase}_scheduled`}
      yAxisId={axis}
      name={`${variable}_${phase}_scheduled`}
      stroke={this.state.activePhases[phase] ? this.phaseColors()[phase] : null}
      strokeDasharray="2 4"
      // when the line is hovered, darken its schedule line
      strokeOpacity={this.state.hovered === phase ? '1' : '0.5'}
      strokeWidth="1"
      dataKey={`${variable}_${phase}_scheduled`}
      isAnimationActive={false}
      dot={false}
      connectNulls={false}
      type="stepAfter"
    />
  );

  generateArea = (phase, variable, axis) => (
    <Area
      key={`area_${phase}`}
      yAxisId={axis}
      name={`${variable}_range_${phase}`}
      stroke="none"
      fill={this.state.activePhases[phase] ? `url(#${variable}ViolationsGradient)` : null}
      fillOpacity={0.25}
      dataKey={`${variable}_range_${phase}`}
      isAnimationActive={false}
      connectNulls={false}
      type="stepAfter"
    />
  );

  generateReferenceLines = (valueTypes, axis) => {
    const createLine = (value, type) => (
      <ReferenceLine
        key={type}
        yAxisId={axis}
        y={value}
        stroke="red"
        connectNulls={false}
      />
    );

    const lines = [];
    valueTypes.forEach((type) => {
      if (isDefined(this.props[type])) {
        lines.push(createLine(this.props[type], type));
      }
    });

    return lines;
  }

  generateGradient = (data, type, legendColour) => {
    const gradientBreaks = this.gradientOffset(data, type);
    return (
      <defs>
        <linearGradient id={`${type}ViolationsGradient`} x1="0" y1="0" x2="0" y2="1">
          <stop offset={0} stopColor="red" stopOpacity={1} />
          <stop offset={gradientBreaks[0]} stopColor="red" stopOpacity={1} />
          <stop offset={gradientBreaks[0]} stopColor={legendColour} stopOpacity={1} />
          <stop offset={gradientBreaks[1]} stopColor={legendColour} stopOpacity={1} />
          <stop offset={gradientBreaks[1]} stopColor="red" stopOpacity={1} />
          <stop offset={1} stopColor="red" stopOpacity={1} />
        </linearGradient>
      </defs>
    );
  }

  gradientOffset = (data, type) => {
    let values = [];
    data.forEach((entry) => {
      const keys = Object.keys(entry);
      const rangeKey = keys.find(key => key.includes(`${type}_range`));
      if (rangeKey && entry[rangeKey]) {
        values = [...values, ...entry[rangeKey]];
      }
    });

    if (!values.length) {
      return [0, 1];
    }

    const dataMax = Math.max(...values);
    const dataMin = Math.min(...values);

    const size = dataMax - dataMin;

    const validMax = this.props[`max${type.toUpperCase()}`];
    const validMin = this.props[`min${type.toUpperCase()}`];

    const distanceFromMax = (isDefined(validMax) && size) ? (dataMax - validMax) / size : 0;
    const distanceFromMin = (isDefined(validMin) && size) ? (validMin - dataMin) / size : 0;
    return [Math.max(distanceFromMax, 0), Math.min(1 - distanceFromMin, 1)];
  }

  getDisabledPhases(pqData) {
    return ['A', 'B', 'C'].filter(phase => !pqData.some(timePoint => `p_${phase}` in timePoint || `q_${phase}` in timePoint
        || `p_${phase.toLowerCase()}_avg` in timePoint || `q_${phase.toLowerCase()}_avg` in timePoint));
  }

  getLegendColour(disabledPhases) {
    const activePhases = ['A', 'B', 'C'].filter(phase => this.state.activePhases[phase] && !disabledPhases.includes(phase));
    const phaseColors = this.phaseColors();
    return activePhases.length === 1 ? phaseColors[activePhases[0]] : phaseColors.ABC;
  }

  graphData(power_type) {
    const { maxRange, pqTimeSeriesData } = this.props;

    if (!pqTimeSeriesData.length) {
      return [];
    }
    let aggregation;
    let data = pqTimeSeriesData.map((e) => {
      const newE = {
        timepoint: e.timepoint,
        aggregation: e.aggregation,
      };

      if (!aggregation) {
        aggregation = e.aggregation !== 'none' ? e.aggregation : 'hour';
      }

      const mapPhaseData = (phase) => {
        const lowerPhase = phase.toLowerCase();
        const avg = e[`${power_type}_${lowerPhase}_avg`] || 0;
        const min = e[`${power_type}_${lowerPhase}_min`];
        const max = e[`${power_type}_${lowerPhase}_max`];
        const loss_avg = e[`${power_type}_loss_${lowerPhase}_avg`] || 0;
        const loss_min = e[`${power_type}_loss_${lowerPhase}_min`];
        const loss_max = e[`${power_type}_loss_${lowerPhase}_max`];
        const scheduled = e[`${power_type}_${lowerPhase}_scheduled`];
        newE[`${power_type}_${phase}`] = avg;
        newE[`${power_type}_range_${phase}`] = min !== undefined && max !== undefined ? [min, max] : undefined;
        newE[`${power_type}_loss_${phase}`] = loss_avg;
        newE[`${power_type}_loss_range_${phase}`] = loss_min !== undefined && loss_max !== undefined ? [loss_min, loss_max] : undefined;
        newE[`${power_type}_${phase}_scheduled`] = scheduled;
      };

      if (this.state.activePhases.ABC) {
        mapPhaseData('ABC');
      }
      if (this.state.activePhases.A) {
        mapPhaseData('A');
      }
      if (this.state.activePhases.B) {
        mapPhaseData('B');
      }
      if (this.state.activePhases.C) {
        mapPhaseData('C');
      }
      return newE;
    });
    if (maxRange) {
      data = extendDataTimeRange(data, 'timepoint', maxRange.start, moment(maxRange.end).startOf(aggregation));
    }
    data = addMissingTimepoints(data, 'timepoint');
    return data;
  }

  xAxisFormatter = (aggregation) => {
    switch (aggregation) {
      case 'day':
        return 'YYYY/MM/DD';
      case 'month':
        return 'YYYY/MM';
      case 'year':
        return 'YYYY';
      case 'hour':
      default:
        return 'HH:mm';
    }
  };

  getYMin = (dataMin) => {
    if (dataMin === Infinity || dataMin === -Infinity) {
      return 0;
    }
    const niceTicks = getNiceTickValues([dataMin, 0]);
    const minTick = niceTicks[0];
    if (isDefined(minTick) && typeof minTick === 'number') {
      return Math.min(minTick, 0);
    }
    return 0;
  };

  // ensure max is always 1kW or greater
  getYMax = (dataMax) => {
    if (dataMax === Infinity || dataMax === -Infinity) {
      return 1000;
    }
    const niceTicks = getNiceTickValues([0, dataMax]);
    const maxTick = niceTicks[niceTicks.length - 1];
    if (isDefined(maxTick) && typeof maxTick === 'number') {
      return Math.max(maxTick, 1000);
    }
    return 1000;
  };

  render() {
    const { activePhases } = this.state;
    const {
      loading, pqTimeSeriesData, timeBarZoomLevel, highlightRange,
    } = this.props;
    const theme = typeof this.context === 'string' ? this.context : 'dark';
    const phaseColors = this.phaseColors();

    const disabledPhases = this.getDisabledPhases(this.props.pqTimeSeriesData);
    const legendColour = this.getLegendColour(disabledPhases);
    const pData = this.graphData(this.props.dataKey);

    const highlightStart = toISO(highlightRange.start);
    const highlightEnd = toISO(getStartOfInterval(highlightRange.end, timeBarZoomLevel));

    let cardState = 'initial';
    if (loading) {
      cardState = 'loading';
    } else if (pqTimeSeriesData.length > 0) {
      cardState = 'loaded';
    }

    return (
      <ResultsChartCard
        title={this.props.title}
        className="time-series-chart"
        theme={theme}
        state={cardState}
      >
        <div className="unit-label-row">
          <p>
            (
            {this.props.unit}
            )
          </p>
        </div>
        <div
          className={classNames({
            'chart-pane': true,
            'chart-pane--expanded': this.props.expanded,
          })}
        >
          <ResponsiveContainer height="100%" width="100%">
            <ComposedChart
              syncId="pqTimeChart"
              data={pData}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#606060" />
              <XAxis
                dataKey="timepoint"
                stroke="#949899"
                scale="point"
                axisLine={false}
                tick={false} // dont show any ticks on the upper graph
                tickFormatter={val => moment.parseZone(val).format(this.xAxisFormatter(pData[0].aggregation))}
              />
              <YAxis
                yAxisId="paxis"
                orientation="left"
                stroke="#949899"
                tickLine={false}
                width={50}
                tickFormatter={val => val / 1000}
                domain={[this.getYMin, this.getYMax]}
              />
              <Tooltip content={this.customTooltip} />
              {['ABC', 'A', 'B', 'C'].map(ph => this.generateScheduleLine(this.props.dataKey, ph, 'paxis'))}
              {['ABC', 'A', 'B', 'C'].map(ph => this.generateLine(ph, this.props.dataKey, 'paxis'))}
              {['ABC', 'A', 'B', 'C'].map(ph => this.generateArea(ph, this.props.dataKey, 'paxis'))}
              {this.props.dataKey === 'p' && this.generateReferenceLines(['minP', 'maxP'], 'paxis')}
              {this.props.dataKey === 'q' && this.generateReferenceLines(['minQ', 'maxQ'], 'paxis')}
              {this.generateGradient(pData, this.props.dataKey, legendColour)}
              {highlightStart === highlightEnd ? (
                <ReferenceLine
                  yAxisId="paxis"
                  x={highlightStart}
                  stroke="teal"
                />
              ) : (
                <ReferenceArea
                  yAxisId="paxis"
                  x1={highlightStart}
                  x2={highlightEnd}
                  ifOverflow="visible"
                  fillOpacity={0.3}
                  fill="teal"
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <PhaseBadgeContainer
          activePhases={activePhases}
          disabledPhases={disabledPhases}
          phaseColors={phaseColors}
          onActivePhaseChange={this.handleActivePhaseChange}
          onHoverChange={this.handleHoverChange}
        />
      </ResultsChartCard>
    );
  }
}

ContinuousTimeSeriesChart.contextType = ThemeContext;

ContinuousTimeSeriesChart.defaultProps = {
  tooltipTitle: null,
  unit: null,
};

ContinuousTimeSeriesChart.propTypes = {
  dataKey: PropTypes.string.isRequired, // The key used to look up the data value
  pqTimeSeriesData: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  tooltipTitle: PropTypes.string,
  unit: PropTypes.string,
  highlightRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
};

export default ContinuousTimeSeriesChart;
