import React from 'react';
import SelectBadgeItem from 'components/SelectBadgeItem';
import { ANALYSIS_TYPES } from '../helpers/NetworkHelpers';

export const getBadgeForAnalysis = (option) => {
  let tooltip = '';
  let label = '';
  switch (option.type) {
    case ANALYSIS_TYPES.POWERFLOW:
      tooltip = 'Powerflow';
      label = 'PF';
      break;
    case ANALYSIS_TYPES.QSTS:
      tooltip = 'Timeseries Powerflow';
      label = 'TPF';
      break;
    case ANALYSIS_TYPES.QSTS_OPF:
      tooltip = 'Optimal Powerflow';
      label = 'OPF';
      break;
    case ANALYSIS_TYPES.HOSTING_CAPACITY:
      tooltip = 'Hosting Capacity';
      label = 'HC';
      break;
    case ANALYSIS_TYPES.EV_CAPACITY:
      tooltip = 'EV Capacity';
      label = 'EVC';
      break;
    case ANALYSIS_TYPES.BATTERY_SIZING:
      tooltip = 'Battery Sizing';
      label = 'BAT';
      break;
    default:
      break;
  }

  return { tooltip, key: option.id, label };
};

export const getRunningAnalysisBadge = (option) => ({ tooltip: 'Running', key: `${option.id}=running`, label: 'R' });

const getBadgedAnalysisOption = (opt, isAnalysisActive = false) => {
  const badgeInfos = [];

  if (isAnalysisActive) {
    badgeInfos.push(getRunningAnalysisBadge(opt));
  }
  badgeInfos.push(getBadgeForAnalysis(opt));

  return ({
    value: opt.id,
    label: (
      <SelectBadgeItem
        item={{ value: opt.id, label: opt.name }}
        badgeInfos={badgeInfos}
      />
    ),
    analysis: opt,
  });
};

export default getBadgedAnalysisOption;
