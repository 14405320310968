export const getEditTooltip = (canEdit, permissions) => {
  if (canEdit) {
    return 'Enter Edit Mode';
  } if (permissions.has('modify_network') && !permissions.has('modify_network_as_built')) {
    return 'You do not have permission to edit as built network.';
  }
  return 'You do not have permission to edit this network.';
};

export const userCanEdit = (permissions, currentBranch) => (
  permissions.has('modify_network')
  && !(currentBranch === 'master' && !permissions.has('modify_network_as_built'))
);

export const userCanImport = (permissions, currentBranch) => (
  permissions.has('import_network')
  && !(currentBranch === 'master' && !permissions.has('import_network_as_built'))
);

const helpers = { getEditTooltip, userCanEdit };
export default helpers;
