import TopNav from 'components/TopNav';
import React, { useContext, useState } from 'react';
import Button from 'components/Button';
import Card from 'components/Card';
import IconButton from 'components/IconButton';
import ThemeContext from 'helpers/ThemeContext';
import './Profile.scss';

const Profile = () => {
  const theme = useContext(ThemeContext);
  const [showToken, setShowToken] = useState(false);
  const [tokenRef, setTokenRef] = useState(null);

  const handleAPIKeyCopy = () => {
    tokenRef.select();
    document.execCommand('Copy');
  };

  return (
    <div className="profile-container">
      <TopNav label="Back" />
      <div className="profile-card-container">
        <Card
          className="profile-card"
          theme={theme}
          title="Account"
        >
          <div className="profile-details">
            <div className="profile-details-row">
              <div className="profile-details-row--left">
                <p>Authentication Token</p>
              </div>
              <div className="profile-details-row--right">
                {!showToken
                  && (
                  <Button
                    className="show-api-key__btn"
                    onClick={() => setShowToken(true)}
                    theme={theme}
                    type="primary"
                  >
                    <p>Show Authentication Token</p>
                  </Button>
                  )}
                {showToken
                  && (
                  <div className="copy-api-key">
                    <input
                      className="copy-api-key__input"
                      ref={input => setTokenRef(input)}
                      value={localStorage.getItem('authToken')}
                      readOnly
                    />
                    <IconButton
                      className="copy-api-key__btn"
                      icon="content_copy"
                      onClick={handleAPIKeyCopy}
                      theme={theme}
                      tooltip="Copy Token"
                    />
                  </div>
                  )}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Profile;
