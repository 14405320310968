import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import nullable from 'helpers/nullablePropType';
import classNames from 'classnames';
import CustomScrollBar from 'components/CustomScrollBar';
import ThemedTabs from 'components/ThemedTabs';
import { useAttachments } from 'contexts/AttachmentsContext';
import Allocation from 'routes/WorkspaceLayout/routes/Network/containers/AllocationContainer';
import NotesPanel from 'components/Notes';
import { ActivityLogContext } from 'contexts/ActivityLogContext';

import ReportList from '../ContainerPanel/ReportList';
import AssetSchedule from '../AssetPanel/templates/partials/AssetSchedule';
import AnalysisResultsSection from './components/AnalysisResultsSection';
import BulkLifecycleUpload from './components/BulkLifecycleUpload';
import SubstationSelect from './containers/SubstationSelect';

import './FeederPanel.scss';

const FeederPanel = ({
  anyResults,
  containers,
  selectedContainers,
  selectedAnalysis,
  subHourInterval,
  selectedScenario,
  selectedScenarioType,
  workspace,
  permissions,
  isAuthEnabled,
  branch,
  theme,
  inEditMode,
  downloadPowerFlowReport,
  generatePowerflowReport,
  downloadHostingCapacityReport,
  downloadCostTrackingReport,
  expanded,
  refreshNetworkData,
  timeRange,
  maxRange,
  timeBarZoomLevel,
  selectedAssetID,
  selectedAssetViewModelClass,
  containerInAnalysis,
  updateSelectedScenario,
}) => {
  const { notes } = useAttachments();
  const { analysisActive } = useContext(ActivityLogContext);
  const updateScenario = useCallback(
    () => updateSelectedScenario(workspace, branch, selectedScenario, selectedScenarioType),
    [workspace, branch, selectedScenario, selectedScenarioType, updateSelectedScenario],
  );

  const feeder = selectedContainers[0];
  const canAccessNotes = !isAuthEnabled || permissions.has('view_asset_notes');
  const substations = containers.filter(c => c.type === 'Substation');
  let analysisRunning = false;
  if (selectedAnalysis) {
    analysisRunning = analysisActive(branch, selectedScenario, selectedAnalysis.name);
  }
  return (
    <div className="feeder-content">
      {selectedContainers.length === 1 && (
        <ThemedTabs
          theme={theme}
          tabs={[
            {
              disabled: false,
              name: 'General',
            },
            {
              disabled: !canAccessNotes,
              name: `Notes${notes && notes.length ? ` (${notes.length})` : ''}`,
              permissionTipOptions: {
                title: 'Asset Notes',
                placement: 'left',
                hide: canAccessNotes,
              },
            },
          ]}
        >
          {TabPanel => [
            <TabPanel key="Tab-Panel-1">
              <CustomScrollBar>
                <div
                  className={classNames({
                    'container-panel-tab-contents': true,
                    'container-panel-tab-contents--expanded': expanded,
                  })}
                >
                  <SubstationSelect
                    feeder={feeder}
                    substations={substations}
                    disabled={!inEditMode}
                  />

                  <Allocation workspace={workspace} feeder={feeder} permissions={permissions} />
                  <ReportList
                    workspace={workspace}
                    branch={branch}
                    containers={containers}
                    selectedAnalysis={selectedAnalysis}
                    selectedScenario={selectedScenario}
                    inEditMode={inEditMode}
                    downloadPowerFlowReport={downloadPowerFlowReport}
                    generatePowerflowReport={generatePowerflowReport}
                    downloadHostingCapacityReport={downloadHostingCapacityReport}
                    downloadCostTrackingReport={downloadCostTrackingReport}
                    anyResults={anyResults}
                    containerInAnalysis={containerInAnalysis}
                    analysisRunning={analysisRunning}
                    timeRange={timeRange}
                    subHourInterval={subHourInterval}
                  />
                  {!inEditMode && (
                    <>
                      <AssetSchedule
                        workspace={workspace}
                        branch={branch}
                        scenario={selectedScenario}
                        scenarioType={selectedScenarioType}
                        header="Feeder Schedule"
                        asset={{ id: feeder.id, class: 'feeder' }}
                        scheduleType="Feeder"
                        timeRange={timeRange}
                        maxRange={maxRange}
                        timeBarZoomLevel={timeBarZoomLevel}
                        editable={!isAuthEnabled || permissions.has('modify_asset_schedule')}
                        expanded={expanded}
                        onScheduleUploadSuccess={updateScenario}
                      />

                      <AnalysisResultsSection id="analysis-results-section" />
                    </>
                  )}
                  <BulkLifecycleUpload
                    workspace={workspace}
                    branch={branch}
                    feeder={feeder.id}
                    afterUpload={() => refreshNetworkData(workspace, branch, [feeder.id])}
                    selectedAssetID={selectedAssetID}
                    selectedAssetViewModelClass={selectedAssetViewModelClass}
                  />
                </div>
              </CustomScrollBar>
            </TabPanel>,
            <TabPanel key="Tab-Panel-2">
              <NotesPanel
                assetID={selectedContainers[0].id}
                branch={branch}
                permissions={permissions}
                theme={theme}
                workspace={workspace}
              />
            </TabPanel>,
          ]}
        </ThemedTabs>
      )}
    </div>
  );
};

FeederPanel.defaultProps = {
  selectedContainers: [],
};

FeederPanel.propTypes = {
  anyResults: PropTypes.bool.isRequired,
  containers: PropTypes.array.isRequired,
  selectedScenario: PropTypes.string.isRequired,
  selectedScenarioType: PropTypes.string.isRequired,
  selectedAnalysis: nullable(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  subHourInterval: PropTypes.number.isRequired,
  timeRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  selectedContainers: PropTypes.array,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  downloadPowerFlowReport: PropTypes.func.isRequired,
  generatePowerflowReport: PropTypes.func.isRequired,
  downloadHostingCapacityReport: PropTypes.func.isRequired,
  downloadCostTrackingReport: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  refreshNetworkData: PropTypes.func.isRequired,
  selectedAssetID: nullable(PropTypes.string).isRequired,
  selectedAssetViewModelClass: nullable(PropTypes.string).isRequired,
  containerInAnalysis: PropTypes.bool.isRequired,
  updateSelectedScenario: PropTypes.func.isRequired,
};

export default FeederPanel;
