import React, { useEffect, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import ThemedTabs from 'components/ThemedTabs';
import { Request } from '@opusonesolutions/gridos-app-framework';
import ValidationDashboard from './ValidationDashboard';
import NetworkTopNav from '../../../../../containers/NetworkTopNavContainer';
import './ValidateNetwork.scss';

const ERROR = 'ERROR';
const WARNING = 'WARNING';

const EQ_LIB_KEYS = ['cables', 'concCables', 'overheadConductors', 'eqXfmrs', 'switchInfos'];
const INST_KEYS = ['async', 'batteries', 'capacitors', 'lines', 'loads', 'pvs', 'switches', 'sync', 'xfmr'];
const FEEDER_KEYS = ['feederModel', 'island', 'phase', 'topology', 'voltage'];

const ValidateNetwork = ({ match }) => {
  const { branch, workspace } = match.params;

  const [loading, setLoading] = useState(true);
  const [validationData, setValidationData] = useState(null);

  useEffect(() => {
    let didCancel = false;

    async function loadValidationErrors() {
      const request = new Request(`/api/workspace/${workspace}/branch/${branch}/validate`);
      const { data } = await request.post();
      if (didCancel) {
        // Cancelled before the request finished so do nothing
        return;
      }

      Object.entries(data).forEach(([id, errorType]) => {
        // Compute the number of errors & warnings for each error type so that we
        // can quickly show graphs
        const { data: errorData } = errorType;

        let errorCount = 0;
        let warningCount = 0;

        Object.values(errorData).forEach(({ messages }) => {
          if (messages.some(m => m.severity === ERROR)) {
            errorCount += 1;
          } else if (messages.some(m => m.severity === WARNING)) {
            warningCount += 1;
          }
        });

        errorType.id = id;
        errorType.warningCount = warningCount;
        errorType.errorCount = errorCount;
      });

      let tempValidationData = [
        {
          keys: EQ_LIB_KEYS,
          name: 'Equipment Library',
        },
        {
          keys: INST_KEYS,
          name: 'Assets',
        },
        {
          keys: FEEDER_KEYS,
          name: 'Feeders',
        },
      ];
      tempValidationData = tempValidationData.map(d => ({
        ...d,
        disabled: d.keys.every(k => data[k] === undefined),
        data: d.keys.map(k => data[k]).filter(v => !!v),
      }));

      setValidationData(tempValidationData);
      setLoading(false);
    }

    loadValidationErrors();
    return () => { didCancel = true; };
  }, [branch, workspace]);

  let defaultIndex = 0;
  if (validationData && validationData[0].disabled) {
    defaultIndex = validationData[1].disabled ? 2 : 1;
  }

  return (
    <>
      <NetworkTopNav params={useParams()} />
      <div className="validate-network">
        {loading && (
          <i className="material-icons rotate" style={{ fontSize: 70 }}>
            refresh
          </i>
        )}
        {!loading && (
          <ThemedTabs
            className="validation-tabs"
            defaultIndex={defaultIndex}
            tabs={validationData}
          >
            {TabPanel => validationData.map(data => (
              <TabPanel key={data.name}>
                <ValidationDashboard
                  branch={branch}
                  types={data.data}
                  workspace={workspace}
                />
              </TabPanel>
            ))}
          </ThemedTabs>
        )}
      </div>
    </>
  );
};

ValidateNetwork.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(ValidateNetwork);
