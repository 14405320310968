import React, { FunctionComponent, useContext } from 'react';
import ThemeContext from 'helpers/ThemeContext';
import { IconPropsSchema } from '../../types/index';
import { getIconBGColor } from '../helpers/formatters';

const TargetIcon: FunctionComponent<IconPropsSchema> = ({
  color,
  height = '20px',
  width = '20px',
}) => {
  const theme = useContext(ThemeContext);
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" version="1.1">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="IDP-home-Copy-38-(b)-Copy-15" transform="translate(-530.000000, -126.000000)">
          <rect fill={getIconBGColor(theme, color)} x="0" y="0" width="1366" height="800" />
          <rect id="Rectangle" fillOpacity="0.4" fill="#000000" x="0" y="40" width="1369" height="890" />
          <rect id="Rectangle" stroke="#EDF0F5" fill={getIconBGColor(theme, color)} x="377.5" y="51.5" width="642" height="738" rx="4" />
          <g id="gun-target-copy" transform="translate(530.000000, 126.000000)" fillRule="nonzero">
            <g id="gun-target">
              <path d="M10,18.6207031 C5.23898438,18.6207031 1.37929688,14.7610547 1.37929688,10 C1.37929688,5.23894531 5.23898438,1.37929688 10,1.37929688 C14.7610156,1.37929688 18.6207031,5.23898438 18.6207031,10 C18.6207031,14.7610156 14.7610156,18.6207031 10,18.6207031 Z M10,2.75863281 C6.00070312,2.75863281 2.75863281,6.00070312 2.75863281,10 C2.75863281,13.9992969 6.00070312,17.2413672 10,17.2413672 C13.9992969,17.2413672 17.2413672,13.9992969 17.2413672,10 C17.2413672,6.00070312 13.9992969,2.75863281 10,2.75863281 Z" id="Shape" fill="#0033B2" />
              <path d="M19.3103516,9.31035156 L15.4689844,9.31035156 C15.1524219,6.81378906 13.1862109,4.84757812 10.6896875,4.53105469 L10.6896875,0.689648438 C10.6896484,0.308632812 10.3810156,0 10,0 C9.61898437,0 9.31035156,0.308632812 9.31035156,0.689648438 L9.31035156,4.53101563 C6.81378906,4.84757812 4.84757812,6.81378906 4.53105469,9.3103125 L0.689648437,9.3103125 C0.308632812,9.31035156 0,9.61898438 0,10 C0,10.3810156 0.308632812,10.6896484 0.689648437,10.6896484 L4.53101562,10.6896484 C4.84757812,13.1862109 6.81378906,15.1524219 9.3103125,15.4689453 L9.3103125,19.3103125 C9.3103125,19.6913281 9.61894531,19.9999609 9.99996094,19.9999609 C10.3809766,19.9999609 10.6896094,19.6913281 10.6896094,19.3103125 L10.6896094,15.4689453 C13.1861719,15.1523828 15.1523828,13.1861719 15.4689062,10.6896484 L19.3102734,10.6896484 C19.6912891,10.6896484 19.9999219,10.3810156 19.9999219,10 C19.9999219,9.61898438 19.6913672,9.31035156 19.3103516,9.31035156 Z M10,14.1379297 C7.71484375,14.1379297 5.86207031,12.2851562 5.86207031,10 C5.86207031,7.71484375 7.71484375,5.86207031 10,5.86207031 C12.2851562,5.86207031 14.1379297,7.71484375 14.1379297,10 C14.1379297,12.2851562 12.2851562,14.1379297 10,14.1379297 L10,14.1379297 Z" id="Shape" fill="#FF003E" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TargetIcon;
