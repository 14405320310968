import React, { FunctionComponent, useContext } from 'react';
import ThemeContext from 'helpers/ThemeContext';
import { IconPropsSchema } from '../../types/index';
import { getIconBGColor } from '../helpers/formatters';

const PencilEditorIcon: FunctionComponent<IconPropsSchema> = ({
  color,
  height = '20px',
  width = '20px',
}) => {
  const theme = useContext(ThemeContext);
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" version="1.1">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="IDP-home-Copy-38-(b)-Copy-15" transform="translate(-530.000000, -382.000000)">
          <rect fill={getIconBGColor(theme, color)} x="0" y="0" width="1366" height="800" />
          <rect id="Rectangle" fillOpacity="0.4" fill="#000000" x="0" y="40" width="1369" height="890" />
          <rect id="Rectangle" stroke="#EDF0F5" fill={getIconBGColor(theme, color)} x="377.5" y="51.5" width="642" height="738" rx="4" />
          <g id="iso-user-copy-2" transform="translate(530.000000, 382.000000)" fillRule="nonzero">
            <g id="signature">
              <path d="M17.6278125,17.9904375 L3.35769531,17.9904375 C1.50589844,17.9904375 0,16.4415134 0,14.5368482 C0,12.632183 1.50589844,11.0832188 3.35769531,11.0832188 L5.03652344,11.0832188 C5.49988281,11.0832188 5.8759375,11.4691741 5.8759375,11.9466161 C5.8759375,12.4240982 5.49988281,12.8100134 5.03652344,12.8100134 L3.35769531,12.8100134 C2.43179687,12.8100134 1.67886719,13.5844955 1.67886719,14.536808 C1.67886719,15.4891607 2.43183594,16.2636027 3.35769531,16.2636027 L17.6278125,16.2636027 C18.0919922,16.2636027 18.4672266,16.649558 18.4672266,17.127 C18.4672266,17.604442 18.0919922,17.9904375 17.6278125,17.9904375 Z" id="Path" fill="#FF003E" />
              <path d="M18.4672266,1.58580804 L18.4672266,1.58580804 C17.5396875,0.632611607 16.0371094,0.632611607 15.1095312,1.58580804 L9.43507812,7.42238839 C8.76859375,8.11052679 8.39417969,9.04126339 8.39417969,10.0125804 L8.39417969,11.9466161 L10.2744922,11.9466161 C11.2188281,11.9466161 12.12375,11.5615446 12.7927344,10.8759777 L18.4672266,5.03935714 C19.3939453,4.08535714 19.3939453,2.53984821 18.4672266,1.58580804 Z" id="Path" fill="#C2DDF2" />
              <path d="M10.2744922,12.8100536 L8.39417969,12.8100536 C7.93082031,12.8100536 7.55476562,12.4240982 7.55476562,11.9466563 L7.55476562,10.0126205 C7.55476562,8.80473214 8.01140625,7.66763839 8.84074219,6.81283929 L14.5160937,0.975375 C15.7685156,-0.311946429 17.8082813,-0.312830357 19.0607031,0.975375 C19.0607031,0.975375 19.0607031,0.975375 19.0615234,0.97621875 C20.3122656,2.26442411 20.3122656,4.361625 19.0615234,5.64983036 L13.3862109,11.4872946 C12.5526563,12.3403259 11.4488281,12.8100536 10.2744922,12.8100536 Z M9.23359375,11.0832187 L10.2744922,11.0832187 C11.0005859,11.0832187 11.6847266,10.7922455 12.2001172,10.2647009 L17.87375,4.42896429 C18.4714063,3.81423214 18.4714063,2.81181696 17.87375,2.19620089 C17.2752344,1.58146875 16.3006641,1.5823125 15.7030078,2.19620089 L10.0285547,8.03282143 C9.51652344,8.56120982 9.23359375,9.26489732 9.23359375,10.0126205 L9.23359375,11.0832187 L9.23359375,11.0832187 Z" id="Shape" fill="#0030B9" />
              <path d="M17.1325391,6.41221875 L18.4672266,5.03939732 C19.3939453,4.08535714 19.3939453,2.53984821 18.4672266,1.58576786 L18.4672266,1.58576786 C17.5396875,0.632571429 16.0371094,0.632571429 15.1095312,1.58576786 L13.9259375,2.768625" id="Path" fill="#FFFFFF" />
              <g id="Group" transform="translate(12.929688, 0.000000)" fill="#0030B9">
                <path d="M4.79632813,7.02265179 L3.609375,5.80178571 L4.9440625,4.42896429 C5.54171875,3.81334821 5.54171875,2.81181696 4.94324219,2.19620089 C4.34726562,1.58404018 3.37269531,1.58146875 2.77335937,2.19704464 L1.58136719,3.38854018 L0.410390625,2.14955357 L1.59398438,0.966696429 C2.84050781,-0.312870536 4.87863281,-0.310258929 6.13019531,0.975334821 C7.38175781,2.26438393 7.38175781,4.36158482 6.13101563,5.64979018 L4.79632813,7.02265179 Z" id="Path" />
                <path d="M4.20285156,7.27561607 C3.98796875,7.27561607 3.77308594,7.191 3.609375,7.02265179 L0.251679688,3.56902232 C-0.0765234375,3.23144196 -0.0765234375,2.68577679 0.251679688,2.34815625 C0.579882813,2.01057589 1.11039063,2.01057589 1.43863281,2.34815625 L4.79632813,5.80178571 C5.12453125,6.13936607 5.12453125,6.68503125 4.79632813,7.02265179 C4.63265625,7.19188393 4.41773438,7.27561607 4.20285156,7.27561607 Z" id="Path" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PencilEditorIcon;
