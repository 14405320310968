import React, { FunctionComponent, useEffect, useState } from 'react';
import { ThemeProp } from 'types/index';
import ResultsCard from 'components/ResultsCard';
import DatePicker from 'components/DatePicker';
import { CustoTypeSettings } from 'types/edit';
import NumberInput from 'components/NumberInput';
import moment from 'moment';
import Select from 'components/Select';
import capitalize from 'lodash/capitalize';
import { defaultSettings } from '../helpers/ScenarioGenerationHelpers';

type ScheduleBulkEVSettingsProps = {
    theme: ThemeProp,
    settingsByCustoType?: CustoTypeSettings,
    setSettingsByCustoType: (args: CustoTypeSettings) => void,
    evAssetList: { [key: string]: string }[],
};

const ScheduleBulkEVSettings: FunctionComponent<ScheduleBulkEVSettingsProps> = ({
  theme,
  settingsByCustoType = {},
  setSettingsByCustoType,
  evAssetList,
}) => {
  const customerTypes = evAssetList.reduce((obj, asset) => {
    if (!obj[asset.load_type]) {
      obj[asset.load_type] = 0;
    }
    obj[asset.load_type] += 1;
    return obj;
  }, {} as {[key: string]: number});
  const [selectedcusType, setSelectedCusType] = useState<{ value: string, lebel: string }[]>([]);
  useEffect(() => {
    const newObj: CustoTypeSettings = {};
    selectedcusType.forEach((type) => {
      if (settingsByCustoType[type.value]) {
        newObj[type.value] = settingsByCustoType[type.value];
      } else {
        newObj[type.value] = defaultSettings;
      }
    });
    setSettingsByCustoType(newObj);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedcusType]);
  return (
    <div>
      <div className="margin-10">
        <Select
          label="Select Customer Types"
          onChange={(option: { value: string, lebel: string }[]) => {
            setSelectedCusType(option ?? []);
          }}
          id="select-cus-types"
          options={Object.keys(customerTypes)?.map(
            (type) => ({ value: type, label: type === '' ? 'Unspecified loads' : type }),
          )}
          value={selectedcusType}
          theme={theme}
          type="secondary"
          clearable={false}
          multiSelectType="checkbox"
          isMulti
          width={200}
        />
      </div>
      { Object.entries(settingsByCustoType).map(([key, type]) => {
        const windowTimeDiff = moment.duration(moment(type.end_time, 'HH:mm').diff(moment(type.start_time, 'HH:mm')));
        return (
          <div className="bulk-ev-settings grid-columns one-two margin-10" key={key}>
            <div>
              <div className="flex-vertical-center margin-10">
                <span>{key !== '' ? capitalize(key) : 'Unspecified loads'}</span>
              </div>
              <p className="label-light">{`${customerTypes[key]} EV chargers`}</p>
            </div>
            <div>
              <ResultsCard
                theme={theme}
                withBorder={false}
                className="ev-settings-card"
              >
                <div>
                  <div className="start-end-time">
                    <p className="text-bold">Window start and end time</p>
                    <div className="grid-columns one-one">
                      <div className="margin-10">
                        <p className="label-light">Start time</p>
                        <DatePicker
                          onChange={(value: moment.Moment) => {
                            setSettingsByCustoType({
                              ...settingsByCustoType,
                              [key]: { ...settingsByCustoType[key], start_time: moment(value).format('HH:mm') },
                            });
                          }}
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                            time_24hr: true,
                            defaultDate: settingsByCustoType[key]?.start_time,
                          }}
                          showArrows={false}
                          theme={theme}
                          id={key}
                          type="primary"
                        />
                      </div>
                      <div className="margin-10">
                        <p className="label-light">End time</p>
                        <DatePicker
                          onChange={(value: moment.Moment) => {
                            setSettingsByCustoType({
                              ...settingsByCustoType,
                              [key]: { ...settingsByCustoType[key], end_time: moment(value).format('HH:mm') },
                            });
                          }}
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                            time_24hr: true,
                            defaultDate: settingsByCustoType[key]?.end_time,
                            minTime: settingsByCustoType[key]?.start_time,
                          }}
                          showArrows={false}
                          theme={theme}
                          type="primary"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="margin-10">
                    <p className="text-bold">Number of events a day</p>
                    <div className="grid-columns one-one">
                      <NumberInput
                        id="min-events"
                        label="Minimum Events"
                        value={settingsByCustoType[key]?.min_events}
                        onChange={(e: any) => {
                          setSettingsByCustoType({
                            ...settingsByCustoType,
                            [key]: { ...settingsByCustoType[key], min_events: e.value },
                          });
                        }}
                        theme={theme}
                        inputStyle="primary"
                        ge={0}
                        le={settingsByCustoType[key]?.max_events}
                        required
                      />
                      <NumberInput
                        id="max-events"
                        label="Maximum Events"
                        value={settingsByCustoType[key]?.max_events}
                        onChange={(e: any) => {
                          setSettingsByCustoType({
                            ...settingsByCustoType,
                            [key]: { ...settingsByCustoType[key], max_events: e.value },
                          });
                        }}
                        theme={theme}
                        inputStyle="primary"
                        le={100}
                        ge={1}
                        required
                      />
                    </div>
                  </div>
                  <div className="margin-10">
                    <p className="text-bold">Charge duration</p>
                    <div className="grid-columns one-one">
                      <div className="margin-10">
                        <p className="label-light">Minimum duration</p>
                        <DatePicker
                          onChange={(value: moment.Moment) => {
                            setSettingsByCustoType({
                              ...settingsByCustoType,
                              [key]: { ...settingsByCustoType[key], min_charge_length: moment(value).format('HH:mm') },
                            });
                          }}
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                            time_24hr: true,
                            defaultDate: settingsByCustoType[key]?.min_charge_length,
                            minTime: '00:15',
                          }}
                          showArrows={false}
                          theme={theme}
                          type="primary"
                        />
                      </div>
                      <div className="margin-10">
                        <p className="label-light">Maximum duration</p>
                        <DatePicker
                          onChange={(value: moment.Moment) => {
                            setSettingsByCustoType({
                              ...settingsByCustoType,
                              [key]: { ...settingsByCustoType[key], max_charge_length: moment(value).format('HH:mm') },
                            });
                          }}
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                            time_24hr: true,
                            defaultDate: settingsByCustoType[key]?.max_charge_length,
                            maxTime: `${Math.trunc(windowTimeDiff.asHours())}:${Math.trunc(windowTimeDiff.asMinutes() % 60)}`,
                            minTime: settingsByCustoType[key]?.min_charge_length,
                          }}
                          showArrows={false}
                          theme={theme}
                          type="primary"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </ResultsCard>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ScheduleBulkEVSettings;
