import React, {
  FunctionComponent, useContext, useEffect,
} from 'react';
import Modal from 'components/Modal';
import { ThemeProp } from 'types/index';
import GridLayout from 'layouts/GridLayout';
import Select from 'components/Select';
import TargetIcon from 'components/Icons/TargetIcon';
import { useRequest, useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import ResultsCard from 'components/ResultsCard';
import { useToasts } from 'react-toast-notifications';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';
import useResetableState from 'hooks/useResetableState';
import { ScenarioGenerationContext } from '../context/ScenarioGenerationContext';
import { modalTypes } from '../helpers/ScenarioGenerationHelpers';

type ExternalSourceModalProps = {
    theme: ThemeProp,
    workspace: string,
    branch: string,
};

type ExternalSchedulesData = {
  id: string, name: string,
}[];

const ExternalSourceModal: FunctionComponent<ExternalSourceModalProps> = ({
  theme, workspace, branch,
}) => {
  const { addToast } = useToasts();
  const { modalActiveType, resetModalType, selectedScenario } = useContext(ScenarioGenerationContext);
  const { analyticsConfig } = useContext(WorkspaceSettingsContext);
  const sourceOptions = Object.entries(analyticsConfig?.schedule_sources as { [key: string]: {[key: string]: string}}
    ?? []).map(([id, source]) => ({
    value: id, label: `${source.name} - ${source.url}`,
  }));
  const [source, setSource, resetSource] = useResetableState(null);
  const [selectedSchedule, setSelectedSchedule, resetSelectedSchedule] = useResetableState(null);
  const {
    makeRequest: externalScheduleRequest, loading,
  } = useRequest(
    `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios/${selectedScenario?.value}/schedule-source`,
  );
  const generateExternalSchedule = async () => {
    try {
      await externalScheduleRequest({
        method: 'put',
        body: {
          schedule_source_id: source,
          priority: 10,
          external_scenario: selectedSchedule,
        },
        toast: {
          error: 'Request to external server failed',
          settings: {
            autoDismiss: true,
          },
        },
        onSuccess: () => { resetModalType(); },
      });
    } catch (error) {
    }
  };
  const {
    data: externalSchedules,
    loading: getSourceListLoading,
  } = useRequestEffect<ExternalSchedulesData>({
    url: `/api/workspace/${workspace}/analytics-config/schedule-source/${source}/external-scenarios`,
    method: 'get',
    refetchOnChange: [workspace, source],
    blockRequest: () => !(workspace && source),
    onError: (err) => {
      let errorMSg = 'Failed to get external schedules from selected source';
      if (err?.response?.status && [502].includes(err?.response?.status) && err?.response?.data?.message) {
        errorMSg = err?.response?.data?.message;
      }
      addToast(errorMSg, { appearance: 'error' });
    },
  });
  useEffect(() => {
    if (source) resetSource();
    if (selectedSchedule) resetSelectedSchedule();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace]);
  return (
    <Modal
      active={modalActiveType === modalTypes.externalSource}
      title="External schedule"
      onConfirm={() => generateExternalSchedule()}
      onCancel={() => resetModalType()}
      theme={theme}
      modalType="primary"
      width="600px"
      height="350px"
      labels={{
        confirm: loading ? <i className="material-icons rotate">refresh</i> : 'Done',
        cancel: 'Cancel',
      }}
      scrollBody
      disableConfirm={!(source && selectedSchedule)}
    >
      <GridLayout>
        <div className="grid-columns one-two margin-10 schedule-details">
          <div className="header flex-end margin-10">
            <div style={{ marginRight: '10px' }}>Schedule details</div>
            <TargetIcon />
          </div>
          <div>
            <ResultsCard
              theme={theme}
              withBorder={false}
              className="schedule-detail-card"
            >
              <Select
                label="Select URL"
                onChange={(e) => setSource(e.value)}
                id="select-asset"
                options={sourceOptions}
                value={source}
                theme={theme}
                type="secondary"
                clearable={false}
              />
              <div className="margin-10">
                { getSourceListLoading ? (
                  <LoadingSkeleton template="line" width={100} theme={theme} />
                ) : (
                  <Select
                    label="Select schedule"
                    onChange={(e) => setSelectedSchedule(e.value)}
                    id="select-schedule"
                    options={externalSchedules
                      ?.map((sce) => ({ label: sce.name, value: sce.id })) || []}
                    value={selectedSchedule}
                    theme={theme}
                    type="secondary"
                    clearable={false}
                    disabled={!source || getSourceListLoading}
                  />
                )}
              </div>
            </ResultsCard>
            <p className="text-bold">Note: You can add URL to the list in your admin panel</p>
          </div>
        </div>
      </GridLayout>
    </Modal>
  );
};

export default ExternalSourceModal;
