import moment from 'moment';
import { ANALYSIS_TYPES, ACTIVITY_LOG_INTERVAL } from '../../helpers/NetworkHelpers';

const qstsMaxTime = (permissions, startDate, interval = 60) => {
  const returnMaxDate = (timepoints) => {
    let dte;
    switch (interval) {
      case 5:
        dte = moment(startDate).add(timepoints / 12, 'hours');
        break;
      case 15:
        dte = moment(startDate).add(timepoints / 4, 'hours');
        break;
      case 30:
        dte = moment(startDate).add(timepoints / 2, 'hours');
        break;
      case 60:
      default:
        dte = moment(startDate).add(timepoints, 'hours');
    }
    return dte;
  };

  let maxDate = moment(startDate).add(87672, 'hours');

  if (!permissions || (interval === 30 && permissions.has('run_powerflow_qsts_87672_timepoints_extra'))) {
    maxDate = moment(startDate).add(87672, 'hours');
  } else if (!permissions || permissions.has('run_powerflow_qsts_87672_timepoints')) {
    maxDate = returnMaxDate(87672);
  } else if (!permissions || permissions.has('run_powerflow_qsts_744_timepoints')) {
    maxDate = returnMaxDate(744);
  } else if (!permissions || permissions.has('run_powerflow_qsts_24_timepoints')) {
    maxDate = returnMaxDate(24);
  }
  return maxDate;
};

const hcMaxTime = (permissions, startDate) => {
  if (!permissions
    || permissions.has('run_hosting_capacity')
    || permissions.has('run_network_hosting_capacity')) {
    return moment(startDate).add(1, 'years');
  }
  if (permissions.has('run_hosting_capacity_day')
    || permissions.has('run_network_hosting_capacity_day')) {
    return moment(startDate).add(1, 'days');
  }

  return moment(startDate).add(1, 'years');
};

const batMaxTime = (permissions, startDate) => {
  if (!permissions || permissions.has('run_battery_sizing')) {
    return moment(startDate).add(1, 'years');
  }
  if (permissions.has('run_battery_sizing_day')) {
    return moment(startDate).add(1, 'days');
  }

  return moment(startDate).add(1, 'years');
};

export const getMaxTime = (permissions, startDate, interval) => ({
  [ANALYSIS_TYPES.QSTS]: qstsMaxTime(permissions, startDate, interval),
  [ANALYSIS_TYPES.QSTS_OPF]: qstsMaxTime(permissions, startDate, interval),
  [ANALYSIS_TYPES.QSTS_NWE]: qstsMaxTime(permissions, startDate, interval),
  [ANALYSIS_TYPES.POWERFLOW]: moment(startDate).add(10, 'years'),
  // we are currently making the assumption that
  // HC and NHC will always have the max duration.
  [ANALYSIS_TYPES.HOSTING_CAPACITY]: hcMaxTime(permissions, startDate),
  [ANALYSIS_TYPES.EV_CAPACITY]: hcMaxTime(permissions, startDate),
  [ANALYSIS_TYPES.BATTERY_SIZING]: batMaxTime(permissions, startDate),
});

export const logEntriesOutOfDate = (lastRun, lastLogUpdate) => {
  if (!lastRun) return false;
  // we have to pad the last update time by one interval, because the most recent update
  // could have been started before the analysis was started on the server.
  const paddedLastUpdateTime = moment(lastRun).add(
    ACTIVITY_LOG_INTERVAL,
    'milliseconds',
  );
  return paddedLastUpdateTime.isAfter(lastLogUpdate);
};
