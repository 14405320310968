import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import { ThemeProp } from 'types/index';
import classNames from 'classnames';
import Tree, {
  useTreeState,
  treeHandlers,
} from 'react-hyper-tree';
import CircleCheckMarkIcon from '../Icons/CircleCheckMarkIcon';

type DataProps = {
    id: string,
    name: string,
    children: DataProps[],
};
type HierarchyViewProps = {
    theme: ThemeProp,
    data: DataProps[],
    renderNode?: ReactNode,
    feedersInScenario?: {[key: string]: string}[]
 };

const HierarchyView: FunctionComponent<HierarchyViewProps> = ({
  theme,
  data = [],
  feedersInScenario = [],
}) => {
  const { required, handlers } = useTreeState({
    id: 'tree',
    data,
    defaultOpened: true,
    multipleSelect: false,
  });
  const renderNode = useCallback(({
    node,
    onToggle,
  }) => (
    <div className="tree-node" key={node.data.name}>
      <div
        role="button"
        onClick={onToggle}
        className={classNames({
          'tree-icon': true,
          'empty-icon': !node.hasChildren(),
          [node.options.opened
            ? 'close-icon'
            : 'open-icon']: node.hasChildren(),
        })}
        tabIndex={0}
        onKeyUp={() => {}}
        id="tree-icon"
      >
        close-icon
      </div>
      <div
        role="button"
        className={classNames({
          'node-content-wrapper': true,
          'node-selected': node.isSelected(),
        })}
        onClick={
          () => treeHandlers.trees.tree.handlers.setSelected(
            node,
            !node.isSelected(),
          )
        }
        tabIndex={-1}
        onKeyUp={() => {}}
      >
        <div className="titles">
          <div className="node-title">
            {node.data.name}
          </div>
        </div>
        <div className="node-check">
          { feedersInScenario?.find((feeder) => feeder.id === node.data.id) && <CircleCheckMarkIcon />}
        </div>
      </div>
    </div>
  ), [feedersInScenario]);
  const lineStyles = {
    stroke: '#95A6B8',
    strokeDasharray: '0',
    strokeWidth: 1,
  };
  return (
    <div className={`hierarchy-tree-view ${theme}`}>
      <Tree
        {...required}
        {...handlers}
        horizontalLineStyles={lineStyles}
        verticalLineStyles={lineStyles}
        draggable={false}
        gapMode="padding"
        depthGap={20}
        disableLines={false}
        disableHorizontalLines={false}
        disableVerticalLines={false}
        verticalLineTopOffset={-12}
        verticalLineOffset={4}
        renderNode={renderNode}
        staticNodeHeight={35}
      />
    </div>
  );
};

export default HierarchyView;
