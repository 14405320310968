import React, {
  useContext, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';

import HelpTooltip from 'components/HelpTooltip';
import Input from 'components/Input';

import Analytics from 'helpers/Analytics';
import { kWh } from 'helpers/units';
import ThemeContext from 'helpers/ThemeContext';
import SingleEditableValue from '../../Inputs/SingleEditableValue';

const BatteryControlMode = (props) => {
  const theme = useContext(ThemeContext);
  const [panelValues, setPanelValues] = useState(props.battery);
  const [edited, setEdited] = useState(null);
  const validationValues = {
    minEnergy: panelValues.min_energy,
    maxEnergy: panelValues.max_energy,
  };

  const initialEnergyTooltip = 'The energy level of the battery at the start of an analysis. '
    + 'If an analysis spans multiple days, initial energy will be the value at the start of each day.';
  const finalEnergyTooltip = 'The energy level of the battery at the end of an analysis. '
    + 'If an analysis spans multiple days, final energy will be the value at the end of each day.';

  // Refetch the battery values when panel finishes saving
  useEffect(() => {
    if (!props.disabled) {
      setPanelValues(props.battery);
    }
  }, [props.disabled, props.battery]);

  const updateEditableValue = (e, id) => {
    setPanelValues({ ...panelValues, [id]: e });
    setEdited(id);
  };

  const handleSave = ({ target: { name } }) => {
    const attributeMap = { initialEnergy: 'initial_energy', finalEnergy: 'final_energy' };
    const field = attributeMap[name] || name;
    const value = panelValues[name];
    props.saveEdit(props.battery.id, { attributes: { [field]: field === 'final_energy' && value === '' ? null : value } });
    Analytics.logEvent('Battery Global SoC Changed', 'Assets', edited);
    return setEdited(null);
  };

  return (
    <>
      <div className="editable-section">
        <h3>{props.battery.name}</h3>
        <div className="input-spacing">
          <SingleEditableValue
            key="energy"
            name="energy"
            id="energy"
            label={(
              <div className="label-help">
                Initial Energy
                <HelpTooltip className="" message={initialEnergyTooltip} />
              </div>
            )}
            type="number"
            precision={1}
            unit={kWh}
            divisor={1000}
            disabled={props.disabled}
            value={panelValues.energy}
            edited={edited === 'energy'}
            onBlur={handleSave}
            onChange={updateEditableValue}
            htmlFor={props.battery.id}
            {...props.validationSchema.initialEnergy}
            {...validationValues}
          />
        </div>
        <div className="input-spacing">
          <Input
            label="Initial State of Charge"
            value={panelValues.energy}
            key="initialStateOfCharge"
            unit="%"
            id="initialStateOfCharge"
            type="number"
            inputStyle="panel"
            theme={theme}
            disabled
            htmlFor={props.battery.name}
            options={{
              precision: 1,
              divisor: panelValues.rated_energy / 100,
            }}
          />
        </div>
        {props.showFinal && (
          <>
            <div className="input-spacing">
              <SingleEditableValue
                key="final_energy"
                name="final_energy"
                id="final_energy"
                label={(
                  <div className="label-help">
                    Final Energy
                    <HelpTooltip className="" message={finalEnergyTooltip} />
                  </div>
                )}
                type="number"
                precision={1}
                unit={kWh}
                divisor={1000}
                disabled={props.disabled}
                value={panelValues.final_energy}
                edited={edited === 'final_energy'}
                onBlur={handleSave}
                onChange={updateEditableValue}
                htmlFor={props.battery.id}
                {...props.validationSchema.finalEnergy}
                {...validationValues}
              />
            </div>
            <div className="input-spacing">
              <Input
                label="Final State of Charge"
                value={panelValues.final_energy}
                key="finalStateOfCharge"
                unit="%"
                id="finalStateOfCharge"
                type="number"
                inputStyle="panel"
                theme={theme}
                disabled
                htmlFor={props.battery.name}
                options={{
                  precision: 1,
                  divisor: panelValues.rated_energy / 100,
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

BatteryControlMode.defaultProps = {
  disabled: true,
  validationSchema: {},
  showFinal: false,
};

BatteryControlMode.propTypes = {
  battery: PropTypes.object.isRequired,
  saveEdit: PropTypes.func.isRequired,
  validationSchema: PropTypes.object,
  disabled: PropTypes.bool,
  showFinal: PropTypes.bool,
};

export default BatteryControlMode;
