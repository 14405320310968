import React, { ReactChild, FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';
import { ThemeProp } from '../../types/index';

import './Button.scss';

type ButtonProps = {
  disabled?: boolean,
  type?: 'primary' | 'secondary' | 'text' | 'tour' | 'info';
  width?: string,
  height?: string,
  loading?: boolean,
  label?: string,
  circular?: boolean,
  square?: boolean,
  onClick?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void|null|Promise<void>;
  title?: string,
  showArrow?: boolean,
  className?: string,
  theme?: ThemeProp,
  children?: ReactChild|ReactNode;
  id?: string,
};

const Button: FunctionComponent<ButtonProps> = ({
  onClick,
  disabled = false,
  type = 'primary',
  width = '',
  height = '',
  loading = false,
  className = '',
  label = '',
  children = null,
  circular = false,
  square = true,
  title = '',
  theme = 'dark',
  showArrow = false,
  id = '',
}) => (
  <button
    className={classNames(
      'custom-btn', `${type}-btn`, theme, className,
      { 'circular-type': circular }, { 'square-type': square },
    )}
    style={{ width, height }}
    disabled={disabled}
    onClick={onClick}
    title={title}
    id={id}
    type="button"
  >
    {label || children }
    {loading && <i className="material-icons rotate"> autorenew </i>}
    {showArrow && <i className="material-icons arrow_down">keyboard_arrow_down </i>}
  </button>
);

export default Button;
