import React, {
  FunctionComponent, useContext,
} from 'react';
import ResultsCard from 'components/ResultsCard';
import Button from 'components/Button';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { ThemeProp } from 'types/index';
import { ScenarioTypes } from 'helpers/scenarios';
import { ZoomLevel } from 'components/ZoomableRangeSlider/intervals';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';
import { BETA_PERMISSION } from 'helpers/permissions';
import { Moment } from 'moment';
import RadioButtonGroup from 'components/RadioButtonGroup';
import AssetSchedule from '../../Network/components/SlidingInfoPanel/AssetPanel/templates/partials/AssetSchedule';
import { ScenarioGenerationContext } from '../context/ScenarioGenerationContext';
import { views } from '../helpers/ScenarioGenerationHelpers';

type SchedulesProps = {
    workspace: string,
    branch: string,
    theme: ThemeProp,
    scenario?: {[key: string]: string}|null,
    scenarioRange: { start: Moment|null, end: Moment|null },
    timeBarZoomLevel: ZoomLevel|null,
  };

const Schedules: FunctionComponent<SchedulesProps> = ({
  theme,
  scenario = null,
  workspace,
  branch,
  scenarioRange,
  timeBarZoomLevel,
}) => {
  const {
    feedersInScenario, feedersInSceLoading, setView, setSelectedContainer,
  } = useContext(ScenarioGenerationContext);
  const { permissions } = useAuthContext();

  return (
    <div data-test="schedules" className={`schedules ${theme}`}>
      { feedersInScenario && feedersInScenario?.length !== 0 && scenario && (
      <div className={`${permissions.has(BETA_PERMISSION) ? 'three-one-one' : 'four-two'} grid-columns margin-10`} id="scedule-graph-section">
        <div>
          <div className="auto-fit grid-columns label-light header">
            <div>
              Feeder Schedules
            </div>
            <div>
              Start date & time
            </div>
            <div>
              End date & time
            </div>
          </div>
        </div>
        <div className="label-light header">
          Asset Schedules
        </div>
        { permissions.has(BETA_PERMISSION) && (
          <div className="label-light header">
            Prioritize
          </div>
        )}
      </div>
      )}
      {
        feedersInSceLoading
          ? (
            <div className="three-one-one grid-columns">
              <LoadingSkeleton template="square" height={225} width={303} theme={theme} />
              <LoadingSkeleton template="square" height={225} width={200} theme={theme} />
            </div>
          )
          : feedersInScenario?.map((container) => (
            <div className={`${permissions.has(BETA_PERMISSION) ? 'three-one-one' : 'four-two'} grid-columns`} key={container.id}>
              <ResultsCard
                theme={theme}
                withBorder={false}
                className="schedules-card"
              >
                <div className="auto-fit grid-columns">
                  <div className="results-card-header">
                    <span className="title">
                      {container.name}
                    </span>
                  </div>
                  <div className="timerange-start">{scenarioRange?.start?.format('DD MMM, YYYY HH:mm')}</div>
                  <div className="timerange-end">{scenarioRange?.end?.format('DD MMM, YYYY HH:mm')}</div>
                </div>
                { workspace && branch && scenario && scenario.value && scenarioRange.start
                  && scenarioRange.end && timeBarZoomLevel && scenario.type === ScenarioTypes.timeseries && (
                  <div className="sliding-panel">
                    <AssetSchedule
                      workspace={workspace}
                      branch={branch}
                      scenario={scenario?.value}
                      scenarioType={scenario?.type}
                      asset={{ id: container.id, class: 'feeder' }}
                      scheduleType="Feeder"
                      timeRange={{ start: scenarioRange.start || null, end: scenarioRange.start || null }}
                      maxRange={scenarioRange}
                      timeBarZoomLevel={timeBarZoomLevel}
                      editable={false}
                      expanded
                      displayActionIcons={false}
                    />
                  </div>
                )}
              </ResultsCard>
              <ResultsCard
                theme={theme}
                withBorder={false}
                className="asset-schedules"
              >
                <Button
                  type="text"
                  onClick={() => {
                    setSelectedContainer(container.id);
                    setView(views.assetSchedule);
                  }}
                  className="asset-schedule-link"
                >
                  View Asset Schedules
                </Button>
              </ResultsCard>
              {permissions.has(BETA_PERMISSION) && (
                <div>
                  <RadioButtonGroup
                    options={[
                      { id: 'external', label: 'External', disabled: true },
                      { id: 'internal', label: 'Internal (NA)' },
                    ]}
                    id="priority-selector"
                    theme={theme}
                    value="internal"
                    listType="row"
                    radioType="primary"
                    onChange={() => {}}
                  />
                </div>
              )}
            </div>
          ))
      }
    </div>
  );
};

export default Schedules;
