/* eslint-disable import/no-cycle */
import { Request } from '@opusonesolutions/gridos-app-framework';
import Analytics from 'helpers/Analytics';
import { MODAL_TYPES } from 'helpers/EditMode';
import asyncActionStates from 'helpers/asyncActionStates';
import { displayAlertMessage } from 'store/appState';
import { alphabetizeByKey } from 'helpers/utils';
import {
  UPDATE_APPLY_DIFFERENCE,
  UPDATE_SELECTED_BRANCH,
  SET_SELECTED_ASSET,
  DELETE_ASSET_SUCCESS,
  updateSelectedBranch,
  fetchBranches,
  setActivePanel,
  actions,
} from './network';

import { SET_SELECTED_CONTAINER, getContainersList } from './feeders';

const {
  INITIAL, LOADING, SUCCESS, ERROR,
} = asyncActionStates;
const BRANCH_MERGED = 'BRANCH_MERGED';
const TOGGLE_EDIT_MODE = 'TOGGLE_EDIT_MODE';
const TOGGLE_PATH_EDIT_MODE = 'TOGGLE_PATH_EDIT_MODE';
const TOGGLE_PATH_CREATE_MODE = 'TOGGLE_PATH_CREATE_MODE';
const CAN_MERGE_BRANCH = 'CAN_MERGE_BRANCH';
const SAVE_BRANCH_EDIT = 'SAVE_BRANCH_EDIT';
const CLEAR_SAVE_EDITS_STATUS = 'CLEAR_SAVE_EDITS_STATUS';
export const SELECT_NEW_ASSET = 'SELECT_NEW_ASSET';
const SET_NEW_ASSET_FEEDER = 'SET_NEW_ASSET_FEEDER';
export const CLEAR_NEW_ASSET = 'CLEAR_NEW_ASSET';
const CLEAR_PATH_MODES = 'CLEAR_PATH_MODES';
const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';
const SET_SAVE_MODAL = 'SET_SAVE_MODAL';

/**
 * Edits a single piece of equipment. Usually this is to edit the
 * phasing of equipment
 * @param {String} workspace The workspace the equipment is in
 * @param {String} branch The branch the equipment is in
 * @param {String} type The type of equipment (such as 'switch')
 * @param {String} rdf_id The ID of the equipment being edited
 * @param {Object} payload_data The payload data to send
 * @param {string} updateType The type of update (used to trigger different state)
 */
function editSingleEquipment(
  workspace,
  branch,
  type,
  rdf_id,
  payload_data,
  updateType = 'editValues',
) {
  return async (dispatch, getState) => {
    const request = new Request(
      `/api/workspace/${workspace}/branch/${branch}/editor/${type}/${rdf_id}`,
    );
    dispatch({
      type: UPDATE_APPLY_DIFFERENCE,
      status: LOADING,
      updateType,
    });
    const { selectedProject } = getState().edit;
    payload_data.project_id = selectedProject;
    try {
      // The response is a JSON diff model that we will apply to the app state
      const { data } = await request.patch(payload_data);
      const { networkInstance } = getState().network;
      networkInstance.applyDifferenceModel(data);
      dispatch({
        type: UPDATE_APPLY_DIFFERENCE,
        status: SUCCESS,
        updateType,
        network: networkInstance,
      });

      // Refresh the selected node after diff is applied
      if (getState().network.selectedAssetID === rdf_id) {
        dispatch({
          type: SET_SELECTED_ASSET,
          selectedJSCimAsset: networkInstance.lookup[rdf_id],
          activePanel: 'asset',
          leftRailPanel: 'asset',
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_APPLY_DIFFERENCE,
        status: ERROR,
        updateType,
        error,
      });
    }
  };
}

function submitEditRequest(request, type, payload) {
  return async (dispatch, getState) => {
    const updateType = 'editValues';
    dispatch({
      type: UPDATE_APPLY_DIFFERENCE,
      status: LOADING,
      updateType,
    });

    try {
      // The response is a JSON diff model that we will apply to the app state
      const { data } = await request[type](payload);
      const { networkInstance } = getState().network;
      networkInstance.applyDifferenceModel(data);

      dispatch({
        type: UPDATE_APPLY_DIFFERENCE,
        status: SUCCESS,
        updateType,
        network: networkInstance,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_APPLY_DIFFERENCE,
        status: ERROR,
        updateType,
      });
    }
  };
}

function editUsagePoint(rdf_id, payload) {
  return async (dispatch, getState) => {
    const { workspace, branch } = getState().network;
    const { selectedProject } = getState().edit;
    payload.project_id = selectedProject;
    const request = new Request(
      `/api/workspace/${workspace}/branch/${branch}/editor/usage_point/${rdf_id}`,
    );
    dispatch(submitEditRequest(request, 'patch', payload));
  };
}

function addNewInstance(type, id, payload = null) {
  return async (dispatch, getState) => {
    const { workspace, branch } = getState().network;
    const { selectedProject } = getState().edit;
    const request = new Request(`/api/workspace/${workspace}/branch/${branch}/editor/${type}`);
    dispatch(submitEditRequest(request, 'post', { equipment: id, project_id: selectedProject, ...payload }));
  };
}

function deleteReference(type, id) {
  return async (dispatch, getState) => {
    const { workspace, branch } = getState().network;
    const { selectedProject } = getState().edit;
    const request = new Request(`/api/workspace/${workspace}/branch/${branch}/editor/${type}/${id}`);
    dispatch(submitEditRequest(request, 'deleteWithData', { project_id: selectedProject }));
  };
}

/**
 * Toggles the edit mode status
 *   - If in edit mode: discards the edit branch and reverts to original branch
 *   - If not in edit mode: creates the edit mode branch and sets as target for saving
 */
function toggleEditMode() {
  return async (dispatch, getState) => {
    dispatch({ type: TOGGLE_EDIT_MODE, status: LOADING });
    const { branch, workspace, displayBranch } = getState().network;

    if (getState().edit.inEditMode) {
      try {
        const req = new Request(`/api/workspace/${workspace}/branch/${branch}`);
        await req.delete();
        dispatch({ type: TOGGLE_EDIT_MODE, status: SUCCESS });
        dispatch(updateSelectedBranch(displayBranch));
      } catch (err) {
        dispatch({ type: TOGGLE_EDIT_MODE, status: ERROR });
        dispatch(
          displayAlertMessage(
            'Exit Edit Mode Failure',
            'An error occured while discarding changes, please try again.',
            () => dispatch({ type: TOGGLE_EDIT_MODE, status: INITIAL }),
          ),
        );
      }
    } else {
      Analytics.logEvent('Entered Edit Mode', 'Edit Mode');
      try {
        const req = new Request(`/api/workspace/${workspace}/branch/${branch}/edit_branch`);
        await req.post();
        dispatch({ type: TOGGLE_EDIT_MODE, status: SUCCESS });
        dispatch(updateSelectedBranch(branch, null, false));
      } catch (err) {
        dispatch({ type: TOGGLE_EDIT_MODE, status: ERROR });
        dispatch(
          displayAlertMessage(
            'Enter Edit Mode Failure',
            'An error occured while entering edit mode, please try again.',
            () => dispatch({ type: TOGGLE_EDIT_MODE, status: INITIAL }),
          ),
        );
      }
    }
  };
}

/**
 * Saves the edits to a permanent branch
 * If a branch name is provided that branch is created.
 * If nothing is provided changes are saved to original branch.
 * @param {String} branchName Optional : Name of branch to create
 */
function saveEdits(branchName, modalType = MODAL_TYPES.TOGGLE, switchBranch = null) {
  return async (dispatch, getState) => {
    dispatch({ type: SAVE_BRANCH_EDIT, payload: LOADING });
    const { edit, network } = getState();
    const { inEditMode } = edit;
    const { displayBranch, workspace } = network;
    const target = branchName ? `?target_branch=${encodeURIComponent(branchName)}` : '';
    try {
      const saveReq = new Request(
        `/api/workspace/${workspace}/branch/${displayBranch}/save_edit_branch${target}`,
      );
      await saveReq.post();
      dispatch({ type: SAVE_BRANCH_EDIT, payload: SUCCESS });
      await dispatch(fetchBranches(workspace));
      let branch = branchName || displayBranch;
      if (modalType === MODAL_TYPES.SAVEAS || modalType === MODAL_TYPES.VERSION) {
        if (switchBranch) branch = switchBranch;
        try {
          const req = new Request(`/api/workspace/${workspace}/branch/${branch}/edit_branch`);
          await req.post();
        } catch (err) {
        }
      }
      if (modalType !== MODAL_TYPES.WORKSPACE) dispatch(updateSelectedBranch(branch));
    } catch (err) {
      let message = (err.response && err.response.message) || '';
      if (
        err.response
        && err.response.data
        && err.response.data.errors
        && err.response.data.errors[0]
        && err.response.data.errors[0].message
      ) {
        message = err.response.data.errors[0].message;
      }

      if (err.response && err.response.status === 409) {
        // If we receive a conflict response with a branch name we can still merge
        // back to the original branch. Just means the selected branch name already exists.
        const canMerge = !!branchName;
        dispatch({ type: CAN_MERGE_BRANCH, payload: canMerge, message });
      } else if (message.includes('does not have an edit branch.') && inEditMode) {
        dispatch({ type: BRANCH_MERGED });
      } else {
        dispatch({ type: SAVE_BRANCH_EDIT, payload: ERROR });
      }
    }
  };
}

/**
 * Gets the branch meta data and sets if the branch can be mereged back to the original or not.
 */
function checkCanMergeBranch() {
  return async (dispatch, getState) => {
    try {
      const { workspace, displayBranch } = getState().network;
      const checkReq = new Request(
        `/api/workspace/${workspace}/branch/${displayBranch}/edit_branch`,
      );
      const resp = await checkReq.get();
      let canMerge = true;
      if (resp.data) {
        canMerge = !resp.data.conflict_with_origin;
      }
      dispatch({ type: CAN_MERGE_BRANCH, payload: canMerge });
    } catch (err) {
      // Default to true and allow save to fail and diplay the warning after
      dispatch({ type: CAN_MERGE_BRANCH, payload: true });
    }
  };
}

function togglePathEditMode(discardPathEdit) {
  return (dispatch) => {
    dispatch(setActivePanel('asset'));
    dispatch({ type: TOGGLE_PATH_EDIT_MODE, discardPathEdit });
  };
}

/**
 * Edit the location of a shunt device
 * @param {String} workspace The workspace the equipment is in
 * @param {String} branch The branch the equipment is in
 * @param {Strung} rdf_id The ID of the equipment being edited
 * @param {Number} longitude Longitude to move the icon to
 * @param {Number} latitude Latitude to move the icon to
 */
function editNodePosition(workspace, branch, rdf_id, longitude, latitude) {
  return editSingleEquipment(
    workspace,
    branch,
    'node',
    rdf_id,
    { position: { latitude, longitude } },
    'editNodePosition',
  );
}

/**
 * Edit the location of a shunt device
 * @param {String} workspace The workspace the equipment is in
 * @param {String} branch The branch the equipment is in
 * @param {String} type The type of the equipment (such as 'switch')
 * @param {Strung} rdf_id The ID of the equipment being edited
 * @param {Number} longitude Longitude to move the icon to
 * @param {Number} latitude Latitude to move the icon to
 */
function editShuntPosition(workspace, branch, type, rdf_id, longitude, latitude) {
  return editSingleEquipment(
    workspace,
    branch,
    type,
    rdf_id,
    { position: { latitude, longitude } },
    'editShuntPosition',
  );
}

const selectNewAsset = asset => (dispatch) => {
  dispatch({ type: SELECT_NEW_ASSET, payload: asset });
  dispatch(actions.setActivePanel('createDeviceOptions'));
};

/**
 * Set the feeder to add the new asset to
 * @param {String} feederID  UUID of the feeder
 */
const setNewAssetFeeder = feederID => ({ type: SET_NEW_ASSET_FEEDER, feeder: feederID });

const findAndSetNewAssetFeeder = (dispatch, getState) => {
  const { feeders } = getState();
  if (feeders) {
    const { selected } = feeders;
    if (selected) {
      const sortedFeeders = alphabetizeByKey(selected, 'name');
      dispatch(setNewAssetFeeder(sortedFeeders[0].id));
      if (sortedFeeders.length > 1) {
        dispatch(actions.setActivePanel('createDeviceOptions'));
      }
    }
  }
};

/*
  Toggles the status of the Add Node mode
  Toggle ON - Set new asset type to node, set the feeder id and show the feeder selection panel
              if there is more than 1 selected feeder
  Toggle OFF - Clear the new asset type and set the panel back to the asset panel.
*/
const toggleAddNodeMode = () => (dispatch, getState) => {
  if (getState().edit.newAsset && getState().edit.newAsset.id === 'node') {
    dispatch({ type: CLEAR_NEW_ASSET });
    dispatch(actions.setActivePanel('asset'));
  } else {
    dispatch({
      type: SELECT_NEW_ASSET,
      payload: { id: 'node', name: 'Connectivity Node', icon: 'node' },
    });
    findAndSetNewAssetFeeder(dispatch, getState);
  }
};

const togglePathCreateMode = () => (dispatch, getState) => {
  findAndSetNewAssetFeeder(dispatch, getState);
  dispatch(setActivePanel('asset'));
  dispatch({ type: TOGGLE_PATH_CREATE_MODE });
};

const clearSaveEditStatus = () => ({ type: CLEAR_SAVE_EDITS_STATUS });

/**
 * Recalculate the length of a line based on GIS length
 * @param {String} workspace The workspace the equipment is in
 * @param {String} branch The branch the equipment is in
 * @param {Strung} rdf_id The ID of the line being edited
 */
function recalculateLineLength(workspace, branch, rdf_id) {
  return async (dispatch, getState) => {
    const request = new Request(
      `/api/workspace/${workspace}/branch/${branch}/editor/line/${rdf_id}`,
    );
    dispatch({
      type: UPDATE_APPLY_DIFFERENCE,
      status: LOADING,
      updateType: 'editValues',
    });
    try {
      const { selectedProject } = getState().edit;
      const { data } = await request.patch({ length: null, project_id: selectedProject });
      const { networkInstance } = getState().network;
      networkInstance.applyDifferenceModel(data);
      dispatch({
        type: UPDATE_APPLY_DIFFERENCE,
        status: SUCCESS,
        updateType: 'editValues',
        network: networkInstance,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_APPLY_DIFFERENCE,
        status: ERROR,
        updateType: 'editValues',
      });
    }
  };
}

export const updateContainer = (type, id, update, updateType) => async (dispatch, getState) => {
  const { workspace, branch } = getState().network;
  const request = new Request(
    `/api/workspace/${workspace}/branch/${branch}/${type.toLowerCase()}/${id}`,
  );
  dispatch({
    type: UPDATE_APPLY_DIFFERENCE,
    status: LOADING,
    updateType,
  });

  try {
    // The response is a JSON diff model that we will apply to the app state
    const { data } = await request.patch(update);
    const { networkInstance } = getState().network;
    networkInstance.applyDifferenceModel(data);

    dispatch({
      type: UPDATE_APPLY_DIFFERENCE,
      status: SUCCESS,
      updateType,
      network: networkInstance,
    });

    dispatch(getContainersList());
  } catch (error) {
    dispatch({
      type: UPDATE_APPLY_DIFFERENCE,
      status: ERROR,
      updateType,
    });

    // Clear error after 5s
    setTimeout(() => {
      dispatch({
        type: UPDATE_APPLY_DIFFERENCE,
        status: INITIAL,
        updateType,
      });
    }, 5000);
  }
};

const clearPathModes = () => ({ type: CLEAR_PATH_MODES });

function duplicateAsset(asset_id, asset_class) {
  return async (dispatch) => {
    if (asset_class !== 'ACLineSegment') {
      return;
    }

    dispatch(actions.duplicateLine(asset_id));
  };
}
const setSelectedProject = (project_id) => ({ type: SET_SELECTED_PROJECT, payload: project_id });

const setSaveModal = (saveModalActive, modalType, newView, newLink) => ({
  type: SET_SAVE_MODAL, saveModalActive, modalType, newView, newLink,
});

export const networkEditActions = {
  editNodePosition,
  editShuntPosition,
  editSingleEquipment,
  updateContainer,
  toggleEditMode,
  togglePathEditMode,
  saveEdits,
  checkCanMergeBranch,
  clearSaveEditStatus,
  selectNewAsset,
  toggleAddNodeMode,
  setNewAssetFeeder,
  recalculateLineLength,
  togglePathCreateMode,
  editUsagePoint,
  addNewInstance,
  deleteReference,
  clearPathModes,
  duplicateAsset,
  setSelectedProject,
  setSaveModal,
};

const initialState = {
  pendingReq: {},
  inEditMode: false,
  inPathEditMode: false,
  inPathCreateMode: false,
  inAddNodeMode: false,
  discardPathEdit: false,
  editBranchReq: INITIAL,
  canMergeBranch: true,
  saveEditBranchReq: INITIAL,
  newAsset: undefined,
  branchMerged: false,
  selectedProject: null,
  saveModalActive: false,
  saveModalType: null,
  newView: null,
  newLink: null,
};

export default function NetworkEditReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_EDIT_MODE:
      return {
        ...state,
        inEditMode: action.status === SUCCESS ? !state.inEditMode : state.inEditMode,
        editBranchReq: action.status,
        inPathEditMode: false,
        discardPathEdit: false,
        inPathCreateMode: false,
        newAsset: null,
        selectedProject: null,
      };
    case UPDATE_SELECTED_BRANCH:
      return {
        ...state,
        inEditMode: action.inEditMode,
        branchMerged: false,
      };
    case CAN_MERGE_BRANCH:
      return {
        ...state,
        canMergeBranch: action.payload,
        editSaveMsg: action.message || null,
      };
    case BRANCH_MERGED:
      return {
        ...state,
        saveEditBranchReq: undefined,
        branchMerged: true,
      };
    case SAVE_BRANCH_EDIT:
      return {
        ...state,
        saveEditBranchReq: action.payload,
        branchMerged: false,
      };
    case TOGGLE_PATH_EDIT_MODE:
      return {
        ...state,
        inPathEditMode: !state.inPathEditMode,
        inPathCreateMode: false,
        discardPathEdit: action.discardPathEdit,
      };
    case TOGGLE_PATH_CREATE_MODE:
      return {
        ...state,
        inPathCreateMode: !state.inPathCreateMode,
        inPathEditMode: false,
        newAsset: null,
      };
    case CLEAR_SAVE_EDITS_STATUS:
      return {
        ...state,
        editSaveMsg: null,
        saveEditBranchReq: INITIAL,
        canMergeBranch: true,
      };
    case SELECT_NEW_ASSET:
      return {
        ...state,
        newAsset: action.payload,
        inPathEditMode: false,
        inPathCreateMode: false,
      };
    case SET_NEW_ASSET_FEEDER:
      return {
        ...state,
        newAssetFeeder: action.feeder,
      };
    case SET_SELECTED_ASSET:
    case SET_SELECTED_CONTAINER:
      return {
        ...state,
        newAsset: state.newAsset && state.newAsset.id === 'node' ? null : state.newAsset,
        inPathCreateMode:
          action.selectedJSCimAsset && action.selectedJSCimAsset.class !== 'ConnectivityNode'
            ? false
            : state.inPathCreateMode,
      };
    case DELETE_ASSET_SUCCESS:
      // Drop out of specific asset edit/add modes if user deletes something
      return {
        ...state,
        inPathCreateMode: false,
        inPathEditMode: false,
        newAsset: null,
      };
    case CLEAR_NEW_ASSET:
      return {
        ...state,
        newAsset: null,
      };
    case CLEAR_PATH_MODES:
      return {
        ...state,
        inPathEditMode: false,
        inPathCreateMode: false,
      };
    case SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: action.payload,
      };
    case SET_SAVE_MODAL:
      return {
        ...state,
        saveModalActive: action.saveModalActive,
        saveModalType: action.modalType,
        newView: action.newView,
        newLink: action.newLink,
      };
    default:
      return state;
  }
}
