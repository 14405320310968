import React, { PureComponent } from 'react';
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceArea,
  ReferenceLine,
  Line,
  Area,
} from 'recharts';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { getStartOfInterval } from 'components/ZoomableRangeSlider/intervals';
import ThemeContext from 'helpers/ThemeContext';
import { toISO, extendDataTimeRange, addMissingTimepoints } from 'helpers/utils';
import './TimeSeriesChart.scss';
import ResultsChartCard from './ResultsChartCard';
import PhaseBadgeContainer from '../../templates/partials/PhaseBadgeContainer';

class RegulatorTapChart extends PureComponent {
  state = {
    activePhases: {
      A: true,
      B: false,
      C: false,
    },
    hovered: null,
  };

  phaseColors = () => {
    const theme = typeof this.context === 'string' ? this.context : 'dark';

    return {
      A: '#639DD1',
      B: '#2dafa8',
      C: '#fd813b',
      ABC: theme === 'dark' ? '#FFFFFF' : '#262626',
    };
  }

  handleActivePhaseChange = (value) => {
    this.setState(prevState => ({ activePhases: { ...prevState.activePhases, ...value } }));
  }

  handleHoverChange = (value) => {
    this.setState({ hovered: value });
  }

  customTooltip = ({ payload, label }) => {
    const values = payload && payload[0] ? payload[0].payload : {};
    const { activePhases } = this.state;
    const phaseColors = this.phaseColors();
    const hasValue = name => values[`${name}_A`] !== undefined
                             || values[`${name}_B`] !== undefined
                             || values[`${name}_C`] !== undefined;
    const hasTapPosition = hasValue('tap_position');
    const hasTapPositionRange = hasValue('tap_position_range');

    const generateRows = phase => (
      <>
        <tr>
          <td>{`${phase} Max:`}</td>
          {!!hasTapPositionRange
            && <td style={{ color: phaseColors[phase] }}>{(values[`tap_position_range_${phase}`][1])}</td>}
        </tr>
        <tr>
          <td>{`${phase} Average:`}</td>
          {!!hasTapPosition
            && <td style={{ color: phaseColors[phase] }}>{(values[`tap_position_${phase}`])}</td>}
        </tr>
        <tr>
          <td>{`${phase} Min:`}</td>
          {!!hasTapPositionRange
            && <td style={{ color: phaseColors[phase] }}>{(values[`tap_position_range_${phase}`][0])}</td>}
        </tr>
      </>
    );

    const getFormatString = (aggregation) => {
      switch (aggregation) {
        case 'day':
          return 'YYYY/MM/DD';
        case 'month':
          return 'YYYY/MM';
        case 'year':
          return 'YYYY';
        case 'hour':
        default:
          return 'YYYY/MM/DD HH:mm';
      }
    };

    return (
      <div className="tooltip">
        <p><b>{moment.parseZone(label).format(getFormatString(values.aggregation))}</b></p>
        <table>
          <thead>
            <tr>
              <td>Phase</td>
              <td>Tap Position</td>
            </tr>
          </thead>
          <tbody>
            {activePhases.A && (values.tap_position_A !== undefined) && generateRows('A')}
            {activePhases.B && (values.tap_position_B !== undefined) && generateRows('B')}
            {activePhases.C && (values.tap_position_C !== undefined) && generateRows('C')}
          </tbody>
        </table>
      </div>
    );
  }

  generateLine = phase => (
    <Line
      key={`line_${phase}`}
      name={phase}
      stroke={this.state.activePhases[phase] ? this.phaseColors()[phase] : null}
      strokeWidth={this.state.hovered === phase ? 3 : 1}
      dataKey={`tap_position_${phase}`}
      isAnimationActive={false}
      dot={false}
      type="stepAfter"
    />
  );

  generateArea = phase => (
    <Area
      key={`area_${phase}`}
      name={`range_${phase}`}
      stroke={this.state.activePhases[phase] ? this.phaseColors()[phase] : null}
      strokeOpacity={0.25}
      fill={this.state.activePhases[phase] ? this.phaseColors()[phase] : null}
      fillOpacity={0.25}
      dataKey={`tap_position_range_${phase}`}
      isAnimationActive={false}
      type="stepAfter"
    />
  );

  getDisabledPhases(tapData) {
    return ['A', 'B', 'C', 'ABC'].filter(phase => !tapData.some(timePoint => `tap_position_${phase}` in timePoint));
  }

  get graphData() {
    const { maxRange, tapPositionTimeSeriesData } = this.props;
    let aggregation;
    let data = tapPositionTimeSeriesData.map((e) => {
      const newE = {
        timepoint: e.timepoint,
        aggregation: e.aggregation,
      };
      if (!aggregation) {
        aggregation = e.aggregation !== 'none' ? e.aggregation : 'hour';
      }
      const mapPhaseData = (phase) => {
        let avg; let min; let max;
        if (e[`tap_position_${phase}`]) {
          avg = e[`tap_position_${phase}`].avg;
          min = e[`tap_position_${phase}`].min;
          max = e[`tap_position_${phase}`].max;
        }

        newE[`tap_position_${phase}`] = avg;
        newE[`tap_position_range_${phase}`] = min !== undefined && max !== undefined ? [min, max] : undefined;
      };

      if (this.state.activePhases.A) {
        mapPhaseData('A');
      }
      if (this.state.activePhases.B) {
        mapPhaseData('B');
      }
      if (this.state.activePhases.C) {
        mapPhaseData('C');
      }

      return newE;
    });

    data = maxRange ? extendDataTimeRange(data, 'timepoint', maxRange.start, moment(maxRange.end).startOf(aggregation)) : data;
    data = addMissingTimepoints(data, 'timepoint');
    return data;
  }

  getActivePhaseColor = (active) => {
    const activePhase = Object.entries(active).find(([, isActive]) => isActive);
    return activePhase ? this.phaseColors()[activePhase[0]] : '#FFF';
  }

  render() {
    const { activePhases } = this.state;
    const {
      loading, tapPositionTimeSeriesData, highlightRange, timeBarZoomLevel,
    } = this.props;
    const phaseColors = this.phaseColors();
    const disabledPhases = this.getDisabledPhases(this.props.tapPositionTimeSeriesData);
    const activePhaseColor = this.getActivePhaseColor(activePhases);

    const xAxisFormatter = (aggregation) => {
      switch (aggregation) {
        case 'day':
          return 'YYYY/MM/DD';
        case 'month':
          return 'YYYY/MM';
        case 'year':
          return 'YYYY';
        case 'hour':
        default:
          return 'HH:mm';
      }
    };
    const data = this.graphData;
    const theme = typeof this.context === 'string' ? this.context : 'dark';

    const highlightStart = toISO(highlightRange.start);
    const highlightEnd = toISO(getStartOfInterval(highlightRange.end, timeBarZoomLevel));
    let cardState = 'initial';
    if (loading) {
      cardState = 'loading';
    } else if (tapPositionTimeSeriesData.length > 0) {
      cardState = 'loaded';
    }

    return (
      <ResultsChartCard
        title="Regulator Tap"
        className="time-series-chart"
        theme={theme}
        state={cardState}
      >
        <div className="legend" style={{ color: activePhaseColor }}>
          <div className="legend-entry">
            <div className="left-axis-box" style={{ borderTopColor: activePhaseColor }} />
            <div>Tap Position</div>
          </div>
        </div>
        <p className="y-axis-label">(Tap)</p>
        <div
          className={classNames({
            'chart-pane': true,
            'chart-pane--expanded': this.props.expanded,
          })}
        >
          <ResponsiveContainer height="100%" width="100%">
            <ComposedChart
              data={data}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#606060" />
              <XAxis
                dataKey="timepoint"
                stroke="#949899"
                scale="point"
                axisLine={false}
                tickFormatter={val => (
                  moment.parseZone(val).format(xAxisFormatter(data[0].aggregation))
                )}
              />
              <YAxis
                stroke="#949899"
                tickLine={false}
                width={50}
                domain={['auto', 'auto']}
              />
              <Tooltip content={this.customTooltip} />
              {
                ['A', 'B', 'C'].map(ph => this.generateLine(ph))
              }
              {
                ['A', 'B', 'C'].map(ph => this.generateArea(ph))
              }
              {highlightStart === highlightEnd ? (
                <ReferenceLine
                  x={highlightStart}
                  stroke="teal"
                />
              ) : (
                <ReferenceArea
                  x1={highlightStart}
                  x2={highlightEnd}
                  ifOverflow="visible"
                  fillOpacity={0.3}
                  fill="teal"
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <PhaseBadgeContainer
          activePhases={activePhases}
          disabledPhases={disabledPhases}
          phaseColors={phaseColors}
          onActivePhaseChange={this.handleActivePhaseChange}
          onHoverChange={this.handleHoverChange}
        />
      </ResultsChartCard>
    );
  }
}

RegulatorTapChart.contextType = ThemeContext;

RegulatorTapChart.defaultProps = {
  expanded: false,
};

RegulatorTapChart.propTypes = {
  tapPositionTimeSeriesData: PropTypes.array.isRequired,
  highlightRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
};

export default RegulatorTapChart;
