export const calculateSum = (values, divisor, precision) => {
  // Filter out any keys that aren't phases
  const onlyPhaseValues = Object.entries(values)
    .reduce((valueList, value) => {
      if ('ABC'.includes(value[0])) {
        return [...valueList, value[1]];
      }
      return valueList;
    }, []);

  let total = ' ';
  // Only sum up if there are actually values
  if (onlyPhaseValues.length && onlyPhaseValues.some(val => val !== undefined && val !== null)) {
    const sumOfValues = onlyPhaseValues.reduce((a, b) => a + b, 0);
    total = (sumOfValues / divisor).toFixed(precision || precision === 0 ? precision : 3);
  }
  return total;
};

export const transposePhaseAttributes = (attrs) => {
  const transposedAttrs = {};
  Object.entries(attrs).forEach(([phase, values]) => {
    Object.entries(values).forEach(([name, value]) => {
      if (!transposedAttrs[name]) {
        transposedAttrs[name] = {};
      }
      transposedAttrs[name][phase] = value;
    });
  });

  return transposedAttrs;
};

export const diffPerPhaseAttributes = (oldPhaseAttrs, newPhaseAttrs) => {
  const phaseDiffs = {};
  Object.keys(oldPhaseAttrs).forEach((phase) => {
    if (oldPhaseAttrs[phase] !== newPhaseAttrs[phase]) {
      phaseDiffs[phase] = true;
    }
  });
  return phaseDiffs;
};

const extractors = {
  calculateSum,
  transposePhaseAttributes,
  diffPerPhaseAttributes,
};
export default extractors;
