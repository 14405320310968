import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import withAuth from 'helpers/withAuth';
import { actions } from 'routes/WorkspaceLayout/routes/Network/modules/network';
import { eqLibActions } from '../modules/equipmentLibrary';

/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import EquipmentLibrary from '../components/EquipmentLibrary';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...eqLibActions,
    ...actions,
  }, dispatch),
});

const mapStateToProps = state => ({
  libraryLoadingState: state.equipmentLibrary.libraryLoadingState,
  library: state.equipmentLibrary.library,
  selectedEquipment: {
    type: state.equipmentLibrary.selectedEquipmentType,
    id: state.equipmentLibrary.selectedEquipmentID,
  },
  createInstanceReq: state.equipmentLibrary.createInstanceReq,
  expandedCategories: state.equipmentLibrary.expandedCategories,
  location: state.location,
  eqInfoCategories: state.equipmentLibrary.eqInfoCategories,
  deleteStatus: state.equipmentLibrary.deleteStatus,
  theme: state.global.theme,
  saveLibraryError: state.equipmentLibrary.saveLibraryError,
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EquipmentLibrary));
