/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import HelpTooltip from '../HelpTooltip';
import './RadioButtonGroup.scss';

class RadioButtonGroup extends PureComponent {
  handleChange = (e) => {
    this.props.onChange(e);
  }

  handleLabelInteraction = id => this.handleChange({ target: { value: id } });

  render() {
    return (
      <div
        className={`radio-btn-group radio-btn-group--${this.props.listType} ${this.props.theme}`}
        style={this.props.style}
      >
        {this.props.options.map(option => (
          <div className="option-group" key={option.id}>
            <input
              className="radio-btn-input"
              type="radio"
              value={option.id}
              id={`${this.props.id}-${option.id}`}
              name={this.props.id}
              onChange={this.handleChange}
              disabled={option.disabled}
              checked={this.props.value === option.id}
            />
            <div
              role="button"
              tabIndex={0}
              onClick={() => !option.disabled && this.handleLabelInteraction(option.id)}
              onKeyPress={({ key }) => (
                (key === 'Enter' && !option.disabled) && this.handleLabelInteraction(option.id)
              )}
              className="option-label-container"
            >
              <label htmlFor={`${this.props.id}-${option.id}`}>
                <div className="option-icon-group">
                  <div className={`radio-btn ${this.props.value === option.id ? 'radio-btn--selected' : ''} ${this.props.radioType}
                  ${option.invalid ? 'invalid' : ''}`}
                  >
                    <div className="radio-btn-fill" />
                  </div>
                  { option.tooltip && (<HelpTooltip message={option.tooltip} type="primary" />)}
                  <span>{option.label}</span>
                </div>
              </label>
              {option.body && <div className="option-label-body">{option.body}</div>}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

RadioButtonGroup.defaultProps = {
  theme: null,
  listType: 'row',
  radioType: 'default',
  style: null,
};

RadioButtonGroup.propTypes = {
  options: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  theme: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  listType: PropTypes.oneOf(['row', 'column']),
  radioType: PropTypes.oneOf(['primary', 'default']),
  style: PropTypes.object,
};

export default RadioButtonGroup;
