import React, { FunctionComponent, useState } from 'react';
import { ThemeProp } from 'types/index';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import { useRequest } from '@opusonesolutions/gridos-app-framework';
import { useToasts } from 'react-toast-notifications';

type ScheduleSourcePanelProps = {
    theme: ThemeProp,
    workspace: string,
    disabled: boolean,
}

const ScheduleSourcePanel: FunctionComponent<ScheduleSourcePanelProps> = ({
  theme,
  workspace,
  disabled,
}) => {
  const [sourceData, setSourceData] = useState<{[key: string]: string}>({});
  const [validData, setValidData] = useState(false);
  const { makeRequest: putScheduleSourceReq, loading } = useRequest(
    `/api/workspace/${workspace}/analytics-config/schedule-source`,
  );
  const { addToast } = useToasts();
  const saveScheduleSource = async () => {
    await putScheduleSourceReq({
      method: 'put',
      body: sourceData,
      onSuccess: () => {
        setSourceData({});
        addToast('Saved source url successfully', { appearance: 'success' });
      },
      onError: (data) => {
        let errorMSg = 'Failed to save source url';
        if (data?.response?.status && [502, 421].includes(data?.response?.status)) {
          errorMSg = 'Remote validation failed for URL';
        }
        addToast(errorMSg, { appearance: 'error' });
      },
    });
  };
  return (
    <div className="workspace-card-tab-panel" id="schedule-source-panel">
      <div className="gis-tab">
        <TextInput
          label="Name"
          value={sourceData.name}
          id="name"
          onChange={(e) => {
            setSourceData({ ...sourceData, name: e?.target?.value || '' });
          }}
          inputWidth="400px"
          theme={theme}
          disabled={disabled}
        />
        <TextInput
          label="Url"
          value={sourceData.url}
          id="url"
          onChange={(e) => {
            const urlValue = e?.target?.value || '';
            setSourceData({ ...sourceData, url: urlValue });
            try {
              const parsedUrl = new URL(urlValue);
              setValidData(parsedUrl.searchParams.entries().next().done ?? true);
            } catch (error) {
              setValidData(false);
            }
          }}
          theme={theme}
          invalid={!validData}
          disabled={disabled}
        />
        <Button
          label="Apply Changes"
          className="workspace-card-button"
          id="auth-button"
          onClick={() => saveScheduleSource()}
          theme={theme}
          type="primary"
          disabled={!validData || !sourceData.name || !sourceData.url || disabled}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ScheduleSourcePanel;
