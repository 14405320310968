import React, {
  FunctionComponent, ReactNode, ReactChild, useContext,
} from 'react';
import { ThemeProp } from 'types/index';
import Button from 'components/Button';
import ThemeContext from 'helpers/ThemeContext';

type ActionButtonsProps = {
    className?: string,
    headerContents?: ReactChild|ReactNode,
    onClickBackBtn?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void|null;
    onClickSubmitBtn: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void|null;
    disableSubmit?: boolean,
    submitLabel?: string,
    submitBtnId?: string,
    title?: string,
};
const ActionButtons: FunctionComponent<ActionButtonsProps> = ({
  className = '',
  onClickBackBtn = null,
  onClickSubmitBtn,
  disableSubmit = false,
  submitLabel = 'Submit',
  submitBtnId = 'submit-btn',
  title = '',
}) => {
  const theme = useContext(ThemeContext) as ThemeProp;
  return (
    <div className={`action-buttons ${className} ${theme}`} data-test="action-buttons">
      {
        onClickBackBtn && (
        <Button
          className="back-btn"
          id="back-btn"
          type="secondary"
          theme={theme}
          onClick={onClickBackBtn}
          label="Previous"
        />
        )
      }
      <Button
        className="submit-btn"
        label={submitLabel}
        id={submitBtnId}
        type="primary"
        theme={theme}
        disabled={disableSubmit}
        onClick={onClickSubmitBtn}
        title={title}
      />
    </div>
  );
};

export default ActionButtons;
