import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadForecastActions } from '../../routes/Network/modules/loadForecast';
/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import ScenarioSection from '../ScenarioSection';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(loadForecastActions, dispatch),
});

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  theme: state.global.theme,
  branch: state.network.branch,
  newBranchReq: state.network.newBranchReq,
  selectedFeeders: state.feeders.selected,
  allFeeders: state.feeders.list,
  view: state.global.view,
});

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioSection);
