import CustomCheckbox from 'components/CustomCheckbox';
import VoltageLimits from 'components/VoltageLimits';
import React, { FunctionComponent, useEffect } from 'react';
import { SimulationOptions } from 'types/analysis';
import { ANALYSIS_TYPES } from '../../helpers/NetworkHelpers';
import './OPFOptions.scss';

const { QSTS_OPF, HOSTING_CAPACITY, EV_CAPACITY } = ANALYSIS_TYPES;

const options = {
  dont_constrain_currents: {
    label: 'Ignore Current Constraints',
    id: 'dont_constrain_currents',
  },
  dont_constrain_powers: {
    label: 'Ignore Power Constraints',
    id: 'dont_constrain_powers',
  },
  dont_constrain_voltages: {
    label: 'Ignore Voltage Constraints',
    id: 'dont_constrain_voltages',
  },
};

type OPFOptionsProps = {
  simulationOptions: SimulationOptions,
  setSimulationOptions: React.Dispatch<React.SetStateAction<SimulationOptions>>,
  substationId?: string,
  disableButton: (disabled: boolean) => void,
  selectedAnalysisType: string,
}

const OPFOptions: FunctionComponent<OPFOptionsProps> = ({
  simulationOptions,
  setSimulationOptions,
  substationId,
  disableButton,
  selectedAnalysisType,
}) => {
  useEffect(() => {
    // set allow_violations to false by default
    setSimulationOptions(prev => ({ ...prev, allow_violations: false }));
  }, [setSimulationOptions]);

  return (
    <div className="opf-options-section">
      {[QSTS_OPF, HOSTING_CAPACITY, EV_CAPACITY].includes(selectedAnalysisType) && (
      <>
        <h3>Configure Constraints</h3>
        <div className="opf-options-item">
          <CustomCheckbox
            id="allow_violations"
            checked={simulationOptions.allow_violations}
            onClick={(event: React.MouseEvent) => {
              setSimulationOptions({
                ...simulationOptions,
                allow_violations: (event.target as HTMLInputElement).checked,
                dont_constrain_currents: false,
                dont_constrain_powers: false,
                dont_constrain_voltages: false,
              });
            }}
          />
          <p className="opf-options-item-label">Relax Constraints</p>
        </div>
        <div>
          {simulationOptions.allow_violations && Object.entries(options).map(([key, opts]) => (
            <div className="opf-options-item" key={key}>
              <>
                <CustomCheckbox
                  id={key}
                  checked={simulationOptions[key as keyof SimulationOptions] as boolean}
                  onClick={(event: React.MouseEvent) => setSimulationOptions({
                    ...simulationOptions,
                    [key]: (event.target as HTMLInputElement).checked,
                  })}
                />
                <p className="opf-options-item-label">
                  {' '}
                  {opts.label}
                  {' '}
                </p>
              </>
            </div>
          ))}
        </div>
      </>
      )}
      <VoltageLimits
        substationId={substationId}
        simulationOptions={simulationOptions}
        setSimulationOptions={setSimulationOptions}
        isAnalysis
        disableButton={disableButton}
      />
    </div>
  );
};

export default OPFOptions;
