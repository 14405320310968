import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import NumberInput from 'components/NumberInput';
import asyncActionStates from 'helpers/asyncActionStates';
import { perMeter } from 'helpers/units';
import {
  areAssetModelPropertiesChanged,
  getAssetModelProperties,
} from 'routes/WorkspaceLayout/routes/EquipmentLibrary/helpers/assetModelHelpers';
import {
  areBaseCableValuesUpdated,
  createBaseCableDiffModel,
  createEditDiffModel,
  getBaseCableInputValues,
  isBaseCableValid,
  getNewTapeShieldCable,
  renderCableInputs,
} from '../../../helpers/cablePanelHelpers';
import Helpers from '../../../helpers/EquipmentLibraryHelpers';

import DescriptionEditor from '../DescriptionEditor';
import CostEditor from '../CostEditor';
import ReliabilityMetrics from '../ReliabilityMetrics';
import PanelTabs from '../PanelTabs';

import '../common.scss';

class TapeShieldCablePanel extends Component {
  state = { ...(getNewTapeShieldCable()) }

  UNSAFE_componentWillMount() {
    this.setState({ ...this.getInputValues(this.props.selected) });
  }

  UNSAFE_componentWillReceiveProps({ createInstanceReq, selected }) {
    const { LOADING, SUCCESS } = asyncActionStates;
    const newSelected = this.props.selected !== selected;
    const saved = this.props.createInstanceReq === LOADING && createInstanceReq === SUCCESS;

    if (newSelected || saved) {
      this.setState({ ...this.getInputValues(selected) });
    }
  }

  getInputValues = (selected) => {
    let asset = { ...getNewTapeShieldCable() };

    if (selected) {
      const baseInputValues = getBaseCableInputValues(selected);
      const {
        tapeLap, tapeThickness,
      } = selected;
      asset = {
        ...baseInputValues,
        tapeLap: `${tapeLap}` || asset.tapeLap,
        tapeThickness: `${tapeThickness}` || asset.tapeThickness,
      };
    }

    return asset;
  }

  handleSave = () => {
    let diffModel = createBaseCableDiffModel(this.state);

    diffModel = {
      ...diffModel,
      tapeLap: parseFloat(this.state.tapeLap),
      tapeThickness: parseFloat(this.state.tapeThickness),
    };

    if (this.state.id === 'add') {
      this.props.handleCreate('tape_shield_cable_info', diffModel, 'TapeShieldCableInfo');
    } else {
      const { selected } = this.props;
      const editDiffModel = createEditDiffModel(selected, diffModel);
      this.props.handleEdit(selected.id, editDiffModel);
    }
  }

  handleInputChange = ({ id, value }) => this.setState({ [id]: value });

  handleSelectChange = (id, value) => this.setState({ [id]: value });

  handleCheckChange = (e) => {
    const { id } = e.target;
    this.setState(prevState => ({ [id]: !prevState[id] }));
  };

  valuesUpdated = (selected) => {
    if (!selected) return true;

    const numberUpdated = [
      'tapeLap',
      'tapeThickness',
    ].some(val => selected[val] !== parseFloat(this.state[val]));

    return areBaseCableValuesUpdated(this.state, selected) || numberUpdated;
  }

  shiftFactorValid = (isShiftFactorValid = false) => this.setState({ isValidShiftFactor: isShiftFactorValid });

  formValid = () => {
    const { state } = this;

    if (!this.valuesUpdated(this.props.selected)) {
      return false;
    }

    if (!isBaseCableValid(state)) {
      return false;
    }

    const positiveValue = val => (val?.toString().length ?? 0) > 0 && parseFloat(val) > 0;

    return (
      positiveValue(state.tapeLap)
      && positiveValue(state.tapeThickness)
      && this.state.isValidShiftFactor
    );
  };

  updateAssetModel = prop => this.setState(prevState => ({
    assetModel: {
      ...prevState.assetModel,
      ...prop,
    },
  }));

  render() {
    const isDisabled = (
      this.props.isAuthEnabled
      && ((this.state.id === 'add' && !this.props.permissions.has('create_equipment_type'))
      || (this.state.id !== 'add' && !this.props.permissions.has('edit_equipment_type'))
      || (this.props.match.params.branch === 'master' && !this.props.permissions.has('modify_network_as_built')))
    );

    const defaultProps = {
      theme: this.props.theme,
      disabled: isDisabled,
    };

    const inputDefaultProps = { ...defaultProps, onChange: this.handleInputChange };

    const tapeLap = Helpers.createDisplayObject('Tape Lap', 'tapeLap', this.state.tapeLap, '%');
    const tapeThickness = Helpers.createDisplayObject('Tape Thickness', 'tapeThickness', this.state.tapeThickness, 'm');

    return (
      <PanelTabs
        submitDisabled={!this.formValid() || isDisabled}
        onSubmit={this.handleSave}
        createInstanceReq={this.props.createInstanceReq}
        tabs={['General', 'Description', 'Costs', 'Reliability Metrics']}
        assetID={this.state.id}
        showSave
      >
        {[
          <div className="equipment-info-container" key={this.state.id}>
            <div className="right-panel">
              {this.props.children}
              <h2>Tape Shield Cable</h2>
              {renderCableInputs(
                inputDefaultProps,
                isDisabled,
                this.state,
                this.handleSelectChange,
                this.handleCheckChange,
              )}
              <NumberInput
                ge={1}
                le={100}
                {...inputDefaultProps}
                {...tapeLap}
                required
                inputStyle="eq-lib"
              />
              <NumberInput
                ge={0.000001}
                {...inputDefaultProps}
                {...tapeThickness}
                required
                inputStyle="eq-lib"
              />
            </div>
            <div className="column">
              <h2 className="column-title">Description</h2>
              <div className="markdown-body">
                <Markdown
                  escapeHtml
                  source={decodeURIComponent(this.state.description)}
                />
              </div>
            </div>
          </div>,
          <DescriptionEditor
            description={this.state.description}
            key={`${this.state.id}-description`}
            onChange={d => this.setState({ description: d })}
            isDisabled={isDisabled}
          />,
          <CostEditor
            assetModel={this.state.assetModel}
            assetModelDiff={areAssetModelPropertiesChanged(
              this.state.assetModel,
              getAssetModelProperties(this.props.selected?.AssetModel),
            )[1]}
            key={`${this.state.id}-costs`}
            onChange={prop => this.setState(prevState => ({
              assetModel: { ...prevState.assetModel, ...prop },
            }))}
            isDisabled={isDisabled}
            theme={this.props.theme}
            unit={perMeter}
            includePerUnitMaintenance
          />,
          <ReliabilityMetrics
            assetModel={this.state.assetModel || null}
            probabilityOfFailureEquation={this.state.assetModel.AssetFailureInfo.probabilityOfFailureEquation || null}
            mTTR={this.state.assetModel.AssetFailureInfo.mTTR}
            key={`${this.state.id}-reliability`}
            onChange={this.updateAssetModel}
            isDisabled={isDisabled}
            theme={this.props.theme}
            saveLibraryError={this.props.saveLibraryError}
            shiftFactorValid={this.shiftFactorValid}
          />,
        ]}
      </PanelTabs>
    );
  }
}

TapeShieldCablePanel.defaultProps = {
  selected: null,
  children: null,
  saveLibraryError: {},
};

TapeShieldCablePanel.propTypes = {
  children: PropTypes.object,
  handleCreate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  createInstanceReq: PropTypes.number.isRequired,
  selected: PropTypes.object,
  isAuthEnabled: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  saveLibraryError: PropTypes.object,
};

export default TapeShieldCablePanel;
