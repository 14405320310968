import React, {
  useState, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Request } from '@opusonesolutions/gridos-app-framework';
import IconButton from 'components/IconButton';
import Modal from 'components/Modal';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import DatePicker from 'components/DatePicker';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import nullable from 'helpers/nullablePropType';
import ThemeContext from 'helpers/ThemeContext';
import { alphabetizeByKey } from 'helpers/utils';
import { getProjects } from '../routes/Network/helpers/NetworkHelpers';

import './DropdownSections.scss';

const ProjectSection = ({
  addNumber,
  workspace,
  setSelectedProject,
  selectedProject,
}) => {
  const theme = useContext(ThemeContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [completionDate, setCompletionDate] = useState(moment.utc().format('YYYY-MM-DD'));
  const [options, setOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  async function fetchProjects() {
    const projects = await getProjects(
      workspace,
    );
    setOptions(
      alphabetizeByKey(
        Object.values(projects).map((proj) => ({ label: `${proj.name}`, value: proj.id })),
        'label',
      ),
    );
  }
  async function saveProject(projectName) {
    setErrorMessage(null);
    const request = new Request(`/api/workspace/${workspace}/construction_project`);
    let projectCreated = {};
    try {
      const { data } = await request.post({
        name: projectName,
        completion_date: completionDate,
      });
      projectCreated = data;
      if (projectCreated.project_id !== undefined) {
        setSelectedProject(projectCreated.project_id);
        setOptions([...options, { label: projectName, value: projectCreated.project_id }]);
        setModalOpen(false);
        setNewProjectName('');
      }
    } catch (error) {
      let nameMessage = ''; let dateMessage = '';
      if (error?.response?.data?.message.name) {
        nameMessage = error?.response?.data?.message.name[0];
      }
      if (error?.response?.data?.message.completion_date) {
        dateMessage = error?.response?.data?.message.completion_date[0];
      }
      setErrorMessage(`${nameMessage}\n${dateMessage}`);
    }
  }
  useEffect(() => {
    fetchProjects(workspace);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace]);

  const nameIsUnique = options.findIndex(proj => proj.label === newProjectName) === -1;

  const renderCreateButton = () => (
    <PermissionDisabledTip title="Create " theme={theme}>
      <IconButton
        icon="add"
        id="add-project-btn"
        onClick={() => setModalOpen(true)}
        theme={theme}
        tooltip="Create construction project"
      />
    </PermissionDisabledTip>
  );
  return (
    <div>
      <div className="branch-scenario-section scenario-section" id="scenario-selector-container">
        <div className="select-container project-container">
          <div className="select-top-row">
            <p className="select-label">
              {addNumber ? `${addNumber}. ` : ''}
              Project
            </p>
            <div className="buttons">
              {renderCreateButton()}
            </div>
          </div>
          <Select
            className="scenario-select"
            theme={theme}
            options={options}
            value={selectedProject}
            onChange={e => setSelectedProject(e?.value)}
            clearable
            searchable={false}
            placeholder="Select project"
            id="project-selector"
          />
        </div>
      </div>
      <Modal
        active={modalOpen}
        title="Create Project"
        onConfirm={() => saveProject(newProjectName)}
        onCancel={() => {
          setModalOpen(false);
          setNewProjectName('');
          setErrorMessage(null);
        }}
        disableConfirm={newProjectName.length === 0 || !nameIsUnique || !!errorMessage}
        height="290px"
        width="346px"
        theme={theme}
      >
        {/* This is needed to clear text input props after each creation */}
        {modalOpen && (
          <>
            <div className="project-input">
              <p>Name *</p>
              <TextInput
                id="name"
                value={newProjectName}
                onChange={e => {
                  setNewProjectName(e.target.value);
                  setErrorMessage(null);
                }}
                placeholder="Enter project name"
                required
                invalid={!nameIsUnique}
                validationMessage="Please select a unique project name"
                theme={theme}
              />
            </div>
            <div className="project-input">
              <p>Completion date</p>
              <DatePicker
                showArrows={false}
                clearable
                theme={theme}
                dateFormat="Y-m-d"
                options={{
                  altFormat: 'Y-m-d',
                }}
                onChange={e => {
                  setCompletionDate(e ? moment(e).format('YYYY-MM-DD') : null);
                  setErrorMessage(null);
                }}
                date={completionDate ? moment(completionDate) : null}
                useUTC
              />
            </div>
            <div className="project-error-message">{errorMessage}</div>
          </>
        )}
      </Modal>
    </div>
  );
};

ProjectSection.propTypes = {
  addNumber: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
  setSelectedProject: PropTypes.func.isRequired,
  selectedProject: nullable(PropTypes.number).isRequired,
};

export default ProjectSection;
