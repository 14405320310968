import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import TextInput from 'components/TextInput';

import asyncActionStates from 'helpers/asyncActionStates';
import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';

import { Request } from '@opusonesolutions/gridos-app-framework';

const AnalyticsConfig = ({
  disabled,
  displayAlertMessage,
  theme,
  workspace,
}) => {
  // Analytics Config State
  const [analyticsEdited, setAnalyticsEdited] = useState(false);
  const [analyticsSavingState, setAnalyticsSavingState] = useState(asyncActionStates.INITIAL);
  const { analyticsConfig, analyticsConfigLoading, refetchAnalyticsConfig } = useContext(WorkspaceSettingsContext);
  const [maxWorkers, setMaxWorkers] = useState('');
  useEffect(() => {
    setMaxWorkers(analyticsConfig.max_workers);
  }, [analyticsConfig]);

  const saveAnalyticsConfig = async () => {
    const requestUrl = `/api/workspace/${workspace}/analytics-config`;
    const request = new Request(requestUrl);
    setAnalyticsSavingState(asyncActionStates.LOADING);

    try {
      await request.put({
        max_workers: maxWorkers,
      });
      setAnalyticsSavingState(asyncActionStates.SUCCESS);
      setAnalyticsEdited(false);
      refetchAnalyticsConfig();
    } catch (error) {
      setAnalyticsSavingState(asyncActionStates.ERROR);
      displayAlertMessage(
        'Failed to save analytics configuration',
        'Could not save analytics configuration. Please try again.',
        null,
        () => saveAnalyticsConfig(),
      );
    }
  };

  return (
    <div className="workspace-card-tab-panel">
      <div className="column">
        <TextInput
          label="Max Workers"
          value={maxWorkers}
          id="maxWorkers"
          disabled={
            analyticsConfigLoading
            || analyticsSavingState === asyncActionStates.LOADING
            || disabled
          }
          onChange={(e) => {
            setAnalyticsEdited(true);
            setMaxWorkers(e.target.value);
          }}
          inputWidth="300px"
          theme={theme}
        />
      </div>
      <div className="column column-right">
        <Button
          label="Apply Changes"
          className="workspace-card-button analytics-save"
          disabled={
            !analyticsEdited
            || !maxWorkers
            || analyticsSavingState === asyncActionStates.LOADING
            || disabled
          }
          onClick={saveAnalyticsConfig}
          loading={analyticsSavingState === asyncActionStates.LOADING}
          theme={theme}
          type="primary"
        />
      </div>
    </div>
  );
};

AnalyticsConfig.defaultProps = {
  disabled: true,
};

AnalyticsConfig.propTypes = {
  disabled: PropTypes.bool,
  displayAlertMessage: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
};

export default AnalyticsConfig;
