import React, {
  FunctionComponent, useContext, useEffect, useState,
} from 'react';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { ThemeProp } from 'types/index';
import ThemeContext from 'helpers/ThemeContext';
import { alphabetizeByKey, isEmptyObject } from 'helpers/utils';
import Accordion from 'components/Accordion';
import getControlModeName, { controlModesList, controlModesWithAnalysis, validControlModesByAsset } from 'helpers/cim/getControlModeName';
import RadioButtonGroup from 'components/RadioButtonGroup';
import HelpTooltip from 'components/HelpTooltip';
import Select from 'components/Select';
import _ from 'lodash';
import { TYPE_MAP } from '../../helpers/NetworkHelpers';

type AnalysisMapping = {
  asset_id: string,
  analysis_id: string|null,
}[];

type ControlModesProps = {
  selectedContainers?: { id: string }[],
  workspace: string,
  branch: string,
  onChange: (args?: { [key: string]: string }, analysisMappings?: AnalysisMapping) => void,
  analyses?: [],
  selectedAnalysisInterval: number,
  setControlModesInValid: (value?: boolean) => void
};

type ControlModeResults = [{
    cim_class: string,
    control_mode: string,
    id: string,
    linked_equipment_container: null|string,
    name: string,
  }
];

type AssetsByControlModes = {
  [asset: string]: {
    [id: string]: {
      cim_class: string,
      control_mode: string,
      id: string,
      linked_equipment_container: null|string,
      name: string,
      selectedAnalysis: null|string,
      typeName: string,
    }
  }
};

const assetSupportPrevAnalysis = ['EnergySource', 'EquivalentSubstation'];

const controlModesWithAnalysisSelect = {
  ...controlModesWithAnalysis,
  selectAnalysis: 'Select analysis',
};

const getHelpTooptip = (assetClass: string, controlMode: string) => {
  if (controlMode === 'analysisSchedule') {
    return 'This asset is configured to use the results of a previous analysis on the same interval for its schedule. '
      + 'Select which analysis should be used or change the control mode.';
  }

  return `Only assets with a ${getControlModeName(assetClass, 'analysisSchedule')} control mode need an analysis selected`;
};

const modesSection = (
  controlModes: [{[key: string]: string}],
  theme: ThemeProp, assetsByControlModes: AssetsByControlModes,
  loading: boolean, setAssetsByControlModes: (args: AssetsByControlModes) => void,
  analyses: [],
  selectedAnalysisInterval: number,
  includeAssetSupportPrevAnalysis = false,
) => {
  const gridColums = includeAssetSupportPrevAnalysis ? controlModes?.length - 1 : controlModes?.length;
  const extraFragmentation = includeAssetSupportPrevAnalysis ? 2 : 0;
  return (
    <div>
      <div className="asset-group">
        <div className="asset-name" />
        <div
          className="radio-button-group controlmode-types"
          style={{ gridTemplateColumns: `170px repeat(${gridColums}, 1fr) ${extraFragmentation}fr`, padding: '0' }}
        >
          {
            controlModes?.map((mode: {[id: string]: string}) => (
              <span key={mode.id}>{mode.name}</span>))
          }
        </div>
      </div>
      { loading ? (
        <LoadingSkeleton template="line" width={100} theme={theme} count={4} />
      ) : (
        <Accordion
          theme={theme}
          tabs={Object.entries(assetsByControlModes)?.filter(([assetName]) => {
            if (includeAssetSupportPrevAnalysis) {
              return assetSupportPrevAnalysis.includes(assetName);
            }
            return !assetSupportPrevAnalysis.includes(assetName);
          })?.map(
            ([key, assetObj], index: number) => {
              const controlModeByAssetType = _.uniqBy(Object.values(assetObj), 'control_mode');
              const controlModeRadioOptions = controlModes.filter((mode) => mode.id !== 'selectAnalysis');
              const controlmodesDisabledOption = controlModeRadioOptions.map((mode) => {
                const unsupportedModeSelected = !validControlModesByAsset?.[key]?.includes(mode.id)
                  && Object.values(assetObj)?.some((asset) => !validControlModesByAsset?.[key]
                    ?.includes(asset.control_mode));
                return ({
                  ...mode,
                  disabled: (mode.id === 'analysisSchedule' && Object.values(assetObj)
                    ?.some((asset) => !asset?.linked_equipment_container))
                    || (!validControlModesByAsset?.[key]?.includes(mode.id)),
                  tooltip: unsupportedModeSelected ? `This mode is not supported on ${key} assets` : null,
                  invalid: unsupportedModeSelected,
                });
              });
              return ({
                key: index,
                title: Object.values(assetObj)[0]?.typeName ?? key,
                tabHeader:
          <div className="radio-button-group">
            <RadioButtonGroup
              options={controlmodesDisabledOption}
              id={`asset-type-selector-${key}`}
              key={`asset-type-selector-${key}`}
              theme={theme}
              value={controlModeByAssetType && controlModeByAssetType.length === 1
                ? controlModeByAssetType[0].control_mode : ''}
              listType="row"
              onChange={({ target }: {[key: string]: { value: string}}) => {
                const assetByIds = Object.values(assetObj).reduce((obj, assetType) => {
                  obj = {
                    ...obj,
                    [assetType.id]: {
                      ...assetType,
                      control_mode: target.value === 'analysisSchedule' && !assetType?.linked_equipment_container
                        ? assetType.control_mode : target.value,
                    },
                  };
                  return obj;
                }, {});
                setAssetsByControlModes({
                  ...assetsByControlModes,
                  [key]: {
                    ...assetObj,
                    ...assetByIds,
                  },
                });
              }}
              radioType="primary"
              style={{ gridTemplateColumns: `repeat(${gridColums}, 1fr) ${extraFragmentation}fr` }}
            />
          </div>,
                tabBody:
            alphabetizeByKey(Object.values(assetObj), 'name')?.map((asset: AssetsByControlModes[0][0]) => {
              const optionswithValidAnalysis = controlModeRadioOptions.map((mode) => {
                const unsupportedModeSelected = !validControlModesByAsset?.[key]?.includes(asset.control_mode)
                  && !validControlModesByAsset?.[key]?.includes(mode.id);
                return (
                  {
                    ...mode,
                    disabled: (mode.id === 'analysisSchedule' && !asset?.linked_equipment_container)
                              || (!validControlModesByAsset?.[key]?.includes(mode.id)),
                    tooltip: unsupportedModeSelected ? `Selected control mode is invalid for ${asset.name}` : null,
                    invalid: unsupportedModeSelected,
                  });
              });
              return (
                <div className="asset-group" key={asset.id}>
                  <div className="asset-name">{asset.name}</div>
                  <div className="radio-button-group" style={{ gridTemplateColumns: `${gridColums}fr ${extraFragmentation}fr`, display: 'grid' }}>
                    <RadioButtonGroup
                      options={optionswithValidAnalysis}
                      id={`asset-selector-${asset.id}`}
                      key={`asset-selector-${asset.id}`}
                      theme={theme}
                      value={asset.control_mode}
                      listType="row"
                      onChange={({ target }: {[key: string]: { value: string}}) => {
                        const newAssetObj = {
                          ...assetsByControlModes,
                          [key]: {
                            ...assetObj,
                            [asset.id]: {
                              ...asset,
                              control_mode: target.value,
                              selectedAnalysis: target.value === 'analysisSchedule' ? asset.selectedAnalysis : null,
                            },
                          },
                        };
                        setAssetsByControlModes(newAssetObj);
                      }}
                      radioType="primary"
                      style={{ gridTemplateColumns: `repeat(${gridColums}, 1fr)`, display: 'grid' }}
                    />
                    { includeAssetSupportPrevAnalysis && (
                      <div className="flex-centered">
                        <Select
                          value={asset?.selectedAnalysis}
                          id={`analysis-selector-${asset.id}`}
                          options={analyses?.filter(
                            (analysis: { [key: string]: [string|null]}) => analysis.containers
                              .includes(asset?.linked_equipment_container),
                          ).map(
                            (x: {id: string, name: string, interval : number}) => ({
                              value: x.id,
                              label: x.name,
                              disabled: x.interval !== selectedAnalysisInterval,
                            }),
                          )}
                          theme={theme}
                          onChange={(analysis) => {
                            const newAssetObj = {
                              ...assetsByControlModes,
                              [key]: { ...assetObj, [asset.id]: { ...asset, selectedAnalysis: analysis?.value } },
                            };
                            setAssetsByControlModes(newAssetObj);
                          }}
                          clearable={false}
                          disabled={asset.control_mode !== 'analysisSchedule'}
                          type="secondary"
                          invalid={asset.control_mode === 'analysisSchedule' && !asset?.selectedAnalysis}
                          noOptionsMessage="No analyses available"
                        />
                        <HelpTooltip message={getHelpTooptip(asset.cim_class, asset.control_mode)} />
                      </div>
                    )}
                  </div>
                </div>
              );
            }),
              });
            },
          )}
        />
      )}
    </div>
  );
};
const ControlModesMenu: FunctionComponent<ControlModesProps> = ({
  workspace,
  branch,
  selectedContainers,
  onChange,
  analyses = [],
  selectedAnalysisInterval,
  setControlModesInValid,
}) => {
  const theme = useContext(ThemeContext);
  const [assetsByControlModes, setAssetsByControlModes] = useState({} as AssetsByControlModes);
  const {
    data: ControlmodesData, loading,
  } = useRequestEffect<ControlModeResults>({
    url: `/api/workspace/${workspace}/branch/${branch}/asset/control_modes`,
    method: 'get',
    params: {
      container: selectedContainers?.map((x) => x.id),
    },
    refetchOnChange: [workspace, branch, selectedContainers],
    blockRequest: () => !(
      workspace && branch && selectedContainers && selectedContainers && selectedContainers?.length
    ),
    onSuccess: (results) => {
      const resultsData = results as ControlModeResults;
      const allAssets = alphabetizeByKey(resultsData, 'cim_class')?.reduce((list: AssetsByControlModes, asset: ControlModeResults[0]) => {
        const assetName = TYPE_MAP as {[assetClass: string]: {[key: string]: string}};
        const assetClass = asset.cim_class;
        list[assetClass] = {
          ...list[assetClass],
          [asset.id]: { ...asset, selectedAnalysis: null, typeName: assetName[assetClass]?.name },
        };
        return list;
      }, {});
      setAssetsByControlModes(allAssets);
    },
  });
  const controlModes = alphabetizeByKey(Object.entries(controlModesList)
    ?.map(([key, value]) => ({ id: key, name: value })), 'id');
  const modesWithAnalysis = alphabetizeByKey(Object.entries(controlModesWithAnalysisSelect)
    ?.map(([key, value]) => ({ id: key, name: value })), 'id');
  useEffect(() => {
    if (!isEmptyObject(assetsByControlModes)) {
      setControlModesInValid(false);
      const assetAnalysisMapping = [] as AnalysisMapping;
      const controlModesUpdated = ControlmodesData?.reduce(
        (newMode: {[id: string]: string}, mode) => {
          const assignedMode = assetsByControlModes[mode.cim_class][mode.id].control_mode;
          newMode[mode.id] = assignedMode;
          if (!assignedMode || (assignedMode && !validControlModesByAsset?.[mode.cim_class]?.includes(assignedMode))) {
            setControlModesInValid(true);
          }
          if (assignedMode === 'analysisSchedule') {
            assetAnalysisMapping.push({
              asset_id: mode.id, analysis_id: assetsByControlModes[mode.cim_class][mode.id].selectedAnalysis,
            });
          }
          return newMode;
        }, {},
      );
      onChange(controlModesUpdated, assetAnalysisMapping);
    }
  }, [assetsByControlModes, ControlmodesData, onChange, setControlModesInValid]);
  return (
    <div className="controlmodes-selection">
      <p className="title-text">Control Modes</p>
      {modesSection(modesWithAnalysis, theme, assetsByControlModes, loading,
        setAssetsByControlModes, analyses, selectedAnalysisInterval, true)}
      {modesSection(controlModes, theme, assetsByControlModes, loading,
        setAssetsByControlModes, analyses, selectedAnalysisInterval)}
    </div>
  );
};
export default ControlModesMenu;
