import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import asyncActionStates from 'helpers/asyncActionStates';
import fileExportSave from 'helpers/FileDownload';
import { Request } from '@opusonesolutions/gridos-app-framework';
import ErrorPage from './ErrorPage';
import './ErrorDetails.scss';

/**
 * Creates the contents of the validation error details modal. Creates one
 * list of errors based on the selected profile.
 */
const ErrorDetails = ({
  applyFixers,
  fixersRunning,
  theme,
  validationRunning,
  workspace,
  branch,
  workspaceErrors,
  displayAlertMessage,
}) => {
  const handleFixClick = () => {
    applyFixers(workspace, workspaceErrors);
  };
  const [downloadReportLoadingState, setDownloadReportLoadingState] = useState(asyncActionStates.INITIAL);

  const downloadValidationErrorsReport = async () => {
    setDownloadReportLoadingState(asyncActionStates.LOADING);
    const request = new Request(`/api/workspace/${workspace}/branch/${branch}/validate/report`);
    try {
      const { data, headers } = await request.postRequestToGetFile();
      fileExportSave(data, headers);
      setDownloadReportLoadingState(asyncActionStates.SUCCESS);
    } catch (err) {
      displayAlertMessage(
        'Failed to download Network Validation Report',
        'Could not downlod any Errors/Warnings. Please try again.',
        null,
        () => downloadValidationErrorsReport(),
      );
      setDownloadReportLoadingState(asyncActionStates.ERROR);
    }
  };

  return (
    <div className="error-details-container">
      <div className="error-details-contents">
        <ErrorPage
          theme={theme}
          workspaceErrors={workspaceErrors}
        />
      </div>
      <div className="navigation-container">
        <Button
          onClick={downloadValidationErrorsReport}
          loading={downloadReportLoadingState === asyncActionStates.LOADING}
          disabled={downloadReportLoadingState === asyncActionStates.LOADING || validationRunning}
          label="Download Report"
          className="report-download-btn"
          type="primary"
          theme={theme}
        />
        <Button
          disabled={fixersRunning || validationRunning}
          className="next-button"
          onClick={handleFixClick}
          theme={theme}
          type="primary"
        >
          <span className="button-text-next">Apply Fixers</span>
          {!fixersRunning && <i className="material-icons">arrow_forward</i>}
          {fixersRunning && <i className="material-icons rotate">refresh</i>}
        </Button>
      </div>
    </div>
  );
};

ErrorDetails.defaultProps = {
  fixersRunning: false,
  workspaceErrors: null,
  validationRunning: false,
};

ErrorDetails.propTypes = {
  fixersRunning: PropTypes.bool,
  workspaceErrors: PropTypes.object,
  applyFixers: PropTypes.func.isRequired,
  displayAlertMessage: PropTypes.func.isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  validationRunning: PropTypes.bool,
};

export default ErrorDetails;
