import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Select from 'components/Select';
import asyncActionStates from 'helpers/asyncActionStates';

import { Request } from '@opusonesolutions/gridos-app-framework';
import {
  amps, kVA, kW, kVAr, kVln,
} from 'helpers/units';
import valueCheck from 'routes/WorkspaceLayout/routes/Network/components/SlidingInfoPanel/AssetPanel/helpers/valueCheck';
import valueExtractors from 'routes/WorkspaceLayout/routes/Network/components/SlidingInfoPanel/AssetPanel/helpers/valueExtractors';
import { isDefined } from 'helpers/utils';
import PerPhaseContainer from '../../templates/partials/PerPhaseContainer';
import ProbabilityOfFailure from './ProbabilityOfFailure';
import PerPhaseRow from '../../templates/partials/PerPhaseRow';

import ContinuousTimeSeriesChart from './ContinuousTimeSeriesChart';
import RegulatorTapChart from './RegulatorTapChart';

import './ResultsSection.scss';

// Information about each variable that is displayed
const VARIABLES = {
  i: {
    label: 'Current',
    key: 'i',
    unit: amps,
    type: 'number',
    getTotal: valueExtractors.calculateSum,
  },
  apparentPower: {
    label: 'Apparent Power',
    key: 'apparentPower',
    unit: kVA,
    divisor: 1000,
    type: 'number',
    getTotal: valueExtractors.calculateSum,
  },
  actualP: {
    label: 'Real Power',
    key: 'actualP',
    unit: kW,
    divisor: 1000,
    type: 'number',
    getTotal: valueExtractors.calculateSum,
  },
  actualQ: {
    label: 'Reactive Power',
    key: 'actualQ',
    unit: kVAr,
    divisor: 1000,
    type: 'number',
    getTotal: valueExtractors.calculateSum,
  },
  pLosses: {
    label: 'Real Losses',
    key: 'pLosses',
    unit: kW,
    divisor: 1000,
    type: 'number',
    getTotal: valueExtractors.calculateSum,
  },
  qLosses: {
    label: 'Reactive Losses',
    key: 'qLosses',
    unit: kVAr,
    divisor: 1000,
    type: 'number',
    getTotal: valueExtractors.calculateSum,
  },
  powerFactor: {
    label: 'Power Factor',
    key: 'powerFactor',
    type: 'number',
    getTotal: () => '',
  },
  voltages: {
    key: 'voltages',
    divisor: 1000 * Math.sqrt(3),
    getTotal: () => '',
    label: 'Voltage',
    type: 'number',
    unit: kVln,
  },
  tapSteps: {
    key: 'tapSteps',
    getTotal: () => '',
    type: 'number',
    label: 'Tap Position',
    precision: 0,
  },
};

const GRAPH_LABELS = {
  p: 'Real Power',
  p_loss: 'Real Power Losses',
  q: 'Reactive Power',
  q_loss: 'Reactive Power Losses',
  tap: 'Tap Positions',
};
const GRAPH_DATA = {
  p: {
    title: 'Real Power',
    tooltipTitle: 'Real Power',
    unit: kW,
  },
  p_loss: {
    title: 'Real Power Losses',
    tooltipTitle: 'Real Power Losses',
    unit: kW,
  },
  q: {
    title: 'Reactive Power',
    tooltipTitle: 'Reactive Power',
    unit: kVAr,
  },
  q_loss: {
    title: 'Reactive Power Losses',
    tooltipTitle: 'Reactive Power Losses',
    unit: kVAr,
  },
};

function _updateTapPositionValues(collatedData, phaseName, ratioTapChanger, tapPosition, i) {
  const value = tapPosition[i].tap_values[ratioTapChanger[phaseName].id];
  if (phaseName === 'ABC' || phaseName === 'ABCN') {
    collatedData[i].tap_position_A = value;
    collatedData[i].tap_position_B = value;
    collatedData[i].tap_position_C = value;
  } else {
    collatedData[i][`tap_position_${phaseName}`] = value;
  }
}

const TransformerResultsSection = ({
  xfmrID,
  equipmentContainer,
  asset: {
    ratio_tap_changer_attributes: tapChangers,
  },
  timeRange,
  maxRange,
  expanded,
  newPanelValues,
  pqTimeSeriesData,
  pqTimeSeriesStatus,
  theme,
  workspace,
  branch,
  scenarioID,
  analysisName,
  violations,
  results,
  tapResults,
  timeBarZoomLevel,
  subHourInterval,
}) => {
  const graphVariables = ['p', 'q', 'p_loss', 'q_loss'];
  const hasTapChanger = Object.keys(tapChangers).length > 0;
  if (hasTapChanger) {
    // If no tap changer, cannot view tap changer chart
    graphVariables.push('tap');
  }

  const [selectedGraphVariable, setSelectedGraphVariable] = useState(graphVariables[0]);

  const [tapResultsLoadingState, setTapResultsLoadingState] = useState(asyncActionStates.INITIAL);
  const [tapPositionTimeSeriesData, setTapPositionTimeSeriesData] = useState([]);

  const loadResults = () => {
    let didCancel = false;

    async function fetchTapPositionResults() {
      setTapResultsLoadingState(asyncActionStates.LOADING);
      // Cannot do this through axios directly without setting up some custom serializers.
      // Stick to our original method for now
      const url = `/api/workspace/${workspace}/branch/${branch}/power-flow-results/tap-changer`;
      const params = {
        feeder: equipmentContainer,
        tap_changer: Object.values(tapChangers).map(rtc => rtc.id),
        // when bucketing by days or greater in the backend, we need
        // to know what the local timezone is so we know where the local day
        // starts and ends.
        start_date: maxRange.start.toISOString(true),
        end_date: maxRange.end.toISOString(true),
        scenario_id: scenarioID,
        analysis_name: analysisName,
      };
      const request = new Request(url);

      try {
        const { data } = await request.get({ params });
        if (didCancel) {
          // Cancelled before the request finished so do nothing
          setTapResultsLoadingState(asyncActionStates.CANCELLED);
          return;
        }

        Object.keys(tapChangers).forEach((phase) => {
          for (let i = 0; i < data.length; i += 1) {
            _updateTapPositionValues(data, phase, tapChangers, data, i);
          }
        });

        data.forEach(entry => delete entry.tap_values);
        setTapPositionTimeSeriesData(data);
        setTapResultsLoadingState(asyncActionStates.SUCCESS);
      } catch (error) {
        if (error.response.status === 404) {
          setTapPositionTimeSeriesData([]);
          setTapResultsLoadingState(asyncActionStates.SUCCESS);
        } else {
          setTapResultsLoadingState(asyncActionStates.ERROR);
        }
      }
    }

    if (hasTapChanger) {
      fetchTapPositionResults();
    }
    return () => { didCancel = true; };
  };

  useEffect(
    loadResults,
    [
      workspace,
      branch,
      scenarioID,
      analysisName,
      xfmrID,
      maxRange,
      hasTapChanger,
      tapChangers,
      equipmentContainer,
    ],
  );
  const isSingleInterval = timeRange.end && timeRange.start
    && timeRange.start.clone().add(subHourInterval, 'minutes') > timeRange.end;
  const hideTable = !isSingleInterval || !valueCheck.hasValues(Object.keys(VARIABLES), results);

  return (
    <div className="asset-panel-values results-section">
      <h4>Analysis Results</h4>
      {!hideTable
        && (
        <PerPhaseContainer showTotal>
          {Object.values(VARIABLES).map((variable) => {
            const values = {};
            ['A', 'B', 'C'].forEach(phase => {
              if (variable.key === 'tapSteps') {
                values[phase] = tapResults[phase]?.avg;
              } else if (isDefined(results?.[variable.key]?.[`${phase}_avg`])) {
                values[phase] = results?.[variable.key]?.[`${phase}_avg`];
              }
            });
            return (
              <PerPhaseRow
                id={variable.key}
                key={variable.key}
                violations={violations}
                {...{
                  ...variable,
                  phases: newPanelValues.phase,
                  values,
                }}
                timeRange={timeRange}
              />
            );
          })}
        </PerPhaseContainer>
        )}
      <>
        { isSingleInterval && (
        <ProbabilityOfFailure
          maxRange={maxRange}
          workspace={workspace}
          branch={branch}
          feeder={equipmentContainer}
          scenario={scenarioID}
          analysisName={analysisName}
          assetID={xfmrID}
        />
        )}
        <h4>Choose parameter to visualize over time</h4>
        <Select
          clearable={false}
          name="graphValue"
          value={selectedGraphVariable}
          options={graphVariables.map(value => ({ label: GRAPH_LABELS[value], value }))}
          searchable={false}
          theme={theme}
          width={215}
          onChange={({ value }) => setSelectedGraphVariable(value)}
        />
        {(selectedGraphVariable !== 'tap') && (
          <ContinuousTimeSeriesChart
            dataKey={selectedGraphVariable}
            highlightRange={timeRange}
            maxRange={maxRange}
            pqTimeSeriesData={pqTimeSeriesData}
            {...GRAPH_DATA[selectedGraphVariable]}
            expanded={expanded}
            loading={pqTimeSeriesStatus === asyncActionStates.LOADING}
            timeBarZoomLevel={timeBarZoomLevel}
          />
        )}
        {selectedGraphVariable === 'tap' && (
          <RegulatorTapChart
            highlightRange={timeRange}
            maxRange={maxRange}
            tapPositionTimeSeriesData={tapPositionTimeSeriesData}
            expanded={expanded}
            loading={tapResultsLoadingState === asyncActionStates.LOADING}
            timeBarZoomLevel={timeBarZoomLevel}
          />
        )}
      </>
    </div>
  );
};

TransformerResultsSection.defaultProps = {
  violations: {},
};

TransformerResultsSection.propTypes = {
  xfmrID: PropTypes.string.isRequired,
  equipmentContainer: PropTypes.string.isRequired,
  asset: PropTypes.object.isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  timeRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  newPanelValues: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  pqTimeSeriesData: PropTypes.array.isRequired,
  pqTimeSeriesStatus: PropTypes.number.isRequired,
  scenarioID: PropTypes.string.isRequired,
  analysisName: PropTypes.string.isRequired,
  violations: PropTypes.object,
  results: PropTypes.object.isRequired,
  tapResults: PropTypes.object.isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  subHourInterval: PropTypes.number.isRequired,
};

export default TransformerResultsSection;
