import React, { Component } from 'react';
import PropTypes from 'prop-types';
import nullable from 'helpers/nullablePropType';
import Select from 'components/Select';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import ButtonToggle from 'components/ButtonToggle';
import Button from 'components/Button';
import asyncActionStates from 'helpers/asyncActionStates';
import DelayedModal from 'components/DelayedModal';
import { MODAL_TYPES } from 'helpers/EditMode';
import browserHistory from 'routes/history';
import { ActivityLogContext } from 'contexts/ActivityLogContext';
import { ImportsContext } from 'routes/ImportCIM/modules/importsContext';
import { ACTIVITY_LOG_STATUS } from 'routes/WorkspaceLayout/routes/Network/helpers/NetworkHelpers';
import BranchSection from './BranchSection';
import EditModeRibbon from './containers/EditModeRibbonContainer';
import ImportWizardNav from './ImportWizardNav';
import ScenarioSection from './containers/ScenarioSectionContainer';
import AnalysisSection from './containers/AnalysisSectionContainer';
import NetworkNavExpandedMenu from './NetworkNavLinks/NetworkNavExpandedMenu';
import WorkspaceSection from './WorkspaceSection';
import ProjectSection from './ProjectSection';

import './NetworkTopNav.scss';

const {
  PENDING, RUNNING, CANCELING, POSTPROCESSING,
} = ACTIVITY_LOG_STATUS;

class NetworkTopNav extends Component {
  state = {
    exitModalActive: false,
  };

  componentDidMount() {
    this.props.actions.fetchBranches(this.props.params.workspace);
    this.setView();
  }

  componentDidUpdate(prevProps) {
    const { LOADING, ERROR } = asyncActionStates;
    if (prevProps.newBranchReq === LOADING && this.props.newBranchReq === ERROR) {
      this.props.actions.displayAlertMessage(
        'Version Creation Failed',
        'An error occured while creating a new network version. Please try again.',
      );
    }

    if (prevProps.params.workspace !== this.props.params.workspace
      || prevProps.view !== this.props.view) {
      this.props.actions.fetchBranches(this.props.params.workspace);
    }

    if (!prevProps.editBranchReq === asyncActionStates.ERROR
      && this.props.editBranchReq === asyncActionStates.ERROR
      && this.state.exitModalActive) {
      this.setState({ exitModalActive: false });
    }
  }

  hasPendingImports = (importContext) => {
    const { imports: { pending }, initialized } = importContext;
    return pending.length || !initialized;
  }

  disabledMessage = (analysesInProgress, importContext) => {
    let disabledMsg = 'You do not have edit permissions';
    if (this.hasPendingImports(importContext)) {
      disabledMsg = 'You cannot enter edit mode while there are pending imports in the workspace';
    }
    if (analysesInProgress) {
      disabledMsg = 'You cannot enter edit mode while analyses are in progress';
    }
    return disabledMsg;
  }

  getRoutes = () => {
    const branch = this.props.params.branch || 'master';
    return ({
      gis: `/${this.props.params.workspace}/${branch}/gis`,
      apiDocs: '/api-docs',
      cim: `/${this.props.params.workspace}/${branch}/cim`,
      import: `/${this.props.params.workspace}/${branch}/import`,
      library: `/${this.props.params.workspace}/${branch}/library`,
      workspaces: '/?canGoBack=true',
      comparison: `/${this.props.params.workspace}/${branch}/comparison`,
      overview: `/${this.props.params.workspace}`,
      scenario: `/${this.props.params.workspace}/${branch}/scenario`,
    });
  }

  setView = () => {
    let view = Object.keys(this.getRoutes()).find(route => this.props.location.pathname.includes(route));
    if (view === undefined) view = 'overview';
    this.props.actions.setView(view);
  }

  viewOptions = () => ([
    { label: 'Import & Validation', value: 'import' },
    { label: 'Scenario Generation', value: 'scenario' },
    { label: 'Analysis', value: 'gis' },
    { label: 'Results Comparison', value: 'comparison' },
    { label: 'Equipment Library', value: 'library' },
    { label: 'CIM Data', value: 'cim' },
    { label: 'Workspace Overview', value: 'overview' },
  ].filter(Boolean));

  isActive = prop => (prop ? 'active' : 'inactive');

  handleMenuSelection = (id) => {
    if (!this.props.inEditMode) {
      this.handleSwitchViews(id);
      this.props.actions.setSaveModal(false, null, id);
    } else {
      this.props.actions.setSaveModal(true, MODAL_TYPES.VIEW, id);
      this.props.actions.checkCanMergeBranch();
    }
    return true;
  };

  handleSwitchViews = (id, link = null) => {
    const newLink = link || this.getRoutes()[id];
    browserHistory.push(newLink);
    this.props.actions.setView(id);
  };

  handleSaveModalOpen = (
    modalType,
    newBranchName = null,
    newWorkspaceName = null,
  ) => {
    this.setState({ newBranchName, newWorkspaceName });
    this.props.actions.setSaveModal(true, modalType);
    this.props.actions.checkCanMergeBranch();
  };

  closeSave = (cancel) => {
    this.props.actions.setSaveModal(false, null, this.props.newView, this.props.newLink);

    if (cancel) this.props.actions.setSaveModal(false, null, this.props.view);
  }

  enterEditor = () => {
    if (!this.props.inEditMode) this.props.actions.toggleEditMode();
  }

  render() {
    return (
      <ImportsContext.Consumer>
        {(importContext) => (
          <ActivityLogContext.Consumer>
            {(logContext) => {
              const analysesInProgress = logContext.logEntries
                .filter(entry => [PENDING, RUNNING, CANCELING, POSTPROCESSING].includes(entry.status)).length > 0;
              const canEdit = (!this.props.isAuthEnabled
                 || (this.props.permissions.has('modify_network')
                 && !(this.props.params.branch === 'master'
                 && !this.props.permissions.has('modify_network_as_built'))))
                 && !this.hasPendingImports(importContext)
                 && !analysesInProgress;
              if (this.props.view === 'none') {
                return <div>loading</div>;
              }
              return (
                <div className="gis-top-nav">
                  <div className="gis-top-nav--container">
                    <div className="gis-top-nav--left">
                      <WorkspaceSection
                        actions={this.props.actions}
                        permissions={this.props.permissions}
                        isAuthEnabled={this.props.isAuthEnabled}
                        theme={this.props.theme}
                        workspace={this.props.params.workspace}
                        inEditMode={this.props.inEditMode}
                        saveModalOpen={this.handleSaveModalOpen}
                        handleMenuSelection={this.handleMenuSelection}
                        params={this.props.params}
                        view={this.props.view}
                      />
                      <div className="view-selector-container" id="view-selector-container">
                        <div className="select-container select-container--first">
                          <div className="view-top-row">
                            <p className="select-label">2. View</p>
                          </div>
                          <Select
                            theme={this.props.theme}
                            options={this.viewOptions()}
                            value={this.props.view}
                            onChange={e => this.handleMenuSelection(e.value)}
                            clearable={false}
                            searchable={false}
                            id="view-selector"
                          />
                        </div>
                      </div>
                      {['gis', 'library', 'import', 'scenario'].includes(this.props.view) && (
                      <BranchSection
                        workspace={this.props.params.workspace}
                        branch={this.props.params.branch}
                        branches={this.props.branches}
                        newBranchReq={this.props.newBranchReq}
                        createBranch={this.props.actions.createBranch}
                        updateSelectedBranch={this.props.actions.updateSelectedBranch}
                        deleteBranch={this.props.actions.deleteBranch}
                        permissions={this.props.permissions}
                        theme={this.props.theme}
                        toggleEditMode={this.props.actions.toggleEditMode}
                        displayAlertMessage={this.props.actions.displayAlertMessage}
                        view={this.props.view}
                        inEditMode={this.props.inEditMode}
                        saveModalOpen={this.handleSaveModalOpen}
                        title="3. Network Version"
                      />
                      )}
                      {this.props.view === 'gis' && !this.props.inEditMode && (
                      <div className="scenario-analysis" id="scenario-analysis">
                        <ScenarioSection
                          workspace={this.props.params.workspace}
                          canCreate={!this.props.isAuthEnabled || this.props.permissions.has('alter_qsts_scenarios')}
                          canDelete={(!this.props.isAuthEnabled || this.props.permissions.has('delete_qsts_scenarios')) && !analysesInProgress}
                          canEdit={(!this.props.isAuthEnabled || this.props.permissions.has('alter_qsts_scenarios')) && !analysesInProgress}
                          view={this.props.view}
                          addNumber="4"
                          scenario={this.props.selectedScenario}
                          scenarios={this.props.scenarios}
                        />
                        <AnalysisSection />
                      </div>
                      )}
                      {this.props.view === 'gis' && this.props.inEditMode && (
                      <div className="scenario-analysis" id="scenario-analysis">
                        <ProjectSection
                          addNumber="4"
                          workspace={this.props.params.workspace}
                          selectedProject={this.props.selectedProject}
                          setSelectedProject={this.props.actions.setSelectedProject}
                        />
                      </div>
                      )}
                      {this.props.view === 'import' && (
                      <ImportWizardNav
                        branch={this.props.params.branch}
                        path={this.props.location.pathname}
                        workspace={this.props.params.workspace}
                      />
                      )}
                    </div>
                    <div id="edit-mode-toggle" className="edit-mode-toggle">
                      {this.props.inEditMode && (
                      <div className="save-as-button">
                        <Button
                          type="primary"
                          id="save-edits"
                          label="Create Version Copy"
                          onClick={() => this.handleSaveModalOpen(MODAL_TYPES.SAVEAS)}
                          theme={this.props.theme}
                        />
                      </div>
                      )}
                      {this.props.view === 'gis' && (
                      <PermissionDisabledTip
                        key="editor-mode"
                        title="Editor Mode"
                        hide={canEdit}
                        theme={this.props.theme}
                        message={this.disabledMessage(analysesInProgress, importContext)}
                      >
                        <ButtonToggle
                          leftLabel="Analysis"
                          rightLabel="Editor"
                          leftActive={!this.props.inEditMode}
                          onClickLeft={this.handleSaveModalOpen}
                          onClickRight={this.enterEditor}
                          height={36}
                          disabled={!canEdit}
                        />
                      </PermissionDisabledTip>
                      )}
                      {!this.props.inEditMode && (
                      <NetworkNavExpandedMenu
                        handleMenuSelection={this.handleMenuSelection}
                        theme={this.props.theme}
                        permissions={this.props.permissions}
                        isAuthEnabled={this.props.isAuthEnabled}
                      />
                      )}
                    </div>
                  </div>
                  {this.props.inEditMode && (
                  <EditModeRibbon
                    workspace={this.props.params.workspace}
                    addingAsset={this.props.addAssetRequest === asyncActionStates.LOADING}
                    savingAssetEdit={this.props.editAssetRequest === asyncActionStates.LOADING}
                    editBranchLoading={this.props.editBranchReq === asyncActionStates.LOADING}
                    editBranchError={this.props.editBranchReq === asyncActionStates.ERROR}
                    saveEditBranchError={this.props.saveEditBranchReq === asyncActionStates.ERROR}
                    saveEditBranchLoading={this.props.saveEditBranchReq === asyncActionStates.LOADING}
                    saveModalActive={this.props.saveModalActive}
                    exitModalActive={this.state.exitModalActive}
                    closeSave={this.closeSave}
                    modalType={this.props.saveModalType}
                    switchToBranch={this.state.newBranchName}
                    changeToView={this.props.newView}
                    switchToView={this.handleSwitchViews}
                    switchToWorkspace={this.state.newWorkspaceName}
                  />
                  )}
                  <DelayedModal
                    active={!this.props.inEditMode && this.props.editBranchReq === asyncActionStates.LOADING}
                    width="250px"
                    theme={this.props.theme}
                    showFooter={false}
                    showHeader={false}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <p>Entering Edit Mode... </p>
                      <i className="material-icons rotate">refresh</i>
                    </div>
                  </DelayedModal>
                </div>
              );
            }}
          </ActivityLogContext.Consumer>
        )}
      </ImportsContext.Consumer>
    );
  }
}

NetworkTopNav.defaultProps = {
  branches: [],
  newBranchReq: 0,
  scenarios: [],
  addAssetRequest: 0,
  editAssetRequest: 0,
  editBranchReq: 0,
  saveEditBranchReq: 0,
  selectedScenario: '',
};

NetworkTopNav.propTypes = {
  params: PropTypes.shape({
    workspace: PropTypes.string,
    branch: PropTypes.string,
    equipmentId: PropTypes.string,
    equipmentType: PropTypes.string,
  }).isRequired,
  actions: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  branches: PropTypes.array,
  newBranchReq: PropTypes.number,
  theme: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  addAssetRequest: PropTypes.number,
  editAssetRequest: PropTypes.number,
  editBranchReq: PropTypes.number,
  saveEditBranchReq: PropTypes.number,
  location: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired,
  selectedScenario: PropTypes.string,
  scenarios: PropTypes.array,
  selectedProject: nullable(PropTypes.number).isRequired,
  saveModalActive: PropTypes.bool.isRequired,
  saveModalType: nullable(PropTypes.string).isRequired,
  newView: nullable(PropTypes.string).isRequired,
  newLink: nullable(PropTypes.string).isRequired,
};

export default NetworkTopNav;
