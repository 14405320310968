import { Request } from '@opusonesolutions/gridos-app-framework';

export const safeGetErrorMessage = (error) => {
  let errorMessage = '';
  try {
    if (error && error.response && error.response.data && error.response.data.errors) {
      errorMessage = error.response.data.errors.reduce((message, curr) => {
        if (typeof curr.message === 'string') {
          message += `${curr.message}\n`;
        }
        return message;
      }, '');
    } else if (error && error.response && error.response.data && error.response.data.message) {
      if (typeof error.response.data.message === 'string') {
        errorMessage = error.response.data.message;
      } if (error.response.data.message === Object(error.response.data.message)) {
        const [errorMessages] = Object.values(error.response.data.message);
        [errorMessage] = errorMessages;
      }
    }
  } catch (ex) {
    errorMessage = '';
  }

  if (!errorMessage) {
    errorMessage = 'Unknown Error';
  }
  return errorMessage.trim();
};
export const initialScheduleData = {
  variables: {},
  datapoints: [],
};
export const fetchAssetSchedule = async (workspace, branch, assetID, scenario,
  maxRange, timeBarZoomLevel, scheduleType) => {
  const request = new Request(
    `/api/workspace/${workspace}/branch/${branch}/asset_schedule/${assetID}`,
  );
  const response = await request.get({
    params: {
      scenario_id: scenario,
      start_date: maxRange.start.toISOString(),
      end_date: maxRange.end.toISOString(),
      schedule_type: scheduleType,
      aggregation: timeBarZoomLevel,
    },
  });
  return response;
};

const helpers = { safeGetErrorMessage, initialScheduleData, fetchAssetSchedule };
export default helpers;
