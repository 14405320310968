import React, {
  FunctionComponent, useContext, useState, useEffect,
} from 'react';
import ThemeContext from 'helpers/ThemeContext';
import Button from 'components/Button';
import HelpTooltip from 'components/HelpTooltip';
import { ANALYSIS_TYPES, getAllBatterySizingResults } from '../../../helpers/NetworkHelpers';
import ActivityModal from '../ActivityLogPanel/ActivityModal';

type ReportListProps = {
  workspace: string;
  branch: string;
  containers: { id: string; }[];
  selectedScenario: string;
  inEditMode: boolean;
  selectedAnalysis: {
    id: string;
    type: string;
    name: string;
  };
  subHourInterval: number;
  downloadPowerFlowReport: () => Promise<void>;
  generatePowerflowReport: () => Promise<void>;
  downloadHostingCapacityReport: () => Promise<void>;
  downloadCostTrackingReport: () => Promise<void>;
  anyResults: boolean;
  analysisRunning: boolean;
  containerInAnalysis: boolean;
  timeRange: {
    start: moment.Moment,
    end: moment.Moment,
  }
}

const getResultsTooltip = (hasRes: boolean, jobActive: boolean, jobType: string, validRange?: boolean) => {
  if (jobActive) {
    return `${jobType} results are not available while the selected analysis is running`;
  } if (hasRes && !validRange && jobType === 'Hosting capacity') {
    return `Specify a time range less than 1 week to download the ${jobType} report`;
  } if (hasRes) {
    return '';
  }

  return 'No results available';
};

const ReportList: FunctionComponent<ReportListProps> = ({
  workspace,
  branch,
  containers,
  selectedScenario,
  inEditMode,
  selectedAnalysis,
  subHourInterval,
  downloadPowerFlowReport,
  generatePowerflowReport,
  downloadHostingCapacityReport,
  downloadCostTrackingReport,
  anyResults,
  analysisRunning,
  containerInAnalysis,
  timeRange,
}) => {
  const theme = useContext(ThemeContext);
  const [downloadingPFReport, setDownloadingPFReport] = useState(false);
  const [downloadingHCReport, setDownloadingHCReport] = useState(false);
  const [downloadingCTReport, setDownloadingCTReport] = useState(false);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [batteryResults, setBatteryResults] = useState(null);
  const [batteryModalActive, setBatteryModalActive] = useState(false);

  useEffect(() => {
    async function fetchBatteryAnalysisResults() {
      setResultsLoading(true);
      try {
        const rawBatteryResults = await getAllBatterySizingResults(
          workspace,
          branch,
          containers.map(x => x.id),
          selectedScenario,
          selectedAnalysis.name,
        );
        setBatteryResults(rawBatteryResults);
        setResultsLoading(false);
      } catch (err) {
        setResultsLoading(false);
      }
    }

    if (selectedAnalysis && selectedAnalysis.type === ANALYSIS_TYPES.BATTERY_SIZING) {
      fetchBatteryAnalysisResults();
    }
  }, [workspace, branch, containers, selectedScenario, selectedAnalysis]);

  const isValidHCReportTimeRange = () => {
    // disable HC reports > 2 weeks selected
    const { start, end } = timeRange;
    if (start && end) {
      return start.clone().add(1, 'weeks') >= end;
    }

    return false;
  };

  return (
    <>
      <h3>Reports</h3>
      {!inEditMode && (
      <>
        <div className="powerflow-btn-row">
          <div className="label">
            Powerflow
            <HelpTooltip message={`
              Downloads a powerflow report for the selected time range.
              For long time ranges, results will be aggregated.`}
            />
          </div>
          <Button
            disabled={
              !selectedAnalysis
              || !anyResults
              || analysisRunning
              || downloadingPFReport
              || !containerInAnalysis
              || ![
                ANALYSIS_TYPES.POWERFLOW,
                ANALYSIS_TYPES.QSTS,
                ANALYSIS_TYPES.QSTS_OPF,
              ].includes(selectedAnalysis.type)
            }
            onClick={async () => {
              setDownloadingPFReport(true);
              await downloadPowerFlowReport();
              setDownloadingPFReport(false);
            }}
            loading={downloadingPFReport}
            label="Download"
            className="report-download-btn"
            title={getResultsTooltip(anyResults, analysisRunning, 'Powerflow')}
            theme={theme}
          />
        </div>
        <div className="hourly-powerflow-btn-row">
          <div className="label">
            Generate Hourly PF Report
            <HelpTooltip message={`
              Generates an hour-by-hour report for the selected time range.
              This may take a long time and is currently only supported at hourly intervals`}
            />
          </div>
          <Button
            disabled={
              !selectedAnalysis
              || subHourInterval !== 60
              || !anyResults
              || analysisRunning
              || downloadingPFReport
              || !containerInAnalysis
              || ![
                ANALYSIS_TYPES.QSTS,
                ANALYSIS_TYPES.QSTS_OPF,
              ].includes(selectedAnalysis.type)
            }
            onClick={generatePowerflowReport}
            label="Generate"
            className="report-download-btn"
            title={getResultsTooltip(anyResults, analysisRunning, 'Powerflow')}
            theme={theme}
          />
        </div>
        <div className="hosting-capacity-btn-row">
          <div className="label">
            Hosting Capacity
            <HelpTooltip message={`
              Downloads an hour-by-hour report for the selected time range. 
              This report is currently only available for timeranges of 14 days or less.
            `}
            />
          </div>
          <Button
            onClick={async () => {
              setDownloadingHCReport(true);
              await downloadHostingCapacityReport();
              setDownloadingHCReport(false);
            }}
            disabled={
              !selectedAnalysis
              || !anyResults
              || analysisRunning
              || downloadingHCReport
              || !containerInAnalysis
              || !isValidHCReportTimeRange()
              || selectedAnalysis.type !== ANALYSIS_TYPES.HOSTING_CAPACITY
            }
            label="Download"
            loading={downloadingHCReport}
            className="report-download-btn"
            title={getResultsTooltip(
              anyResults,
              analysisRunning,
              'Hosting capacity',
              isValidHCReportTimeRange(),
            )}
            theme={theme}
          />
        </div>
        <div className="battery-sizing-btn-row">
          <div className="label">Battery Sizing</div>
          <Button
            onClick={() => {
              setBatteryModalActive(true);
            }}
            disabled={
              !selectedAnalysis
              || !batteryResults
              || analysisRunning
              || selectedAnalysis.type !== ANALYSIS_TYPES.BATTERY_SIZING
            }
            label="Open"
            className="report-download-btn"
            title={getResultsTooltip(
              !!batteryResults,
              analysisRunning,
              'Battery sizing',
              true,
            )}
            theme={theme}
          />
        </div>
      </>
      )}
      <div className="cost-tracking-btn-row">
        <div className="label">Network Change and Costing</div>
        <Button
          onClick={async () => {
            setDownloadingCTReport(true);
            await downloadCostTrackingReport();
            setDownloadingCTReport(false);
          }}
          disabled={downloadingCTReport}
          label="Download"
          loading={downloadingCTReport}
          className="report-download-btn"
          title={getResultsTooltip(
            true,
            false,
            'Network change and costing',
            true,
          )}
          theme={theme}
        />
      </div>
      {batteryModalActive && (
        <ActivityModal
          toggleModal={setBatteryModalActive}
          batteryResults={batteryResults}
          theme={theme}
          resultsLoading={resultsLoading}
          workspace={workspace}
          branch={branch}
        />
      )}
    </>
  );
};

export default ReportList;
