import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import withAuth from 'helpers/withAuth';
import { actions } from '../modules/network';
import { updateContainerFilter } from '../modules/feeders';
/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import NetworkLeftRail from '../components/NetworkLeftRail/NetworkLeftRail';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...actions,
    updateContainerFilter,
  }, dispatch),
});

const mapStateToProps = (state, ownProps) => ({
  branch: state.network.branch,
  containerTree: state.feeders.tree,
  filter: state.feeders.filter,
  networkGeoJSON: state.network.networkGeoJSON,
  panelData: state.network.filteredPanel,
  selectedAssetID: state.network.selectedAssetID,
  selectedAssetViewModelClass: state.network.selectedAssetViewModelClass,
  selectedFeeders: state.feeders.selected,
  deleteStatus: state.network.deleteStatus,
  leftRailPanel: state.network.leftRailPanel,
  pendingReq: state.network.pendingReqs.networkReq,
  feedersPending: state.feeders.pendingReq.feederReq,
  requestStatus: state.network.requestStatus,
  assetTypeVisibility: state.network.assetTypeVisibility,
  containerList: state.feeders.list,
  theme: state.global.theme,
  ...ownProps,
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(NetworkLeftRail));
