import React, {
  FunctionComponent, useContext, useEffect,
} from 'react';
import { useParams } from 'react-router';
import GridLayout from 'layouts/GridLayout';
import ThemeContext from 'helpers/ThemeContext';
import NetworkTopNav from '../../../containers/NetworkTopNavContainer';
import './ScenarioGeneration.scss';
import FeederPanel from './FeederPanel';
import ScenarioSchedules from './ScenarioSchedules';

const ScenarioGenerationPanels: FunctionComponent = (props: any) => {
  const workspace = props.match?.params?.workspace;
  const branch = props.match?.params?.branch;
  const theme = useContext(ThemeContext);
  useEffect(() => {
    props.actions.getContainersList(null, workspace, branch);
  }, [workspace, branch, props.actions]);
  return (
    <>
      <NetworkTopNav params={useParams()} />
      <div className={`scenario-generation ${theme}`} data-test="scenario-generation">
        <GridLayout
          className="scenario-view"
        >
          <div className="one-six grid-columns">
            <div className="single-column feeder-panel">
              <FeederPanel
                containerTree={props.containerTree}
                theme={theme}
                pendingReq={props.pendingReq}
              />
            </div>
            <div className="single-column schedule-panel">
              <ScenarioSchedules
                workspace={workspace}
                branch={branch}
                theme={theme}
              />
            </div>
          </div>
        </GridLayout>
      </div>
    </>
  );
};
export default ScenarioGenerationPanels;
