import * as d3 from 'd3';

export const checkQueue = (job_id, logEntries) => {
  let jobQueued = false;
  if (logEntries.filter(entry => entry.job_id === job_id)) {
    jobQueued = true;
  }
  return jobQueued;
};

export const violationCategory = {
  Voltage: { label: 'Voltage', param: 'voltage' },
  Current: { label: 'Current', param: 'current' },
  ApparentPower: { label: 'Apparent Power', param: 'apparent_power' },
  RealPower: { label: 'Real Power', param: 'real_power' },
  ReactivePower: { label: 'Reactive Power', param: 'reactive_power' },
  Energy: { label: 'Energy', param: 'energy' },
};

const violationCategoryMap = [
  ['under_v_min', violationCategory.Voltage.label],
  ['over_v_max', violationCategory.Voltage.label],
  ['over_s', violationCategory.ApparentPower.label],
  ['over_i', violationCategory.Current.label],
  ['over_p_charge_limit', violationCategory.RealPower.label],
  ['over_p_discharge_limit', violationCategory.RealPower.label],
  ['over_q_charge_limit', violationCategory.ReactivePower.label],
  ['over_q_discharge_limit', violationCategory.ReactivePower.label],
  ['under_min_energy', violationCategory.Energy.label],
  ['over_max_energy', violationCategory.Energy.label],
  ['over_q', violationCategory.ReactivePower.label],
];
export const violationTypes = new Map(violationCategoryMap);

export const violationKeys = Object.keys(violationCategory);

export const violationValues = Object.values(violationCategory);

export const violationsSelected = violationKeys.reduce((violations, v) => {
  violations[v] = true;

  return violations;
}, {});

export const violationsMagCreator = (allViolations) => {
  const violations = violationValues.map((val) => val.label).reduce((all, type) => {
    // get array of all the violation values for the type
    if (allViolations[type]) {
      const type_violations = allViolations[type];
      // Determine optimal bins of equal size, target ~ 10
      const histogram = d3.histogram().thresholds(10);
      const bins = histogram(type_violations);
      const dict_bin_value = {};
      // Create a bin_limits array with the upper limit of each bin.
      const bin_limits = [];
      for (let i = 0; i < bins.length; i += 1) {
        const upper_limit = bins[i].x1;
        const { length } = bins[i];
        bin_limits.push(upper_limit);
        dict_bin_value[upper_limit] = length;
      }
      all[type] = [...bin_limits].map((val) => ({
        name: parseFloat((0 + (val / 100)).toFixed(3)),
        value: dict_bin_value[val],
      }));
    } else {
      all[type] = [];
    }
    return all;
  }, {});
  return violations;
};

export const violationsCountCreator = (violationsMag) => {
  const results = violationValues.map((val) => val.label).map(type => {
    const value = violationsMag[type].reduce(
      (percent_total, percentOver) => percent_total + percentOver.value, 0,
    );
    return {
      name: type,
      value,
    };
  }, {});
  return results;
};
