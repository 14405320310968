import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import asyncActionStates from 'helpers/asyncActionStates';

import { Request } from '@opusonesolutions/gridos-app-framework';
import { kVln } from 'helpers/units';
import valueCheck from 'routes/WorkspaceLayout/routes/Network/components/SlidingInfoPanel/AssetPanel/helpers/valueCheck';
import { ANALYSIS_TYPES } from '../../../../../helpers/NetworkHelpers';
import PerPhaseContainer from '../../templates/partials/PerPhaseContainer';
import PerPhaseRow from '../../templates/partials/PerPhaseRow';
import HostingCapacityChart from './HostingCapacityChart';
import NodeVoltageTimeSeriesChart from './NodeVoltageTimeSeriesChart';

import './ResultsSection.scss';

// Information about each variable that is displayed
const VARIABLES = {
  v: {
    key: 'voltages',
    divisor: 1000 * Math.sqrt(3),
    getTotal: () => '',
    label: 'Voltage',
    type: 'number',
    unit: kVln,
  },
  puVoltage: {
    key: 'puVoltage',
    getTotal: () => '',
    label: 'Voltage per unit',
    unit: '',
    type: 'number',
  },
  angle: {
    key: 'voltageAngles',
    getTotal: () => '',
    label: 'Angle',
    type: 'number',
    unit: '°',
  },
};

const NodeResultsSection = ({
  nodeID,
  equipmentContainer,
  branch,
  expanded,
  maxRange,
  newPanelValues,
  scenarioID,
  selectedAnalysis,
  analysisName,
  timeRange,
  workspace,
  violations,
  results,
  timeBarZoomLevel,
  subHourInterval,
}) => {
  const [resultsLoadingState, setResultsLoadingState] = useState(asyncActionStates.INITIAL);
  const [nodeTimeSeriesData, setNodeTimeSeriesData] = useState([]);
  const [hcResultsLoadingState, setHCResultsLoadingState] = useState(asyncActionStates.INITIAL);
  const [hcResults, setHCResults] = useState([]);
  const [hcAggregation, setHCAggregation] = useState('hour');
  const hcChartMode = selectedAnalysis.type === 'EV_CAPACITY' ? 'load' : 'generation';
  const isNodalAnalysis = [
    ANALYSIS_TYPES.EV_CAPACITY, ANALYSIS_TYPES.HOSTING_CAPACITY, ANALYSIS_TYPES.BATTERY_SIZING,
  ].includes(selectedAnalysis.type);
  const isHostingCapacity = [
    ANALYSIS_TYPES.EV_CAPACITY, ANALYSIS_TYPES.HOSTING_CAPACITY,
  ].includes(selectedAnalysis.type);

  const loadResults = () => {
    let didCancel = false;

    async function fetchResults() {
      setResultsLoadingState(asyncActionStates.LOADING);
      const url = `/api/workspace/${workspace}/branch/${branch}/power-flow-results/node`;
      const request = new Request(url);

      try {
        const { data } = await request.get({
          params: {
            node_id: nodeID,
            feeder: equipmentContainer,
            start_date: maxRange.start.toISOString(),
            end_date: maxRange.end.toISOString(),
            scenario_id: scenarioID,
            analysis_name: analysisName,
          },
        });
        if (didCancel) {
          // Cancelled before the request finished so do nothing
          setResultsLoadingState(asyncActionStates.CANCELLED);
          return;
        }
        setNodeTimeSeriesData(data);
        setResultsLoadingState(asyncActionStates.SUCCESS);
      } catch (error) {
        if (error?.response?.status === 404) {
          setNodeTimeSeriesData([]);
          setResultsLoadingState(asyncActionStates.SUCCESS);
        } else {
          setResultsLoadingState(asyncActionStates.ERROR);
        }
      }
    }

    if (!isNodalAnalysis) {
      fetchResults();
    }
    return () => { didCancel = true; };
  };
  const loadHCResults = () => {
    let didCancel = false;

    async function fetchHCResults() {
      setHCResultsLoadingState(asyncActionStates.LOADING);
      const options = {
        feeder: equipmentContainer,
        scenario_id: scenarioID,
        start_date: maxRange.start.toISOString(),
        end_date: maxRange.end.toISOString(),
        hc_type: hcChartMode,
        analysis_name: analysisName,
      };
      const request = new Request(`/api/workspace/${workspace}/branch/${branch}/hosting-capacity-results/node/${nodeID}`);

      try {
        const { data: { results: HCresults, aggregation } } = await request.get({ params: options });
        if (didCancel) {
          // Cancelled before the request finished so do nothing
          setHCResultsLoadingState(asyncActionStates.CANCELLED);
          return;
        }

        setHCAggregation(aggregation);
        setHCResults(HCresults);
        setHCResultsLoadingState(asyncActionStates.SUCCESS);
      } catch (error) {
        if (error.response.status === 404) {
          setHCResults([]);
          setHCResultsLoadingState(asyncActionStates.SUCCESS);
        } else {
          setHCResultsLoadingState(asyncActionStates.ERROR);
        }
      }
    }

    if (isHostingCapacity) {
      fetchHCResults();
    }
    return () => { didCancel = true; };
  };
  useEffect(
    loadResults,
    [workspace, branch, nodeID, equipmentContainer, scenarioID, analysisName, maxRange, isNodalAnalysis],
  );
  useEffect(
    loadHCResults,
    [workspace, branch, nodeID, equipmentContainer, scenarioID, analysisName, maxRange, hcChartMode, isHostingCapacity],
  );

  const isSingleInterval = timeRange.end && timeRange.start
    && timeRange.start.clone().add(subHourInterval, 'minutes') > timeRange.end;
  const hideTable = !isSingleInterval || !valueCheck.hasValues(Object.keys(VARIABLES), results);

  let minV;
  let maxV;
  if (nodeTimeSeriesData
    && nodeTimeSeriesData.length > 0
    && nodeTimeSeriesData[0].simulation_options) {
    minV = nodeTimeSeriesData[0].simulation_options.voltage_lower_limit;
    maxV = nodeTimeSeriesData[0].simulation_options.voltage_upper_limit;
  }

  return (
    <div className="asset-panel-values results-section">
      <h4>Analysis Results</h4>
      {!hideTable
        && (
        <PerPhaseContainer showTotal>
          {Object.values(VARIABLES).map((variable) => {
            const values = {};
            ['A', 'B', 'C'].forEach(phase => {
              values[phase] = results?.[variable.key]?.[`${phase}_avg`];
            });
            return (
              <>
                <PerPhaseRow
                  id={variable.key}
                  key={variable.key}
                  violations={violations}
                  {...{
                    ...variable,
                    phases: newPanelValues.phase,
                    values,
                  }}
                  timeRange={timeRange}
                />
              </>
            );
          })}
        </PerPhaseContainer>
        )}
      { !isNodalAnalysis && (
        <NodeVoltageTimeSeriesChart
          timeSeriesData={nodeTimeSeriesData}
          baseVoltage={newPanelValues.baseVoltage}
          id={nodeID}
          highlightRange={timeRange}
          maxRange={maxRange}
          minV={minV}
          maxV={maxV}
          expanded={expanded}
          loading={resultsLoadingState === asyncActionStates.LOADING}
          timeBarZoomLevel={timeBarZoomLevel}
        />
      )}
      { isHostingCapacity
        && (
        <HostingCapacityChart
          hostingCapacityResults={hcResults}
          resultsAggregated={hcAggregation !== 'hour'}
          aggregation={hcAggregation}
          nodeId={nodeID}
          phase={newPanelValues.phase}
          highlightRange={timeRange}
          maxRange={maxRange}
          expanded={expanded}
          chartMode={hcChartMode}
          loading={hcResultsLoadingState === asyncActionStates.LOADING}
          timeBarZoomLevel={timeBarZoomLevel}
        />
        )}
    </div>
  );
};

NodeResultsSection.propTypes = {
  nodeID: PropTypes.string.isRequired,
  equipmentContainer: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  maxRange: PropTypes.object.isRequired,
  newPanelValues: PropTypes.object.isRequired,
  scenarioID: PropTypes.string.isRequired,
  analysisName: PropTypes.string.isRequired,
  timeRange: PropTypes.object.isRequired,
  workspace: PropTypes.string.isRequired,
  violations: PropTypes.object.isRequired,
  selectedAnalysis: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  results: PropTypes.object.isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  subHourInterval: PropTypes.number.isRequired,
};

export default NodeResultsSection;
