import React, { FunctionComponent } from 'react';
import { Moment } from 'moment';
import ResultsCard from 'components/ResultsCard';
import { isDefined } from 'helpers/utils';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import { ThemeProp } from 'types/index';
import { useBaseline } from '../context/ResultsComparisonContext';
import ValueCard from './ValueCard';

type CostProps = {
    theme: ThemeProp,
    workspace: string|null,
    branch: string|null,
    maxRange: {
      start?: Moment|null,
      end?: Moment|null,
    },
    scenario: string|null,
    analysis: {[key: string]: any}|null,
    feeders: [],
    isBaseline: boolean,
};

type ProgramResults = {
    totals: {
        administration_cost: number,
        availability_cost: number,
        utilization_cost: number,
    };
  };

type OpexResults = {
    totals: {
        operation_cost: number,
        community_generation_credit: number,
    };
  };

type CapexResults = {
    estimatedUnitCost: number,
    estimatedUnitDisposalCost: number,
    estimatedUnitInstallationCost: number,
    estimatedUnitRemovalCost: number,
    estimatedUnitUpgradeCost: number
}

const CostSummary: FunctionComponent<CostProps> = ({
  theme,
  workspace,
  branch,
  maxRange,
  scenario,
  analysis,
  feeders,
  isBaseline,
}) => {
  const parameters = {
    feeder: feeders?.map((fdr: {[key:string]: string}) => fdr.id),
    scenario_id: scenario,
    start_date: maxRange?.start?.toISOString(),
    end_date: maxRange?.end?.toISOString(),
    analysis_name: analysis?.name,
  };

  const { baseline: OpexBaseline, setBaseline: setOpexBaseline } = useBaseline<OpexResults>('opexBaseline');
  const { baseline: ProgramBaseline, setBaseline: setProgramBaseline } = useBaseline<ProgramResults>('programBaseline');
  const { baseline: CapexBaseline, setBaseline: setCapexBaseline } = useBaseline<CapexResults>('capexBaseline');

  const { data: opexData, loading: opexLoading } = useRequestEffect<OpexResults>({
    url: `/api/workspace/${workspace}/branch/${branch}/power-flow-results/operation-costs/summary`,
    method: 'get',
    params: parameters,
    refetchOnChange: [workspace, branch, scenario, analysis?.name, maxRange, feeders],
    blockRequest: () => !(workspace && branch && scenario && analysis?.name && maxRange?.start && maxRange?.end
        && feeders?.length),
    onSuccess: (results) => {
      if (results && isBaseline) {
        setOpexBaseline(results);
      }
    },
  });
  const { data: capexData, loading: capexLoading } = useRequestEffect<CapexResults>({
    url: `/api/workspace/${workspace}/branch/${branch}/capital-cost/total`,
    method: 'get',
    params: parameters,
    refetchOnChange: [workspace, branch, feeders],
    blockRequest: () => !(workspace && branch && feeders?.length),
    onSuccess: (results) => {
      if (results && isBaseline) {
        setCapexBaseline(results);
      }
    },
  });

  const { data: programData, loading: programLoading } = useRequestEffect<ProgramResults>({
    url: `/api/workspace/${workspace}/branch/${branch}/power-flow-results/der-program-costs/summary`,
    method: 'get',
    params: parameters,
    refetchOnChange: [workspace, branch, scenario, analysis?.name, maxRange, feeders],
    blockRequest: () => !(workspace && branch && scenario && analysis?.name && maxRange?.start && maxRange?.end
        && feeders?.length),
    onSuccess: (results) => {
      if (results && isBaseline) {
        setProgramBaseline(results);
      }
    },
  });

  const totals = {
    // explicity converting falsy values (including 0) into null
    capitalCost: (capexData && Object.values(capexData).reduce((a, b) => a + b, 0)) || null,
    operatingCost: (opexData?.totals?.operation_cost) || null,
    communityGenerationCredit: (opexData?.totals?.community_generation_credit) || null,
    administrationCost: (programData?.totals?.administration_cost) || null,
    availabilityCost: (programData?.totals?.availability_cost) || null,
    utilizationCost: (programData?.totals?.utilization_cost) || null,
  };

  const getTotalCost = (opex_results?: OpexResults, capex_results?: CapexResults, program_results?: ProgramResults) => {
    const opex = opex_results?.totals?.operation_cost ?? 0;
    const cgc = opex_results?.totals?.community_generation_credit ?? 0;
    const admin = program_results?.totals?.administration_cost ?? 0;
    const avail = program_results?.totals?.availability_cost ?? 0;
    const util = program_results?.totals?.utilization_cost ?? 0;
    const capex = (capex_results && Object.values(capex_results).reduce((a, b) => a + b, 0)) ?? 0;

    return opex + cgc + admin + avail + util + capex;
  };

  return (
    <ResultsCard
      theme={theme}
      expandableContents={(
        <>
          <div className="one-one grid-columns">
            <ValueCard
              label="Capital Cost"
              labelOnly
            />
            <ValueCard
              value={totals.capitalCost}
              isCurrency
              baselineValue={
                isDefined(CapexBaseline)
                  ? (
                    Object.values(CapexBaseline).reduce((a, b) => a + b, 0)
                  ) : null
              }
              loading={capexLoading}
            />
          </div>
          <div className="one-one grid-columns margin-10">
            <ValueCard
              label="Operating Cost"
              labelOnly
            />
            <ValueCard
              value={totals.operatingCost}
              isCurrency
              baselineValue={OpexBaseline?.totals?.operation_cost || null}
              loading={opexLoading}
            />
          </div>
          <div className="one-one grid-columns margin-10">
            <ValueCard
              label="Administration Cost"
              hoverMsg="Customer Program Cost representing the cost of maintaining and keeping DER contracts up to date"
              labelOnly
            />
            <ValueCard
              value={totals.administrationCost}
              isCurrency
              baselineValue={ProgramBaseline?.totals?.administration_cost || null}
              loading={programLoading}
            />
          </div>
          <div className="one-one grid-columns margin-10">
            <>
              <ValueCard
                label="Utilization Cost"
                labelOnly
                hoverMsg="Customer Program Cost representing the cost of utilizing DERs"
              />
              <ValueCard
                value={totals.utilizationCost}
                isCurrency
                baselineValue={ProgramBaseline?.totals?.utilization_cost || null}
                loading={programLoading}
              />
            </>
          </div>
          <div className="one-one grid-columns margin-10">
            <ValueCard
              label="Availability Cost"
              labelOnly
              hoverMsg="Customer Program Cost representing the cost of having DERs on standby"
            />
            <ValueCard
              value={totals.availabilityCost}
              isCurrency
              baselineValue={ProgramBaseline?.totals?.availability_cost || null}
              loading={programLoading}
            />
          </div>
          <div className="one-one grid-columns margin-10">
            <ValueCard
              label="Community Generation Credit"
              labelOnly
              hoverMsg="DER societal benefit"
            />
            <ValueCard
              value={totals.communityGenerationCredit}
              isCurrency
              baselineValue={OpexBaseline?.totals?.community_generation_credit || null}
              loading={opexLoading}
            />
          </div>
        </>
      )}
      className="results-comparison-card summary-card"
    >
      <div className="one-one grid-columns items-centered">
        <div>Costs</div>
        <ValueCard
          primary
          isCurrency
          value={getTotalCost(opexData, capexData, programData)}
          baselineValue={getTotalCost(OpexBaseline, CapexBaseline, ProgramBaseline) || null}
          showComparisonPercent
          loading={opexLoading || programLoading || capexLoading}
        />
      </div>
    </ResultsCard>
  );
};

export default CostSummary;
