import React, { FunctionComponent, useContext, useState } from 'react';
import { ThemeProp } from 'types/index';
import ScenarioSection from 'routes/WorkspaceLayout/NetworkTopNav/containers/ScenarioSectionContainer';
import Select from 'components/Select';
import Button from 'components/Button';
import moment, { Moment } from 'moment';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';
import { BETA_PERMISSION } from 'helpers/permissions';
import { determineDefaultZoomLevel, UnitOfTime, ZoomLevel } from 'components/ZoomableRangeSlider/intervals';
import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';
import Schedules from './Schedules';
import { ScenarioGenerationContext } from '../context/ScenarioGenerationContext';
import { determineScenarioTimeSpan } from '../../Network/helpers/NetworkHelpers';
import { views, modalTypes } from '../helpers/ScenarioGenerationHelpers';
import AssetSchedules from './AssetSchedules';
import ExternalSourceModal from './ExternalSourceModal';

type ScenarioSchedulesProps = {
    workspace: string,
    branch: string,
    theme: ThemeProp,
  };

const ScenarioSchedules: FunctionComponent<ScenarioSchedulesProps> = ({
  theme,
  workspace,
  branch,
}) => {
  const {
    selectedScenario, setSelectedScenario, feedersInScenario, view, setView, selectedContainer,
    setSelectedContainer, scenarios, resetScenario, setModalActiveType,
  } = useContext(ScenarioGenerationContext);
  const { analyticsConfig } = useContext(WorkspaceSettingsContext);
  const FeederOptions = feedersInScenario?.map((container) => ({ value: container.id, label: container.name })) || [];
  const [scenarioRange, setScenarioRange] = useState<
  { start: Moment|null, end: Moment|null }>({ start: null, end: null });
  const [timeBarZoomLevel, setTimeBarZoomLevel] = useState<ZoomLevel|null>(null);
  const { permissions } = useAuthContext();
  const getTimeRange = async (scenarioID: string) => {
    try {
      setScenarioRange({ start: null, end: null });
      setTimeBarZoomLevel(null);
      if (workspace && branch && scenarioID) {
        const timeSpan = await determineScenarioTimeSpan(
          workspace, branch, scenarioID,
        );
        if (timeSpan && timeSpan.start && timeSpan.end) {
          const zoomLevel = determineDefaultZoomLevel(moment.utc(timeSpan.start),
            moment.utc(timeSpan.end), 5) as UnitOfTime;
          setTimeBarZoomLevel(zoomLevel);
          setScenarioRange(timeSpan);
        }
      }
    } catch (err) {
    }
  };

  return (
    <div data-test="scenario-schedules" className={`scenario-schedules ${theme}`}>
      { view === views.assetSchedule && (
        <>
          <Button
            type="text"
            onClick={() => setView(views.scenario)}
            className="pink-underline-section"
          >
            Back to view scenarios
          </Button>
          <h1><strong>Asset schedules</strong></h1>
        </>
      )}
      <div className="four-two grid-columns">
        <div className="flex-vertical-center">
          <ScenarioSection
            workspace={workspace}
            branch={branch}
            scenario={selectedScenario?.value}
            scenarios={scenarios}
            view="results"
            handleScenarioChange={(e: string) => {
              e ? setSelectedScenario(scenarios.find(({ value: val }) => val === e) as { [key: string]: string})
                : resetScenario();
              setSelectedContainer(null);
              getTimeRange(e);
            }}
            theme={theme}
            hideButtons
            selectType="secondary"
          />
          { view === views.assetSchedule && (
            <Select
              theme={theme}
              options={FeederOptions}
              onChange={(e: {value: string, label: string}|null) => setSelectedContainer(e?.value ?? null)}
              value={selectedContainer}
              label="Feeder"
              type="secondary"
              width={240}
            />
          )}
        </div>
        { permissions.has(BETA_PERMISSION) && permissions.has('view_qsts_scenarios')
          && permissions.has('alter_qsts_scenarios') && (
          <div className="flex-vertical-center" style={{ justifyContent: 'flex-end' }}>
            <Button
              theme={theme}
              label="Choose from external URL"
              type="info"
              onClick={() => setModalActiveType(modalTypes.externalSource)}
              disabled={!selectedScenario || !analyticsConfig.schedule_sources}
              className="external-schedule"
            />
          </div>
        )}
        <ExternalSourceModal
          theme={theme}
          workspace={workspace}
          branch={branch}
        />
      </div>
      <div className="margin-10">
        { view === views.assetSchedule ? (
          <AssetSchedules
            workspace={workspace}
            branch={branch}
            scenario={selectedScenario}
            theme={theme}
            selectedContainer={selectedContainer}
          />
        ) : (
          <Schedules
            workspace={workspace}
            branch={branch}
            scenario={selectedScenario}
            theme={theme}
            timeBarZoomLevel={timeBarZoomLevel}
            scenarioRange={scenarioRange}
          />
        )}
      </div>
    </div>
  );
};

export default ScenarioSchedules;
