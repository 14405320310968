import React, { FunctionComponent, useContext } from 'react';
import ThemeContext from 'helpers/ThemeContext';
import { IconPropsSchema } from '../../types/index';
import { getIconBGColor } from '../helpers/formatters';

const ControlSettingsIcon: FunctionComponent<IconPropsSchema> = ({
  color,
  height = '20px',
  width = '20px',
}) => {
  const theme = useContext(ThemeContext);
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" version="1.1">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="IDP-home-Copy-38-(b)-Copy-15" transform="translate(-530.000000, -202.000000)">
          <rect fill={getIconBGColor(theme, color)} x="0" y="0" width="1366" height="800" />
          <rect id="Rectangle" fillOpacity="0.4" fill="#000000" x="0" y="40" width="1369" height="890" />
          <rect id="Rectangle" stroke="#EDF0F5" fill={getIconBGColor(theme, color)} x="377.5" y="51.5" width="642" height="738" rx="4" />
          <g id="control-settings-(3)" transform="translate(530.000000, 202.000000)" fillRule="nonzero">
            <g id="settings-(3)">
              <path d="M19.1666797,17.109375 L0.833320312,17.109375 C0.373320312,17.109375 0,16.736875 0,16.2760547 C0,15.8152344 0.373320312,15.4427344 0.833320312,15.4427344 L19.1666406,15.4427344 C19.6274609,15.4427344 19.9999609,15.8152344 19.9999609,16.2760547 C19.9999609,16.736875 19.6275,17.109375 19.1666797,17.109375 Z" id="Path" fill="#0035AB" />
              <circle id="Oval" fill="#C2DDF2" cx="14.1666797" cy="16.2760547" r="2.08332031" />
              <g id="Group" transform="translate(0.000000, 2.109375)">
                <path d="M14.1666797,17.0833203 C12.5583594,17.0833203 11.25,15.775 11.25,14.1666406 C11.25,12.5582813 12.5583203,11.25 14.1666797,11.25 C15.7750391,11.25 17.0833594,12.5583203 17.0833594,14.1666797 C17.0833594,15.7750391 15.775,17.0833203 14.1666797,17.0833203 Z M14.1666797,12.9166797 C13.4775,12.9166797 12.9166797,13.4775 12.9166797,14.1666797 C12.9166797,14.8558594 13.4775,15.4166797 14.1666797,15.4166797 C14.8558594,15.4166797 15.4166797,14.8558594 15.4166797,14.1666797 C15.4166797,13.4775 14.8558203,12.9166797 14.1666797,12.9166797 Z" id="Shape" fill="#FF174D" />
                <path d="M19.1666797,1.66667969 L0.833320312,1.66667969 C0.373320313,1.66667969 0,1.29332031 0,0.833320313 C0,0.373320313 0.373320313,0 0.833320312,0 L19.1666406,0 C19.6275,0 20,0.373320313 20,0.833320313 C20,1.29332031 19.6275,1.66667969 19.1666797,1.66667969 Z" id="Path" fill="#0035AB" />
              </g>
              <circle id="Oval" fill="#C2DDF2" cx="14.1666797" cy="2.94269531" r="2.08332031" />
              <g id="Group">
                <path d="M14.1666797,5.859375 C12.5583203,5.859375 11.25,4.55105469 11.25,2.94269531 C11.25,1.33433594 12.5583203,0.026015625 14.1666797,0.026015625 C15.7750391,0.026015625 17.0833594,1.33433594 17.0833594,2.94269531 C17.0833594,4.55105469 15.775,5.859375 14.1666797,5.859375 Z M14.1666797,1.69269531 C13.4775,1.69269531 12.9166797,2.25351562 12.9166797,2.94269531 C12.9166797,3.631875 13.4775,4.19269531 14.1666797,4.19269531 C14.8558594,4.19269531 15.4166797,3.631875 15.4166797,2.94269531 C15.4166797,2.25351562 14.8558203,1.69269531 14.1666797,1.69269531 Z" id="Shape" fill="#FF174D" />
                <path d="M19.1666797,10.4426953 L0.833320312,10.4426953 C0.373320312,10.4426953 0,10.0701953 0,9.609375 C0,9.149375 0.373320312,8.77605469 0.833320312,8.77605469 L19.1666406,8.77605469 C19.6274609,8.77605469 19.9999609,9.149375 19.9999609,9.609375 C19.9999609,10.0701953 19.6275,10.4426953 19.1666797,10.4426953 Z" id="Path" fill="#0035AB" />
              </g>
              <circle id="Oval" fill="#C2DDF2" cx="5.83332031" cy="9.609375" r="2.08332031" />
              <path d="M5.83332031,12.5260547 C4.225,12.5260547 2.91664062,11.2177344 2.91664062,9.609375 C2.91664062,8.00101563 4.22496094,6.69269531 5.83332031,6.69269531 C7.44167969,6.69269531 8.75,8.00105469 8.75,9.609375 C8.75,11.2176953 7.44167969,12.5260547 5.83332031,12.5260547 Z M5.83332031,8.359375 C5.14414063,8.359375 4.58332031,8.92019531 4.58332031,9.609375 C4.58332031,10.2985547 5.14414063,10.859375 5.83332031,10.859375 C6.5225,10.859375 7.08332031,10.2985547 7.08332031,9.609375 C7.08332031,8.92019531 6.5225,8.359375 5.83332031,8.359375 Z" id="Shape" fill="#FF174D" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ControlSettingsIcon;
