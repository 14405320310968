import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@opusonesolutions/gridos-app-framework';

import WorkspaceLayoutContainer from './containers/WorkspaceLayoutContainer';
import CimExplorer from './routes/CimExplorer';
import EquipmentLibrary from './routes/EquipmentLibrary';
import Import from './routes/Import';
import Network from './routes/Network';
import ResultsComparison from './routes/ResultsComparison';
import ScenarioGeneration from './routes/ScenarioGeneration';
import WorkspaceOverview from './routes/WorkspaceOverview';

const WorkspaceRoutes = () => (
  <WorkspaceLayoutContainer>
    <Switch>
      <ProtectedRoute exact path="/:workspace/:branch/cim">
        <CimExplorer />
      </ProtectedRoute>
      <ProtectedRoute
        path={[
          '/:workspace/:branch/library/:equipmentType/:equipmentId',
          '/:workspace/:branch/library',
        ]}
      >
        <EquipmentLibrary />
      </ProtectedRoute>
      <ProtectedRoute path="/:workspace/:branch/import">
        <Import />
      </ProtectedRoute>
      <ProtectedRoute
        path={[
          '/:workspace/:branch/gis/:feeder_ids/asset/:asset',
          '/:workspace/:branch/gis/:feeder_ids',
          '/:workspace/:branch/gis',
        ]}
      >
        <Network />
      </ProtectedRoute>
      <ProtectedRoute path="/:workspace/:branch/comparison">
        <ResultsComparison />
      </ProtectedRoute>
      <ProtectedRoute
        path="/:workspace/:branch/scenario"
      >
        <ScenarioGeneration />
      </ProtectedRoute>
      <ProtectedRoute
        path="/:workspace"
      >
        <WorkspaceOverview />
      </ProtectedRoute>
    </Switch>
  </WorkspaceLayoutContainer>
);

export default WorkspaceRoutes;
