import React, {
  FunctionComponent, useState, useEffect,
} from 'react';
import { Moment } from 'moment';
import ResultsCard from 'components/ResultsCard';
import { ThemeProp } from 'types/index';
import asyncActionStates from 'helpers/asyncActionStates';
import {
  violationCategory,
  violationTypes,
  violationsCountCreator,
} from 'routes/WorkspaceLayout/routes/Network/helpers/PowerflowHelpers';
import { Request } from '@opusonesolutions/gridos-app-framework';
import ValueCard from './ValueCard';
import ViolationChart from './ViolationChart';
import { useBaseline } from '../context/ResultsComparisonContext';
import { views } from '../helpers/ResultsComparisonHelpers';
import AssetsViolations from './AssetsViolations';

type ViolationsMagType = {
  name: number,
  value: number,
}

type ViolationsMagResult = {
  [key: string]: ViolationsMagType[]
}

type ViolationsCountType = {
  name: string,
  value: number,
}

type BaselineType = {
  violationsCount: ViolationsCountType[],
  violationsMag: ViolationsMagResult
}

type ViolationsSummaryResult = {
  violation_buckets: { [key: string]: { [key: string]: number } };
};

type ViolationsProps = {
  theme: ThemeProp,
  violationLimit: number | null,
  violationType: string,
  setViolationType: (value: string) => void,
  isBaseline: boolean,
  workspace: string | null,
  branch: string | null,
  maxRange: {
    start?: Moment | null,
    end?: Moment | null,
  },
  scenario: string|null,
  analysis: { name: string } | null,
  feeders?: { id: string }[],
  viewType: string,
}

const Violations: FunctionComponent<ViolationsProps> = ({
  theme,
  violationLimit,
  violationType,
  setViolationType,
  isBaseline,
  workspace,
  branch,
  feeders,
  maxRange,
  scenario,
  analysis,
  viewType,
}) => {
  const [violationsMag, setViolationsMag] = useState<ViolationsMagResult>({});
  const [violationsCount, setViolationsCount] = useState<ViolationsCountType[]>([]);
  const [loading, setLoading] = useState(asyncActionStates.INITIAL);

  const { baseline, setBaseline } = useBaseline<BaselineType>('baselineViolations');

  useEffect(() => {
    const fetchViolations = async () => {
      const categories = Object.values(violationCategory).map(cat => cat.label);
      const vMag = categories.reduce((categoryMap: ViolationsMagResult, category: string) => {
        categoryMap[category] = [];
        return categoryMap;
      }, {});
      const url = `/api/workspace/${workspace}/branch/${branch}`
      + '/power-flow-results/violations/summary';
      const params = {
        feeder: feeders?.map(fdr => fdr.id) || [],
        start_date: maxRange?.start?.toISOString(),
        end_date: maxRange?.end?.toISOString(),
        scenario_id: scenario,
        analysis_name: analysis?.name,
      };
      try {
        setLoading(asyncActionStates.LOADING);
        const request = new Request(url);
        const results = await request.get<ViolationsSummaryResult>({ params });
        // Create a dictionary of all violations grouped by Violation Type
        // Data is formatted such that the number of violations between
        // 0-1 is on key 0, 1-5 on key 1, 5-50 on key 5...
        const violationBuckets = Object.entries(results.data?.violation_buckets);
        violationBuckets.forEach(([bucket, values], idx) => {
          const next = violationBuckets[idx + 1];
          Object.entries(values).forEach(([value_type, count]) => {
            const type = violationTypes.get(value_type) || 'Other';
            if (!vMag[type]) {
              vMag[type] = [];
            }

            const name = Number.parseInt(bucket, 10);
            if (next) {
              const [, next_value] = next;
              const value = count - (next_value[value_type] ?? 0);
              vMag[type].push({ name, value });
            } else {
              const value = count;
              vMag[type].push({ name, value });
            }
          });
        });
        // { type: [{ name: bucket, value: count }] }
        setViolationsMag(vMag);
        const vCount: ViolationsCountType[] = violationsCountCreator(vMag);
        setViolationsCount(vCount);
        if (isBaseline) {
          setBaseline({
            violationsMag: vMag,
            violationsCount: vCount,
          });
        }
        setLoading(asyncActionStates.SUCCESS);
      } catch (err) {
        setLoading(asyncActionStates.ERROR);
      }
    };
    setViolationsMag({});
    setViolationsCount([]);
    if (workspace && branch && scenario && maxRange && analysis) {
      fetchViolations();
    }
  }, [
    workspace,
    branch,
    feeders,
    scenario,
    maxRange,
    analysis,
    isBaseline,
    setBaseline,
    setLoading,
  ]);
  const violationsTotal = violationsCount?.reduce(
    (total, entry) => total + entry.value, 0,
  ) || null;
  const baselineViolationsTotal = baseline?.violationsCount?.reduce(
    (total, entry) => total + entry.value, 0,
  ) || null;
  const violationsTotalOverLimit = Object.keys(violationsMag).reduce((total, entry) => {
    const typeViolations = violationsMag[entry]?.reduce(
      (percent_total, percentOver) => {
        if (violationLimit && percentOver.name >= violationLimit) {
          percent_total += percentOver.value;
        }
        return percent_total;
      }, 0,
    );
    return total + typeViolations;
  }, 0) || null;

  const baselineViolationsTotalOverLimit = (baseline?.violationsMag
      && Object.keys(baseline?.violationsMag).reduce((total, type) => {
        const typeViolations = baseline?.violationsMag[type]?.reduce(
          (percent_total, percentOver) => {
            if (violationLimit && percentOver.name >= violationLimit) {
              percent_total += percentOver.value;
            }
            return percent_total;
          }, 0,
        );
        return total + typeViolations;
      }, 0))
    || null;

  const placeholderElement = (
    <div className="no-violations-placeholder">
      { isBaseline
        ? 'This analysis has no violations data to display'
        : 'This analysis or the baseline analysis has no violations data to display'}
    </div>
  );
  return (
    <div>
      <ResultsCard
        theme={theme}
        expandableContents={
          viewType === views.overview && (
            baselineViolationsTotal ? (
              <ViolationChart
                violationsMag={violationsMag}
                theme={theme}
                baselineViolations={baseline}
                violationType={violationType}
                setViolationType={setViolationType}
                isBaseline={isBaseline}
                loading={loading}
              />
            ) : (placeholderElement)
          )
        }
        className="results-comparison-card summary-card"
      >
        <div className="eight grid-columns no-margin">
          <ValueCard
            primary
            includeWarning
            value={violationsTotal ?? 0}
            baselineValue={baselineViolationsTotal}
            tooltipMsg={violationsTotal ? '' : 'This analysis has no violations'}
            warnLowSeverity={!violationsTotal}
            label="Violations"
            showComparisonPercent
            loading={loading === asyncActionStates.LOADING}
            error={loading === asyncActionStates.ERROR}
          />
          {viewType === views.overview && !!violationLimit && (
            <ValueCard
              primary
              includeWarning
              value={violationsTotalOverLimit ?? 0}
              baselineValue={baselineViolationsTotalOverLimit}
              warnLowSeverity={!violationsTotal}
              label={`Over limit (${violationLimit}%)`}
              showComparisonPercent
              loading={loading === asyncActionStates.LOADING}
              error={loading === asyncActionStates.ERROR}
            />
          )}
        </div>
      </ResultsCard>
      { viewType === views.assets && (
        <AssetsViolations
          theme={theme}
          isBaseline={isBaseline}
          workspace={workspace}
          branch={branch}
          scenario={scenario}
          analysis={analysis}
          maxRange={maxRange}
          feeders={feeders}
          violationsTotal={violationsTotal}
        />
      )}
    </div>
  );
};

export default Violations;
