import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import asyncActionStates from 'helpers/asyncActionStates';

import { Request } from '@opusonesolutions/gridos-app-framework';
import { violationTypes } from 'routes/WorkspaceLayout/routes/Network/helpers/PowerflowHelpers';
import fileExportSave from 'helpers/FileDownload';

import NodeResultsSection from './NodeResultsSection';
import ResultsSection from './ResultsSection';
import TransformerResultsSection from './TransformerResultsSection';
import ViolationsSection from './ViolationsSection';

import './Results.scss';
import { ANALYSIS_TYPES } from '../../../../../helpers/NetworkHelpers';
import { initialScheduleData, fetchAssetSchedule } from '../../../../../helpers/AssetScheduleHelpers';
import assetClassLookup, {
  expectRealPowerTimeSeriesData,
  expectReactivePowerTimeSeriesData,
  invertScheduleOnResultsGraph,
  shouldInvertRealPower,
  shouldInvertReactivePower,
} from '../../helpers/assetClass';

function formatViolations(violations, aggregation) {
  const allViolations = {};
  if (violations && violations.length > 0) {
    violations.forEach((v) => {
      const type = violationTypes.get(v.violation) || 'Other';
      if (!allViolations[type]) {
        allViolations[type] = [];
      }
      let timeFormat;
      switch (aggregation) {
        case 'year':
          timeFormat = 'YYYY';
          break;
        case 'month':
          timeFormat = 'YYYY-MM';
          break;
        case 'day':
          timeFormat = 'YYYY-MM-DD';
          break;
        default:
          timeFormat = 'YYYY-MM-DD HH:mm';
          break;
      }
      if (v.timepoint) {
        v.timepoint = moment(v.timepoint).utc().format(timeFormat);
      }
      allViolations[type].push(v);
    });
  }
  return allViolations;
}

const ZERO_THRESHOLD = 10;
function updatePQValues(datapoint, property_name, keepPQData, invertData) {
  if (!keepPQData) {
    delete datapoint[property_name];
    return;
  }
  // some values should be inverted to display as positive on the graph
  if (invertData) {
    datapoint[property_name] *= -1;
  }
  // any value <10W is coerced to 0 because it is considered
  // insignificant in the context of a distribution network
  if (Math.abs(datapoint[property_name]) < ZERO_THRESHOLD) {
    datapoint[property_name] = 0;
  }
}

const CUSTOM_PANELS = new Set([
  'ConnectivityNode',
  'PowerTransformer',
  'Regulator',
]);

const COST_SUPPORTED = new Set([
  'EnergySource',
  'SynchronousMachine',
  'CHP',
  'RunOfRiverHydro',
]);

const DER_PROGRAM_COST_SUPPORTED = new Set([
  'Battery',
  'SynchronousMachine',
  'CHP',
  'RunOfRiverHydro',
  'InverterPV',
  'Wind',
]);

const COMMUNITY_GEN_COST_SUPPORTED = new Set([
  'InverterPV',
  'Battery',
  'SynchronousMachine',
  'CHP',
  'RunOfRiverHydro',
]);

const ResultsPanel = ({
  analysisSettings,
  asset,
  assetID,
  assetClass,
  branch,
  expanded,
  timeRange,
  maxRange,
  selectedAnalysis,
  timeBarZoomLevel,
  newPanelValues,
  scenarioID,
  analysisName,
  theme,
  workspace,
  results,
  subHourInterval,
}) => {
  const [resultData, setResultData] = useState([]);
  const [resultsLoadingState, setResultsLoadingState] = useState(asyncActionStates.INITIAL);
  const [violations, setViolations] = useState({});
  const [violationsLoadingState, setViolationsLoadingState] = useState(asyncActionStates.INITIAL);
  const [costData, setCostData] = useState({ datapoints: [], error: '' });
  const [commGenCostData, setCommGenCostData] = useState({ datapoints: [], error: '' });
  const [costLoadingState, setCostLoadingState] = useState(asyncActionStates.INITIAL);
  const [maxDERProgCostData, setMaxDERProgCostData] = useState({ datapoints: {}, error: '' });
  const [selectedDERProgCostData, setSelectedDERProgCostData] = useState({ datapoints: {}, error: '' });
  const [costReportLoadingState, setCostReportLoadingState] = useState(asyncActionStates.INITIAL);
  const isNodalAnalysis = [
    ANALYSIS_TYPES.EV_CAPACITY, ANALYSIS_TYPES.HOSTING_CAPACITY, ANALYSIS_TYPES.BATTERY_SIZING,
  ].includes(selectedAnalysis.type);

  const loadData = () => {
    let didCancel = false;

    async function fetchResultData() {
      setResultsLoadingState(asyncActionStates.LOADING);

      const params = {
        feeder: asset.container.id,
        start_date: maxRange.start.toISOString(true),
        end_date: maxRange.end.toISOString(true),
        scenario_id: scenarioID,
        analysis_name: analysisName,
      };

      let assetScheduleData = initialScheduleData;
      try {
        const response = await fetchAssetSchedule(workspace, branch, assetID, scenarioID,
          maxRange, timeBarZoomLevel, ['Normal']);
        assetScheduleData = response.data ? response.data : initialScheduleData;
      } catch (error) {
      }
      const scheduleData = assetScheduleData.datapoints.reduce(
        (schedule, timepoint) => {
          const filtered_timepoint = Object.keys(timepoint).reduce((data, key) => {
            if (key !== 'timestamp') {
              data[key] = timepoint[key];
            }
            return data;
          }, {});
          schedule[timepoint.timestamp] = filtered_timepoint;
          return schedule;
        },
        {}, // start from an empty object
      );
      // Defaults to make code common between link & shunt devices
      let expectPData = true;
      let expectQData = true;
      let invertPData = false;
      let invertQData = false;
      const scheduleDataSign = invertScheduleOnResultsGraph(assetClass) ? -1 : 1;
      let url;

      if (assetClassLookup.shunt.includes(assetClass)) {
        // Load shunt data
        expectPData = expectRealPowerTimeSeriesData(assetClass);
        expectQData = expectReactivePowerTimeSeriesData(assetClass);
        invertPData = shouldInvertRealPower(assetClass, analysisSettings);
        invertQData = shouldInvertReactivePower(assetClass, analysisSettings);

        const terminalId = asset.terminal.id;
        params.terminal_id = terminalId;
        url = `/api/workspace/${workspace}/branch/${branch}/power-flow-results/terminal`;
      } else {
        // Link device
        const { terminals } = asset;
        params.terminal_id1 = terminals[0].id;
        params.terminal_id2 = terminals[1].id;
        url = `/api/workspace/${workspace}/branch/${branch}/power-flow-results/link-device`;
      }

      const request = new Request(url);

      try {
        const { data } = await request.get({ params });

        if (didCancel) {
          setResultsLoadingState(asyncActionStates.CANCELLED);
          return;
        }

        data.forEach((entry) => {
          Object.keys(entry).forEach((property) => {
            if (property.startsWith('p')) {
              updatePQValues(entry, property, expectPData, invertPData);
            } else if (property.startsWith('q')) {
              updatePQValues(entry, property, expectQData, invertQData);
            }
          });

          const invertMinMaxProp = (property) => {
            const otherProp = `${property.substring(0, property.length - 3)}max`;
            const otherPropVal = entry[otherProp];
            entry[otherProp] = entry[property];
            entry[property] = otherPropVal;
          };
          if (invertPData) {
            Object.keys(entry).filter(prop => prop.startsWith('p') && prop.endsWith('min')).forEach(invertMinMaxProp);
          }
          if (invertQData) {
            Object.keys(entry).filter(prop => prop.startsWith('q') && prop.endsWith('min')).forEach(invertMinMaxProp);
          }

          // put schedule data in with powerflow results
          const scheduled = scheduleData[entry.timepoint] || {};
          let value;
          ['p', 'q'].forEach((quantity) => {
            ['_a', '_b', '_c', ''].forEach((phase_key) => {
              value = scheduleDataSign * scheduled[`${quantity}${phase_key.toUpperCase()}`];
              if (!Number.isNaN(value)) { entry[`${quantity}${phase_key || '_abc'}_scheduled`] = value; }
            });
          });
        });

        setResultData(data);
        setResultsLoadingState(asyncActionStates.SUCCESS);
      } catch (error) {
        if (error.response.status === 404) {
          setResultData([]);
          setResultsLoadingState(asyncActionStates.SUCCESS);
        } else {
          setResultsLoadingState(asyncActionStates.ERROR);
        }
      }
    }

    async function fetchViolations() {
      setViolationsLoadingState(asyncActionStates.LOADING);
      const url = `/api/workspace/${workspace}/branch/${branch}/power-flow-results/violations`;
      const request = new Request(url);

      try {
        const { data } = await request.get({
          params: {
            asset_id: assetID,
            feeder: asset.container.id,
            start_date: moment.utc(maxRange.start).toISOString(),
            end_date: moment.utc(maxRange.end).toISOString(),
            aggregation: timeBarZoomLevel,
            scenario_id: scenarioID,
            analysis_name: analysisName,
          },
        });

        if (didCancel) {
          // Cancelled before the request finished so do nothing
          setViolationsLoadingState(asyncActionStates.CANCELLED);
          return;
        }
        setViolations(formatViolations(data, timeBarZoomLevel));
        setViolationsLoadingState(asyncActionStates.SUCCESS);
      } catch (error) {
        if (error.response.status === 404) {
          setViolations({});
          setViolationsLoadingState(asyncActionStates.SUCCESS);
        } else {
          setViolationsLoadingState(asyncActionStates.ERROR);
        }
      }
    }

    async function fetchCostData() {
      let opCostData = { datapoints: [], error: '' };
      setCostLoadingState(asyncActionStates.LOADING);
      const request = new Request(
        `/api/workspace/${workspace}/branch/${branch}/power-flow-results/operation-costs`,
      );
      try {
        const response = await request.get({
          params: {
            scenario_id: scenarioID,
            start_date: moment(maxRange.start).toISOString(),
            end_date: moment(maxRange.end).toISOString(),
            feeder: asset.container.id,
            asset_id: assetID,
            analysis_name: analysisName,
          },
        });
        if (!didCancel) {
          setCostLoadingState(asyncActionStates.SUCCESS);
          const result = {
            error: '',
            datapoints: response.data,
          };
          opCostData = result;
        }
      } catch (err) {
        if (!didCancel) {
          const errorCode = err && err.response && err.response.status;

          const errorMsg = errorCode === 404 ? '' : err.message;
          setCostLoadingState(asyncActionStates.ERROR);
          opCostData = { datapoints: [], error: errorMsg };
        }
      }
      setCostData(COST_SUPPORTED.has(assetClass) ? opCostData : { datapoints: [], error: '' });
      setCommGenCostData(COMMUNITY_GEN_COST_SUPPORTED.has(assetClass) ? opCostData : { datapoints: [], error: '' });
    }
    if (assetClass !== 'ConnectivityNode' && scenarioID && analysisName && !isNodalAnalysis) {
      fetchResultData();
      fetchCostData();
    } else {
      setResultData([]);
      setCostData({ datapoints: [], error: '' });
      setCommGenCostData({ datapoints: [], error: '' });
    }

    if (scenarioID && analysisName && !isNodalAnalysis) {
      fetchViolations();
    } else {
      setViolations({});
    }

    return () => { didCancel = true; };
  };

  async function fetchDERProgramCostData({ start, end }, setResults) {
    let didCancel = false;

    const request = new Request(
      `/api/workspace/${workspace}/branch/${branch}/power-flow-results/der-program-costs/summary`,
    );
    let costResults;
    try {
      const response = await request.get({
        params: {
          scenario_id: scenarioID,
          start_date: moment(start).toISOString(),
          end_date: moment(end).toISOString(),
          feeder: asset.container.id,
          asset_ids: [assetID],
          analysis_name: analysisName,
        },
      });
      if (!didCancel) {
        costResults = {
          error: '',
          datapoints: response.data,
        };
      }
    } catch (err) {
      if (!didCancel) {
        const errorCode = err && err.response && err.response.status;

        const errorMsg = errorCode === 404 ? '' : err.message;
        costResults = {
          datapoints: {},
          error: errorMsg,
        };
      }
    }
    setResults(costResults);

    return () => { didCancel = true; };
  }

  useEffect(() => {
    if (
      DER_PROGRAM_COST_SUPPORTED.has(assetClass)
      && scenarioID
      && analysisName
      && !isNodalAnalysis
      && asset.container.id
      && assetID
      && timeRange.start
      && timeRange.end
    ) {
      fetchDERProgramCostData(timeRange, setSelectedDERProgCostData);
    } else {
      setSelectedDERProgCostData({ datapoints: {}, error: '' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, assetID, assetClass, scenarioID, analysisName, isNodalAnalysis, timeRange]);

  useEffect(() => {
    if (
      DER_PROGRAM_COST_SUPPORTED.has(assetClass)
      && scenarioID
      && analysisName
      && !isNodalAnalysis
      && asset.container.id
      && assetID
      && maxRange.start
      && maxRange.end
    ) {
      fetchDERProgramCostData(maxRange, setMaxDERProgCostData);
    } else {
      setMaxDERProgCostData({ datapoints: {}, error: '' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, assetID, assetClass, scenarioID, analysisName, isNodalAnalysis, maxRange]);

  async function downloadOperationCostReport() {
    const baseURL = `/api/workspace/${workspace}/branch/${branch}/power-flow-results/operation-costs/report`;
    const params = {
      params: {
        feeder: asset.container.id,
        start_date: timeRange.start.toISOString(true),
        end_date: timeRange.end.toISOString(true),
        asset_id: assetID,
        scenario_id: scenarioID,
        analysis_name: analysisName,
      },
    };
    const request = new Request(baseURL);
    try {
      setCostReportLoadingState(asyncActionStates.LOADING);
      const { data, headers } = await request.getFile(params);
      fileExportSave(data, headers);
      setCostReportLoadingState(asyncActionStates.SUCCESS);
    } catch (err) {
      setCostReportLoadingState(asyncActionStates.ERROR);
    }
  }

  useEffect(
    loadData,
    [
      workspace, branch, assetID, assetClass,
      scenarioID, analysisName, maxRange, timeBarZoomLevel,
      analysisSettings,
      asset, isNodalAnalysis,
    ],
  );

  return (
    <div className="results-violations-panel">
      {!CUSTOM_PANELS.has(assetClass) && !isNodalAnalysis
        && (
        <ResultsSection
          analysisSettings={analysisSettings}
          assetID={assetID}
          assetClass={assetClass}
          asset={asset}
          expanded={expanded}
          maxRange={maxRange}
          timeRange={timeRange}
          newPanelValues={newPanelValues}
          results={results?.[assetID]}
          pqTimeSeriesData={resultData}
          pqTimeSeriesStatus={resultsLoadingState}
          theme={theme}
          workspace={workspace}
          branch={branch}
          scenarioID={scenarioID}
          analysisName={analysisName}
          selectedAnalysis={selectedAnalysis}
          costData={costData}
          commGenCostData={commGenCostData}
          costDataRequestStatus={costLoadingState}
          DERProgramCostData={{
            maxRange: maxDERProgCostData,
            selectedRange: selectedDERProgCostData,
          }}
          downloadOperationCostReport={downloadOperationCostReport}
          operationCostReportStatus={costReportLoadingState}
          violations={violations}
          timeBarZoomLevel={timeBarZoomLevel}
          subHourInterval={subHourInterval}
        />
        )}
      {isNodalAnalysis && assetClass !== 'ConnectivityNode'
        && (
        <div className="error-message">
          This asset has no results associated with the analysis selected.
        </div>
        )}
      {(assetClass === 'ConnectivityNode') && (
        <NodeResultsSection
          nodeID={assetID}
          equipmentContainer={asset.container.id}
          branch={branch}
          expanded={expanded}
          maxRange={maxRange}
          newPanelValues={newPanelValues}
          results={results?.[assetID]}
          scenarioID={scenarioID}
          analysisName={analysisName}
          timeRange={timeRange}
          workspace={workspace}
          violations={violations}
          selectedAnalysis={selectedAnalysis}
          timeBarZoomLevel={timeBarZoomLevel}
          subHourInterval={subHourInterval}
        />
      )}
      {(['PowerTransformer', 'Regulator'].includes(assetClass)) && !isNodalAnalysis && (
        <TransformerResultsSection
          xfmrID={assetID}
          equipmentContainer={asset.container.id}
          asset={asset}
          expanded={expanded}
          maxRange={maxRange}
          timeRange={timeRange}
          newPanelValues={newPanelValues}
          results={results?.[assetID]}
          tapResults={Object.entries(asset?.ratio_tap_changer_attributes ?? {}).reduce((prev, [phase, tc]) => {
            if (results?.[tc.id]) prev[phase] = results[tc.id];
            return prev;
          }, {})}
          pqTimeSeriesData={resultData}
          pqTimeSeriesStatus={resultsLoadingState}
          theme={theme}
          workspace={workspace}
          branch={branch}
          scenarioID={scenarioID}
          analysisName={analysisName}
          violations={violations}
          selectedAnalysis={selectedAnalysis}
          timeBarZoomLevel={timeBarZoomLevel}
          subHourInterval={subHourInterval}
        />
      )}
      {resultsLoadingState === asyncActionStates.LOADING && !isNodalAnalysis
        && (
        <div className="load-message">
          Loading Results
        </div>
        )}
      {resultsLoadingState === asyncActionStates.ERROR && !isNodalAnalysis
        && (
        <div className="error-message">
          Error retrieving results.
        </div>
        )}
      {violationsLoadingState === asyncActionStates.LOADING && !isNodalAnalysis
        && (
        <div className="load-message">
          Loading Violations
        </div>
        )}
      {violationsLoadingState === asyncActionStates.ERROR && !isNodalAnalysis
        && (
        <div className="error-message">
          Error retrieving violations.
        </div>
        )}
      {violationsLoadingState === asyncActionStates.SUCCESS && !isNodalAnalysis
        && (
        <ViolationsSection
          violations={violations}
        />
        )}
    </div>
  );
};

ResultsPanel.propTypes = {
  analysisSettings: PropTypes.object.isRequired,
  asset: PropTypes.object.isRequired, // GET API for the asset
  assetID: PropTypes.string.isRequired,
  assetClass: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  timeRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  newPanelValues: PropTypes.object.isRequired,
  scenarioID: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  analysisName: PropTypes.string.isRequired,
  selectedAnalysis: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  results: PropTypes.object.isRequired,
  subHourInterval: PropTypes.number.isRequired,
};

export default ResultsPanel;
