import React, { FunctionComponent, useContext } from 'react';
import { isEmptyObject } from 'helpers/utils';
import { ThemeProp } from 'types/index';
import HierarchyView from 'components/HierarchyView';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { ScenarioGenerationContext } from '../context/ScenarioGenerationContext';

type ContainerTreeType = {
   id: string,
   name: string,
   children: ContainerTreeType[],
   parent?: null|string,
   substation?: null|string,
   type?: 'Feeder'|'Substation',
};

type FeederPanelProps = {
    containerTree?: ContainerTreeType[],
    theme: ThemeProp,
    pendingReq?: Record<string, unknown>|null,
  };

const FeederPanel: FunctionComponent<FeederPanelProps> = ({
  theme,
  containerTree = [],
  pendingReq,
}) => {
  const { feedersInScenario } = useContext(ScenarioGenerationContext);
  return (
    <div data-test="scenario-generation-feeder-panel" className={`feeder-panel ${theme}`}>
      {pendingReq && !isEmptyObject(pendingReq) ? (
        <LoadingSkeleton template="line" width={100} theme={theme} count={4} />
      ) : (
        <HierarchyView
          theme={theme}
          data={containerTree}
          feedersInScenario={feedersInScenario}
        />
      )}
    </div>
  );
};

export default FeederPanel;
