import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomScrollBar from 'components/CustomScrollBar';
import SearchInput from 'components/SearchInput';
import ThemedTabs from 'components/ThemedTabs';
import asyncActionStates from 'helpers/asyncActionStates';
import { userCanImport } from 'routes/WorkspaceLayout/routes/Network/helpers/EditHelpers';
import nullable from 'helpers/nullablePropType';
import InstanceDropdown from './InstanceDropdown';
import ContainerTab from './ContainerTab';
import AttachmentsLayerToggle from './AttachmentsLayerToggle';
import './NetworkLeftRail.scss';

/**
 * Creates the left rail on the Network view. Contains details about instances of
 * all assets we are currently rendering
 */

const ASSET = 'asset';
const NETWORK = 'network';

class NetworkLeftRail extends Component {
  state = {
    open: [],
    filterString: '',
    showOnlyVisible: false,
    assets: [],
  };

  componentDidMount() {
    this.setState({ assets: this.extractAssets() });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.networkGeoJSON !== this.props.networkGeoJSON) {
      this.setState({ assets: this.extractAssets() });
    }
  }

  /**
   * Set the correct menu state on menu tile click.
   * @param  {String} type CIM class type
   */
  handleMenuClick = (type) => {
    const openState = this.state.open;
    const updatedList = openState.includes(type)
      ? openState.filter(className => className !== type)
      : openState.concat(type);
    this.setState({ open: updatedList });
  };

  extractAssets = () => {
    const lookup = {};
    if (this.props.networkGeoJSON) {
      const assets = [
        ...Object.values(this.props.networkGeoJSON.linkIcons),
        ...Object.values(this.props.networkGeoJSON.nodeIcons),
        ...Object.values(this.props.networkGeoJSON.nodes),
        ...Object.values(this.props.networkGeoJSON.lines),
      ];
      assets.forEach(asset => {
        if (!lookup[asset.properties.display_type]) {
          lookup[asset.properties.display_type] = [];
        }
        lookup[asset.properties.display_type].push(asset);
      });
    }

    // return a list of objects sorted by type
    return Object.keys(lookup)
      .filter(x => lookup[x].length > 0)
      .sort()
      .map(k => ({ type: k, assets: lookup[k] }));
  };

  handlePanelNavClick = (filterString, type) => {
    this.setState({ filterString });
    this.props.actions.setActiveLeftPanel(type);
  };

  handleSearchChange = (e) => {
    e.persist();
    this.setState({ filterString: e.target.value });
  };

  selectTab = (tabIndex) => {
    const type = tabIndex === 0 ? NETWORK : ASSET;
    this.handlePanelNavClick(this.state.filterString, type);
  };

  /* Handle Events */
  handleSearchFilterToggle = () => this.setState(prevState => ({
    showOnlyVisible: !prevState.showOnlyVisible,
  }));

  render() {
    const canImportNetwork = !this.props.isAuthEnabled || userCanImport(this.props.permissions, this.props.branch);
    const assetInstances = this.state.assets;
    return (
      <div className="network-left-rail" id="network-left-rail">
        <ThemedTabs
          theme={this.props.theme}
          className="network-left-rail"
          selectedIndex={this.props.leftRailPanel === NETWORK ? 0 : 1}
          onSelect={this.selectTab}
          underline="line"
          tabs={[
            {
              disabled: false,
              name: 'Network',
            },
            {
              disabled: this.props.selectedFeeders.length === 0,
              name: 'Asset',
            },
          ]}
        >
          {TabPanel => [
            <TabPanel className="left-rail-tab-panel" key="tab-panel-1">
              <ContainerTab
                containerTree={this.props.containerTree}
                containerList={this.props.containerList}
                handleContainerClick={this.props.setSelectedContainer}
                deleteContainer={this.props.actions.deleteContainer}
                openCreateContainerModal={this.props.openCreateContainerModal}
                feedersPending={!!this.props.feedersPending}
                requestStatus={this.props.requestStatus}
                selectedFeeders={this.props.selectedFeeders}
                deleteStatus={this.props.deleteStatus}
                inEditMode={this.props.inEditMode}
                theme={this.props.theme}
                canImportNetwork={canImportNetwork}
                workspace={this.props.workspace}
                branch={this.props.branch}
                permissions={this.props.permissions}
                setFilter={this.props.actions.updateContainerFilter}
                filter={this.props.filter}
                fullLeftRail={this.props.showSearch}
              />
            </TabPanel>,
            <TabPanel className="left-rail-tab-panel asset-panel" key="tab-panel-2">
              {this.props.showSearch && (
                <div className="asset-search-header">
                  <SearchInput
                    onChange={this.handleSearchChange}
                    value={this.state.filterString}
                    placeholder="Search..."
                    theme={this.props.theme}
                  />
                  <div className="search-filter-row">
                    <CustomCheckbox
                      id="filter-search-by-visible"
                      checked={this.state.showOnlyVisible}
                      onClick={this.handleSearchFilterToggle}
                    />
                    <p className="caption-text">See assets based on map view</p>
                  </div>
                  <AttachmentsLayerToggle />
                </div>
              )}
              <div className="asset-selection">
                <CustomScrollBar className="asset-selection-scrollbar">
                  {assetInstances.map(({ type, assets }) => (
                    <InstanceDropdown
                      filterString={this.state.filterString}
                      instanceList={assets}
                      assetsInView={this.props.panelData}
                      showOnlyVisible={this.state.showOnlyVisible}
                      key={type}
                      type={type}
                      openTabs={this.state.open}
                      feeders={this.props.selectedFeeders}
                      selectedAssetID={this.props.selectedAssetID}
                      selectedAssetViewModelClass={this.props.selectedAssetViewModelClass}
                      onMenuClick={this.handleMenuClick}
                      workspace={this.props.workspace}
                      onInstanceHover={this.props.actions.setHoveredObjectID}
                      setSelectedAssetID={this.props.actions.setSelectedAssetID}
                      inEditMode={this.props.inEditMode}
                      assetTypeVisibility={this.props.assetTypeVisibility}
                      setAssetTypeVisibility={this.props.actions.setAssetTypeVisibility}
                    />
                  ))}
                </CustomScrollBar>
              </div>
            </TabPanel>,
          ]}
        </ThemedTabs>
      </div>
    );
  }
}

NetworkLeftRail.defaultProps = {
  panelData: [],
  feedersPending: null,
  showSearch: true,
  deleteStatus: asyncActionStates.INITIAL,
};

NetworkLeftRail.propTypes = {
  leftRailPanel: PropTypes.string.isRequired,
  panelData: PropTypes.array,
  workspace: PropTypes.string.isRequired,
  selectedAssetID: nullable(PropTypes.string).isRequired,
  selectedAssetViewModelClass: nullable(PropTypes.string).isRequired,
  containerTree: PropTypes.array.isRequired,
  containerList: PropTypes.array.isRequired,
  filter: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  setSelectedContainer: PropTypes.func.isRequired,
  selectedFeeders: PropTypes.array.isRequired,
  requestStatus: PropTypes.object.isRequired,
  feedersPending: PropTypes.object,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  networkGeoJSON: nullable(PropTypes.object).isRequired,
  inEditMode: PropTypes.bool.isRequired,
  openCreateContainerModal: PropTypes.func.isRequired,
  assetTypeVisibility: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    setActiveLeftPanel: PropTypes.func,
    setHoveredObjectID: PropTypes.func,
    createContainer: PropTypes.func,
    setAssetTypeVisibility: PropTypes.func,
    updateContainerFilter: PropTypes.func.isRequired,
    deleteContainer: PropTypes.func.isRequired,
    setSelectedAssetID: PropTypes.func.isRequired,
  }).isRequired,
  showSearch: PropTypes.bool,
  deleteStatus: PropTypes.number,
};

export default NetworkLeftRail;
