import React, {
  FunctionComponent, useState, useEffect, ReactChild,
} from 'react';
import { ThemeProp } from 'types/index';
import ReactSelect, { components } from 'react-select';
import IconOption from 'components/IconOption';
import ArrowDown from 'components/Icons/ArrowDown';
import Tooltip from 'components/Tooltip';
import CustomCheckbox from '../CustomCheckbox';
import './Select.scss';

type SelectOption = {
  value: any;
  label: any;
  disabled?: boolean;
}

type SelectProps = {
  value?: any;
  options: SelectOption[];
  defaultOptions?: SelectOption[];
  disabled?: boolean;
  searchable?: boolean;
  clearable?: boolean;
  isMulti?: boolean;
  onChange: (option: any) => void;
  height?: number;
  width?: number;
  onMenuOpen?: (event?: React.FormEvent<HTMLElement>) => void;
  onMenuClose?: (event?: React.FormEvent<HTMLElement>) => void;
  loadOptions?: (event?: React.FormEvent<HTMLElement>) => [];
  async?: boolean;
  tooltip?: string;
  placeholder?: string;
  theme?: ThemeProp;
  className?: string;
  id?: string;
  htmlFor?: string;
  type?: 'default' | 'primary' | 'secondary' | 'text';
  label?: ReactChild;
  labelPosition?: 'top' | 'left';
  invalid?: boolean,
  noOptionsMessage?: string,
  multiSelectType?: 'default' | 'checkbox';
};

const Select: FunctionComponent<SelectProps> = ({
  theme = 'light',
  value = '',
  options = [],
  disabled = false,
  searchable = true,
  clearable = true,
  isMulti = false,
  onMenuOpen = null,
  onMenuClose = null,
  loadOptions = () => [],
  defaultOptions = null,
  async = false,
  tooltip = '',
  placeholder = 'Select...',
  onChange,
  className = '',
  type = 'default',
  id = '',
  htmlFor = '',
  label,
  labelPosition = 'top',
  width = null,
  invalid = false,
  noOptionsMessage = 'No options',
  multiSelectType = 'default',
}) => {
  const themes: any = {
    dark: {
      textColor: '#FFFFFF',
      backgroundColor: 'transparent',
      hoverColor: { default: '#06afa8', primary: '#262626', secondary: '#262626' },
      selectedColor: '#303030',
      borderColor: '#606060',
      disabledColor: '#606060',
      highlightColor: '#262626',
      menuColor: '#303030',
    },
    light: {
      textColor: '#000',
      backgroundColor: '#FFFFFF',
      hoverColor: { default: '#06afa8', primary: '#ECF3FA', secondary: '#ECF3FA' },
      selectedColor: '#FFFFFF',
      borderColor: '#d4d4d4',
      disabledColor: '#cccccc',
      highlightColor: '#ECF3FA',
      menuColor: '#FFFFFF',
    },
  };

  const [selectOptions, setSelectOptions] = useState(defaultOptions || options);
  const [menuOpen, setMenuOpen] = useState(false);
  let actualValue = null;
  if (value instanceof Array) {
    actualValue = value;
  } else {
    actualValue = selectOptions.find(({ value: val }) => val === value) ?? null;
  }
  const onSelectMenuOpen = () => {
    if (async) {
      setSelectOptions(loadOptions());
    }
    setMenuOpen(true);
    return onMenuOpen && onMenuOpen();
  };

  const onSelectMenuClose = () => {
    setMenuOpen(false);
    return onMenuClose && onMenuClose();
  };
  const DropdownIndicator = () => <ArrowDown color={themes[theme].textColor} />;
  const CustomOption = (props: any) => (
    <components.Option {...props}>
      <div className="option-row">
        <div
          title={props.data?.label}
          className="label-row"
        >
          <CustomCheckbox
            id="empty-workspace"
            checked={props.selectProps?.value
              && props.selectProps?.value
                ?.filter((obj: SelectOption) => obj.value === props.data?.value)?.length !== 0}
            type="primary"
          />
          <div className="label-text">{props.data?.label}</div>
        </div>
      </div>
    </components.Option>
  );
  const MultiValueContainer = (props: any) => {
    const selectLabel = props.data?.label;
    const allSelected = props.selectProps?.value;
    const index = allSelected?.findIndex((selected: SelectOption) => selected.label === selectLabel);
    const isLastSelected = index === allSelected?.length - 1;
    const selectedMsg = isLastSelected ? `${allSelected?.length}/${selectOptions?.length} selected` : '';
    return (
      <div className="custom-select-multi-value">
        {selectedMsg}
      </div>
    );
  };
  useEffect(() => {
    if (async && defaultOptions) {
      setSelectOptions(defaultOptions);
    }
  }, [defaultOptions, async]);
  useEffect(() => {
    if (options && !async) {
      setSelectOptions(options);
    }
  }, [options, async]);
  return (
    <div className={`select-dropdown-section label-${labelPosition}`}>
      {label && (
        <label
          htmlFor={htmlFor || id}
          className={`select__label ${type}`}
        >
          {label}
        </label>
      )}
      <Tooltip content={tooltip}>
        <ReactSelect
          id={id}
          className={`select-dropdown ${className} ${type} ${theme}`}
          menuIsOpen={menuOpen}
          value={actualValue}
          isDisabled={disabled}
          onChange={onChange}
          isSearchable={searchable}
          isClearable={clearable}
          isMulti={isMulti}
          onMenuOpen={onSelectMenuOpen}
          onMenuClose={onSelectMenuClose}
          classNamePrefix="custom-select"
          isOptionDisabled={option => option.disabled}
          options={selectOptions}
          closeMenuOnSelect={!isMulti || (isMulti && multiSelectType !== 'checkbox')}
          blurInputOnSelect={!isMulti || (isMulti && multiSelectType !== 'checkbox')}
          hideSelectedOptions={isMulti && multiSelectType !== 'checkbox'}
          components={{
            Option: isMulti && multiSelectType === 'checkbox' ? CustomOption : IconOption,
            DropdownIndicator,
            MultiValueContainer: isMulti && multiSelectType === 'checkbox'
              ? MultiValueContainer : components.MultiValueContainer,
          }}
          placeholder={placeholder}
          noOptionsMessage={() => noOptionsMessage}
          styles={{
            indicatorSeparator: defaults => ({
              ...defaults,
              width: 0,
            }),
            option: (defaults, state) => {
              let color = themes[theme].textColor;
              if (state.isDisabled) {
                color = themes[theme].disabledColor;
              }
              return {
                ...defaults,
                color,
                ':hover': {
                  backgroundColor: type === 'default' ? '#06afa8' : themes[theme].highlightColor,
                },
              };
            },
            menu: defaults => ({
              ...defaults,
              marginTop: '2px',
              padding: '3px 0',
              zIndex: 1000,
              boxShadow:
                theme === 'dark'
                  ? '0 4px 8px -2px #000, 0 19px 38px -2px  #000'
                  : '0 1px 3px rgba(160,160,160,0.7), 0 1px 2px rgba(160,160,160,0.7)',
              borderColor: themes[theme].borderColor,
              backgroundColor: themes[theme].menuColor,
            }),
            control: defaults => ({
              ...defaults,
              backgroundColor:
                theme === 'light' && type === 'primary' ? '#ECF3FA' : themes[theme].backgroundColor,
              borderColor: invalid ? '#FC7169 !important' : themes[theme].borderColor,
              width: width || 'auto',
              boxShadow: 'none',
            }),
            container: defaults => ({
              ...defaults,
              backgroundColor: 'transparent',
              borderColor: themes[theme].borderColor,
              maxWidth: width || 'auto',
            }),
            valueContainer: defaults => ({
              ...defaults,
              padding: '0 8px',
              color: theme === 'dark' ? '#fff' : '#000',
            }),
            clearIndicator: defaults => ({
              ...defaults,
              padding: 0,
            }),
            dropdownIndicator: (defaults, state) => {
              let color = '#0337A4';
              if (state.isDisabled) color = themes[theme].disabledColor;
              return {
                ...defaults,
                paddingLeft: 0,
                color,
              };
            },
            singleValue: defaults => ({
              ...defaults,
              width: 'calc(100% - 15px)',
            }),
            multiValue: styles => ({
              ...styles,
              backgroundColor: theme === 'dark' ? '#606060' : '#fff',
              border: theme === 'dark' ? 'none' : '1px solid #606060',
              borderRadius: '5px',
            }),
            multiValueLabel: styles => ({
              ...styles,
              color: theme === 'dark' ? 'white' : '#606060',
            }),
            multiValueRemove: styles => ({
              ...styles,
              color: theme === 'dark' ? 'white' : '#606060',
              ':hover': {
                color: '#0337A4',
              },
            }),
          }}
          theme={(currentTheme: any) => ({
            ...currentTheme,
            colors: {
              ...currentTheme.colors,
              primary25: themes[theme].hoverColor,
              primary: themes[theme].selectedColor,
              primary50: themes[theme].hoverColor,
              neutral0: themes[theme].backgroundColor.default,
              neutral80: themes[theme].textColor,
            },
          })}
        />
      </Tooltip>
    </div>
  );
};
export default Select;
