import React, { FunctionComponent } from 'react';
import { Moment } from 'moment';
import ResultsCard from 'components/ResultsCard';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import { ThemeProp } from 'types/index';
import { gram } from 'helpers/units';
import { useBaseline } from '../context/ResultsComparisonContext';
import ValueCard from './ValueCard';

type EmissionsProps = {
    theme: ThemeProp,
    workspace: string|null,
    branch: string|null,
    maxRange: {
      start?: Moment|null,
      end?: Moment|null,
    },
    scenario: string|null,
    analysis: {[key: string]: any}|null,
    feeders: [],
    isBaseline: boolean,
};

type EmissionsResults = {
    totals: {
        co2?: number,
        der_co2?: number,
        der_co2_saved?: number,
        der_co2_cost?: number,
        source_co2?: number,
        source_co2_cost?: number,
    };
  };

const EmissionsSummary: FunctionComponent<EmissionsProps> = ({
  theme,
  workspace,
  branch,
  maxRange,
  scenario,
  analysis,
  feeders,
  isBaseline,
}) => {
  const { baseline, setBaseline } = useBaseline<EmissionsResults>('emissionsBaseline');
  const {
    data: emissionsData, loading,
  } = useRequestEffect<EmissionsResults>({
    url: `/api/workspace/${workspace}/branch/${branch}/power-flow-results/emissions/summary`,
    method: 'get',
    params: {
      feeder: feeders?.map((fdr: any) => fdr.id),
      scenario_id: scenario,
      start_date: maxRange?.start?.toISOString(),
      end_date: maxRange?.end?.toISOString(),
      analysis_name: analysis?.name,
    },
    refetchOnChange: [workspace, branch, scenario, analysis?.name, maxRange, feeders],
    blockRequest: () => !(workspace && branch && scenario && analysis?.name && maxRange?.start && maxRange?.end
        && feeders?.length),
    onSuccess: (results) => {
      if (results && isBaseline) {
        setBaseline(results);
      }
    },
  });
  const getTotalCost = (data?: EmissionsResults) => {
    const der_cost = data?.totals?.der_co2_cost ?? 0;
    const sourceCost = data?.totals?.source_co2_cost ?? 0;
    return der_cost + sourceCost;
  };
  // note: we are intentioning nulling falsy values including 0 such that they appear as --
  return (
    <ResultsCard
      theme={theme}
      expandableContents={(
        <>
          <div className="one-one grid-columns">
            <ValueCard
              value={emissionsData?.totals?.der_co2 || null}
              unit={gram}
              label="Distributed energy resources"
              baselineValue={baseline?.totals?.der_co2 || null}
              loading={loading}
            />
            <ValueCard
              value={emissionsData?.totals?.der_co2_cost || null}
              isCurrency
              label="Cost"
              baselineValue={baseline?.totals?.der_co2_cost || null}
              loading={loading}
            />
          </div>
          <div className="one-one grid-columns margin-10">
            <ValueCard
              value={emissionsData?.totals?.source_co2 || null}
              unit={gram}
              label="Bulk resources"
              baselineValue={baseline?.totals?.source_co2 || null}
              loading={loading}
            />
            <ValueCard
              value={emissionsData?.totals?.source_co2_cost || null}
              isCurrency
              label="Cost"
              baselineValue={baseline?.totals?.source_co2_cost || null}
              loading={loading}
            />
          </div>
          <div className="one-one grid-columns margin-10">
            <ValueCard
              value={emissionsData?.totals?.der_co2_saved || null}
              unit={gram}
              label="CO2 Saved"
              baselineValue={baseline?.totals?.der_co2_saved || null}
              loading={loading}
            />
          </div>
        </>
      )}
      className="results-comparison-card summary-card"
    >
      <div className="one-one grid-columns items-centered">
        <ValueCard
          primary
          value={emissionsData?.totals?.co2 || null}
          unit={gram}
          baselineValue={baseline?.totals?.co2 || null}
          showComparisonPercent
          loading={loading}
          label="CO2 emissions"
        />
        <ValueCard
          primary
          value={getTotalCost(emissionsData) || null}
          isCurrency
          baselineValue={getTotalCost(baseline) || null}
          showComparisonPercent
          loading={loading}
          label="CO2 cost"
        />
      </div>
    </ResultsCard>
  );
};

export default EmissionsSummary;
