import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import nullable from 'helpers/nullablePropType';
import moment from 'moment';
import classNames from 'classnames';
import { Request, apm } from '@opusonesolutions/gridos-app-framework';

import asyncActionStates from 'helpers/asyncActionStates';
import Analytics from 'helpers/Analytics';
import fileDownload from 'helpers/FileDownload';
import { displayAsBuilt } from 'helpers/utils';

import FeederPanel from '../FeederPanel';
import SubstationPanel from '../SubstationPanel';
import PanelHeader from '../PanelHeader';
import MultiContainerPanel from './MultiContainerPanel';
import ContainerHeader from './ContainerHeader';

import './ContainerPanel.scss';

const CONTAINER_PANELS = {
  Substation: {
    panel: SubstationPanel,
    icon: '/substation.svg',
  },
  Feeder: {
    panel: FeederPanel,
    icon: '/ic_feeder.svg',
  },
};

const PERMISSIONS_TO_DELETE = {
  master: 'delete_feeder_as_built',
  default: 'delete_feeder',
};

const deletePermission = branch => PERMISSIONS_TO_DELETE[branch] || PERMISSIONS_TO_DELETE.default;

const ContainerPanel = ({
  selectedContainers,
  selectedScenario,
  selectedScenarioType,
  selectedAnalysis,
  subHourInterval,
  timeRange,
  maxRange,
  timeBarZoomLevel,
  showModal,
  onClose,
  containers,
  permissions,
  isAuthEnabled,
  workspace,
  branch,
  status,
  inEditMode,
  theme,
  actions,
  timepoints,
  expanded,
  allBatterySizingData,
  selectedAssetID,
  selectedAssetViewModelClass,
}) => {
  const [selectedContainer, setSelectedContainer] = useState(null);

  const [container] = selectedContainer ?? [];
  const panelConfig = CONTAINER_PANELS[container?.type];
  const Panel = panelConfig?.panel;

  const permissionToDeleteFeeders = !isAuthEnabled || permissions.has(deletePermission(branch));
  const handleDeleteFn = permissionToDeleteFeeders ? () => showModal(container) : null;

  useEffect(() => {
    if (selectedContainers.length === 1) {
      setSelectedContainer(selectedContainers);
    } else if (!selectedContainer || (selectedContainer && selectedContainer.length >= 1
      && !selectedContainers.some((con) => con.id === selectedContainer[0]?.id))) {
      setSelectedContainer([]);
    }
  }, [selectedContainer, selectedContainers]);

  const downloadReport = async (baseURL, additionalParams = null) => {
    const request = new Request(baseURL);
    let result;

    try {
      const { data, headers } = await request.getFile({
        params: {
          feeder: selectedContainer.map(fdr => fdr.id),
          scenario_id: selectedScenario,
          ...(additionalParams ?? {}),
        },
      });
      fileDownload(data, headers);
    } catch (err) {
      apm.captureError(err);
      result = err.response?.status;
    }
    return result;
  };

  const downloadPowerFlowReport = async () => {
    if (selectedContainer.length === 1) {
      const baseURL = `/api/workspace/${workspace}/branch/${branch}/powerflow/report`;
      Analytics.logEvent('Downloaded Powerflow Report', 'Analysis');

      await downloadReport(baseURL, {
        analysis_name: selectedAnalysis.name,
        start_date: timeRange.start.toISOString(),
        end_date: timeRange.end.toISOString(),
      });
    }
  };

  const generatePowerflowReport = async () => {
    if (selectedContainer.length === 1) {
      const baseURL = `/api/workspace/${workspace}/branch/${branch}/powerflow/report/schedule`;
      Analytics.logEvent('Generate Hourly Powerflow Report', 'Analysis');
      const request = new Request(baseURL);
      await request.post({}, {
        params: {
          feeder: selectedContainer.map(fdr => fdr.id),
          scenario_id: selectedScenario,
          analysis_name: selectedAnalysis.name,
          start_date: timeRange.start.toISOString(),
          end_date: timeRange.end.toISOString(),
        },
      });
      actions.setActivePanel('activityLog');
    }
  };

  const downloadHostingCapacityReport = async () => {
    if (selectedContainer.length === 1) {
      const baseURL = `/api/workspace/${workspace}/branch/${branch}/hosting-capacity-results/report`;
      Analytics.logEvent('Downloaded Hosting Capacity Report', 'Analysis');

      await downloadReport(baseURL, {
        analysis_name: selectedAnalysis.name,
        start_date: timeRange.start.toISOString(),
        end_date: timeRange.end.toISOString(),
      });
    }
  };

  const downloadCostTrackingReport = async () => {
    if (selectedContainer.length === 1) {
      const baseURL = `/api/workspace/${workspace}/branch/${branch}/track`;
      Analytics.logEvent('Downloaded Cost Tracking Report', 'Analysis');
      const result = await downloadReport(baseURL);
      if (result === 404) {
        actions.displayAlertMessage(
          'Download Network Change and Costing',
          `No changes have been made to the network in the ${displayAsBuilt(branch)} network version so no report is available.`,
        );
      }
    }
  };

  useEffect(() => {
    if (selectedContainer?.length === 1) {
      actions.getNodeSubstationDistances(
        workspace, branch, selectedContainer[0].id,
      );
    }
  }, [workspace, branch, selectedContainer, actions]);

  const { start, end } = timeRange;
  const anyResults = timepoints.results?.some(val => moment(val).isBetween(start, end, null, '[]')) ?? false;
  const containerInAnalysis = selectedContainer && selectedContainer.length !== 0
    && selectedAnalysis?.containers?.includes(selectedContainer[0]?.id);
  return (
    <div className="container-panel">
      <div
        className={classNames({
          'container-panel-header': true,
          'container-panel-header--expanded': expanded,
        })}
      >
        { selectedContainers.length > 1 && (
          <MultiContainerPanel
            containers={selectedContainers}
            selectedContainer={selectedContainer}
            setSelectedContainer={setSelectedContainer}
            theme={theme}
          />
        )}
        { selectedContainer && container && (
        <PanelHeader
          name={(
            <ContainerHeader
              container={container}
              renameContainer={name => actions.updateContainer(container.type, container.id, { name }, 'renameContainer')}
              branch={branch}
              inEditMode={inEditMode}
              permissions={permissions}
              theme={theme}
              toggleEditMode={actions.toggleEditMode}
              deleteFn={handleDeleteFn}
              loading={
                (status.renameContainer || status.updateFeeder) === asyncActionStates.LOADING
              }
            />
        )}
          type={container.type}
          icon={panelConfig.icon}
          showClose={false}
          onClose={() => onClose(null)}
          className="support-tabs"
          customIconClass="teal"
          id={container.id}
        />
        )}
      </div>
      { selectedContainer && selectedContainer.length === 1 && (
        <Panel
          theme={theme}
          showModal={showModal}
          selectedContainers={selectedContainer}
          selectedAnalysis={selectedAnalysis}
          subHourInterval={subHourInterval}
          selectedScenario={selectedScenario}
          selectedScenarioType={selectedScenarioType}
          containers={containers}
          downloadHostingCapacityReport={downloadHostingCapacityReport}
          downloadCostTrackingReport={downloadCostTrackingReport}
          downloadPowerFlowReport={downloadPowerFlowReport}
          generatePowerflowReport={generatePowerflowReport}
          anyResults={anyResults}
          containerInAnalysis={containerInAnalysis}
          permissions={permissions}
          isAuthEnabled={isAuthEnabled}
          expanded={expanded}
          workspace={workspace}
          branch={branch}
          inEditMode={inEditMode}
          refreshNetworkData={actions.loadNetworkData}
          timeRange={timeRange}
          maxRange={maxRange}
          timeBarZoomLevel={timeBarZoomLevel}
          allBatterySizingData={allBatterySizingData}
          selectedAssetID={selectedAssetID}
          selectedAssetViewModelClass={selectedAssetViewModelClass}
          updateSelectedScenario={actions.updateSelectedScenario}
        />
      )}
    </div>
  );
};

ContainerPanel.propTypes = {
  selectedContainers: PropTypes.array.isRequired,
  showModal: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  containers: PropTypes.array.isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  status: PropTypes.object.isRequired,
  timeRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  timepoints: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  selectedScenario: PropTypes.string.isRequired,
  selectedScenarioType: PropTypes.string.isRequired,
  selectedAnalysis: nullable(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
  subHourInterval: PropTypes.number.isRequired,
  expanded: PropTypes.bool.isRequired,
  allBatterySizingData: PropTypes.object.isRequired,
  selectedAssetID: nullable(PropTypes.string).isRequired,
  selectedAssetViewModelClass: nullable(PropTypes.string).isRequired,
};

export default ContainerPanel;
