import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { apm } from '@opusonesolutions/gridos-app-framework';

import nullable from 'helpers/nullablePropType';
import asyncActionStates from 'helpers/asyncActionStates';
import Modal from 'components/Modal';
import TextInput from 'components/TextInput';
import { updateLayerStorage } from 'routes/WorkspaceLayout/routes/Network/helpers/VisualizationHelpers';
import ThreePanelLayout from 'layouts/ThreePanelLayout';
import AssetViolationsProvider from 'contexts/AssetViolationsContext';
import ImportsContextProvider from 'routes/ImportCIM/modules/importsContext';
import AttachmentsProvider from 'contexts/AttachmentsContext';
import ActivityLogContextProvider from 'contexts/ActivityLogContext';

import GISPanel from './GISPanel';
import SlidingInfoPanel from '../containers/SlidingInfoPanelContainer';
import NetworkTopNav from '../../../containers/NetworkTopNavContainer';
import NetworkBottomBar from '../containers/NetworkBottomBarContainer';
import NetworkLeftRail from '../containers/NetworkLeftRailContainer';
import '../styles/Network.scss';

class Network extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      createContainerOpen: false,
      invalidNewContainerName: true,
      newContainerName: '',
    };

    props.actions.setCurrentWorkspace(props.match.params.workspace);
  }

  componentDidMount() {
    const { workspace } = this.props.match.params;
    this.props.actions.setView('gis');

    this.props.actions.fetchBranches(workspace);

    const { branch } = this.props.match.params;
    const feederIDs = this.props.match.params.feeder_ids;
    const activeFeeders = feederIDs ? feederIDs.split(',') : [];
    this.props.actions.checkCoordinateSystem(workspace, branch);
    this.props.actions.updateSelectedBranch(branch, activeFeeders);
  }

  componentDidUpdate(prevProps) {
    const oldNetwork = prevProps.network.lookup;
    const newNetwork = this.props.network.lookup;
    const newSelected = this.props.feeders.selected;
    const newFeederList = this.props.feeders.list;

    const feederInstances = this.props.network.networkInstance?.feeders || {};
    const feederIDs = this.props.match.params.feeder_ids;
    const { asset } = this.props.match.params;
    const { selectedAssetID } = this.props.network;

    // If the network has reloaded, set the map center and check if there should be
    // a selected asset.
    if (oldNetwork !== newNetwork && feederInstances) {
      if (asset && !selectedAssetID && newNetwork[this.props.match.params.asset]) {
        this.props.actions.setSelectedAssetID(this.props.match.params.asset);
      }
    }

    // If there are feeder ids in the url but are not selected in state, update feeder selection
    // This state only happens when there is a selected feeder on load
    if (
      feederIDs
      && newFeederList.length
      && !newSelected.length
      && !Object.keys(feederInstances).length
    ) {
      const { requestStatus } = this.props.network;
      const paramFeeders = feederIDs.split(',');
      // Make sure the feeders haven't already errored out. This catches really quick
      // failure responses
      const updatedSelected = this.props.feeders.list.filter(
        fdr => paramFeeders.includes(fdr.id)
          && requestStatus[fdr.id] !== asyncActionStates.ERROR,
      );

      if (updatedSelected.length) {
        this.props.actions.overrideSelectedContainers(updatedSelected);
      }
    }

    // Allow users to use forward and back navigation to reselect previous assets
    const newAssetParam = asset !== prevProps.match.params.asset;
    const newAssetInstance = (selectedAssetID && asset !== selectedAssetID) || !selectedAssetID;
    const notPushAction = this.props.location.action === 'POP'
      || this.props.location.action === 'REPLACE';
    if (newAssetParam && newAssetInstance && notPushAction) {
      if (asset) {
        this.props.actions.setSelectedAssetID(this.props.match.params.asset);
      } else {
        this.props.actions.setSelectedAssetID(null);
      }
    }

    if (prevProps.network.workspace !== this.props.network.workspace
      || prevProps.network.displayBranch !== this.props.network.displayBranch
      || prevProps.loadForecast.selectedScenario !== this.props.loadForecast.selectedScenario
      || prevProps.loadForecast.selectedAnalysis?.id !== this.props.loadForecast.selectedAnalysis?.id
    ) {
      if (prevProps.layerOptionsUpdated && prevProps.loadForecast.selectedAnalysis) {
        updateLayerStorage(
          prevProps.inEditMode,
          prevProps.network.branch,
          prevProps.network.workspace,
          prevProps.loadForecast.selectedScenario,
          prevProps.loadForecast.selectedAnalysis.id,
          prevProps.layerOptions,
          prevProps.network.displayBranch,
        );
      }
    }

    if (this.props.loadForecast.selectedAnalysis && (
      prevProps.loadForecast.selectedAnalysis?.id
      !== this.props.loadForecast.selectedAnalysis?.id
    )) {
      this.props.actions.getLayerOptions();
    }
  }

  componentDidCatch(err) {
    this.setState({ error: true });
    apm.captureError(err);
  }

  componentWillUnmount() {
    const { workspace } = this.props.match.params;
    this.props.actions.clearNetworkData();
    this.props.actions.setCurrentWorkspace(workspace);
  }

  getSelectedFeeders = selected => {
    const feeders = this.props.match.params.feeder_ids;
    if (selected.length) {
      return selected;
    } if (
      feeders
      && feeders.length
      && Object.keys(this.props.network.lookup).length === 0
    ) {
      return feeders.split(',');
    }
    return [];
  };

  getMap = () => this.props.network.map;

  handleCloseNewContainerModal = () => {
    this.setState({ createContainerOpen: false });
  };

  handleContainerCreation = () => {
    this.props.actions.createContainer(
      this.props.network.workspace,
      this.props.network.branch,
      this.state.newContainerName,
      this.state.containerType,
      this.state.substation,
    );
    this.setState({ createContainerOpen: false, newContainerName: '' });
  };

  handleContainerNameChange = ({ target }) => {
    const name = target.value;
    const invalidNewContainerName = name.length === 0 || name.match(/[^A-Za-z0-9-_]/g) !== null;
    this.setState({
      newContainerName: name,
      invalidNewContainerName,
    });
  };

  handleContainerNameKeyPress = e => {
    if (e.charCode === 13) {
      this.handleContainerCreation();
    }
  };

  openCreateContainerModal = (containerType, substation) => () => {
    this.setState({ createContainerOpen: true, containerType, substation });
  };

  render() {
    const { selected } = this.props.feeders;
    const { params } = this.props.match;
    const workspaceName = this.props.match.params.workspace;
    const canCreateContainer = !this.props.isAuthEnabled || this.props.permissions.has('modify_network');
    const containerNameInvalid = this.state.invalidNewContainerName
      && this.state.newContainerName
      && this.state.newContainerName.trim().length;

    return (
      <AssetViolationsProvider>
        <AttachmentsProvider
          workspace={params.workspace}
          branch={params.branch}
        >
          <ImportsContextProvider workspace={params.workspace} branch={params.branch}>
            <ActivityLogContextProvider workspace={params.workspace} branch={params.branch}>
              <ThreePanelLayout
                className="gis-container"
                rightPanelOpen={this.props.network.panelOptions?.open}
                rightPanelExpanded={this.props.network.panelOptions?.expanded}
                renderHeaderContent={() => <NetworkTopNav params={params} />}
                renderFooterContent={() => <NetworkBottomBar />}
                renderLeftContent={() => (
                  <NetworkLeftRail
                    workspace={workspaceName}
                    setSelectedContainer={this.props.actions.setSelectedContainers}
                    inEditMode={this.props.inEditMode}
                    openCreateContainerModal={this.openCreateContainerModal}
                  />
                )}
                renderMainContent={() => (
                  <GISPanel
                    {...this.props}
                    selectedFeeders={this.getSelectedFeeders(selected)}
                    invalidCoordinateSystem={
                      this.props.network.invalidCoordinateSystem
                    }
                    error={this.state.error}
                  />
                )}
                renderRightContent={() => (
                  <SlidingInfoPanel
                    workspace={workspaceName}
                    getMap={this.getMap}
                    match={this.props.match}
                  />
                )}
                renderModals={() => (
                  <>
                    <div id="modal-portal" />
                    {canCreateContainer && (
                      <Modal
                        active={this.state.createContainerOpen}
                        onCancel={this.handleCloseNewContainerModal}
                        onConfirm={this.handleContainerCreation}
                        width="320px"
                        title={`Create ${this.state.containerType}`}
                        theme={this.props.theme}
                        disableConfirm={this.state.invalidNewContainerName}
                      >
                        <div className="create-modal">
                          <label htmlFor="branch-name">
                            {`Enter New ${this.state.containerType} Name:`}
                          </label>
                          <TextInput
                            id="branch-name"
                            value={this.state.newContainerName}
                            onChange={this.handleContainerNameChange}
                            onKeyPress={this.handleContainerNameKeyPress}
                            required
                            invalid={containerNameInvalid}
                            validationMessage="Invalid name. Name must be at least 1 character and can
                            not include special characters or spaces."
                            theme={this.props.theme}
                          />
                        </div>
                      </Modal>
                    )}
                  </>
                )}
                showFooter={!this.props.inEditMode}
                showHeader
              />
            </ActivityLogContextProvider>
          </ImportsContextProvider>
        </AttachmentsProvider>
      </AssetViolationsProvider>
    );
  }
}

Network.propTypes = {
  network: PropTypes.shape({
    map: PropTypes.object,
    lookup: PropTypes.object,
    workspace: PropTypes.string,
    branch: PropTypes.string.isRequired,
    displayBranch: PropTypes.string,
    selectedAssetID: PropTypes.string,
    panelOptions: PropTypes.object,
    networkInstance: PropTypes.object,
    invalidCoordinateSystem: PropTypes.bool,
    requestStatus: PropTypes.object,
  }).isRequired,
  feeders: PropTypes.shape({
    list: PropTypes.array,
    selected: PropTypes.array,
    pendingReq: PropTypes.shape({
      feederReq: PropTypes.object,
    }),
  }).isRequired,
  loadForecast: PropTypes.shape({
    selectedScenario: PropTypes.string,
    selectedAnalysis: nullable(PropTypes.object).isRequired,
  }).isRequired,
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  layerOptionsUpdated: PropTypes.bool.isRequired,
  layerOptions: PropTypes.object.isRequired,
};

export default Network;
