import React, { FunctionComponent } from 'react';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';

import Button from 'components/Button';
import Card from 'components/Card';
import LoadingSpinner from 'components/LoadingSpinner';
import { ThemeProp } from 'types';

import './Login.scss';

type LoginProps = {
  theme: ThemeProp;
}

const Login: FunctionComponent<LoginProps> = ({ theme }) => {
  const { isAuthenticated, isLoading, login } = useAuthContext();

  return (
    <div className="login-container">
      {!isLoading && !isAuthenticated && (
        <Card className="center-modal" hideTitle theme={theme}>
          <div className="logo-container">
            <img
              src={theme === 'dark' ? '/gridOS-white-red.png' : '/gridos_logo_light.png'}
              alt="gridos-logo"
              className="gridos-logo"
            />
          </div>
          <div className="contents-container">
            <Button
              className="login-button"
              onClick={() => login()}
              theme={theme}
            >
              Login
            </Button>
          </div>
        </Card>
      )}
      {(isLoading || isAuthenticated) && (
        <div className="contents-container">
          <LoadingSpinner type="circles" />
        </div>
      )}
    </div>
  );
};

export default Login;
