import React, { FunctionComponent, useState, useContext } from 'react';
import { ThemeProp } from 'types/index';
import { useRequest } from '@opusonesolutions/gridos-app-framework';
import Modal from 'components/Modal';
import { CustoTypeSettings } from 'types/edit';
import { isDefinedNonEmpty } from 'helpers/utils';
import moment from 'moment';
import {
  assetTypes, modalTypes, assetProperties, assetTypesStataus,
} from '../helpers/ScenarioGenerationHelpers';
import ScheduleBulkEVSettings from './ScheduleBulkEVSettings';
import { ScenarioGenerationContext } from '../context/ScenarioGenerationContext';

type BulkScheduleModalProps = {
    workspace: string,
    branch: string,
    theme: ThemeProp,
    scenario?: {[key: string]: string}|null,
    selectedContainer?: string|null,
    bulkScheduleGenLoading: assetTypesStataus,
    setBulkScheduleGenLoading: (value: assetTypesStataus) => void,
    selectedAssetType: assetTypes,
    assetList?:[{ [key: string]: string }],
};

const BulkScheduleModal: FunctionComponent<BulkScheduleModalProps> = ({
  theme,
  workspace,
  branch,
  selectedContainer,
  scenario,
  bulkScheduleGenLoading,
  setBulkScheduleGenLoading,
  selectedAssetType,
  assetList = [],
}) => {
  const [settingsByCustoType, setSettingsByCustoType] = useState<CustoTypeSettings>({});
  const { modalActiveType, resetModalType } = useContext(ScenarioGenerationContext);
  const {
    makeRequest: bulkScheduleRequest,
  } = useRequest(
    `/api/workspace/${workspace}/branch/${branch}/asset_schedules/generate_schedule`,
  );
  const generateBulkAssetSchedule = async () => {
    const evParams = selectedAssetType === assetTypes.ev ? {
      info_by_load_type: Object.entries(settingsByCustoType).map(([key, evSettings]) => ({
        load_type: key,
        ...evSettings,
      })),
    } : {};
    try {
      resetModalType();
      await bulkScheduleRequest({
        method: 'post',
        body: {
          scenario_id: scenario?.value,
          ...evParams,
        },
        params: {
          feeder: selectedContainer,
          scenario_id: scenario?.value,
          asset_type: assetProperties[selectedAssetType].class,
          schedule_type: selectedAssetType === assetTypes.ev ? 'Global' : 'Normal',
          shift_tz: selectedAssetType !== assetTypes.ev,
        },
        toast: {
          error: 'Could not create bulk schedule.',
          settings: {
            autoDismiss: true,
          },
        },
        onError: () => {
          setBulkScheduleGenLoading(({ ...bulkScheduleGenLoading, [selectedAssetType]: false }));
        },
      });
    } catch (error) {
    }
  };
  return (
    <Modal
      active={modalActiveType === modalTypes.bulkSchedule}
      title={`Bulk Generate Schedules - ${assetProperties[selectedAssetType].label}`}
      onConfirm={() => {
        setBulkScheduleGenLoading(({ ...bulkScheduleGenLoading, [selectedAssetType]: true }));
        generateBulkAssetSchedule();
      }}
      onCancel={() => resetModalType()}
      disableConfirm={
        selectedAssetType === assetTypes.ev && (Object.keys(settingsByCustoType).length === 0
        || Object.values(settingsByCustoType).some((type) => {
          const windowTimeDiff = moment.duration(moment(type.end_time, 'HH:mm').diff(moment(type.start_time, 'HH:mm')));
          const windowTimeDiffTaken = windowTimeDiff.asMinutes() === 59
            ? 60 : windowTimeDiff.asMinutes();
          return (
            !type.start_time
            || !type.end_time
            || type.start_time > type.end_time
            || !isDefinedNonEmpty(type.min_events)
            || type.min_events < 0
            || type.min_events > type.max_events
            || !type.max_events
            || type.max_events > 100
            || !type.min_charge_length
            || !type.max_charge_length
            || type.min_charge_length > type.max_charge_length
            || moment.duration(type.max_charge_length).asMinutes() > windowTimeDiffTaken
          );
        })
        )
      }
      theme={theme}
      modalType="primary"
      width="800px"
      height={selectedAssetType === assetTypes.pv ? 'auto' : '700px'}
      labels={{
        confirm: bulkScheduleGenLoading[selectedAssetType] ? <i className="material-icons rotate">refresh</i> : 'Generate now',
        cancel: 'Discard',
      }}
      scrollBody
    >
      <div>
        { selectedAssetType === assetTypes.pv && (
          <p className="modal-message__p pv-asset-msg">
            PV Assets with existing schedule will be replaced with new schedule.
          </p>
        )}
        { selectedAssetType === assetTypes.ev && (
          <ScheduleBulkEVSettings
            theme={theme}
            settingsByCustoType={settingsByCustoType}
            setSettingsByCustoType={setSettingsByCustoType}
            evAssetList={assetList}
          />
        )}
      </div>
    </Modal>
  );
};

export default BulkScheduleModal;
